@media screen and (min-width: 1600px) {
	.blocks-list-item:not(a) {
		display: inline;
	}
	.backgroundImage {
		right: 1240px
	}
	.magazineContent {
		width: 1240px
	}
	.magazineContent.magazineCover {
		width: 565px;
	}
	.backgroundImage.magazineCover {
		left: 565px;
	}
	.magazineCover .contentWrap {
		width: 435px;
	}
	.magazineCover .contentWrap .subTitle {
		font-size: 70px;
		line-height: 70px;
	}
	.magazineCover .contentWrap .magazine h1 {
		font-size: 40px;
	}
	.magazineCover .tableContent table tr td {
		padding: 5px 65px;
	}
	.magazineCover .nextLesson h1 {
		font-size: 30px;
	}
	.magazineCover .nextLesson {
		width: 434px;
		height: 200px;
	}
	.magazineCover .nextLesson .nextLessonImg img {
		width: 125px;
		display: inline-block;
		height: 125px;
	}
	.magazineCover .nextLesson .lessonText {
		background: rgba(40, 40, 40, 1);
		height: 125px;
		width: 308px;
		left: 125px;
	}
	.magazineCover .nextLesson .nextLessonImg {
		height: 125px;
		width: 125px;
	}
	.magazineCover .nextLesson .lessonTitle {
		font-size: 21px;
		margin-top: 18px;
	}
	.magazineCover .nextLesson .lessonDesc {
		font-size: 22px;
	}
	.magazineCover .tableContent table tr th {
		font-size: 30px;
	}
	.loader-circle .timer {
		font-size: 226px;
		width: 226px;
		height: 226px;
	}
	.loader-circle .timer>.slice {
		width: 226px;
		height: 226px;
	}
	.loader-circle .timer>.slice>.pie {
		border: 8px solid #008a8d;
		width: 210px;
		height: 210px;
		clip: rect(0, 113px, 226px, 0);
	}
	.loader-circle .timer.fill>.slice>.pie {
		width: 226px;
		height: 226px
	}
	.loader-circle {
		width: 226px;
		height: 226px
	}
	.loader-circle .inner {
		width: 210px;
		height: 210px;
		line-height: 210px;
		border: 8px solid rgba(255, 255, 255, .3);
	}
	.loader-circle .inner .text {
		font-size: 25px;
		letter-spacing: 2px;
		font-weight: 700
	}
	.filmles.magazineContent .sceneList {
		width: 968px
	}
	.filmles.backgroundImage {
		width: 100%!important;
		overflow: hidden;
		top: auto;
		left: auto;
		right: auto
	}
	.filmles.backgroundImage .image-text .subTitle {
		max-width: 927px;
		width: 927px;
		line-height: 68px;
		font-size: 68px;
		letter-spacing: 2px;
	}
	.filmles.magazineContent .content {
		max-width: 100%;
		display: inline-block;
		padding-top: 0;
		width: 100%
	}
	.filmles.magazineContent .content .magazine {
		margin-top: 20px;
		color: rgba(160, 130, 80, 1)
	}
	.filmles.backgroundImage .image-text .magazine h1 {
		color: rgba(160, 130, 80, 1);
		font-size: 40px
	}
	.summary.magazineContent {
		width: 1240px;
	}
	.wvProductBlocks .productBlock .col {
		padding: 53px 10px;
	}
	.wvProductBlocks .productBlock.firstBlock .innerBlock,
	.wvProductBlocks .productBlock .innerBlock {
		max-width: 1300px;
	}
	.wvProductBlocks .productBlock .textCol .colContent {
		max-width: 500px
	}
	.wvProductBlocks .productBlock.firstBlock .innerBlock .col .colContent .text p {
		max-width: 500px;
		margin: 0 auto;
	}
	.wvProductBlocks .productBlock .subTitle {
		font-size: 30px;
	}
	.wvProductBlocks .productBlock.firstBlock .title {
		font-size: 60px;
		line-height: 60px;
	}
	.wvProductBlocks .colContent .text {
		width: 600px;
		line-height: 30px;
	}
	.wvProductBlocks .firstBlock .colContent .text {
		width: 450px;
		margin: 0 auto;
	}
	.wvProductBlocks .productBlock .productImg img {
		max-height: 597px;
	}
	.wvProductBlocks .productBlock:not(.firstBlock) .text p {
		width: 590px;
	}
	.wvProductBlocks .productBlock.left:not(.firstBlock) .text p {
		width: 600px;
	}
	.wvProductBlocks .productBlock .productImg {
		width: 597px;
		height: 597px;
		max-width: 597px;
		max-height: 597px;
	}
	.wvProductBlocks .productBlock.firstBlock .productImg {
		width: 597px;
		height: 597px;
	}
	.wvProductBlocks .productBlock .text p {
		max-width: 600px;
		width: 600px;
	}
	.wvStappenplan .productBlock .title {
		font-size: 30px;
	}
	.content .intro {
		max-width: 550px;
	}
	.content .intro .introText {
		font-size: 18px;
	}
	.content .intro .introTitle {
		font-size: 30px;
	}
	.content .title span {
		font-size: 100px;
	}
	.content .title {
		height: 100px;
		line-height: 100px;
	}
	.content table.inputs.followup {
		width: 932px !important;
		border-spacing: 0px 0px;
	}
	.content .subTitleContent .date {
		font-size: 30px;
	}
	.content .title.hasSub {
		height: 140px;
	}
	.wvInterviewContent .contentCol {
		margin-top: -97px;
	}
	.wvInterviewContent {
		top: -300px;
	}
	.profile .title span {
		font-size: 100px !important;
	}
	.wvBaseContainer .content.profile .title {
		max-width: 720px;
		line-height: 100px;
	}
	.content.addModel .profileDetails {
		text-align: center;
	}
	.content.addModel .profileDetails .cropImageWrap {
		margin: 45px 15px;
		display: inline-block;
	}
	.tags {
		text-align: center;
	}
	.content.addModel .title {
		margin-top: 25px;
		max-width: 850px;
	}
	.content.addModel .subText {
		padding: 0px 30px;
	}
	.content.addModel .subText p {
		font-size: 17px;
	}
	.smallHeader {
		font-size: 20px;
	}
	.toetsContent .werkvormWrap.large {
		width: 900px;
	}
	.toetsContent.cover .intro p {
		font-size: 18px;
	}
	.toetsContent .intro h1 {
		font-size: 450px;
		height: 475px;
	}
	.toetsContent .intro h2 {
		font-size: 100px;
	}
	.videoContainer .nextLesson h1 {
		font-size: 25px !important;
	}
	.topWrap {
		max-width: 350px !important;
	}
	h1.magazineTitle {
		font-size: 70px !important;
	}
	.subtitleData .data {
		font-size: 26px !important;
	}
	.searchOverlay {
		width: 1026px;
		height: 768px;
		left: 50%;
		margin-left: -513px;
		top: 30px;
	}
	.trainingBlocks {
		width: 920px;
		text-align: left;
	}
	.profileSection.right {
		width: 580px !important;
	}
	.profile .profileBlock {
		width: 100%;
		position: relative;
	}
	.profile .legenda {
		display: inline-block;
		width: 100px;
		position: absolute;
		top: 50px;
		right: 0;
		text-align: left;
	}
	.profile .profileSection.right .profileBlock {
		width: 500px !important;
		text-align: right;
		display: inline-block;
	}
	.profile .profileSection.right .profileBlockTitle {
		width: 500px !important;
		margin-bottom: 0px !important;
	}
	.profile .legenda {
		display: inline-block;
		margin-top: 7px;
		width: 100px;
		text-align: left;
		float: right;
	}
	.profile .legenda .item {
		display: inline-block;
		margin: 0px 5px 5px;
	}
	.profile .legenda {
		margin-top: -7px;
	}
	.profile .activities .activityGroup {
		background-color: #f8f8f8;
	}
	.profile .activities .activity {
		width: 245px;
		float: left;
	}
	.profile .activities .activity.right {
		float: right;
	}
	.profile .activities .activity:after {
		clear: both;
		content: " ";
		display: none;
	}
	.profile .activityGroup.last .activity {
		border-bottom: 0;
	}
	.blocks-list {
		max-width: 900px;
		text-align: left;
	}
	.blocks-list-item {
		width: 432px;
		display: inline-block;
		text-align: left;
	}
	.blocks-list-item .blocks-list-item-inner {
		border-top: 1px solid #a7a7a7;
	}
	.blocks-list-item:nth-child(1n+3) .blocks-list-item-inner {
		border-top: 0;
	}
	.blocks-list-item:nth-child(2n+2) {
		margin-left: 33px;
	}
	.desktopOnly {
		display: block;
	}
	.mainPortal .footer .pretext {
		color: $darkColor;
		font-size: 18px;
		line-height: 32px;
		margin: 0px 150px;
		float: left;
	}
	.mainPortal .headImage .tagline.large {
		font-size: 100px;
		line-height: 115px;
		width: 550px;
	}
	.mainPortal .userAction {
		right: 127px;
		margin-top: 165px;
	}
	.mainPortal .container {
		width: auto;
		padding: 0px 130px;
	}
	.mainPortal .container .infoText {
		margin: 40px auto 0;
	}
	.mainPortal .bodyObject {
		width: 49%;
		display: inline-block;
		float: right;
		margin: 5px;
		padding: 80px;
	}
	.mainPortal .action .bodyObject {
		float: none;
		margin-top: 220px;
		margin-left: 180px;
		width: 645px;
	}
	.mainPortal.register .action .bodyObject {
		margin-top: 130px;
	}
	.mainPortal .action .userAction {
		top: 20%;
	}
	.mainPortal .action .checklist .item {
		font-size: 28px;
		line-height: 38px;
	}
	.mainPortal .action .headImage {
		right: 150px;
		left: calc(100% - 485px);
	}
	.mainPortal .action .container.main p {
		font-size: 22px;
		line-height: 34px;
	}
	.mainPortal .action .container.main strong,
	.mainPortal .action .container b {
		font-size: 34px;
		line-height: 38px;
	}
	.mainPortal.login .action .bodyObject {
		width: 645px;
		margin-top: 220px;
	}
	.content .wvOptions {
		width: 100%;
		padding: 0px 50px;
	}
	.content .wvPageBack {
		position: absolute;
		top: 10px;
		left: 40px;
	}
	.mainPortal.register .action .registered .userAction {
		top: 400px;
		right: 50%;
	}
	.mainPortal.register .action .userAction {
		top: 5%;
		right: 30%;
	}
}
