@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
datalist,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
source,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
video,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
	background: transparent;
	font-size: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: top;
}

blockquote,
q {
	quotes: none;
}

table,
table td {
	padding: 0;
	border: none;
	border-collapse: collapse;
}

.cbEditItem.small {width: 22px;height: 22px;background-size: 22px 22px;color:#2B78E4;font-size:17px;line-height:22px}
.cbEditItemContentParent {position:absolute;top:110px;left:0;right:0}
.contentVersion {position:absolute;left:0;top:0; background: rgba(255,255,255, 0.6); padding:0 3px 1px 1px; border-bottom-right-radius: 2px;}

article,
aside,
audio,
canvas,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video {
	display: block;
}

mark,
rp,
rt,
ruby,
summary,
time {
	display: inline;
}

* {
	box-sizing: border-box;
}

body {
	font-size: 12px;
	font-family: $mainLightFont, Arial, Helvetica, sans-serif;
	background: $midColor;
	&.beheer {
		background: #ececec;
	}
	&.magazineBody,
	&.profileView,
	&.profile {
		background: #fff;
	}
}

.module-content {
	table.editInput {
		border: 1px solid #000;
		padding: 10px;
		th {
			padding: 10px;
			background: #fff;
			font-weight: bold;
		}
		td {
			border: 1px solid #000;
			padding: 10px;
		}
	}
}

a,
a:link,
a:visited {
	color: $midColor;
	text-decoration: none;
}

a:hover {
	color: $lightestColor;
}

input[type="submit"] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.quickscanContent label span {
	pointer-events: none;
}

label {
	cursor: pointer;
}

.trSim.bignumber_input {
	background: #fff;
	&.disabled {
		background: #dfdfdf;
	}
}

.notAccepted {
	outline: 1px solid red;
}

.error {
	color: red;
}

.inlineblock {
	display: inline-block;
}

.fullSize {
	width: 100%;
	height: 100%;
}

.lowercase {
	text-transform: lowercase !important;
}

.fullWidth:not(.inputRow) {
	width: 100% !important;
	max-width: 100% !important;
}

.transparent {
	background: transparent !important;
}

.action {
	margin: 0 auto;
	.userAction {
		min-height: calc( 100vh - 399px);
	}
}

.initial-hidden {
	display: none !important;
}

.adminDashboard {
	margin: 0 auto;
	width: 960px;
	font-size: 0;
	a {
		display: inline-block;
		width: 240px;
		font-size: 12px;
		text-align: center;
	}
	img {
		width: 150px;
		height: 159px;
	}
}

.itemBlock:hover {
	background: #04389b;
	.itemImage {
		display: none;
	}
	.itemText {
		color: #fff;
		height: 50px;
	}
	.itemIcons {
		display: table-cell;
		text-align: right;
		padding: 0 10px 5px 15px;
		font-size: 12px;
		line-height: normal;
	}
}

$icons: info preview add delete export download edit close save nextEdit prevEdit clone plus;
.itemIcon,
a.itemIcon {
	cursor: pointer;
	display: inline-block;
	padding: 0;
	margin-left: 3px;
	width: 27px;
	height: 20px;
	color: #000;
	background-image: url("img/itemIconBg.png");
	background-color: #fff;
	background-repeat: no-repeat;
	border-radius: 3px;
	@for $i from 1 through length($icons) {
		$icon: nth($icons, $i);
		$pos: -(($i - 1) * 20);
		&.#{$icon} {
			background-position: -27px #{$pos}px;
			&:hover {
				background-position: 0 #{$pos}px;
				background-color: #9999cd;
			}
		}
	}
	&.text {
		width: auto;
		height: 20px;
		background: none;
		padding: 0 10px;
		&:hover {
			width: auto;
			height: 20px;
			background: none;
			background-color: #9999cd;
			color: #fff;
		}
	}
}

.editEntry {
	width: 100%;
	margin: 50px 0;
	color: #fff;
	padding: 0 20px;
	.spacer {
		height: 10px;
		width: 100%;
	}
	h2 {
		font-size: 14px;
		color: #fff;
		margin-top: 10px;
		font-weight: bold;
	}
}

body.faq {
	.editEntry {
		color: #000;
		h2 {
			color: #000;
		}
	}
}

.beheer .editEntry {
	color: #000;
	h2 {
		color: #000;
	}
}

.inputElementToggle {
	white-space: nowrap;
}

.inputTypeName {
	font-size: 11px;
	color: #707070;
	margin-left: 10px;
	font-weight: normal;
	font-style: italic;
	display: inline-block;
	line-height: 20px;
}

.addAnswer,
.removeAnswer {
	cursor: pointer;
	display: inline-block;
	margin: 0 2px;
	line-height: 24px;
	height: 24px;
	span {
		background: #2b78e4 url("img/plusButtonSmall.png") no-repeat center center;
		background-size: 24px 24px;
		display: inline-block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		font-size: 17px;
		margin-right: 10px;
	}
}

.removeAnswer span {
	background-color: #F11F1F;
	background-image: url("img/closeWVsmall.png");
}

.answerTable {
	border: 0 solid #383838;
	margin-bottom: 10px;
	width: 100%;
}

.header {
	color: #fff;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	top: env(safe-area-inset-top);
	right: 0;
	line-height: 50px;
	z-index: 100;
	overflow: hidden;
	height: 50px;
	background: linear-gradient(to right, rgba(0, 0, 0, 1) 47%, rgba(0, 0, 0, 0.83) 72%, rgba(0, 0, 0, 0.66) 99%, rgba(0, 0, 0, 0.65) 100%);
	&.beheerHeader {
		height: 45px;
		background: #000;
		line-height: 45px;
	}
	.nav {
		height: 100%;
		display: inline-block;
	}
}

.header .nav span,
.header-gcp .nav span {
	display: inline-block;
	margin-left: 11px;
	font-family: $mainmediumFont;
	font-size: 14px;
	letter-spacing: 1px;
	color: #fff;
}

.headerSpacer {
	height: 50px;
	width: 100%;
	&.beheerHeader {
		height: 45px;
		top: env(safe-area-inset-top);
	}
	&.startHeader {
		height: 68px;
		margin-top: 0;
		margin-top: env(safe-area-inset-top);
	}
}

.toetsBody .headerSpacer {
	display: none;
}

.whiteHeader.nav span {
	color: #333;
}

.header div.nav span {
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.hasInteractions .interactions,
.header .interactions {
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.hasInteractions .interactions .item,
.header .interactions .item,
.header .nav .icon,
.header-gcp .nav .icon {
	background: #454545 no-repeat center center;
	width: 35px;
	height: 35px;
	margin-right: 5px;
	margin-top: 8px;
	display: inline-block;
	border-radius: 50%;
}

.header-gcp .nav {
	position: absolute;
	left: 0;
	line-height: 55px;
	top: 12px;
}

.header-gcp .nav span {
	margin-left: 0;
}

.hasInteractions .interactions .item:last-child,
.header .interactions .item:last-child,
.header .nav .icon:last-child {
	margin-right: 10px;
}

.header.beheerHeader .interactions .item,
.header.beheerHeader .nav .icon {
	margin-right: 8px;
	margin-top: 5px;
}

.header.beheerHeader .projectName {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	letter-spacing: 1px;
	font-family: open_sanssemibold, sans-serif;
	font-size: 14px;
}

.header .nav .icon,
.header-gcp .nav .icon {
	margin-right: 0;
	margin-left: 5px;
	background-image: url("img/backicon.png");
	background-size: 10px 15px;
}

.hasInteractions .interactions .item,
.header .interactions .item {
	background-size: 15px 12px;
	cursor: pointer;
}

.hasInteractions .interactions .openSlideMenu,
.header .interactions .item {
	background-image: url("img/menuicon.png");
}

a.item.openFaqMenu {
	background-image: none !important;
	line-height: 38px !important;
	font-size: 25px;
	text-align: center;
	color:white !important;
}

.hasInteractions .interactions .dummyAvatar {
	background: url("img/dummy_avatar_female.png") center no-repeat;
	background-size: cover;
	cursor: default;
}

.slideMenu .langpicker {
	text-align: center;
	margin: 10px 0 0;
}

.langpicker {
	text-align: left;
	.lang-select {
		display: none;
		background: rgba(0, 0, 0, 0.8);
		text-align: left;
		padding: 10px;
		border-top: 1px solid #353535;
		&.active {
			display: block;
		}
	}
	.lang {
		width: auto;
		height: auto;
		margin: 0;
		display: block;
		padding: 10px 15px;
		font-size: 18px;
		color: #fff;
		background: rgba(255, 255, 255, 0.05);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		&.jsToggleLang {
			padding: 30px 15px 29px;
			font-size: 20px;
			background: none;
			border-bottom: 0;
			border-top: 1px solid #353535;
		}
		.lang-select .lang {
			background: none;
			&:last-child {
				border-color: transparent;
			}
		}
		&:not(.jsToggleLang) {
			&:focus,
			&:hover {
				background: rgba(255, 255, 255, 0.1);
			}
		}
		.flag {
			display: inline-block;
			width: 25px;
			height: 19px;
			background-size: contain;
			background-repeat: no-repeat;
			margin-top: 2px;
			margin-right: 10px;
			&.flag-nl {
				background-image: url('img/nl.svg');
			}
			&.flag-en,
			&.flag-uk {
				background-image: url('img/gb.svg');
			}
			&.flag-en_us,
			&.flag-usa {
				background-image: url('img/us.svg');
			}
			&.flag-intl {
				background-image: url('img/united-nations.svg');
			}
			&.flag-de {
				background-image: url('img/de.svg');
			}
		}
		.arrow {
			display: inline-block;
			width: 30px;
			height: 30px;
			background-image: url('img/arrow-down-white.png');
			background-repeat: no-repeat;
			background-size: 14px 8px;
			background-position: center center;
			margin: -2px 10px 0 0;
			float: right;
			border-radius: 50%;
			background-color: $darkColor;
		}
	}
}

.menuContainer .lang-select {
	background: inherit;
}

.webshop-overlay .inner .overlay-list .langpicker {
	width: 75%;
	margin: 20px auto 40px;
}

.hasInteractions .interactions .item.openSlideMessages,
.header .interactions .item.openSlideMessages {
	text-align: center;
	background: $themeColor;
	line-height: 35px;
	font-family: $mainFont;
	font-size: 16px;
	color: $midColor;
}

.hasInteractions .interactions .item.openSlideMessages.hasNew,
.header .interactions .item.openSlideMessages.hasNew {
	background: #e11b3c;
	color: #fff;
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: 25px;
	margin-top: 9px;
}

.headerLogo {
	width: 27px;
	height: 50px;
	position: absolute;
	left: 50%;
	top: 0;
	margin-left: -13px;
	background: url("img/logosmall.png") no-repeat center center;
	background-size: 27px 33px;
}

.header-gcp.hasInteractions .interactions .item.hasNew.openSlideMessages {
	margin-top: 20px;
}

.header-gcp.hasInteractions .interactions .item {
	margin-top: 18px;
}

.sub-header {
	position: fixed;
	width: 100%;
	background: #fff;
	height: 43px;
	z-index: 100;
	top: calc(45px + env(safe-area-inset-top));
	&.big {
		height: 60px;
	}
	>span {
		line-height: 42px;
		margin-left: 15px;
		font-size: 14px;
	}
}

.sub-headerSpacer {
	height: 43px;
	width: 100%;
	margin-top: env(safe-area-inset-top);
	&.big {
		height: 60px;
	}
}

.sub-header-subtitle {
	margin-left: 15px;
	margin-top: 12px;
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.sub-header-title {
	line-height: 60px;
	margin-left: 15px;
	font-size: 20px;
	font-family: 'open_sanssemibold', sans-serif;
	display: inline-block;
	&.sub {
		line-height: 20px;
	}
}

.sub-header-controls {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	a {
		display: inline-block;
		height: 100%;
		line-height: 60px;
		padding-left: 17px;
		padding-right: 17px;
		font-family: 'open_sanssemibold', sans-serif;
		font-size: 11px;
		color: #2b78e4;
		text-decoration: none;
		letter-spacing: 1px;
		text-transform: uppercase;
		border-right: 1px solid #f2f2f2;
		&:last-child {
			border-right: 0;
		}
		&.active {
			color: #b5d0f6;
		}
	}
}

.popup .sub-header {
	position: absolute;
}

.portal {
	margin: 0 auto;
	width: calc(100% - 20px);
	max-width: 770px;
	margin-top: 40px;
	text-align: center;
	top: env(safe-area-inset-top);
	&.users {
		width: 100%;
		max-width: 100%;
		.portal-item {
			width: 100px;
		}
		.portal-icon {
			background: transparent;
			width: 75px;
			height: 75px;
			background-size: 75px 75px;
		}
	}
}

.portal-item,
a.portal-item {
	display: inline-block;
	width: 125px;
	text-align: center;
	margin-bottom: 18px;
	color: black;
	position: relative;
	span {
		font-size: 15px;
	}
}

.portal-icon {
	background: #2B78E4 no-repeat center center;
	border-radius: 50%;
	background-size: 40px 40px;
	width: 88px;
	height: 88px;
	margin: 0 auto 6px;
	&.plus {
		background-color: #2B78E4 !important;
		background-image: url("img/plusButton.png") !important;
		line-height: 35px;
		color: #fff;
	}
}

.listView {
	width: 100%;
	font-family: 'open_sansregular', sans-serif;
	font-size: 13px;
	border-top: 1px solid #bebebe;
	border-bottom: 1px solid #efefef;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.listViewItem,
a.listViewItem {
	display: block;
	height: 44px;
	width: 100%;
	line-height: 44px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #bebebe;
	color: black;
	position: relative;
	.editButton {
		position: absolute;
		right: 40px;
		transform: translateY(-50%);
		top: 50%;
		line-height: 30px;
		width: 30px;
		height: 30px;
		text-align: center;
		font-size: 20px;
	}
}

table.listView .listViewItem {
	display: table-row;
}

table.listView th {
	height: 45px;
	vertical-align: middle;
}

table.listView .listViewItem td,
table.listView th {
	text-align: center;
	padding: 0 10px;
}

table.listView .listViewItem td.leftAlign,
table.listView th.leftAlign {
	text-align: left;
}

table.listView .checkbox {
	position: relative;
}

.listViewIntro {
	padding: 10px 30px;
	font-size: 16px;
}

.listViewIntro ol,
.listViewIntro ul {
	list-style-position: inside;
}

.listViewItem.odd,
a.listViewItem.odd {
	background: #f2f2f2;
}

.listViewItem.special {
	background: #cbcbcb!important;
	border-top: 1px solid #969696;
	border-bottom: 1px solid #878282;
}

.listViewItem.even,
a.listViewItem.even {
	background: #e6e6e6;
}

.listViewItem span,
a.listViewItem span {
	margin-left: 15px;
}

.listViewItem .expand.open,
a.listViewItem .expand {
	line-height: 40px;
}

.listViewItem .expand,
a.listViewItem .expand {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 50px;
	font-size: 30px;
	font-weight: bold;
	color: #2b78e4;
	text-align: center;
	cursor: pointer;
	line-height: 44px;
}

.listviewInfoItem {
	display: inline-block;
	position: relative;
}

.listViewItem .listviewInfoItem .trigger {
	display: inline-block;
	position: relative;
	border: 1px solid #000;
	color: #000;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	top: 12px;
	cursor: pointer;
}

.listViewItem .listviewInfoItem .infoContent {
	position: absolute;
	z-index: 10;
	color: #fff;
	right: 0;
	line-height: 1.2em;
	background: rgba(0, 0, 0, 0.8);
	padding: 10px;
	width: 300px;
	left: 40px;
	top: 15px;
	font-weight: normal;
	display: none;
}

.listViewItem .listviewInfoItem .infoContent>div {
	margin-bottom: 10px;
}

.listViewItem .listviewInfoItem .infoContent ul {
	margin-left: 25px;
}

.popupCloneTpl .popupContent {
	position: relative;
	top: 100px;
	height: calc(100% - 160px);
}

.popupCloneTpl .listViewItem {
	cursor: pointer;
}

.popupCloneTpl .listViewItem.selected {
	background: #29456d;
	color: #fff;
}

.popupCloneTpl .listViewItem+.listViewGroup:not(.open) {
	height: 0;
}

.listViewItem span.second,
a.listViewItem span.second {
	position: absolute;
	left: 60%;
}

.triple a.listViewItem span.second {
	position: absolute;
	left: 33%;
}

.triple a.listViewItem span.third {
	position: absolute;
	left: 66%;
}

.listViewItem .verticalLine,
a.listViewItem .verticalLine {
	position: absolute;
	top: -1px;
	bottom: 0;
	left: 60%;
	width: 1px;
	background: #bebebe;
}

.triple a.listViewItem .verticalLine {
	left: 33%;
}

.triple a.listViewItem .verticalLine.second {
	left: 66%;
}

.listViewItem:first-of-type .verticalLine,
a.listViewItem:first-of-type .verticalLine {
	top: 0;
}

.listViewItem.highlight,
a.listViewItem.highlight {
	background-color: #2b78e4;
}

.iconfilter {
	position: absolute;
    right: 28px;
    top: 6px;
	background: url("img/filter.svg") no-repeat center center;
	width: 35px;
    height: 35px;
    background-size: 25px;
}
.iconfilterX {
	background: url("img/filter-x.svg") no-repeat center center;
    background-size: 25px;
}

.iconRightArrow {
	background: url("img/arrowRight-blue.png") no-repeat center center;
	background-size: 9px 15px;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 44px;
}

.iconRightArrow.upArrow {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.iconRightArrow.downArrow {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.listViewItem.selectHide {
	display: none;
}

.listViewItem.searchHide {
	display: none;
}

.popupMoveAssetTpl .popup {
	width: 500px;
	overflow: auto;
}

.popupMoveAssetTpl .popup .listView {
	text-align: left;
}

.popupMoveAssetTpl .popup .listViewEditButtons {
	display: none !important;
}

.popupMoveAssetTpl .popup .listViewItemAsset.selected {
	background-color: #b4c9e8;
}

.popupMoveAssetTpl .popup .listViewItemAsset {
	display: none;
}

.popupMoveAssetTpl .popup .listViewItemAsset.file {
	display: none;
}

.popupMoveAssetTpl .popup .listViewItemAsset.folder {
	display: block;
}

.portal-item.cbContentItem .portal-icon {
	cursor: pointer;
}

.listViewHeader {
	background: #f2f2f2;
	font-size: 0;
	height: 44px;
	line-height: 44px;
	border-top: 1px solid #2b78e4;
	border-bottom: 1px solid #2b78e4;
	position: relative;
}

.listViewHeader .first,
.listViewHeader .second,
.listViewHeader .third {
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
	display: inline-block;
	padding-left: 15px;
}

.listViewHeader .second {
	border-left: 1px solid #2b78e4;
	position: absolute;
	left: 60%;
}

.listViewHeader.triple .second {
	left: 33%;
}

.listViewHeader.triple .third {
	left: 66%;
	border-left: 1px solid #2b78e4;
	position: absolute;
}

.footer-edit {
	background: #2b78e4;
	bottom: -44px;
	height: 44px;
	position: fixed;
	width: 100%;
	text-align: center;
}

.footer-editSpacer {
	height: 0;
}

.footer-edit.inline {
	bottom: auto;
	position: static;
}

.footer-edit.open {
	bottom: 0;
}

.footer-editSpacer.open {
	height: 44px;
}

.footer-edit table {
	height: 100%;
	width: 100%;
}

.footer-edit td {
	height: 100%;
	border-right: 1px solid #276ccd;
}

.footer-edit a {
	display: inline-block;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 44px;
	width: 100%;
}

.checkbox {
	display: none;
	width: 22px;
	height: 22px;
	border: 1px solid #2b78e4;
	background: #fff url("img/checkboxChecked.png") no-repeat center center;
	background-size: 22px 22px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 12px;
}

.checkbox.checked {
	background-color: #2b78e4;
}

.checkbox.visible {
	display: block;
}

.listViewItem .checkbox {
	top: 10px;
}

.outerCheck {
	margin-bottom: 5px;
	display: inline-block;
	width: 25%;
}

.emailCheckbox {
	display: inline-block;
	width: 22px;
	height: 22px;
	border: 1px solid #2b78e4;
	background: #fff url("img/checkboxChecked.png") no-repeat center center;
	background-size: 22px 22px;
	border-radius: 50%;
}

.emailCheckbox.checked {
	background-color: #2b78e4;
}

.emailCheckbox span {
	margin-left: 30px;
}

.outerCheck input[type="checkbox"] {
	display: none;
}

.active-licenses {
	margin-top: 15px;
}

.active-licenses .inputHeader {
	margin-bottom: 5px;
}

.active-licenses .list-items {
	font-size: 0;
}

.active-licenses .list-items:first-child {
	border-bottom: 1px solid #bebebe;
	font-weight: bold;
}

.active-licenses .list-items:nth-child(2) {
	border-top: 1px solid #fff;
	padding-top: 5px;
}

.active-licenses .list-items .sub-item {
	display: inline-block;
	width: 45%;
	font-size: 13px;
	min-height: 26px;
	padding: 5px 5px 5px 0;
}

.active-licenses .list-items .sub-item:first-child {
	width: 10%;
}

.active-licenses .list-items .sub-item input {
	margin: 2px 4px 0;
}

.active-licenses .photoInfo {
	text-align: left;
}

.active-licenses .buttonbox {
	padding: 15px 15px 15px 5px;
}

.photoInfo {
	margin: auto;
	width: 300px;
	text-align: center;
}

.photoInfoPhoto {
	width: 100px;
	margin: auto;
}

.photoInfoText {
	padding-top: 22px;
	min-height: 53px;
}

.photoInfoText.noPhoto {
	padding-left: 15px;
	padding-top: 0;
}

.photoIcon {
	border-radius: 50%;
	width: 75px;
	height: 75px;
	background-size: 75px 75px;
	margin-left: 16px;
}

.photoInfoText .infoHeader {
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.photoInfoText .infoText {
	margin-bottom: 16px;
	font-size: 13px;
}

.statsBlocks {
	margin: 36px 16px 16px;
}

.statsBlock {
	padding: 5px;
	background: #fff;
	margin-top: 10px;
	box-shadow: 0 1px 1px 0 #c5c5c5;
}

.statsPic {
	width: 80px;
	height: 80px;
	background-size: 80px 80px;
	position: absolute;
}

.statsInfo {
	padding-left: 85px;
	padding-top: 3px;
	min-height: 80px;
	position: relative;
}

.statsHeader {
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #000;
}

.statsText {
	font-size: 13px;
}

.statsPercSpacer {
	height: 13px;
	width: 100%;
}

.statsPerc {
	position: absolute;
	right: 0;
	bottom: 0;
	color: #2b78e4;
	font-size: 16px;
}

.statsProgress {
	position: relative;
	height: 5px;
	width: 100%;
}

.statsProgressLine {
	position: absolute;
	width: 100%;
	height: 1px;
	top: 4px;
	left: 0;
	background: #b3b3b3;
}

.statsProgressBar {
	position: absolute;
	width: 0;
	height: 3px;
	top: 3px;
	left: 0;
	background: #2b78e4;
}

.inputBlock {
	margin: 20px 16px 16px;
}

.inputHeader {
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.roundedInput {
	-webkit-appearance: none;
	display: block;
	width: 100%;
	height: 33px;
	padding: 6px;
	font-size: 14px;
	color: #000;
	background: #fff;
	border: 1px solid #ececec;
	border-radius: 2px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	margin-bottom: 12px;
}

.roundedInput.error {
	border: 1px solid red;
}

.selectParent {
	position: relative;
}

.selectGroup {
	font-size: 0;
}

.selectGroup .selectParent {
	width: 50%;
	display: inline-block;
}

.searchIcon,
.selectArrow {
	position: absolute;
	top: 2px;
	right: 0;
	pointer-events: none;
	height: 30px;
	width: 37px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	background: #2b78e4 url("img/selectArrow.png") no-repeat center center;
	background-size: 37px 30px;
}

.searchIcon {
	background: transparent url("img/searchIcon.png") no-repeat center center;
	background-size: 37px 30px;
}

.roundedInput.search {
	padding-right: 30px;
}

.buttonSpacer {
	height: 20px;
	width: 100%;
}

.blueButton,
a.blueButton {
	-webkit-appearance: none;
	border-radius: 0;
	height: 42px;
	width: 100%;
	color: #fff;
	background: #2b78e4;
	border: 0;
	border-bottom: 2px solid #2260b6;
	font-family: 'open_sansregular', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 12px;
}

a.blueButton {
	display: block;
	width: auto;
	text-align: center;
	line-height: 42px;
}

.buttonbox {
	padding: 15px 16px 1px;
}

.buttonbox.color {
	background: #f2f2f2;
}

.blueButton.disabled,
.wvChooseProduct.disabled {
	opacity: 0.3;
	cursor: default;
	pointer-events: none;
}

.sortBar {
	height: 44px;
	background: #f2f2f2;
	border-bottom: 1px solid #2b78e4;
	font-size: 0;
}

.sortColumn {
	position: relative;
	display: inline-block;
	width: 100%;
}

.sortColumn.first {
	width: 60%;
}

.sortColumn.second {
	width: 40%;
	border-left: 1px solid #2b78e4;
}

.sortHeader {
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
	line-height: 44px;
	margin-left: 15px;
}

.sortControls {
	position: absolute;
	top: 0;
	right: 12px;
}

.sortDown,
.sortUp {
	width: 24px;
	height: 24px;
	background: #fff;
	box-shadow: 0 1px 1px 0 #c5c5c5;
	border-radius: 50%;
	display: inline-block;
	margin-top: 8px;
	margin-right: 8px;
	position: relative;
}

.sortDown.active,
.sortUp.active {
	background: #2b78e4;
	box-shadow: none;
}

.sortDown .arrow,
.sortUp .arrow {
	width: 0;
	height: 0;
	left: 8px;
	top: 9px;
	position: absolute;
}

.sortDown .arrow {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 6px solid #2b78e4;
}

.sortUp .arrow {
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 6px solid #2b78e4;
}

.sortDown.active .arrow {
	border-top-color: #fff;
}

.sortUp.active .arrow {
	border-bottom-color: #fff;
}

.popupOverlay,
.werkvormPopup {
	background: rgba(0, 0, 0, 0.65);
	position: fixed;
	left: 0;
	top: env(safe-area-inset-top);
	right: 0;
	bottom: 0;
	z-index: 200;
	width: 100%;
	height: 100%;
	display: none;
	padding-bottom: 0;
	padding-bottom: env(safe-area-inset-bottom);
}

.toetsBody .werkvormPopup {
	position: relative;
	width: auto;
	margin: 0;
	padding: 13px;
	height: 100%;
}

.popupOverlay .userList {
	max-height: 200px;
	overflow: auto;
	font-size: 16px;
	padding-bottom: 15px;
}

.toetsWerkvorm {
	display: block;
}

.werkvormPopup {
	background: #313131;
}

.werkvormBackground {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background-size: cover;
}

.werkvormBackground img {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	display: none;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	max-height: none;
	max-width: none;
}

.toetsBody .werkvormPopup .werkvormBackground {
	background: #313131;
	position: fixed;
	background-size: cover;
}

.toetsBody .werkvormPopup.toetsCover .werkvormBackground {
	background: $lightestColor;
	position: fixed;
}

.popup {
	background: #fff;
	text-align: center;
	display: inline-block;
	max-width: 90%;
	max-height: 90%;
	margin: 0 auto;
	box-shadow: 0 2px 20px 5px #2F2F2F;
	position: relative;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.content .popup {
	position: absolute;
}

.popupContainer {
	display: none;
}

.popup.messagePopup {
	overflow: hidden;
	position: fixed;
	z-index: 100;
	width: 90%;
	max-width: 540px;
}

.popup.messagePopup img {
	max-width: 100%;
}

.popup.messagePopup .popup-header {
	text-align: right;
	overflow: hidden;
}

.popup.messagePopup .popup-header .closeIcon {
	cursor: pointer;
}

.popup.messagePopup .popup-body {
	padding: 20px;
	color: #262626;
	text-align: left;
	overflow: auto;
	max-height: calc(90vh - 40px);
}

body:not(.scormBody) .popup.messagePopup .popup-body {
	font-family: $mainLightFont;
}

.popup.messagePopup .popup-body .videoContainer {
	margin: 1em 0;
}

.popup.fullscreen {
	width: 90%;
	height: 90%;
}

.popupText {
	padding: 30px;
	font-size: 20px;
}

.popupTitle {
	line-height: 60px;
	padding-left: 15px;
	font-size: 20px;
	font-family: 'open_sanssemibold', sans-serif;
	text-align: left;
	background: #fff;
}

.popupTitle.double {
	line-height: 30px;
	padding-right: 15px;
	text-align: center;
	padding-top: 20px;
	font-family: 'open_sansregular', sans-serif;
	border-bottom: 1px solid #2b78e4;
	padding-bottom: 20px;
}

.popupButtons {
	background: #f1f1f1;
	min-width: 290px;
}

.popup.fullscreen .popupButtons {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.popupButtons a.blueButton {
	width: 125px;
	display: inline-block;
	margin: 10px 5px;
}

.popupButtons.loading a.blueButton {
	opacity: 0.4;
	pointer-events: none;
}

.popupButtons.loading:after {
	content: "";
	width: 30px;
	height: 30px;
	background: url("/css/img/loader.gif");
	display: block;
	position: absolute;
	background-size: cover;
	bottom: 0;
	right: 0;
	margin: 15px;
}

.popupHeader {
	padding: 12px 12px 1px;
	background: #f2f2f2;
	border-bottom: 1px solid #2b78e4;
	min-width: 280px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1;
}

.popupContent {
	text-align: left;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	background: #f1f1f1;
}

.popupContent .listView {
	padding: 10px 10px 0;
}

.popupHeaderSpacer {
	height: 59px;
	width: 100%;
}

.popupButtonsSpacer {
	height: 62px;
	width: 100%;
}

.popupRatingExplanationTpl .inputBlock {
	margin-top: 0;
	padding-top: 20px;
	text-align: left;
}

.popupRatingExplanationTpl .roundedInput {
	height: 150px;
}

.popupOverlay.popupInputsTpl{
	z-index:300;
}

.popupInputsTpl .inputBlock {
	margin-top: 0;
	padding-top: 20px;
	text-align: left;
}

.popupAssetTpl .popup {
	text-align: left;
	background-color: #ececec;
}

.popupAssetTpl .sub-header {
	top: 0;
}

.popupAssetTpl .contentManagerBlocks .toolbarSpacer {
	height: 60px;
}

.popupAssetTpl .popup.fullscreen .popupButtons {
	text-align: center;
	background: transparent;
	position: static;
}

.send-overlay {
	top: 0;
	bottom: 0;
	position: fixed;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 100;
}

.popup.email-popup {
	width: 500px;
	position: fixed;
	z-index: 100;
	overflow: hidden;
}

.email-popup .popup-title {
	font-family: 'open_sanssemibold', sans-serif;
	font-size: 25px;
	padding: 25px 0;
	background: #2b78e4;
	color: #fff;
	border: 1px solid;
}

.popup .popup-inner {}

.popup .popup-inner .popup-intro {
	font-family: $mainLightFont;
	font-size: 18px;
	margin: 50px 0;
}

.popup .popup-inner .blueButton {
	line-height: 42px;
	cursor: pointer;
}

.assetImage,
.assetName {
	display: inline-block;
	margin: 10px 5px;
	font-size: 14px;
}

.assetImage .docPreview {
	width: 200px;
	height: 282px;
}

.dummy {
	display: none !important;
}

.dummyAvatar {
	background-image: url('img/dummy_avatar_female.png');
	background-size: cover;
}

.male .dummyAvatar {
	background-image: url('img/dummy_avatar_male.png');
	background-size: cover;
}

.darkBackground {
	background-image: url('img/werkopdracht.png');
	background-size: cover;
}

.contentManagerBlocks {
	width: 100%;
	height: 100%;
	font-size: 0;
}

.contentManagerBlocks .toolbarSpacer {
	height: 105px;
	width: 100%;
}

.publishblock>.centerText {
	font-size: 15px;
	margin: 0 auto 10px;
	max-width: 400px;
}

.publishblock .page {
	margin-bottom: 1.5em;
}

.blockLeftContent,
.blockRightContent {
	height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	display: inline-block;
}

.blockLeftContent {
	width: 30%;
	border-right: 1px solid #2b78e4;
}

.blockRightContent {
	left: 30%;
	width: 70%;
}

.leftToolbar {
	width: 30%;
	border-right: 1px solid #2b78e4;
}

.rightToolbar {
	left: 30%;
	width: 70%;
}

.cbContentItem .portal-icon {
	background: transparent;
	background-size: 125px 125px;
	width: 125px;
	height: 125px;
	border-radius: 0;
}

.cbContentItem .portal-icon.plus {
	background-image: url("img/plusButtonBig.png") !important;
	border-radius: 50%;
}

.cbContentItem.portal-item {
	width: 170px;
	border: 1px solid transparent;
}

.cbContentItem.portal-item.highlight {
	border: 10px double #2b78e4;
}

.blockToolbar {
	padding: 16px 0 10px;
}

.blockToolbar .sortList .label {
	font-size: 15px;
	display: inline-block;
	line-height: 33px;
	margin-right: 30px;
}

.blockToolbar .selectParent {
	width: 200px;
	margin: 0 20px;
	display: inline-block;
}

.blockToolbar .selectParent .filter-text {
	position: absolute;
	left: -0;
	font-size: 15px;
	line-height: 33px;
	transform: translateX(calc(-100% - 5px));
}

.blockLeftContent .blockToolbar {
	border-bottom: 1px solid #2b78e4;
}

.toolbarTabs {
	text-align: center;
	margin-bottom: 25px;
}

a.toolbarTab {
	width: 160px;
	display: inline-block;
	line-height: 35px;
	border: 1px solid #ccc;
	background: #fff;
	text-transform: uppercase;
	color: #2b78e4;
	font-size: 13px;
}

a.toolbarTab.active {
	background: #2b78e4;
	color: #fff;
}

.toolbarTab:not(:first-of-type) {
	border-left: 0;
}

.cbToolbar {
	position: fixed;
	height: 100px;
	width: 100%;
	left: 0;
	top: 45px;
	right: 0;
	z-index: 2;
}

.cbToolbarTop {
	background: #2b78e4;
	height: 44px;
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 4;
}

.cbToolbarTop a {
	display: inline-block;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 44px;
	padding: 0 35px;
	border-right: 1px solid #276ccd;
}

.cbToolbarTop a.hidden {
	opacity: 0;
	visibility: hidden;
	display: none;
	pointer-events: none;
}

.cbToolbarTop a:first-of-type {
	border-left: 1px solid #276ccd;
}

.cbToolbarMiddle {
	background: #fff;
	height: 60px;
	width: 100%;
	position: relative;
	padding-left: 45px;
}

.cbToolbarBottom {
	background: #fff;
	height: 0;
	width: 100%;
	position: relative;
	white-space: nowrap;
	padding-left: 45px;
	overflow: hidden;
}

.cbToolbarBottom.open {
	height: 77px;
}

.toolbarSubtitle {
	margin-left: 15px;
	padding-top: 12px;
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.toolbarTitle {
	line-height: 20px;
	margin-left: 15px;
	font-size: 20px;
	font-family: 'open_sanssemibold', sans-serif;
	display: inline-block;
}

.toolbarControls {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
}

.toolbarControls a {
	display: inline-block;
	height: 100%;
	line-height: 60px;
	padding-left: 17px;
	padding-right: 17px;
	font-family: 'open_sanssemibold', sans-serif;
	font-size: 11px;
	color: #2b78e4;
	text-decoration: none;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.previewLinks {
	position: absolute;
	left: 0;
	top: 5px;
	right: 0;
	text-align: center;
	line-height: 50px;
	font-family: 'open_sansregular', sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.previewLinks a {
	display: inline-block;
	margin: 0 10px;
}

.iconPhone {
	background: url("img/iconphone.png") no-repeat center center;
	background-size: 11px 21px;
	width: 30px;
	height: 50px;
}

.iconTablet {
	background: url("img/icontablet.png") no-repeat center center;
	background-size: 21px 28px;
	width: 50px;
	height: 50px;
}

.iconDesktop {
	background: url("img/icondesktop.png") no-repeat center center;
	background-size: 50px 36px;
	width: 75px;
	height: 50px;
}

.toolbarMetaBlock {
	display: inline-block;
	width: 200px;
	margin: 10px 20px 0;
}

.toolbarMetaBlock .inputHeader {
	padding-left: 3px;
}

.toolbarMetaBlock.cover {
	position: absolute;
	left: 45px;
	top: 2px;
	z-index: 2;
	height: 48px;
	opacity: 0;
	overflow: hidden;
}

.toolbarMetaBlock.cover.open {
	height: 125px;
	opacity: 1;
}

.toolbarMetaBlock.cover .metaData {
	width: 90px;
	height: 90px;
	background-size: 90px 90px;
}

.toolbarMetaBlock.coverSpacer {
	width: 85px;
	height: 1px;
}

.cbSidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: -310px;
	width: 355px;
	background: #1a1a1a;
	font-size: 0;
	box-shadow: 1px 0 10px 1px rgba(117, 117, 117, 0);
	z-index: 3;
	font-family: 'open_sanssemibold', sans-serif;
}

.cbSidebar.open {
	left: 0;
	box-shadow: 1px 0 10px 1px rgba(117, 117, 117, 1);
}

.cbSidebarContent {
	display: inline-block;
	width: 310px;
	height: 100%;
	border-right: 1px solid #888;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.cbSidebarHandle {
	display: inline-block;
	width: 45px;
	height: 100%;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
}

.cbSidebarHandleText {
	position: absolute;
	top: 50%;
	white-space: nowrap;
	line-height: 45px;
	-webkit-transform-origin: top center;
	transform-origin: top center;
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
	width: 10000px;
	text-align: center;
	margin-left: -5000px;
}

.cbSidebarHandleText .sortDown {
	margin: 10px 90px 0 10px;
	box-shadow: none;
}

.cbSidebarContentSpacer {
	height: 110px;
	width: 100%;
}

.cbTemplateItem {
	display: inline-block;
	margin-left: 20px;
	font-size: 12px;
	color: #fff;
}

.cbTemplateIcon {
	width: 125px;
	height: 125px;
	background-size: 125px 125px;
	background-color: #fff;
}

.cbTemplateInfo {
	margin: 10px 0 20px;
}

.cbTemplateInfoText {
	display: inline-block;
	font-family: 'open_sanssemibold', sans-serif;
	width: 90px;
	text-transform: uppercase;
}

a.cbTemplateInfoIcon {
	display: inline-block;
	font-family: 'open_sanssemibold', sans-serif;
	background: #2b78e4;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	font-size: 17px;
	color: #fff;
	text-align: center;
	line-height: 24px;
	margin-left: 5px;
}

.cbWorkspace {
	padding-left: 45px;
}

.cbWorkspaceSpacer {
	height: 105px;
	width: 100%;
}

.cbWorkspaceSpacer.open {
	height: 182px;
}

.cbPageSelector {
	margin: 32px 0 22px;
	text-align: center;
}

a.cbPageItem {
	display: inline-block;
	font-family: 'open_sanssemibold', sans-serif;
	background: #fff;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	font-size: 17px;
	color: #2b78e4;
	text-align: center;
	line-height: 24px;
	box-shadow: 0 1px 1px 0 #c5c5c5;
	margin: 0 5px;
}

a.cbPageItem.active {
	background: #2b78e4;
	color: #fff;
}

a.cbPageItem.left {
	background: transparent url("img/arrowLeft-blue.png") no-repeat center center;
	background-size: 8px 14px;
	box-shadow: none;
}

a.cbPageItem.right {
	background: transparent url("img/arrowRight-blue.png") no-repeat center center;
	background-size: 8px 14px;
	box-shadow: none;
}

a.cbPageItem.add {
	background: #2b78e4 url("img/plusButtonSmall.png") no-repeat center center;
	background-size: 24px 24px;
	box-shadow: none;
	margin: 0 20px;
}

a.cbPageItem.remove {
	background: #2b78e4 url("img/removeButtonSmall.png") no-repeat center center;
	background-size: 24px 24px;
	box-shadow: none;
}

a.cbPageItem.edit {
	background: #2b78e4 url("img/editButtonSmall.png") no-repeat center center;
	background-size: 24px 24px;
	box-shadow: none;
}

a.cbPageItem.copy {
	background: url("img/cloneIconWhite.png") center no-repeat #2b78e4;
	background-size: 24px 24px;
	box-shadow: none;
}

.cbPageSelector a.cbPageItem.chapterItem {
	position: relative;
}

.cbPageSelector .chapterItemWrap .cbPageItemWrap a {
	cursor: move;
}

.cbPageSelector .chapterItemWrap .cbPageItemWrap {
	display: inline-block;
	position: relative;
}

.cbPageSelector .chapterItemWrap {
	display: inline-block;
}

.cbPageSelector .chapterItemWrap .cbPageItemWrap .mover {
	content: "";
	width: 30px;
	height: 30px;
	background: url("img/moveIcon.png");
	position: absolute;
	top: -30px;
	left: 2px;
	background-size: cover;
	display: none;
}

.cbPageContentItem {
	height: 127px;
	background: #fff;
	border: 1px dashed #e6e6e6;
	margin: 10px;
	position: relative;
	overflow: hidden;
}

.cbPageContentItem.insertTpl {
	height: 10px;
	background: #ececec;
	border-color: #ececec;
}

.cbPageContentItem.highlight {
	border-color: #2b78e4;
}

.cbPageContentItemNr {
	font-family: 'open_sanssemibold', sans-serif;
	color: #f2f2f2;
	font-size: 18px;
	margin: 12px 0 -36px 12px;
	line-height: 24px;
}

.cbWorkspace .cbTemplateItem {
	display: block;
	text-align: center;
}

.cbWorkspace .cbTemplateIcon,
.cbWorkspace .cbTemplateInfo {
	display: inline-block;
}

.cbWorkspace .cbTemplateInfoText {
	color: #000;
	text-align: left;
}

.cbEditParent {
	margin-top: 45px;
	display: none;
}

.cbTemplateItem .cbEditParent {
	display: inline-block;
}

.cbEditItem {
	display: inline-block;
	cursor: pointer;
	background: #fff no-repeat center center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-size: 35px 35px;
	box-shadow: 0 1px 1px 0 #c5c5c5;
	margin: 0 5px;
}

.cbEditItem.move {
	background-image: url("img/moveIcon.png");
}
.cbEditItem.lock {
	background-image: url("img/locked.png");
}

.cbEditItem.edit {
	background-image: url("img/editIcon.png");
}

.cbEditItem.user {
	background-image: url("img/profile_icon_blue.png");
	background-size: 80%;
}

.cbEditItem.copy {
	background-image: url("img/cloneIcon.png");
}

.cbEditItem.remove {
	background-image: url("img/trashIcon.png");
}

.previewEditControls {
	display: inline-block;
}

.previewEditControls a {
	margin: 0 10px;
	color: #fff;
}

.previewEditCancel,
.previewEditSave {
	display: none;
}

.previewEditOutline {
	outline: 1px solid #85B3EF;
}

.mediaPreview {
	background: white no-repeat center center;
	background-size: contain;
	border: 1px solid #eaeaea;
	margin: 25px;
	height: 270px;
}

.mediaDropUploadText {
	font-size: 16px;
	font-family: 'open_sanssemibold', sans-serif;
	color: #1a1a1a;
	position: relative;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: inline-block;
}

.mediaPreviewVideo {
	display: none;
	width: 100%;
	height: 100%;
}

.mediaPreviewDocument {
	display: none;
	width: 100%;
	height: 100%;
}

.mediaPreviewDocument iframe {
	width: 100%;
	height: 100%;
}

.mediaInfoWrap {
	margin: 25px;
}

.mediaInfo {
	width: 100%;
}

.miHeader {
	font-size: 12px;
	font-family: 'open_sanssemibold', sans-serif;
	text-transform: uppercase;
	color: #999999;
}

.miContent {
	font-size: 16px;
	font-family: 'open_sanslight', sans-serif;
	color: #1a1a1a;
}

.miContent td {
	padding-bottom: 16px;
}

.uploadInfo .miContent td {
	padding-bottom: 0;
}

.uploadInfo .statsProgress {
	padding-bottom: 20px;
}

.uploadAssetBtnInput {
	position: absolute;
	top: -999px;
	left: 0;
}

.addModelButton+.uploadAssetBtnInput {
	display: none;
}

.listViewIcon {
	display: inline-block;
	width: 44px;
	height: 44px;
}

.listViewIcon.folder {
	background: url("img/foldericon.png") no-repeat center center;
	background-size: 20px 18px;
}

.listViewIcon.file {
	background: url("img/fileicon.png") no-repeat center center;
	background-size: 16px 21px;
}

.listViewIcon.add {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #2b78e4 url("img/plusButtonSmall.png") no-repeat center center;
	background-size: 24px 24px;
	margin: 10px;
}

.treeArrow {
	display: inline-block !important;
	width: 6px;
	height: 44px;
	margin-left: 10px;
	position: relative;
}

.treeArrow div {
	position: absolute;
	top: 19px;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-left: 6px solid #2b78e4;
}

.treeArrow.hidden div {
	border: 0;
}

.listViewItemTitle {
	display: inline-block;
}

.listViewItemTitle.active {
	font-family: 'open_sanssemibold', sans-serif;
	color: #2b78e4;
}

.listViewGroup {
	overflow: hidden;
}

.listViewGroup.closed {
	height:0 !important;  min-height:0 !important; min-block-size:0 !important
}

.listViewEditButtons {
	position: absolute;
	right: 10px;
	top: 10px;
	height: 23px;
	display: none;
}

.listViewEditButtons .cbEditItem {
	width: 23px;
	height: 23px;
	background-size: 23px 23px;
	margin: 0 4px;
	line-height: 23px;
	text-align: center;
	color: #2b78e4;
}

.stats a.listViewItem {
	height: auto;
	min-height: 44px;
}

.stats a.listViewItem li {
	margin: 0 0 0 65px;
	line-height: 20px;
}

.editHotspotNoAsset {
	text-align: center;
}

.editHotspotAssetWrap {
	position: relative;
	display: inline-block;
}

.editHotspotRadius {
	position: absolute;
	background: rgba(43, 120, 228, 0.5);
	z-index: 99;
	border-radius: 50%;
}

.editHotspotDot {
	position: absolute;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 4px solid #fff;
	z-index: 100;
	background: rgba(57, 54, 49, 0.8);
	margin-left: -19px;
	margin-top: -19px;
	-webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
}

.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
}

.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}

.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}

.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}

.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}

.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}

.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}

.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}

.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}

.backgroundImage {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	height: 200px;
	background-size: cover;
}

.werkvormStartImage {
	overflow: hidden;
	background-size: cover;
}

.werkvormStartImage img {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	display: none;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	max-height: none;
	max-width: none;
}

.focuspointSelect {
	margin: 10px;
}

.mediaPreviewImage {
	position: relative;
}

.focuspointSelector {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -50px;
	margin-top: -50px;
	width: 100px;
	height: 100px;
	background: url("img/focuspointTarget.png") no-repeat center center;
	pointer-events: none;
}

.magazineContent {
	margin-top: 100px;
	position: relative;
	background: #fff;
}

.contentWrap {
	padding: 0 12px;
}

.contentFooter {
	width: 100%;
	background: #fff;
	padding: 10px;
}

.contentTitle {
	font-size: 32px;
	font-family: $mainFont;
	text-align: center;
	margin-bottom: 25px;
}

.contentText {
	font-family: $mainLightFont;
	font-size: 12px;
}

.contentSubTitle,
.contentText strong {
	font-family: $mainFont;
	font-weight: normal;
	font-size: 18px;
}

.contentText p {
	margin-bottom: 2em;
	font-size: 15px;
	line-height: 19px;
}

.imageWrap img {
	width: 100%;
}

.imageDescription {
	font-family: 'open_sanslight', sans-serif;
	font-size: 13px;
	color: $lightestColor;
	margin-top: 7px;
	border-top: 1px solid $lightestColor;
	padding-top: 9px;
	margin-bottom: 14px;
}

.paginate {
	height: 70px;
	text-align: center;
	position: relative;
	overflow: hidden;
	margin-bottom: 5px;
}

.paginateLeft {
	z-index: 1;
	display: none;
	background: url("img/paginateLeft.png") no-repeat center center;
	background-size: 21px 41px;
	width: 21px;
	height: 41px;
	position: absolute;
	left: 30px;
	top: 30px;
}

.paginateRight {
	z-index: 1;
	display: none;
	background: url("img/paginateRight.png") no-repeat center center;
	background-size: 21px 41px;
	width: 21px;
	height: 41px;
	position: absolute;
	right: 30px;
	top: 30px;
}

.scormBody .paginate .paginateLeft,
.scormBody .paginate .paginateRight {
	display: none;
}

.paginatePages {
	position: absolute;
	top: 50%;
	margin-top: -25px;
	white-space: nowrap;
	left: -9999px;
}

a.paginatePage.hasNew {
	background: #e11c3d;
	border-radius: 50%;
	height: 30px;
	line-height: 30px;
	width: 30px;
	color: #fff;
	padding: 0;
	margin-top: 9px;
}

a.paginatePage {
	color: #1e1e1e;
	font-size: 16px;
	display: inline-block;
	line-height: 45px;
	height: 45px;
	padding: 0 13px;
}

a.paginatePage.active {
	font-family: $mainFont;
	color: $buttonTextColor;
	background: $themeColor;
	border-radius: 50%;
	font-size: 20px;
	width: 45px;
	height: 45px;
	line-height: 45px;
	margin: 0;
}

a.paginatePage.active.hasNew {
	background: #e11c3d;
	color: #fff;
}

a.paginatePage.active+a.paginatePage.hasNew,
a.paginatePage.hasNew+a.paginatePage.active {
	margin-left: 5px;
}

.paginateFade {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 1) 100%);
}

.filmscene .paginateFade {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(to right, rgba(236, 230, 220, 1) 0%, rgba(236, 230, 220, 1) 5%, rgba(236, 230, 220, 0) 30%, rgba(236, 230, 220, 0) 70%, rgba(236, 230, 220, 1) 95%, rgba(236, 230, 220, 1) 100%);
}

.werkvormStartImage {
	position: absolute;
	width: 100%;
	height: 240px;
	background: #3f3f3f no-repeat center center;
	left: 0;
	background-size: cover;
}

.werkvormStart {
	position: relative;
	width: 100%;
	height: 240px;
	margin-bottom: 28px;
	text-align: center;
	text-transform: uppercase;
	overflow: hidden;
}

.werkvormStartWrap {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.werkvormType {
	font-size: 20px;
	color: $lightestColor2;
	font-family: $mainExtraLightFont;
	font-style: italic;
	text-transform: none;
}

.werkvormTitle {
	font-family: $mainLightFont_italic;
	font-size: 25px;
	color: #fff;
	display: inline-block;
	border-top: 2px solid $themeColor;
	border-bottom: 2px solid $themeColor;
	padding: 10px 0;
	margin: 10px 0 20px;
}

a.werkvormDownloadButton,
a.werkvormStartButton {
	font-family: $mainFont;
	text-transform: none;
	font-size: 17px;
	color: $lightestColor;
	background: #fff;
	border-radius: 50%;
	line-height: 80px;
	width: 80px;
	height: 80px;
	zoom: 1;
	display: block;
	margin: 0 auto;
}

a.werkvormStartButton.completed {
	position: relative;
	background: $themeColor;
}

a.werkvormStartButton.completed .icon.completed {
	background: url('img/GCP_checkmark.png');
	width: 36px;
	height: 29px;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	background-size: cover;
}

a.werkvormDownloadButton .icon.download {
	background: url('img/downloadAsset.png');
	width: 80% !important;
	height: 80% !important;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	background-size: cover;
}

a.werkvormDownloadButton {
	padding: 20px;
	position: relative;
}

.werkvormStartButton span {
	display: block;
}

.nextPageHeader {
	color: $lightestColor;
	padding: 20px;
	font-family: $mainLightFont;
	font-size: 20px;
	font-weight: normal;
	text-align: center;
}

a.nextPageLink {
	background: $midColor;
	color: #fff;
	display: block;
	text-align: center;
	padding: 16px 16px 24px;
}

.nextPageNr {
	font-family: $mainFont;
	background: $themeColor;
	color: $midColor;
	border-radius: 50%;
	font-size: 12px;
	line-height: 23px;
	width: 23px;
	height: 23px;
	margin: 0 auto 8px;
}

.searchResults .nextPageNr {
	margin-top: 8px;
	margin-bottom: 0;
}

.nextPageTitle {
	font-size: 24px;
	font-family: $mainLightFont;
}

a.favoritePage {
	display: inline-block;
	line-height: 38px;
	font-family: $mainFont;
	color: #1e1e1e;
	font-size: 14px;
	text-transform: uppercase;
	margin: 15px;
}

.favoritePageIcon {
	height: 38px;
	width: 38px;
	background: url("img/favicon.png") no-repeat center center;
	background-size: 38px 38px;
	border-radius: 50%;
	border: 1px solid $darkColor;
	display: inline-block;
	margin-right: 14px;
}

a.favoritePage.active .favoritePageIcon {
	background-color: $darkColor;
}

.backgroundImage {
	overflow: hidden;
	background: #000 no-repeat center center;
	background-size: cover;
}

.backgroundImage img {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	display: none;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	max-height: none;
	max-width: none;
}

.werkvormContent {
	-webkit-overflow-scrolling: touch;
	overflow: auto;
	height: 100%;
	position: relative;
}

.werkvormWrap {
	margin-top: 14px;
	text-align: center;
}

.toetsBody .werkvormContent {
	padding-bottom: 20px;
	border: 1px solid #fff;
}

.toetsCover {
	background: #e5ddd0;
	font-family: $mainLightFont;
}

.toetsCover .wvCheckButtonWrap+.wvCheckButtonWrap {
	margin-left: 20px;
}

.wvHeader {
	color: #008a8d;
	font-size: 20px;
}

.wvHeaderBack {
	width: 50px;
	height: 50px;
	position: absolute;
	right: 4px;
	top: 4px;
	display: block;
	margin: 0 auto;
}

.wvHeaderBack span {
	background: $darkColor url("img/closeWVsmall.png") no-repeat center center;
	background-size: 30px 30px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: block;
	margin: 10px;
}

.wvHeaderBack.white span {
	background: $themeColor url("img/cross.png") no-repeat center center;
	background-size: 12px 12px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: block;
}

.wvHeader .wvHeaderTitle {
	font-family: $mainLightFont_italic;
	color: #fff;
	display: inline-block;
	border-top: 1px solid $themeColor;
	border-bottom: 1px solid $themeColor;
	padding: 2px 10px;
}

.wvToetsTitle {
	font-family: "HarmoniaSansStd-Light", sans-serif;
	font-size: 16px;
}

.wvToetsTitle .current {
	color: #fff;
}

.wvListContainer {
	padding: 0 13px;
}

.wvListContainer .intro,
.wvStellingIntro .intro,
.wvWatbijwatContent .intro {
	margin-top: 20px;
	margin-bottom: 25px;
	font-size: 17px;
	color: #fff;
	font-family: $mainLightFont;
}

.wvListContainer .introSub {
	font-size: 14px;
	color: #fff;
	margin-top: -20px;
	margin-bottom: 30px;
	font-family: $mainLightFont;
}

.toetsContent.cover .intro {
	margin-top: 90px;
}

.toetsContent .intro h3 {
	font-size: 16px;
	font-family: "MegaTeam", sans-serif;
	color: #1e1e1e;
}

.toetsContent .intro h2 {
	font-family: "harmoniaSansStd-Light", sans-serif;
	font-size: 30px;
	color: #fff;
}

.toetsContent .intro h1 {
	font-family: "harmoniaSansStd-Light", sans-serif;
	font-size: 220px;
	color: #1e1e1e;
}

.toetsContent.cover .intro p {
	color: #1e1e1e;
	font-family: "open_sanslight", sans-serif;
	font-size: 15px;
	margin-top: 25px;
}

.showCorrectAnswerQuestion {
	text-align: center;
	padding: 6px 0;
	color: #fff;
	border-bottom: 1px solid #fff;
}

.showCorrectAnswerQuestion label.styled {
	white-space: nowrap;
	text-align: left;
	width: 237px;
}

.showCorrectAnswerQuestion label.styled p {
	top: 2px;
	left: 45px;
	font-size: 20px;
	color: #fff;
	position: absolute;
}

.showcorrect:not(.selected) {
	background: #65fcff;
}

.toetsContent .wvCheckButtonWrap {
	display: block;
}

.wvListAnswers {
	margin-bottom: 20px;
}

.wvListAnswer{
	background: #fff;
	margin: 10px 0;
	border-top: 1px solid #fff;
	border-bottom: 1px solid black;
	opacity: 0.2;
	position: relative;
	&.active, &.correct {
		opacity: 1;
	}
	&.selected {
		background: $themeColor;
		border-top: 1px solid black;
		border-bottom: 1px solid $midColor;
		a.wvListText {
			color: $buttonTextColor;
		}
	}
	&.correct, &.wrong{
		&::after{
			content: "";
			width:14px;
			height:14px;
			position:absolute;
			right:30px;
			top: calc(50% - 7px);
			border-radius: 14px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&.correct{
		border: 2px solid #148E00;
		&::after{
			background-color:#148E00;
			background-image:url("img/checkmark-small-white.svg");
		}
	}
	&.wrong{
		border: 2px solid #C20C39;
		&::after{
			background-color:#C20C39;
			background-image:url("img/cross-small-white.svg");
		}
	}
}



a.wvListText {
	color: #1e1e1e;
	padding: 10px 45px 10px 45px;
	font-size: 14px;
	display: block;
	font-family: $mainLightFont;
}

.wvListAnswerPoll {
	margin-bottom: 15px;
	margin-top: -9px;
	height: 20px;
	display: block;
	position: relative;
}

.wvListAnswerPoll .pollFiller {
	height: 100%;
	position: absolute;
	left: 0;
	z-index: 0;
	background: linear-gradient(to right, rgba(204, 164, 25, 1) 0%, rgba(121, 88, 0, 1) 100%);
}

.wvListAnswerPoll .pollInfo {
	font-size: 11px;
	font-family: $mainFont;
	position: relative;
	color: #fff;
	line-height: 20px;
	z-index: 1;
}

.wvIntro {
	font-family: "HarmoniaSansStd-Light", sans-serif;
	color: #1e1e1e;
	font-size: 20px;
	text-transform: uppercase;
	max-width: 220px;
	margin: 15px auto;
}

.wvInspiratieContent {
	width: 100%;
}

.wvInspiratieContent .inspiratiePicture {
	height: 320px;
	width: 320px;
	margin: 0 auto;
	background-size: cover;
}

.oddBackgroundStep {
	background-color: #ece6dc;
}

.evenBackgroundStep {
	background-color: #fff;
}

.wvCheckButtonWrap {
	width: 100px;
	z-index: 1;
	left: 0;
	right: 0;
	margin: auto;
	display: block;
}

.wvCheckButtonWrap.showFB {
	position: absolute;
}

a.wvCheckButton {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	background: #fff;
	font-family: $mainFont;
	color: $lightestColor;
	line-height: 16px;
	font-size: 16px;
	display: table-cell;
	vertical-align: middle;
}

.wvCheckButtonWrap.fadedInput {
	opacity: 0.15;
	pointer-events: none;
}

.wvCheckButtonWrap.hiddenInput {
	opacity: 0;
	pointer-events: none;
}

.wvFeedback {
	background: #fff;
	position: relative;
	z-index: 2;
	text-align: center;
	display: none;
}

.wvFeedback.showFB {
	display: block;
}

.wvFeedbackTitle {
	font-size: 28px;
	color: $lightestColor;
	text-transform: none;
	font-family: $mainLightFont_italic;
	padding-top: 32px;
	border-bottom: 1px solid $themeColor;
}

.wvFeedbackText {
	font-size: 14px;
	padding: 22px 13px;
	line-height: 29px;
	font-family: $mainLightFont;
}

a.wvFeedbackBack {
	border-radius: 50%;
	width: 78px;
	height: 78px;
	background: $darkColor url("img/closeWVlarge.png") no-repeat center center;
	background-size: 78px 78px;
	display: inline-block;
	margin-bottom: 50px;
}

a.wvFeedbackBackText {
	font-family: $mainFont;
	color: #1e1e1e;
	text-transform: uppercase;
	font-size: 12px;
	padding: 0 13px 30px;
	display: block;
}

a.werkvormBack {
	margin-bottom: 13px;
	margin-top: 50px;
	border-radius: 50%;
	width: 78px;
	height: 78px;
	background: #fff url("img/closeWVlargeBlack.png") no-repeat center center;
	background-size: 78px 78px;
	display: inline-block;
}

a.werkvormBackText {
	font-family: $mainFont;
	color: #fff;
	text-transform: uppercase;
	font-size: 12px;
	padding: 0 13px 30px;
	display: block;
}

.wvStellingWrap {
	margin-bottom: 22px;
	font-size: 0;
}

.wvStellingIntro .intro {
	font-size: 16px;
	margin: 18px auto;
	padding: 0 15px;
}

.wvStellingIntro img {
	width: 100%;
	margin-top: 30px;
}

.wvStellingAnswer{
	opacity: 0.2;
	font-family: $mainLightFont;
	font-size: 18px;
	border-bottom: 1px solid $lightestColor;
	cursor: pointer;
	width: 142px;
	background-color: #fff;
	display: inline-block;
	color: $lightestColor;
	line-height: 48px;
	position: relative;

	&:first-child {
		margin-right: 5px;
	}

	&.active, &.correct {
		opacity: 1;
	}

	&.selected {
		background: $themeColor;
		color: $midColor;
		border-bottom: 0;
	}
	&.correct, &.wrong{
		&::after{
			content: "";
			width:14px;
			height:14px;
			position:absolute;
			right:30px;
			top: calc(50% - 7px);
			border-radius: 14px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	&.correct{
		border: 2px solid #148E00;
		&::after{
			background-color:#148E00;
			background-image:url("img/checkmark-small-white.svg");
		}
	}
	&.wrong{
		border: 2px solid #C20C39;
		&::after{
			background-color:#C20C39;
			background-image:url("img/cross-small-white.svg");
		}
	}
}

.wvBerichtContent .testStudentPicture {
	border-radius: 50%;
	background-image: url('img/student2.png');
	background-size: cover;
}

.wvBerichtContent {
	max-width: 300px;
	margin: 0 auto;
}

.wvBerichtContent .row {
	text-align: left;
	border-bottom: 1px solid #000;
	width: 223px;
	height: auto;
	margin-bottom: 15px;
	position: relative;
	display: inline-block;
}

.wvBerichtContent .row.left {
	background-color: #fff;
	color: #000;
}

.wvBerichtContent .row.right {
	position: relative;
	margin-left: 140px;
	background-color: #fff;
	color: #000;
}

.wvBerichtContent .rowContent {
	height: 100px;
	background-color: #fff;
	margin: auto;
}

.wvBerichtContent .row .chatImage img {
	width: 100%;
	height: 100%;
	margin-bottom: 10px;
}

.wvBerichtContent .rowCol {
	display: inline-block;
	width: 100%;
}

.wvBerichtContent .row img {
	width: 71px;
	height: 71px;
	float: left;
}

.wvBerichtContent .row .rowCol .chatImage {
	display: block;
	float: left;
	padding: 2px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
}

.wvBerichtContent .row .rowCol .imageMessage {
	display: block;
	float: left;
	position: relative;
}

.wvBerichtContent .row .rowCol>img {
	margin: 5px 0 0 5px;
	width: 44px;
	height: 44px;
	margin-top: 0;
	margin-left: 13px;
	position: absolute;
	left: 0;
}

.wvBerichtContent .row .image_placeholder {
	width: 39px;
	height: 35px;
	margin-top: 0;
	margin-left: 4px;
	float: left;
}

.wvBerichtContent .msgFrom,
.wvBerichtContent .msgTo {
	font-family: $mainFont;
}

.wvBerichtContent .msgClose {
	width: 8px;
	height: 8px;
	background: url('img/grayCross.png');
	background-size: cover;
	opacity: 0.5;
	position: absolute;
	top: 10px;
	right: 10px;
}

.wvBerichtContent .intro {
	font-family: $mainLightFont;
	font-size: 16px;
	color: #fff;
	padding: 25px 20px;
	line-height: 22px;
}

.wvBerichtContent .row.hasChatImage .image_placeholder {
	float: left;
	margin-left: 0;
}

.wvBerichtContent .row.hasChatImage .imageMessage img {
	width: 44px;
	height: 44px;
	position: absolute;
	left: 0;
}

.wvBerichtContent .row.last {
	margin-bottom: 0;
	border: none;
}

.wvBerichtContent .chatLeft {
	position: absolute;
	background: url('img/chat-icon-mobile.png');
	background-size: 21px 39px;
	width: 21px;
	height: 39px;
	top: 6px;
	left: -14px;
}

.wvBerichtContent .chatRight {
	position: absolute;
	background: url('img/chat-mobile-right.png');
	background-size: 21px 39px;
	width: 21px;
	height: 39px;
	top: 6px;
	right: -13px;
}

.wvBerichtContent .studentTitles {
	background: #f2f2f2;
	padding: 10px 3px;
}

.wvBerichtContent .studentTitle {
	width: 180px;
	display: inline-block;
	font-size: 12px;
	color: #000;
	border-bottom: 1px solid #005f9e;
	padding-bottom: 5px;
	margin-left: 10px;
	font-family: $mainLightFont;
}

.wvBerichtContent .studentSubtitle {
	display: inline-block;
	font-size: 12px;
	color: #005f9e;
	padding-bottom: 1px;
	margin-left: 10px;
	margin-top: 5px;
	font-family: $mainLightFont;
}

.wvBerichtContent .studentInfo {
	top: 0;
	padding: 12px;
	position: relative;
	display: block;
	width: 100%;
	font-family: $mainLightFont;
	font-size: 14px;
	color: #666666;
}

.wvBerichtContent .row.rowContent {
	border-top: 1px solid #b2b2b2;
	text-align: center;
	height: 70px;
	margin: 0 0 0 125px;
}

.wvBerichtContent .bottom-back {
	border-radius: 50%;
	width: 78px;
	height: 78px;
	background: url('img/cross.png');
	background-color: #fff;
	background-size: 30px;
	margin: 35px auto 5px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.wvBerichtContent .bottom-text {
	margin-bottom: 50px;
	text-align: center;
	color: #fff;
	margin-top: 15px;
	font-family: $semiBoldFont;
	font-size: 12px;
}

.wvAdviesContent .time strong,
.wvBerichtContent .time b {
	font-family: $mainFont;
	color: #fff;
	font-size: 14px;
	margin-right: 5px;
}

.wvBerichtContent .time {
	line-height: 18px;
	font-family: "HarmoniaSansStd-Light", sans-serif;
	color: #fff;
	margin-top: 20px;
	margin-bottom: 22px;
}

.wvBerichtContent .studentInfo p {
	padding: 0 10px 0 0;
	display: block;
	line-height: 22px;
	font-size: 14px;
}

.wvBerichtContent .wvBerichtCenter {
	margin-left: -75px;
}

.wvVolgordeList {
	width: 294px;
	margin: auto;
	position: relative;
}

.wvVolgordeList .answer {
	height: 39px;
	border-bottom: 1px solid black;
	padding: 10px;
	cursor: pointer;
	margin: 0 auto 9px;
	position: relative;
	color: #000;
	background-color: #fff;
	box-sizing: content-box;
}





.wvVolgordeList{
	.answer{
		&.correct, &.wrong{
			&::after{
				content: "";
				width:14px;
				height:14px;
				position:absolute;
				right:30px;
				top: calc(50% - 7px);
				border-radius: 14px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		&.correct{
			border: 2px solid #148E00;
			&::after{
				background-color:#148E00;
				background-image:url("img/checkmark-small-white.svg");
			}
		}
		&.wrong{
			border: 2px solid #C20C39;
			&::after{
				background-color:#C20C39;
				background-image:url("img/cross-small-white.svg");
			}
		}
	}
}









.transform {
	-webkit-perspective: 800px;
	perspective: 800px;
	margin-bottom: 41px;
}

.wvVolgordeList .wvVolgordePlaceholder {
	width: 100%;
	height: 58px;
	margin-bottom: 13px;
}

.wvVolgordeIntro .intro {
	width: 295px;
	margin: 19px auto 25px;
	line-height: 20px;
	font-size: 16px;
	color: #fff;
	font-family: $mainLightFont;
	padding: 0 15px;
}

.wvVolgordeList .arrow {
	width: 30px;
	height: 30px;
	position: absolute;
	border-radius: 50%;
	top: 15px;
	right: 10px;
	background: none $lightestColor center no-repeat;
	background-size: 14px 8px;
}

.wvVolgordeList .arrow.up {
	background-image: url('img/smallArrowUpWhite.png');
}

.wvVolgordeList .arrow.down {
	background-image: url('img/smallArrowDownWhite.png');
	left: 10px;
}

.wvVolgordeList .arrow.disabled {
	opacity: 0;
}

.wvVolgordeList .butVisible {
	opacity: 1 !important;
}

.wvFeedbackOrder {
	height: 60px;
	font-family: $mainFont;
	color: #fff;
	line-height: 43px;
	cursor: pointer;
	font-size: 18px;
	margin: auto;
	background-color: $darkColor;
	padding: 10px;
	width: 215px;
	margin-bottom: 29px;
}

.wvVolgordeList .answerText {
	margin: auto;
	width: 200px;
	font-family: $mainLightFont;
	font-size: 15px;
	margin-top: 0;
}

.wvVolgordeList .faded {
	opacity: 0.2;
}

.oddBackground {
	background-color: rgb(255, 255, 255) !important;
	color: #1e1e1e;
}

.evenBackground {
	background-color: rgb(51, 51, 51) !important;
	color: #fff;
}

.wvProductBlocks .productBlock {
	overflow: hidden;
	text-align: center;
	width: 100%;
}

.wvProductBlocks .productBlock .innerBlock {
	position: relative;
	max-width: 320px;
	padding-bottom: 23px;
	padding-top: 22px;
	margin: 0 auto;
	text-align: left;
}

.wvProductBlocks .productBlock.firstBlock .innerBlock {
	text-align: center;
	margin: 0 auto;
}

.wvProductBlocks .productBlock.firstBlock .title {
	width: 230px;
	margin: 0 auto;
	font-size: 32px;
}

.productBlock .col {
	width: 100%;
	padding: 23px 0 0;
}

.productBlock .subTitle {
	margin-bottom: 20px;
	font-size: 12px;
}

.wvStappenplan .productBlock .titleGroup {
	text-align: center;
}

.wvStappenplan .productBlock .title {
	font-family: "MegaTeam", sans-serif;
	font-size: 20px;
}

.wvProductBlocks .productBlock.left .textCol .colContent {
	float: none;
	margin: 0 auto;
}

.wvProductBlocks .productBlock.left .col {
	float: right;
}

.wvProductBlocks .productBlock .leftCol .colContent {
	float: right;
}

.wvProductBlocks .productBlock .rightCol .colContent {
	float: left;
}

.wvProductBlocks .productBlock .colContent img {
	max-height: 100%;
	margin: auto;
	text-align: center;
	width: 100%;
	height: 100%;
	background-size: cover;
}

.wvProductBlocks .productBlock.firstBlock {
	text-align: center;
}

.wvProductBlocks .productBlock.firstBlock .title {
	font-family: $mainFont;
	color: $lightestColor;
}

.wvProductBlocks .productBlock.firstBlock.evenBackground .title {
	color: #fff;
}

.wvProductBlocks .productBlock.firstBlock .subTitle {
	font-size: 15px !important;
	margin-top: 4px;
	font-family: $mainFont;
}

.wvProductBlocks .productBlock {
	font-family: 'HarmoniaSansStd-Light', sans-serif;
}

.wvProductBlocks .productBlock .subTitle {
	font-size: 20px;
	margin-bottom: 30px;
	font-family: $mainFont;
}

.wvProductBlocks .productBlock.evenBackground .subTitle {
	color: #fff;
}

.wvProductBlocks .productBlock .productImg {
	width: 275px;
	height: 275px;
	overflow: hidden;
	border-radius: 50%;
	margin: 0 auto;
	background-size: cover;
}

.wvProductBlocks .productBlock .subTitle {
	margin: 0 auto;
	width: 295px;
	color: $lightestColor;
}

.wvProductBlocks .productBlock .endFooter {
	padding-bottom: 15px;
	padding-top: 15px;
	background: inherit;
}

.wvProductBlocks .productBlock .endFooter .closeBig {
	margin-bottom: 10px;
}

.wvProductBlocks .productBlock:not(.step).right .bottom-back {
	background-color: #fff;
	background-image: url("img/cross.png");
}

.wvProductBlocks .productBlock:not(.step).right .bottom-text {
	color: #fff;
}

.wvProductBlocks .text {
	p, li {
		font-family: "open_sanslight", sans-serif;
		font-size: 16px;
		line-height: 22px;
	}
}

.wvProductBlocks .text p {
	width: 295px;
	margin: 16px auto 5px;
}

.wvProductBlocks .productBlock:not(.firstBlock) .text {
	p, li {
		line-height: 30px;
	}
}

.wvProductBlocks .productBlock:not(.firstBlock) .text p {
	margin: 0 auto;
	width: 295px;
	padding: 15px 0;
}

.productBlock.firstBlock .text {
	p, li {
		line-height: 33px;
	}
}
.productBlock.firstBlock .text p {
	margin: 18px auto 0;
	padding-bottom: 22px;
}

.endFooter .bottom-back,
.wvProductBlocks .bottom-back {
	border-radius: 50%;
	width: 78px;
	height: 78px;
	background: url('img/cross-white.png');
	background-color: $darkColor;
	background-size: 30px;
	margin: 0 auto 5px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.endFooter .bottom-text,
.wvProductBlocks .bottom-text {
	text-align: center;
	color: #000;
	margin: 15px 0 0;
	font-size: 12px;
	font-family: 'open_sansbold', sans-serif;
	font-weight: bold;
}

.wvProductBlocks .productBlock .text {
	p, li {
		letter-spacing: 0.72px;
		line-height: 32px;
	}
}

.wvProductBlocks .productBlock .subTitle {
	letter-spacing: 0.72px;
}

.wvProductBlocks .productBlock.firstBlock .title {
	letter-spacing: 0.72px;
}

.wvInterviewContent .contentCol {
	padding-bottom: 35px;
}

.wvInterviewContent .contentCol .headSubText {
	letter-spacing: 0.72px;
	line-height: 35px;
}

.wvInterviewContent .contentCol .intro {
	letter-spacing: 0.72px;
}

.wvInterviewContent .answer {
	letter-spacing: 0.72px;
	line-height: 30px;
}

.wvInterviewContent .question,
.wvInterviewContent .quoteText {
	letter-spacing: 0.72px;
	line-height: 30px;
}

.wvInterviewContent .endFooter {
	display: block;
}

.oddBackground .wvHeaderTitle {
	color: $lightestColor;
}

.oddBackground .endFooter .bottom-back,
.oddBackground .wvProductBlocks .bottom-back {
	background-image: url('img/cross-white.png');
	background-color: $darkColor;
}

.oddBackground .endFooter .bottom-text,
.oddBackground .wvProductBlocks .bottom-text {
	color: $darkColor;
}

.oddBackgroundStep .endFooter .bottom-back {
	background-image: url('img/cross-white.png');
	background-color: $darkColor;
}

.oddBackgroundStep .endFooter .bottom-text {
	color: $darkColor;
}

.wvInterviewWrap .wvContentBuffer {
	height: 100%;
}

.wvInterviewWrap {
	height: 100%;
}

.wvInterviewContent {
	top: -290px;
	width: 100%;
	position: relative;
	left: 0;
	text-align: center;
	margin-left: 0;
}

.wvInterviewContent:after {
	content: '';
	background: $darkColor;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.95;
	z-index: 1;
}

.spacer {
	width: 1px;
	height: 1px;
}

.wvInterviewContent .contentCol {
	position: relative;
	height: 100%;
	z-index: 2;
}

.wvInterviewContent .contentCol .headText {
	color: #fff;
	font-size: 60px;
	font-family: $mainFont;
	width: 320px;
	margin: auto;
	padding-top: 78px;
	line-height: 61px;
}

.wvInterviewContent .contentCol .headSubText {
	color: #fff;
	text-align: center;
	font-size: 20px;
	font-family: $mainLightFont;
}

.wvInterviewContent .contentCol .intro {
	color: #fff;
	font-size: 20px;
	font-family: $mainFont;
	padding: 60px 85px 0;
	line-height: 30px;
}

.wvInterviewContent .number {
	line-height: 36px;
	color: $midColor;
	height: 34px;
	width: 34px;
	border-radius: 50%;
	background: $themeColor;
	margin: 62px auto 5px;
	font-family: "HarmoniaSansStd-SemiBd", sans-serif;
	font-size: 18px;
}

.wvInterviewContent .question {
	padding: 0 100px;
	font-family: $mainFont;
	font-size: 24px;
	color: #fff;
	border-bottom: 1px solid $themeColor;
	display: inline-block;
}

.wvInterviewContent .answer {
	padding: 42px 85px;
	font-size: 19px;
	font-family: $mainLightFont;
	color: #fff;
	line-height: 30px;
	margin-bottom: 30px;
}

.wvInterviewContent .answerImage {
	padding: 4px 85px;
	margin: auto;
}

.wvInterviewContent .answerImage img {
	width: 100%;
	height: 100%;
}

.wvInterviewContent .quote {
	text-align: center;
	background-color: $themeColor;
	color: $midColor;
	padding: 36px 100px;
	margin-top: 12px;
}

.wvInterviewContent .quote img {
	width: 200px;
	height: 200px;
}

.wvInterviewContent .quoteText {
	width: 340px;
	margin: 26px auto 0;
	font-size: 24px;
	font-family: $mainFont;
}

.wvInterviewContent .quoteText:after {
	content: "\201D";
}

.wvInterviewContent .quoteText:before {
	content: "\201C";
}

.wvInterviewContent .quote+.number {
	margin-top: 46px !important;
}

.rounded-image {
	overflow: hidden;
	width: 198px;
	height: 198px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 auto;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.endFooter .bottom-back {
	background-color: $themeColor;
	background-image: url("img/cross.png");
	margin: 35px auto 50px;
}

.endFooter .bottom-text {
	color: #fff;
}

.wvInterviewContent .answerImage {
	padding: 22px 12px 11px;
	margin-bottom: 20px;
}

.wvInterviewContent .contentCol .intro {
	padding: 32px 15px 45px;
	line-height: 26px;
	font-size: 17px;
}

.wvInterviewContent .number {
	margin-top: 5px;
	font-size: 17px;
	line-height: 30px;
	height: 30px;
	width: 30px;
	margin-bottom: 8px;
}

.wvInterviewContent .answer {
	padding: 1px 12px;
	line-height: 26px;
	font-size: 16px;
}

.wvInterviewContent .question {
	padding: 1px 19px;
	font-size: 20px;
	line-height: 25px;
}

.wvInterviewContent .quote {
	padding: 34px 0 33px;
	margin: 30px 0;
}

.wvInterviewContent .quoteText {
	width: 275px;
	line-height: 26px;
}

.wvWatbijwatContent .blockGrid {
	font-size: 0;
	-webkit-perspective: 1000;
	perspective: 1000;
}

.wvWatbijwatContent .blockGrid .blockSet {
	position: relative;
	width: 304px;
	margin: 0 auto;
}

.wvWatbijwatContent .blockGrid .blockSet .item.selected {
	outline: 4px solid;
}

.wvWatbijwatContent .blockGrid .blockSet .item {
	display: inline-block;
	margin: 5px;
	box-sizing: border-box;
	width: 142px;
	height: 160px;
	background: #fff;
	color: #1e1e1e;
}

.wvWatbijwatContent .blockGrid .blockSet .text {
	position: relative;
	padding: 4px 10px;
}

.wvWatbijwatContent .blockGrid .blockSet.faded {
	opacity: 0.2;
}

.wvWatbijwatContent .blockGrid .blockSet .ghost {
	position: absolute;
	right: 0;
}

.wvWatbijwatContent .blockGrid .blockSet .image {
	max-width: 100%;
	max-height: 100%;
	position: relative;
	background-size: cover;
	background-position: center;
}

.wvWatbijwatContent .blockGrid .blockSet .image:hover {
	outline: 2px solid;
}

.wvWatbijwatContent .blockGrid .blockSet .image.active {
	outline: 4px solid;
}

.wvWatbijwatContent .blockGrid .blockSet .image img {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.wvWatbijwatContent .blockGrid .blockSet .text .vcenter {
	height: 100%;
	width: 100%;
}

.wvWatbijwatContent .blockGrid .blockSet .text .vcenter div.itemText {
	padding: 29px 2px;
	display: table-cell;
	font-size: 16px;
	font-family: $mainLightFont;
	vertical-align: middle;
	text-align: center;
	width: 142px;
	height: 160px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows {
	width: 122px;
	height: 20px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows.top {
	position: absolute;
	top: 0;
	left: 10px;
	margin: 7px 0;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows.bottom {
	position: absolute;
	bottom: 0;
	left: 10px;
	margin: 7px 0;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav {
	background-color: $lightestColor;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: block;
	background-repeat: no-repeat;
	background-size: 8px 12px;
	background-position: center;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.left {
	margin: 7px 10px 4px;
	display: none;
	float: left;
	background-image: url('img/smallArrowPrevWhite.png');
	background-position-x: 5px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.right {
	margin: 7px 10px 4px;
	display: none;
	float: right;
	background-image: url('img/smallArrowNextWhite.png');
	background-position-x: 7px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.top {
	display: block;
	margin: 0 auto;
	background-image: url('img/smallArrowUpWhite.png');
	background-position-x: 4px;
	background-size: 12px 8px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.bottom {
	display: block;
	margin: 0 auto;
	background-image: url('img/smallArrowDownWhite.png');
	background-position-x: 4px;
	background-size: 12px 8px;
}

.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.disabled {
	opacity: 0;
}

.wvHotspotIntro .intro {
	width: 295px;
	margin: 19px auto 25px;
	font-size: 16px;
	color: #fff;
	font-family: $mainFont;
	padding: 0 15px;
}

.wvHotspotContent {
	width: 300px;
	margin: 0 auto;
}

.wvHotspotContent .hotspotImgContainer {
	position: relative;
	margin-bottom: 24px;
}

.wvHotspotContent .hotspotImgContainer img {
	width: 100%;
}

.wvHotspotContent .answer {
	width: 38px;
	height: 38px;
	position: absolute;
	border-radius: 50%;
	border: 4px solid $midColor;
	z-index: 100;
	background: rgba(57, 54, 49, 0.8);
}

.wvHotspotContent .answer.userAnswer {
	-webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	background: rgba(246, 236, 29, 0.8);
	margin-left: -15px;
	margin-top: -15px;
}

.wvHotspotContent .hotspotArea {
	position: absolute;
	opacity: 0;
}

.wvHotspotContent .hotspotArea.circular {
	border-radius: 50%;
}

.wvHotspotContent .hotspotArea .answer {
	top: 50%;
	left: 50%;
	margin-left: -19px;
	margin-top: -19px;
	-webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
}

.wvHotspotContent .answer.dummyPoint {
	display: none;
}

.wvHotspotContent .prepositioned {
	display: block;
	opacity: 1;
}

input:focus,
textarea:focus {
	outline: 0;
}

.header-big-white {
	width: 100%;
	height: 100px;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 0;
	top: 0;
	position: fixed;
}

.header-big {
	width: 100%;
	height: 100px;
	background-color: #a28554;
	text-align: center;
	display: table;
	z-index: 1;
	position: fixed;
}

.header-big .wrap {
	display: table-cell;
	vertical-align: middle;
}

.header-big .wrap .logo {
	background-image: url('img/GCP_logo.png');
	background-size: cover;
	width: 44px;
	height: 52px;
	margin: 5px auto 0;
}

.header-big .wrap .logo img,
.wvBaseContainer .baseHeader .logo img {
	max-height: 52px;
	margin-top: 5px;
}

.baseHeader .tagline,
.wvBaseContainer .header-big .wrap .tagline {
	font-size: 15px;
	font-family: 'open_sanslight', sans-serif;
	color: #fff;
	letter-spacing: 2px;
	margin-top: 6px;
}

.baseHeader .logo {
	height: 100px;
	width: 178px;
	background: url('img/GCP_logo.png');
	background-size: cover;
	margin: 20px auto 8px;
}

.baseHeader .tagline {
	font-size: 20px;
}

.baseHeader {
	margin-bottom: 65px;
	text-align: center;
}

.baseHeaderSpacer {
	height: 60px;
	width: 100%;
}

.content {
	max-width: 100%;
	padding: 0 20px;
}

.miniature.content {
	padding: 0;
}

.content .title span {
	font-family: $mainFont;
	font-size: 40px;
	color: #fff;
}

.content .title {
	width: 100%;
	height: 65px;
	line-height: 50px;
	text-align: center;
}

.content table.inputs {
	border-collapse: separate;
	border-spacing: 0 25px;
	margin: auto;
}

.content table.inputs tr {
	height: 60px;
	background: #ffffff;
}

.content table.inputs tr td {
	border: 1px solid $darkColor;
	width: 280px;
	cursor: text;
	position: relative;
}

.content table.inputs tr td .rememberme {
	margin-top: 15px;
	border: 0;
}

.content table.inputs tr td.error {
	border-color: #ff1432;
}

.content table.inputs tr td.error span {
	color: #ff1432;
}

.content.changePassword table.inputs,
.content.forgotPassword table.inputs,
.content.login table.inputs {
	border-spacing: 0 15px;
	margin-top: 10px;
}

.content.changePassword table.inputs tr,
.content.forgotPassword table.inputs tr,
.content.login table.inputs tr {
	height: 40px;
}

.content.changePassword table.inputs tr td span,
.content.forgotPassword table.inputs tr td span,
.content.login table.inputs tr td span {
	font-size: 12px;
	margin: 5px 5px 5px 10px;
}

.content.changePassword table.inputs tr td input {
	font-size: 17px;
	margin: 20px 10px 10px;
}

.content.forgotPassword table.inputs tr td input,
.content.login table.inputs tr td input {
	font-size: 17px;
	margin: 20px 10px 10px;
}

.action .content.changePassword .forgotPassLink,
.action .content.forgotPassword .forgotPassLink,
.action .content.login .forgotPassLink {
	margin-bottom: 25px;
	margin-top: -12px;
	font-size: 11.5px;
	width: 100%;
	padding: 0 5px;
}

.content.login .submitBtn {
	background: $themeColor;
	border: 0;
	width: 170px;
	height: 40px;
	font-size: 15px;
	line-height: 40px;
}

.action .metalink {
	text-align: center;
	font-size: 13px;
	color: #fff;
}

.webshop .error {
	color: #fff;
}

.webshop .content .error.errorMessage {
	position: absolute;
	margin-top: 10px;
}

.webshop .content .errorMessage {
	position: absolute;
	margin-top: 10px;
	display: none;
}

.webshop .content .error.errorMessage span {
	display: block;
}

.webshop .content .errorMessage span {
	display: none;
}

.webshop .content table.inputs {
	width: 100%;
}

.webshop .content .orderOptions {
	margin: 15px 0 0;
}

.webshop .content table.inputs tr {
	height: 40px;
	background: transparent;
}

.webshop .content table.inputs tr td {
	border: none;
}

.webshop .content table.inputs tr td span {
	margin: 5px 5px 5px 10px;
	font-size: 12px;
}

.webshop .content table.inputs tr td input {
	background: #ffffff;
	font-size: 17px;
	margin: 0;
	padding: 20px 10px 10px;
	width: 230px;
	border: 1px solid $darkColor;
}

.webshop .content .orderDiscount .inputRow .checkDiscount {
	font-size: 15px;
	height: 52px;
	width: 62px;
	line-height: 15px;
	cursor: pointer;
}

.webshopLogin {
	margin: 50px 0 0;
}

.webshopLogin .content {
	padding: 0 15px;
}

.webshopLogin .content table.inputs {
	border-collapse: separate;
	border-spacing: 0 15px;
	margin: auto;
}

.webshopLogin .content table.inputs tr td input {
	width: 100%;
}

.webshopLogin .content .submitBtn {
	width: 100%;
	height: 40px;
	font-size: 15px;
	line-height: 40px;
	max-width: 170px;
	background: $themeColor;
	border: none;
}

.webshopLogin .content table.inputs.loginInputs {
	border-spacing: 0;
}

.webshopLogin .content table.inputs.loginInputs tr td input {
	margin-bottom: 16px;
}

.webshopLogin .content table.inputs.loginInputs tr td input.password {
	margin-bottom: 0;
}

.webshopLogin .content table.inputs.loginInputs .forgotPassLink {
	margin-bottom: 10px;
	text-decoration: none;
}

.webshopLogin .content .forgotPass {
	color: #fff;
	font-size: 13px;
	line-height: 20px;
}

.webshopLogin .content .forgotPassLink {
	text-decoration: none;
}

.register-text {
	color: #fff;
	max-width: 570px;
	margin: auto;
	text-align: center;
}

.register-text .register-intro {
	padding: 35px 50px 0;
	font-family: $mainLightFont;
	font-size: 15px;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.8);
}

.register-text .register-title {
	font-family: $mainBoldFont;
	font-size: 16px;
	padding: 35px 50px;
}

.content.registerPage {
	padding: 0 0 75px;
	font-family: $mainLightFont;
}

.blockTitle {
	color: #fff;
	border-bottom: 2px solid #fff;
	display: inline-block;
	margin: 20px 0;
	padding: 0 0 2px;
	text-align: left;
	font-family: $mainBoldFont;
}

.formInputs .blockTitle+.outerRow {
	display: block;
}

.wvProductGroup .blockTitle {
	margin: 0 0 20px 20px;
}

.formInputs.block.factAddress .rowCol:nth-child(2) {
	margin-top: 61px;
}

.formInputs .rowCol {
	display: inline-block;
	margin: auto;
	width: 100%;
	text-align: center;
}

.formInputs.block {
	text-align: center;
	padding-bottom: 40px;
}

.formInputs.block:not(:last-of-type) {
	border-bottom: 2px solid #707070;
	background: linear-gradient(to top, rgba(112, 112, 112, 0.3) 0%, rgba(112, 112, 112, 0) 10%);
}

.formInputs .outerRow {
	text-align: center;
	margin-bottom: 15px;
	display: inline-block;
}

.formInputs .inputRow {
	border: 1px solid $darkColor;
	cursor: text;
	position: relative;
	height: 47px;
	background: #fff;
	display: inline-block;
	width: 280px;
	max-width: 100%;
	text-align: left;
}

.formInputs .inputRow .selectParentSpace {
	clear: both;
	height: 20px;
	width: 280px;
}

.formInputs .inputRow .selectParentDiv {
	width: 280px;
	text-align: center;
	padding-top: 1px;
}

.formInputs .inputRow.error {
	border-color: #df213c;
	color: #df213c;
}

.quickscan.webshop .wvBaseContainer.webshop.licentie .content .bgImage{
	display:none;
}

.formInputs .bignumberField .inputRow {
	background: transparent;
	border: none;
}

.formInputs .inputRow span {
	position: absolute;
	top: 0;
	left: 0;
	color: $lightestColor;
	font-size: 12px;
	margin: 3px 3px 3px 10px;
	font-family: $mainLightFont;
}

.formInputs .inputRow input,
.formInputs .inputRow textarea {
	background: transparent;
	border: none;
	font-family: $mainLightFont;
	font-size: 17px;
	color: $darkColor;
	margin: 17px 9px 8px;
	width: 260px;
}

.formInputs .genderField .inputRow {
	width: 134px;
	margin-right: 8px;
}

.formInputs .titleField .inputRow {
	width: 134px;
}

.formInputs .initialsField .inputRow {
	width: 134px;
	margin-right: 8px;
}
.formInputs .tussenvoegselField .inputRow {
	width: 134px;
}

.linkedInButton {
	margin: 0;
}

.bgImage {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: no-repeat center top;
	z-index: -1;
	background-size: cover;
}

.bgImage img {
	position: absolute;
	left: 0;
	top: 0;
	display: none;
}

.bgImage::after {
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(rgba(53, 52, 51, 0.5), rgba(53, 52, 51, 1));
	z-index: -1;
}

.wvBaseContainer {
	position: relative;
	z-index: 5;
}

.content.lessons {
	padding: 0 12px;
}

.content table.inputs.lessons tr {
	height: 70px;
}

.content table.inputs.lessons tr td {
	width: 295px;
	border-color: #353535;
	position: relative;
}

.content .indicator.active {
	background: #008a8d;
}

.content .indicator {
	margin-right: 8px;
	background: #1e1e1e;
	text-align: center;
	line-height: 31px;
	font-size: 16px !important;
	display: inline-block;
	border-radius: 50%;
	height: 32px;
	width: 31px;
	color: #fff;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
}

.content table.inputs.lessons tr.hasLink td {
	cursor: pointer;
}

.content table.inputs.lessons tr td .info .date {
	position: relative;
	margin: 0 auto;
	font-family: HarmoniaSansStd-Light, sans-serif;
	font-size: 16px;
	top: 16px;
	color: #fff;
}

.content table.inputs.lessons tr td.started .info .date {
	top: 8px;
}

.content table.inputs.lessons tr td.started .info .indicator {
	margin: 0 5px 5px 10px;
}

.content table.inputs.lessons tr td .info .indicator {
	background: #1e1e1e;
	position: absolute;
	left: 0;
	color: #fff;
	margin: 8px 8px 8px 10px;
}

.content table.inputs.lessons tr td .info {
	height: 40px;
	padding: 11px 11px 11px 2px;
	position: absolute;
	text-align: center;
	width: 284px;
}

.content table.inputs.lessons tr td .progress {
	display: none;
}

.content table.inputs.lessons tr td.started .progress {
	display: block;
	bottom: 9px;
	position: absolute;
	right: 13px;
	height: 5px;
}

.content table.inputs.lessons tr td .progress .progressbar .filler {
	background-color: #008a8d;
	height: 10px;
	height: 5px;
}

.content table.inputs.lessons tr td .progress .progressbar {
	width: 270px;
	margin-top: 0;
	height: 5px;
	display: inline-block;
	background-color: #505050;
}

.content .intro .icon.dots {
	background: url("img/dots-icon.png");
	width: 24px;
	height: 25px;
	background-size: cover;
	display: block;
	margin: 0 auto 22px;
	background-color: #fff;
	border-radius: 50%;
}

.content .intro .introText {
	margin-bottom: 8px;
}

.commmentBlock {
	margin-top: 10px;
}

.commmentBlock td {
	padding: 5px 5px 5px 0;
	vertical-align: middle;
}

.content.login .inputRow label.styled,
.content.profile .inputRow label.styled {
	width: 100%;
	height: 30px;
}

.inputRow .select.radiobutton {
	display: inline-block;
}

.styled-outer {
	max-width: 620px;
	margin: auto;
	text-align: left;
	text-align: center;
	color: #fff;
	font-size: 15px;
	line-height: 15px;
}

label.styled {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 100%;
}

label.styled.newletterCheckbox {
	text-align: center;
	margin: 40px 0 15px;
}

.webshop label.styled.conditions {
	margin: -20px 0 15px -20px;
	padding: 20px;
}

label.styled p {
	color: $darkColor;
	font-family: $mainLightFont;
	font-size: 14px;
	line-height: 22px;
}

.content.login label.styled p,
.content.profile label.styled p {
	right: auto;
	text-align: left;
	padding-left: 40px;
	padding-top: 4px;
}

.inputRow .select label.styled p {
	padding-left: 14px;
	padding-top: 22px;
}

.login label.styled p,
.login label.styled span {
	margin: 10px 0;
}

.content.login .rememberme.login label.styled p {
	right: 25px;
	left: auto;
	padding-top: 0;
}

label.styled [type="checkbox"],
label.styled [type="radio"] {
	//display: none;
}

label.styled [type="checkbox"]+span {
	display: none;
}

label.styled [type="radio"]+span {
	display: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: url('img/GCP_checkbox.png');
	background-size: cover;
	margin: 0 5px 0 0;
}
.registerPage [type="checkbox"] {
	margin: 0px;
}

.registerPage label.styled [type="checkbox"]+span{
	display: none;
}

label.styled [type="radio"]+span {
	width: 15px;
	height: 15px;
}

label.styled.nvt [type="checkbox"]+span,
label.styled [type="radio"]+span {
	width: 25px;
	height: 25px;
	margin: 0 8px;
}

label.styled [type="checkbox"]:checked+span,
label.styled [type="radio"]:checked+span {
	display: inline-block;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-image: url('img/GCP_checkbox_checked.png');
	background-size: cover;
	margin: 0 5px 0 0;
}

.registerPage label.styled [type="checkbox"]:checked+span,
label.styled [type="radio"]:checked+span {
	display: none;
	width: 15px;
	height: 15px;
}

label.styled.nvt [type="checkbox"]:checked+span,
label.styled [type="radio"]:checked+span {
	width: 25px;
	height: 25px;
	margin: 0 8px;
}

.checkedBoxchecked {
	background-image: url('img/GCP_checkbox_checked.png') !important;
}

label.styled [type="checkbox"][disabled]+span,
label.styled [type="radio"][disabled]+span {
	background: #f00;
}

label.styled.purple [type="checkbox"]+span,
label.styled.purple [type="radio"]+span {
	border: none;
	box-shadow: 0 -1px 0 0 rgba(4, 4, 4, 1);
	margin-top: 1px;
	height: 31px;
}

label.styled.purple:checked+span {
	box-shadow: none;
	height: 32px;
	margin-top: 0;
	background-color: #008a8d;
	background-image: url('img/blackCorrect.png');
}

.lesblokParent {
	width: 100%;
	margin: 0 auto;
}

a.lesblok {
	display: block;
	width: 100%;
	border: 1px solid #353535;
	background: $darkColor;
	font-size: 16px;
	font-family: HarmoniaSansStd-Light, sans-serif;
	color: white;
	position: relative;
	margin-bottom: 10px;
}

.lesblokTitle {
	text-align: center;
	padding: 16px 55px 8px;
}

.lesblokIndicator {
	position: absolute;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	background: #1e1e1e;
	left: 20px;
	top: 10px;
	text-align: center;
	line-height: 32px;
	font-family: $semiBoldFont;
}

.lesblokProgress {
	background: #505050;
	height: 5px;
	margin: 10px 20px;
}

.lesblokFiller {
	background: #008a8d;
	height: 5px;
}

.noProgress .lesblokIndicator {
	top: 20px;
}

.noProgress .lesblokTitle {
	padding: 25px 55px;
}

.content table.inputs tr td span {
	position: absolute;
	top: 0;
	left: 0;
	color: $lightestColor;
	font-size: 12px;
	margin: 5px 5px 5px 10px;
	font-family: $mainLightFont;
}

.content.profile table.inputs tr td span {
	display: inline-block;
	margin-top: 0;
	margin-left: 15px;
}

.content table.inputs tr td input,
.content table.inputs tr td textarea {
	background: transparent;
	border: none;
	font-family: $mainLightFont;
	font-size: 17px;
	color: $lightestColor;
	margin: 20px 10px 10px;
	width: 260px;
}

.content.profile table.inputs tr td input,
.content.profile table.inputs tr td textarea {
	margin-left: 5px;
}

.content table.inputs tr td input[type="checkbox"] {
	width: auto;
}

.content table.inputs tr td input.password-mock {
	display: none;
}

.content.profile table.inputs tr td span {
	display: inline-block;
	margin-top: -2px;
	margin-bottom: 1px;
	margin-left: 15px;
}

.chartTop {
	margin-bottom: 15px;
}

.highchart {
	width: 100%;
	height: 175px;
	overflow: visible;
}

.highchartNav {
	display: inline-block;
	text-align: left;
}

.nav-item {
	display: inline-block;
	cursor: pointer;
	width: 65px;
	height: 45px;
	background-color: $lightestColor;
	opacity: 0.3;
	color: #fff;
	text-align: center;
	line-height: 45px;
	font-size: 15px;
	font-family: $mainFont;
	margin: 0 2px;
}

.nav-item.active {
	background-color: $themeColor;
	color: $midColor;
	opacity: 1;
}

.legend {
	text-align: left;
	display: inline-block;
	margin-left: 10px;
	font-family: $mainFont;
}

.legend .item {
	line-height: 10px;
	margin: 8px 0;
}

.legend .bullet {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #3cc0c1;
	display: inline-block;
	margin-right: 3px;
}

.legend .bullet.main{
	background: #f6ec1d;
}

.legend .bullet.alt {
	background: #ef9a30;
}

.content .rememberme input[type="checkbox"] {
	margin: 8px;
	width: auto;
}

.content .rememberme {
	display: block;
	padding-left: 5px;
	height: 44px;
	background: transparent;
	width: 280px;
	margin: 10px auto 0;
	font-family: $mainFont;
	font-size: 16px;
	color: #fff;
	line-height: 30px;
}

.content .error.wvChooseProduct.disabled,
.content .submitBtn.disabled {
	opacity: 0.3;
	cursor: default;
	pointer-events: none;
}

.content .submitBtn {
	width: 100%;
	display: block;
	height: 58px;
	background: $themeColor;
	border: 1px solid #353535;
	max-width: 280px;
	margin: 10px auto 0;
	font-family: $mainFont;
	font-size: 20px;
	color: $buttonTextColor;
	text-align: center;
	line-height: 58px;
	cursor: pointer;
}

.content .submitBtn.changePasswordBtn {
	background: $themeColor;
	border: 1px solid $themeColor;
	color: $midColor;
}

.content .submitBtn.activateLicenseBtn,
.content .submitBtn.changePassword,
.content .submitBtn.forgotPassword,
.content .submitBtn.register {
	background: $themeColor;
	color: $buttonTextColor;
	border: 0;
}

.content .submitBtn.activateLicenseBtn,
.content .submitBtn.activateLicenseBtn,
.content .submitBtn.changePassword,
.content .submitBtn.changePassword,
.content .submitBtn.forgotPassword,
.content .submitBtn.forgotPassword,
.content.passwordChanged .submitBtn,
.register .content .submitBtn.register,
.webshop .content .submitBtn.register {
	max-width: 170px;
	font-size: 15px;
	height: 40px;
	line-height: 40px;
}

.content .submitBtn.submitCode {
	background: #3cc0c1;
	border: 0;
}

.content.passwordChanged .submitBtn span {
	background-image: url("img/GCP_arrowWhiteRightLarge.png");
	height: 25.5px;
	width: 11px;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	margin-left: 15px;
	margin-top: 6px;
}

.content:not(.forgotPassword) .submitBtn {
	text-transform: none;
}

.content.error .submitBtn:not(.changePassword) {
	background-color: #ff1432;
	border-color: #ff1432;
}

.arrowBtn {
	width: 278px;
	margin: 15px auto 0;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.arrowBtn .icon {
	margin-right: 5px;
	display: inline-block;
	width: 19px;
	height: 20px;
	background: #00848d;
	border-radius: 50%;
	background-image: url('img/arrow-button-left.png');
	background-position: center;
	background-size: 100%;
}

.errorMessage .arrowBtn span {
	color: #1e1e1e;
}

.arrowBtn span {
	vertical-align: baseline;
	font-family: $mainLightFont;
	font-size: 15px;
	color: $darkColor;
}

.forgotPassLink {
	text-decoration: underline;
	font-size: 14px;
	font-family: $mainLightFont;
	text-align: right;
	width: auto;
	margin: 0 auto 15px;
}

.forgotPass {
	text-decoration: none;
}

.gcpbody .forgotPass {
	color: #fff;
}

.content .error.errorMessage,
.login .content.error .errorMessage,
.register .content.error .errorMessage {
	width: 280px;
}

.content .error.errorMessage:after,
.content.error .errorMessage:after {
	border-style: solid;
	border-color: #fff transparent;
	display: block;
	width: 0;
	top: -17px;
	bottom: auto;
	left: auto;
	right: 118px;
	border-width: 0 18px 17px;
	content: "";
	position: absolute;
}

.content .error.errorMessage,
.content.error .errorMessage {
	position: relative;
	padding: 20px;
	margin: 1em auto 3em;
	color: #1e1e1e;
	background: #fff;
	margin-top: 10px;
	font-family: $mainLightFont;
	text-align: center;
	font-size: 16px;
	width: 295px;
}

.webshopLogin .errorMessage {
	position: absolute !important;
	transform: translateY(0.5em);
}

.content .error.errorMessage div,
.content .errorMessage .error div {
	color: #1e1e1e !important;
	text-align: left !important;
}

.content .errorMessage {
	-webkit-box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.3);
	-moz-box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.3);
	box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.3);
}

.content:not(.error) .errorMessage {
	display: none;
}

.error .errorMessage {
	display: block;
}

.content.error .arrowBtn.login.openForgotPass {
	display: none;
}

.content .intro {
	padding: 0;
	margin: 0 auto;
	text-align: center;
	width: 290px;
}

.content .intro .introTitle b {
	color: #008a8d;
	font-weight: 400;
}

.content .intro .introTitle {
	font-family: $mainFont;
	font-size: 16px;
	color: #fff;
}

.content .intro .introText {
	font-family: $mainExtraLightFont;
	color: $darkColor;
	font-size: 15px;
	margin-bottom: 22px;
}

.content.passwordChanged .intro .introText {
	color: #ffffff;
}

.formSubmitted {
	margin: 0 auto;
	width: 282px;
}

.formSubmitted .inputPlaceholder .input-placeholder {
	border: none;
	font-family: 'open_sanslight', sans-serif;
	font-size: 17px;
	color: $darkColor;
	margin: 16px 0;
	position: relative;
	top: 20px;
}

.formSubmitted .inputPlaceholder .tagline {
	position: absolute;
	color: #008a8d;
	font-size: 10px;
	margin: 5px 5px 5px 0;
	font-family: $mainFont;
}

.formSubmitted .inputPlaceholder {
	width: 282px;
	border-top: 1px solid #008a8d;
}

div.content.forgotPasswordSent>div.submitBtn.forgotPassword {
	margin-top: 61px;
	background: #008a8d;
	cursor: default;
}

.submenu-spacer {
	height: 40px;
	margin-top: 10px;
	display: block;
}

.submenuPages {
	position: absolute;
	white-space: nowrap;
	top: 5px;
}

.submenu {
	-webkit-overflow-scrolling: touch;
	text-align: center;
	position: relative;
	overflow: hidden;
	height: 40px;
}

.submenu .item.active {
	border-top: 6px solid #ccc9c8;
	padding-bottom: 2px;
	margin-top: 0;
}

.submenu .item {
	margin-top: 6px;
	padding: 3px;
}

.submenu .item {
	font-family: $mainFont;
	font-size: 11px;
	color: #fff;
	text-transform: lowercase;
	display: inline-block;
	margin-right: 42px;
}

.submenu .item:last-child {
	margin-right: 0;
}

.blackMenu .submenu .item {
	color: $darkColor;
}

.submenu .item.active {
	font-family: $mainBoldFont;
}

.wvBaseContainer .content table.inputs.followup {
	border-spacing: 0 8px;
}

.content table.inputs.lessons.followup {
	width: 100%;
}

.content .subTitleContent .date {
	text-transform: uppercase;
	font-family: open_sanslight, sans-serif;
	color: #008a8d;
	font-size: 15px;
	margin-bottom: 22px;
}

.content .subTitleContent {
	text-align: center;
}

.content .subTitleContent .icons {
	margin-bottom: 30px;
	margin-top: 10px;
}

.content .subTitleContent .icons .icon {
	margin: 0 5px;
	height: 35px;
	width: 35px;
	display: inline-block;
	background-color: #008a8d;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px;
}

.content .subTitleContent .icons .icon.chair {
	background-image: url('img/chair-icon.png');
}

.content .subTitleContent .icons .icon.comb {
	background-image: url('img/calc-icon.png');
}

.content .subTitleContent .icons .icon.bucket {
	background-image: url('img/drop-icon.png');
}

.content table.inputs.lessons.followup tr td {
	width: auto;
	height: 80px;
	box-sizing: content-box;
}

.content table.inputs.lessons.followup tr.hidden {
	display: none;
}

.content table.inputs.lessons.followup tr td a.fullLink {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
}

.content table.inputs.lessons.followup tr td.fullWidth {
	background-color: #000;
	background-size: cover;
}

.content table.inputs.lessons.followup tr td.fullWidth .info .text {
	margin: 0 10px;
	font-size: 12px;
}

.content table.inputs.lessons.followup tr td .info .text .subTitle {
	font-size: 15px;
	font-family: $mainLightFont;
	color: $midColor;
	text-align: center;
	padding-bottom: 30px;
	max-width: 180px;
	margin: 8px auto auto;
}

.content table.inputs.lessons.followup tr td.fullWidth .info .text b {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
}

.content table.inputs.lessons.followup tr td.fullWidth .info .text i {
	font-style: normal;
	color: #008a8d;
}

.content table.inputs.lessons.followup tr td .image,
.content table.inputs.lessons.followup tr td.fullWidth .status {
	z-index: 2;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 0;
	top: 0;
	background-size: cover;
}

.content table.inputs.lessons.followup tr td.fullWidth .status .bookUpload {
	background: url("img/bookUpload.png");
	background-size: contain;
	height: 40px;
	width: 40px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
}

.content table.inputs.lessons.followup tr td.fullWidth .status .incomplete {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	background: rgba(0, 0, 0, .3);
	box-shadow: 0 -2px 0 0 rgba(4, 4, 4, 1);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.content table.inputs.lessons.followup tr td .info {
	top: 0;
	right: 0;
	width: 65%;
	margin: -5px 8px;
	height: 80px;
}

.content table.inputs.lessons.followup tr td .info .text {
	text-align: center;
	margin-top: 7px;
}

.content table.inputs.lessons.followup tr td .info .text .smallTitle {
	display: inline-block;
	font-size: 16px !important;
	color: $midColor;
	font-family: $mainLightFont_italic;
	margin-top: 0;
	line-height: 26px !important;
	height: 28px;
}

.content table.inputs.lessons.followup tr td.license .info .text .smallTitle {
	font-family: $mainLightFont;
}

.content table.inputs.lessons.followup tr td .info .text {
	font-family: HarmoniaSansStd-Light, sans-serif;
	font-size: 14px;
	color: #fff;
	line-height: 14px;
}

.content table.inputs.lessons.followup tr td .image:not(.overlay) .imgOverlay {
	display: none;
}

.content table.inputs.lessons.followup tr td .image .imgOverlay.transparent {
	background: 0 0;
}

.content table.inputs.lessons.followup tr td .bookOverlay,
.content table.inputs.lessons.followup tr td .image .imgOverlay {
	background: rgba(30, 30, 30, .8);
	height: 100%;
	width: 100%;
	position: relative;
}

.content table.inputs.lessons.followup tr td .image .imgOverlay .progressLoader .inner .text {
	display: none;
}

.content table.inputs.lessons.followup .loader-circle .timer {
	position: relative;
	font-size: 36px;
	width: 36px;
	height: 36px;
	float: left;
	z-index: 2;
}

.content table.inputs.lessons.followup .loader-circle .timer>.slice {
	position: absolute;
	width: 36px;
	height: 36px;
	clip: rect(0, 36px, 36px, 18px);
}

.content table.inputs.lessons.followup .loader-circle .timer>.slice.gt50 {
	clip: rect(auto, auto, auto, auto);
}

.content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
	box-sizing: content-box;
	border: 3px solid $themeColor;
	position: absolute;
	width: 30px;
	height: 30px;
	clip: rect(0, 18px, 41px, 0);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie.fill {
	-moz-transform: rotate(180deg)!important;
	-webkit-transform: rotate(180deg)!important;
	-o-transform: rotate(180deg)!important;
	transform: rotate(180deg)!important;
}

.content table.inputs.lessons.followup .loader-circle .timer.fill>.percent {
	display: none;
}

.content table.inputs.lessons.followup .loader-circle .timer.fill>.slice>.pie {
	border: transparent;
	background-color: #008a8d;
	width: 36px;
	height: 36px;
}

.content table.inputs.lessons.followup .loader-circle {
	box-sizing: content-box;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	position: absolute;
	width: 36px;
	height: 36px;
	border: 2px solid $midColor;
	border-radius: 50%;
	display: inline-block;
}

.content table.inputs.lessons.followup .loader-circle .inner {
	box-sizing: content-box;
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 30px;
	height: 30px;
	line-height: 90px;
	border-radius: 50%;
	border: 3px solid rgba(255, 255, 255, .3);
	z-index: 0;
}

.content table.inputs.lessons.followup .loader-circle .inner .text {
	position: absolute;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	color: #fff;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 11px;
	letter-spacing: 1px;
}

.content.miniature table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
	border-width: 4px;
	width: 42px;
	height: 42px;
	clip: rect(0, 25px, 50px, 0);
}

.content.miniature table.inputs.lessons.followup .loader-circle .timer>.slice {
	width: 50px;
	height: 50px;
	clip: rect(0, 50px, 50px, 25px);
	&.gt50 {
		clip: rect(auto, auto, auto, auto);
	}
}

.content.miniature table.inputs.lessons.followup .loader-circle .timer {
	font-size: 50px;
	width: 50px;
	height: 50px;
}

.content.miniature table.inputs.lessons.followup .loader-circle .inner {
	width: 42px;
	height: 42px;
	line-height: 90px;
	border: 4px solid rgba(255, 255, 255, .3);
}

.content.miniature table.inputs.lessons.followup tr td .info {
	width: 100%;
	height: 97px;
	bottom: 10px;
}

.content.miniature table.inputs.lessons.followup tr td:not(.fullWidth) .info {
	top: auto;
	bottom: 0;
	padding: 0;
	position: relative;
	margin: auto;
}

.content.miniature table.inputs.lessons.followup tr td:not(.fullWidth) .info .text h3 {
	font-size: 20px !important;
}

.content.miniature table.inputs.lessons.followup tr td:not(.fullWidth) .info .text,
.content.miniature table.inputs.lessons.followup tr td:not(.fullWidth) .info .text h3 {
	margin-bottom: 5px;
	margin-top: 3px;
	font-size: 17px;
	line-height: 16px;
}

.content.miniature table.inputs.lessons.followup .infoWrap {
	width: 100%;
	height: 98px;
	position: relative;
	margin-top: 123px;
}

.content.miniature table.inputs.lessons.followup .loader-circle {
	width: 50px;
	height: 49px;
	-webkit-box-shadow: inset 0 0 64px 11px rgba(0, 0, 0, .7);
	-moz-box-shadow: inset 0 0 64px 11px rgba(0, 0, 0, .7);
	box-shadow: inset 0 0 64px 11px rgba(0, 0, 0, .7);
	border: 2px solid rgba(0, 0, 0, .7);
}

.programRemove {
	background-image: url('img/minusIconLarge.png');
	background-size: 26px 6px;
	background-position: 16px;
}

.programAdd {
	background-image: url('img/plusIconLarge.png');
	background-size: 26px 26px;
	background-position: 16px;
}

.infoWrap {
	width: 100%;
	height: 80px;
}

.wvBlanksWrap .answers {
	width: 474px;
	margin: auto;
}

.wvBlanksWrap .answer {
	color: #000;
	background-color: #fff;
	padding: 10px;
	cursor: pointer;
	position: relative;
}

.wvBlanksWrap {
	position: relative;
	width: 294px;
}

.wvBlanksWrap .answer {
	text-align: center;
	font-size: 18px;
	line-height: 14px;
	margin: 6px 3px 0 auto;
	display: inline-block;
	height: 36px;
	min-width: 106px;
	border-bottom: 1px solid black;
	padding: 10px;
	cursor: pointer;
	position: relative;
}

.wvBlanksWrap .answer>.userAnswer {
	pointer-events: none;
}

.wvBlanksWrap .answer.selected {
	font-size: 18px;
	line-height: 14px;
}

.wvBlanksWrap .answer.selected,
.wvBlanksWrap .answer:hover {
	background: $themeColor;
	color: $midColor;
}

.wvBlanksWrap .answer.active {
	background: $darkColor;
	color: white;
}

.wvBlanksWrap .answer.wrong {
	background: rgba(246, 236, 29, 0.2);
}

.wvBlanksWrap .answer.wrong>span {
	opacity: 0.2;
}

.wvBlanksWrap .answer.empty {
	font-weight: bold;
	font-size: 26px;
	line-height: 0;
}

.wvBlanksWrap .answer.correct {
	background: #008a8d;
	color: white;
}

.wvBlanksWrap .selectAnswers {
	text-align: center;
	display: none;
	position: absolute;
	z-index: 999;
	background: #fff;
	color: #000;
	width: 126px;
	line-height: 32px;
}

.wvBlanksWrap .answerTable {
	width: 100%;
	cursor: pointer;
	font-size: 18px;
}

.wvBlanksWrap .answerTable tr {
	height: 36px;
}

.wvBlanksWrap .answerTable tr:hover {
	background: #008a8d;
	color: #fff;
}

.wvBlanksWrap .disabled:hover {
	background: #fff;
	color: #000;
}

.disabled.correct:hover,
.wvBlanksWrap .disabled.wrong:hover {
	background: #008a8d;
	color: white;
}

.wvBlanksWrap .feedbackButton {
	cursor: pointer;
	background-color: #333333;
	width: 236px;
	height: 45px;
	margin: auto;
	color: #fff;
	line-height: 44px;
	font-family: $mainFont;
}

.wvBlanksWrap .overlay {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 99999;
}

.wvBlanksWrap {
	color: #fff;
	font-family: "open_sanslight", sans-serif;
	margin: 32px auto;
}

.wvBlanksWrap .title {
	text-align: left;
	font-family: $mainmediumFont;
	font-size: 20px;
	margin: 0 auto 10px;
	line-height: 26px;
}

.wvBlanksWrap .text {
	text-align: left;
	font-size: 18px;
	line-height: 46px;
	margin: 6px auto;
	word-spacing: -1px;
}

.wvBlanksWrap .correctAnswer {
	left: 0;
	color: #000;
	position: absolute;
	padding: 10px;
	background-color: #fff;
	height: 16px;
	width: 100%;
	font-size: 18px;
	line-height: 14px;
	text-align: center;
}

.wvBlanksWrap .feedbackArrow {
	background-image: url('img/feedbackArrow.png');
	background-position: 40px;
	background-size: cover;
	width: 10px;
	margin-left: -5px;
	position: absolute;
	height: 5px;
	top: 100%;
	left: 50%;
}

.wvBlanksWrap .selectAnswer {
	z-index: 999;
	background: #fff;
	color: #000;
	text-align: center;
	line-height: 32px;
	position: absolute;
	left: 0;
}

.wvBlanksWrap .selectAnswer .answerOption {
	display: block;
	width: 100%;
	font-size: 18px;
	min-height: 36px;
	font-weight: normal;
	padding: 0 17px;
}

.wvBlanksWrap .selectAnswer .answerOption:hover {
	background: $themeColor;
	color: $midColor;
}

.wvBlanksWrap span.marked {
	background-color: $themeColor;
	padding: 1px;
	margin: -1px;
}

.wvBlanksWrap span.marked.wrong {
	background-color: rgba(240, 0, 140, 0.3);
}

.wvBlanksWrap span.marked.correct {
	background-color: $themeColor;
	color: $themeColor;
}

.wvBlanksWrap span.correct:not(.marked) {
	background-color: #fff;
	color: #008a8d;
	padding: 1px;
	margin: -1px;
}

.wvAdviceContent {
	margin: 167px auto 0;
	text-align: left;
	overflow-x: hidden;
	width: 320px;
}

.wvAdviceContent .title {
	margin-top: 10px;
}

.wvAdviceContent .row {
	font-family: open_sanslight, sans-serif;
	min-height: 0;
	width: 242px;
	padding: 2px 0 0;
	height: auto;
	font-size: 14px;
	line-height: 22px;
	position: relative;
	margin-bottom: 10px;
}

.wvAdviceContent .row.left {
	background-color: $darkColor;
	color: #fff;
	border-bottom: 1px solid #000;
}

.wvAdviceContent .row.right {
	background-color: #fff;
	position: relative;
	margin-left: 55px;
	color: #1e1e1e;
	border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.wvAdviceContent .rowContent {
	height: 100px;
	background-color: #fff;
	margin: auto;
	padding-top: 1px;
	padding-bottom: 3px;
	line-height: 21px;
	min-height: 0;
}

.wvAdviceContent .row .img {
	width: 74px;
	height: 74px;
	float: left;
	margin-top: 4px;
	margin-left: 3px;
	border-radius: 50%;
}

.wvAdviceContent .row.question {
	margin-bottom: 0;
	border: none;
}

.wvAdviceContent .row.question .studentTitle {
	color: #008a8d;
	border: 0;
	font-size: 18px;
	padding-bottom: 1px;
	margin-left: 12px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	position: relative;
	top: 15px;
}

.wvAdviceContent .studentTitle {
	color: #008a8d;
	border-bottom: 1px solid #008a8d;
	font-size: 14px;
	padding-bottom: 1px;
	margin-left: 10px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
}

.wvAdviceContent .row.question .studentInfo {
	top: 14px;
	left: 12px;
	position: relative;
	display: block;
	width: 435px;
	font-size: 14pt;
}

.wvAdviceContent .studentInfo {
	top: 3px;
	position: relative;
	display: block;
	padding-right: 10px;
	padding-bottom: 8px;
	margin-left: 0;
	width: 100%;
	font-size: 14px;
	left: 5px;
	line-height: 21px;
}

.wvAdviceContent .studentInfo .subtitle {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	top: -8px;
	font-size: 14px;
	color: #000;
}

.wvAdviceContent .row.question .studentInfo .subtitle {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 25px;
	color: #1e1e1e;
}

.wvAdviceContent .row.rowContent {
	cursor: pointer;
	border-top: 1px solid #b2b2b2;
	text-align: center;
	min-height: 80px;
	margin: 0;
	font-size: 18px;
	padding: 11px 24px;
	box-sizing: border-box;
	width: 297px;
	line-height: 27px;
	height: auto;
}

.wvAdviceContent .row.rowContent.selected {
	background: #008a8d;
}

.wvAdviceContent .row.rowContent.selected .alignCell {
	color: #fff !important;
}

.wvAdviceContent .row.rowContent .alignTable {
	display: table;
	width: 100%;
	height: 55px;
	overflow: hidden;
}

.wvAdviceContent .row.rowContent .alignCell {
	display: table-cell;
	vertical-align: middle;
	color: #1e1e1e;
}

.adviceContent .row.rowContent,
.wvAdviceContent .row.question {
	width: 297px;
	margin-left: 0;
	padding-bottom: 0;
}

.wvAdviceContent .studentTitle {
	margin-left: 6px;
	top: 3px;
	position: relative;
	width: 100%;
	padding-bottom: 0;
}

.wvAdviceContent .rowCol {
	margin: 5px 5px 0 10px;
	width: 90%;
}

.wvAdviceContent .row .image_placeholder {
	width: 39px;
	height: 35px;
	margin-top: 8px;
	margin-left: 12px;
	float: left;
}

.wvAdviceContent .row .img {
	width: 44px;
	height: 44px;
	margin-top: 4px;
	margin-left: 13px;
	position: absolute;
	left: 0;
}

.wvAdviceContent .row.rowContent .alignCell {
	font-size: 14px;
}

.wvAdviceContent .row.question .studentInfo .subtitle {
	font-size: 16.7px;
}

.wvAdviceContent .row.question .studentInfo {
	top: 0;
	left: 8px;
	font-size: 14px;
}

.wvAdviceContent .row.question .studentTitle {
	font-size: 13.5px;
	top: 5px;
	left: -4px;
}

.wvAdviceContent {
	margin-top: 15px;
	padding-left: 12px;
}

.backgroundImage.magazineCover {
	width: 100%;
	height: 320px;
	position: relative;
}

.magazineContent.magazineCover {
	margin-top: 0;
}

.tdLink {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
}

.magazineCover .tableContent {
	background: #ece6dc;
	text-align: left;
	margin-top: 42px;
}

.magazineCover .tableContent table tr th {
	vertical-align: middle;
	color: $midColor;
	height: 70px;
	text-align: center;
	font-family: $mainLightFont;
	font-size: 26px;
	font-weight: 100;
}

.magazineCover .tableContent table tr td {
	position: relative;
	vertical-align: middle;
	height: 51px;
	font-family: $mainFont;
	padding: 5px 5px 5px 15px;
	font-size: 17px;
}

.magazineCover .tableContent table tr td .title {
	vertical-align: middle;
	display: inline-block;
}

.magazineCover .tableContent table tr td span {
	vertical-align: middle;
}

.magazineCover .tableContent table tr td .status {
	float: right;
	font-family: $mainFont;
	font-size: 14px;
	line-height: 21px;
}

.magazineCover .tableContent table tr td .status.active {
	color: $midColor;
	font-family: $mainmediumFont;
}

.magazineCover .tableContent table {
	width: 100%;
}

.magazineCover .tableContent table tr:nth-child(odd) {
	background-color: #f0f0f0;
}

.magazineCover .tableContent table tr:nth-child(even) {
	background-color: #e9e9e9;
}

.magazineCover .tableContent .indicator {
	margin-right: 8px;
	background: $darkColor;
	text-align: center;
	line-height: 23px;
	display: inline-block;
	border-radius: 50%;
	height: 23px;
	width: 23px;
	color: #fff;
	font-size: 11px;
}

.magazineCover .tableContent .indicator.active {
	background: $themeColor;
	color: $midColor;
}

.magazineCover .tableContent .indicator.hasNew {
	background: #e11b3c;
	font-size: 19px;
	position: relative;
	z-index: 2;
	margin-right: -5px;
}

.magazineCover .nextPageHeader {
	padding: 25px;
	margin-bottom: 0;
}

.magazineCover .magazine {
	width: 100%;
	text-align: center;
	margin-top: 35px;
	display: inline-block;
}

.magazineCover .subTitle {
	font-size: 30px;
	font-family: "MegaTeam", sans-serif;
	color: rgba(160, 130, 80, 1);
	text-align: center;
	width: 275px;
	margin: auto;
	letter-spacing: 2px;
	margin-top: 10px;
	line-height: 30px;
}

.magazineCover .magazine h1 {
	font-size: 20px;
	font-weight: normal;
}

.nextLesson h1 {
	color: $lightestColor;
	padding: 20px;
	font-family: $mainLightFont;
	font-size: 20px;
	font-weight: normal;
}

.nextLesson {
	text-align: center;
	width: 280px;
	margin: auto;
	position: relative;
	margin-bottom: 20px;
}

.nextLesson .nextLessonImg img {
	width: 81px;
	display: inline-block;
	height: 82px;
}

.nextLesson .lessonText {
	background: $darkColor;
	display: inline-block;
	position: absolute;
	height: 82px;
	width: 200px;
	left: 81px;
}

.nextLesson .lessonText .lessonTitle {
	display: inline-block;
}

.nextLesson .lessonText .subTitle {
	display: inline-block;
}

.nextLesson .nextLessonImg {
	display: inline-block;
	position: absolute;
	left: 0;
	height: 82px;
	width: 82px;
	background-size: cover;
}

.nextLesson.subTitle {
	font-size: 14px;
}

.nextLesson .lessonTitle {
	padding: 2px 10px;
	font-family: $mainLightFont_italic;
	font-size: 14px;
	color: #fff;
	margin-top: 13px;
	border-top: 1px solid $themeColor;
	border-bottom: 1px solid $themeColor;
	margin-bottom: 5px;
}

.nextLesson .lessonInfo {
	font-family: 'HarmoniaSansStd-Light', sans-serif;
	font-size: 14px;
	color: $lightestColor;
	height: 82px;
	margin-bottom: 2px;
}

.nextLesson .lessonDesc {
	display: block;
	margin: 0 10px;
	font-family: $mainLightFont_italic;
	font-size: 17px;
}

.nextLesson.reversed .lessonText {
	left: 0;
}

.nextLesson.reversed .nextLessonImg {
	left: 200px;
}

.nextLesson.searchResult {
	width: 310px;
}

.nextLesson.searchResult .nextLessonImg {
	left: 224px;
	width: 85px;
	height: 85px;
}

.nextLesson.searchResult .lessonText {
	width: 224px;
	height: 85px;
}

.nextLesson.searchResult .lessonInfo {
	height: 85px;
}

.icons {
	text-align: center;
	width: 100%;
	padding-bottom: 5px;
	padding-top: 7px;
}

.icons>a,
.icons>div {
	background-color: rgba(227, 0, 126, 1);
	border-radius: 50%;
	margin: auto 7px auto auto;
	width: 34px;
	height: 34px;
	display: inline-block;
}

.icons .themeIcon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px;
}

.icons>a:last-child,
.icons>div:last-child {
	margin-right: 0;
}

.chair-icon {
	background-image: url('img/chair-icon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px;
}

.phone-icon {
	background-image: url('img/calc-icon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 16px 16px;
}

.read {
	line-height: 22px;
	margin: 9px 15px 0;
	font-family: "open_sanslight", sans-serif;
	text-align: left;
}

.readMore {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: url('img/readMore.png')no-repeat;
	background-position: 7px;
	background-color: rgba(40, 40, 40, 1);
	background-size: 19px 4px;
	top: 20px;
	margin: auto;
	position: relative;
}

.loader-circle .timer {
	box-sizing: content-box;
	position: relative;
	font-size: 114px;
	width: 114px;
	height: 114px;
	float: left;
	z-index: 2;
}

.loader-circle .timer>.slice {
	box-sizing: content-box;
	position: absolute;
	width: 245px;
	height: 245px;
	clip: rect(0, 245px, 245px, 122px);
}

.loader-circle .timer>.slice.gt50 {
	box-sizing: content-box;
	clip: rect(auto, auto, auto, auto);
}

.loader-circle .timer>.slice>.pie {
	box-sizing: content-box;
	border: 4px solid $themeColor;
	position: absolute;
	width: 106px;
	height: 106px;
	clip: rect(0, 57px, 114px, 0);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.loader-circle .timer>.slice>.pie.fill {
	-moz-transform: rotate(180deg)!important;
	-webkit-transform: rotate(180deg)!important;
	-o-transform: rotate(180deg)!important;
	transform: rotate(180deg)!important;
}

.loader-circle .timer.fill>.percent {
	display: none;
}

.loader-circle .timer.fill>.slice>.pie {
	box-sizing: content-box;
	border: transparent;
	background-color: #008a8d;
	width: 114px;
	height: 114px;
}

.loader-circle {
	top: 0;
	box-sizing: content-box;
	z-index: 1;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	position: absolute;
	width: 114px;
	height: 114px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border: 2px solid $midColor;
	border-radius: 50%;
	display: inline-block;
	background: $midColor;
}

.loader-circle .inner {
	box-sizing: content-box;
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 106px;
	height: 106px;
	line-height: 106px;
	border-radius: 50%;
	border: 4px solid rgba(255, 255, 255, 0.3);
	z-index: 0;
}

.loader-circle .inner .text {
	text-transform: uppercase;
	position: absolute;
	font-family: 'HarmoniaSansStd-Light', sans-serif;
	color: #fff;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 15px;
	letter-spacing: 1px;
	font-weight: bold;
}

.filmles .centered {
	display: table-cell;
	vertical-align: middle;
}

.filmles.backgroundImage {
	position: relative;
	width: 100%!important;
	height: 336px;
	overflow: hidden;
	display: table;
}

.filmles.magazineContent .nextLesson h1 {
	font-size: 22px;
}

.filmles.magazineContent {
	max-width: 100%;
	display: inline-block;
	padding-top: 0;
	width: 100%;
	margin-top: 0;
}

.filmles.magazineContent .topTime {
	position: absolute;
	top: -22px;
	right: 0;
	background: $darkColor;
	color: #fff;
	padding: 2px 7px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 14px;
	z-index: 1;
}

.filmles.backgroundImage .loader-circle {
	left: 0;
	position: absolute;
	right: 0;
	margin: auto;
	bottom: 30px;
	top: auto;
}

.filmles.magazineContent .magazine {
	margin-top: 15px;
	margin-bottom: 5px;
	color: rgba(160, 130, 80, 1);
}

.filmles.backgroundImage .image-text .subTitle {
	font-size: 30px;
	font-family: MegaTeam, sans-serif;
	text-align: center;
	width: 275px;
	margin: auto;
	letter-spacing: 2px;
	margin-top: -11px;
	line-height: 30px;
}

.filmles.backgroundImage .image-text .magazine h1,
.magazineContent .magazine h1 {
	font-family: $mainLightFont;
	color: #262626;
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 20px;
}

.filmles.backgroundImage .image-text .magazine,
.magazineContent .magazine {
	color: rgba(160, 130, 80, 1);
	text-align: center;
}

.filmles.backgroundImage .image-text .subTitle {
	color: #000;
}

.filmles.backgroundImage .image-text {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 10%;
	z-index: 1;
	text-transform: uppercase;
}

.filmles.magazineContent .circles-link {
	margin: 10px;
}

.filmles.magazineContent .circles-link .circles {
	display: inline-block;
}

.filmles.magazineContent .circles-link .circle {
	position: relative;
	border-radius: 50%;
	height: 71px;
	width: 71px;
	background-size: cover;
	display: inline-block;
}

.filmles.magazineContent .circles-link .circle.circle-left {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	width: 61px;
	height: 61px;
	margin-bottom: 5px;
	z-index: 1;
	margin-top: 5px;
}

.filmles.magazineContent .circles-link .circle.circle-right {
	margin-left: -25px;
	z-index: 2;
}

.filmles.magazineContent .circles-link .link {
	color: #1e1e1e;
	text-decoration: none;
	display: inline-block;
	vertical-align: top;
	margin-top: 30px;
	font-size: 14px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	margin-left: 10px;
}

.filmles.magazineContent .circles-link .link .icon .icon-arrow {
	width: 7px;
	height: 10px;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	position: absolute;
	background-image: url('img/arrow.png');
	background-size: cover;
}

.filmles.magazineContent .circles-link .link .icon {
	background: #222;
	border-radius: 50%;
	background-size: cover;
	width: 20px;
	height: 20px;
	position: relative;
	margin-right: 6px;
	display: inline-block;
	vertical-align: middle;
}

.filmscene {}

.filmscene .scene-header .header-wrap {
	width: 70%;
	margin: 0 auto;
	position: relative;
	padding: 110px 0 75px;
}

.filmscene .scene-header {
	height: auto;
	width: 100%;
	position: relative;
	background: #ece6dc;
}

.filmscene .scene-header .subtitle {
	color: #ae956a;
	text-align: center;
	width: 480px;
	margin: auto;
	letter-spacing: 2px;
	line-height: 24px;
	font-size: 20px;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 25px;
}

.filmscene .scene-header .title {
	font-family: "MegaTeam", sans-serif;
	text-align: center;
	width: 100%;
	margin: auto;
	margin-top: -11px;
	line-height: 30px;
	font-size: 48px;
	color: #000;
	margin-bottom: 0;
	letter-spacing: 2px;
	display: inline-block;
}

.filmscene .header-placeholder {
	height: 50px;
}

.filmscene .scene-header {
	height: auto;
}

.filmscene .scene-header .title {
	margin-top: auto;
}

.filmscene .scene-header .subtitle {
	line-height: 20px;
	font-size: 18px;
	margin-bottom: 23px;
}

.filmscene .scene-header .header-wrap {
	padding: 20px 0;
	width: 296px;
}

.container.filmscene .scene-header .header-wrap .videoContainer.content-video,
.filmscene .content .content-wrap .videoContainer.content-video {
	height: 155px;
}

.filmscene .content .content-wrap {
	width: 320px;
}

.filmscene .contentTitle {
	color: #ae956a;
	line-height: 31px;
	font-size: 30px;
	margin: 30px 20px 20px;
}

.filmscene .contentSubTitle {
	font-family: open_sanslight, sans-serif;
	font-weight: bold;
	margin-top: 25px;
	font-size: 17px;
}

.filmscene .tip.title {
	margin-top: 25px;
	font-size: 17px;
}

.filmscene .tip {
	font-size: 16px;
	font-family: open_sanslight, sans-serif;
}

.filmscene .content .content-wrap {
	padding: 12px;
}

.filmscene .content .cta .cta-title h1 {
	font-size: 25px;
	margin-top: 70px;
}

.filmscene .content .cta {
	height: 216px;
}

.filmscene .content .cta .cta-button {
	width: 80px;
	height: 80px;
}

.filmscene .content .cta .cta-button span {
	font-size: 17px;
	line-height: 80px;
}

.filmscene .content .title-text {
	display: inline-block;
	text-transform: uppercase;
	color: #ae956a;
	text-align: center;
	margin: auto;
	letter-spacing: 1px;
	font-size: 20px;
	margin-bottom: 20px;
	font-family: HarmoniaSansStd-Light, sans-serif;
}

.filmscene .sceneListFooter {
	text-align: center;
	position: relative;
	margin: 0 auto;
}

.filmscene .sceneListFooter .scene:hover .scene-overlay {
	display: block;
	z-index: 10;
	cursor: pointer;
}

.filmscene .sceneListFooter .scene .scene-overlay {
	background: rgba(40, 40, 40, .2);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}

.filmscene .sceneListFooter .scene {
	width: 100%;
	height: 90px;
	margin-bottom: 2px;
	position: relative;
	text-align: center;
}

.filmscene .sceneListFooter .scene .scene-image {
	position: absolute;
	left: 0;
	height: 100%;
	width: 120px;
	background-size: cover;
}

.filmscene .sceneListFooter .scene .scene-image img {
	height: 100%;
}

.filmscene .sceneListFooter .scene .scene-info {
	position: absolute;
	right: 0;
	height: 100%;
	width: 200px;
	background-color: #ece6dc;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 15px;
	color: #1e1e1e;
}

.filmscene .sceneListFooter .scene .time {
	position: absolute;
	bottom: 0;
	right: 0;
	background: $darkColor;
	color: #fff;
	padding: 1px 9px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 14px;
}

.filmscene .sceneListFooter .scene .number {
	height: 24px;
	width: 24px;
	line-height: 24px;
	border-radius: 50%;
	background: $darkColor;
	text-align: center;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	margin-top: 12px;
}

.filmscene .sceneListFooter .scene .number.pink {
	background: #008a8d;
}

.filmscene .sceneListFooter .scene .description {
	padding: 2px 10px 10px;
	text-align: center;
}

.filmscene .sceneListFooter .scene:last-child {
	margin-bottom: 0;
}

.filmscene .content-wrap {
	width: 100%;
}

.filmscene .scene-header {
	height: auto;
}

.filmscene .scene-header .title {
	font-size: 30px;
}

.filmscene .scene-header .subtitle {
	width: 270px;
}

.filmscene .content .content-wrap .videoContainer.content-video .progressbar,
.filmscene .scene-header .header-wrap .videoContainer.content-video .progressbar {
	width: 100px;
}

.filmscene .content-wrap {
	width: 100%;
}

.filmscene .content-wrap .videoContainer.content-video {
	width: 296px;
	height: 155px;
}

.videoContainer.fullScreen {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	z-index: 100;
}

.videoContainer .overlay .filmContent {
	width: 100%;
	position: relative;
	text-align: center;
	height: 67%;
	left: 0;
	top: 0;
}

.videoContainer .overlay .filmContent .wrap {
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	bottom: 0;
	height: 217px;
	margin: auto;
}

.videoContainer .overlay {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 9999999999;
}

.videoContainer .overlay .filmContent .title {
	font-family: MegaTeam, sans-serif !important;
	color: #008a8d;
	font-size: 18px;
}

.videoContainer .overlay .filmContent .subTitle p {
	font-family: "HarmoniaSansStd-Light", sans-serif !important;
}

.videoContainer .overlay .filmContent .subTitle {
	color: #fff;
	line-height: 16px;
	max-width: 550px;
	font-size: 16px;
	font-family: HarmoniaSansStd-Light, sans-serif !important;
	width: 60%;
	margin: 0 auto 15px;
}

.videoContainer .overlay .buttons {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
}

.videoContainer .overlay .buttons .button {
	cursor: pointer;
	width: 95px;
	height: 95px;
	border-radius: 50%;
	background-color: #fff;
	display: inline-block;
}

.videoContainer .overlay .buttons .button.play .icon {
	position: relative;
	top: 33px;
	left: 42%;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 23px solid rgba(0, 0, 0, .9);
}

.videoContainer .overlay .buttons .sideButton {
	text-transform: uppercase;
	font-size: 13px;
	position: relative;
	cursor: pointer;
	display: inline-block;
	height: 85px;
	width: 85px;
	border-radius: 50%;
	background-color: $darkColor;
	line-height: 16px;
	margin: 5px 11px 0;
}

.videoContainer .overlay .buttons .sideButton .inner {
	display: table;
	height: 85px;
	overflow: hidden;
	width: 85px;
}

.videoContainer .overlay .buttons .sideButton .inner .text p {
	color: #fff;
	display: block;
}

.videoContainer .overlay .buttons .sideButton .inner .text {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

.videoContainer .filmFooter {
	border-top: 1px solid rgba(255, 255, 255, .3);
	width: 100%;
	position: absolute;
	bottom: 0;
	height: 33%;
}

.videoContainer .overlay.finished,
.videoContainer .overlay.paused {
	display: none;
	background-color: rgba(0, 0, 0, .85);
}

.videoContainer .nextLesson h1 {
	padding: 5px;
	margin: 0;
	color: #008a8d;
	font-size: 15px !important;
	font-family: 'HarmoniaSansStd-Light', sans-serif !important;
}

.videoContainer .nextLesson {
	text-align: center;
	width: 232px;
	height: 107px;
	margin: auto;
	position: relative;
	font-size: 25px;
}

.videoContainer .nextLessonImg .img {
	width: 85px;
	display: inline-block;
	height: 66px;
	background-size: contain;
}

.videoContainer .lessonText {
	font-family: $mainFont;
	background: #fff;
	display: inline-block;
	position: absolute;
	height: 66px;
	width: 147px;
	left: 85px;
}

.videoContainer .lessonText .lessonTitle {
	display: inline-block;
}

.videoContainer .lessonText .subTitle {
	display: inline-block;
}

.videoContainer .nextLessonImg {
	display: inline-block;
	position: absolute;
	left: 0;
	height: 66px;
	width: 85px;
	font-size: 11px;
}

.videoContainer .nextLesson.subTitle {
	font-size: 14px;
}

.videoContainer .lessonTitle {
	font-family: "MegaTeam", sans-serif;
	font-size: 14px;
	color: #008a8d;
	margin-top: 13px;
}

.videoContainer .lessonInfo {
	position: relative;
	font-size: 14px;
	color: #000;
	height: 66px;
}

.videoContainer .lessonDesc {
	display: inline-block;
	margin: 0 10px;
}

.videoContainer .lessonInfo .duration {
	font-size: 11px;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: $darkColor;
	color: #fff;
	padding: 5px 9px;
	font-family: 'HarmoniaSansStd-Light', sans-serif;
}

.videoContainer .finished .buttons .sideButton .inner .text p {
	color: $darkColor;
}

.videoContainer .finished .buttons .sideButton .inner {
	height: 100px;
	width: 100px;
	font-size: 15px;
}

.videoContainer .finished .buttons .sideButton {
	background-color: #fff;
	color: $darkColor;
	height: 100px;
	width: 100px;
}

.videoContainer .nextLesson h1 .light {
	color: #fff;
	font-weight: normal;
}

.lessonNumber {
	line-height: 20px;
	color: #fff;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: $darkColor;
	margin: 10px auto 5px;
	font-family: $mainFont;
	font-size: 11px;
}

.filmscene .scene-header .header-wrap .videoContainer.content-video {
	height: 155px;
}

.magazineContent.summary {
	margin-top: 100px;
	background: $lightestColor;
	color: #fff;
}

.magazineContent.summary .contentWrap {
	padding: 0 25px 25px;
}

.summary .paginate {
	height: 102px;
}

.magazineContent.summary .paginateLeft,
.magazineContent.summary .paginateRight {
	top: 30px;
}

.magazineContent.summary .paginateFade {
	background: linear-gradient(to right, rgba(160, 130, 80, 1) 0%, rgba(160, 130, 80, 1) 10%, rgba(160, 130, 80, 0) 40%, rgba(160, 130, 80, 0) 60%, rgba(160, 130, 80, 1) 90%, rgba(160, 130, 80, 1) 100%);
}

.magazineContent.summary a.paginatePage.active {
	color: $buttonTextColor;
	background: $themeColor;
}

.magazineCover.summary .subTitle {
	color: #fff;
}

.magazineCover.summary .content-image .image-subtitle {
	font-family: 'HarmoniaSansStd-Light', sans-serif;
	font-size: 14px;
	color: #008a8d;
}

.magazineCover.summary .content-image {
	margin: 20px 0;
}

.magazineCover.summary .imageDescription {
	border-color: #fff;
	color: #fff;
}

.magazineCover.summary .subTitle {
	margin-bottom: 20px;
}

.magazineCover.summary .content-image img {
	max-width: 100%;
	padding-bottom: 7px;
	border-bottom: 1px solid #008a8d;
}

.magazineCover.summary .cta-button .icon {
	height: 33px;
	width: 18px;
	background: url('img/arrow-left-big.png');
	position: absolute;
	left: 0;
	right: 5px;
	top: 0;
	bottom: 0;
	margin: auto;
	background-size: cover;
}

.magazineCover.summary .cta-subtitle {
	font-family: "Myriad Pro", $mainLightFont, sans-serif;
	font-size: 18px;
	color: #1e1e1e;
	padding: 0 13px 30px;
	display: block;
	text-align: center;
}

.magazineCover.summary .cta-button {
	background-color: $darkColor;
	width: 90px;
	height: 90px;
	border-radius: 50%;
	margin: 10px auto;
	position: relative;
	text-align: center;
	display: block;
}

.sceneList {
	width: 100%;
	position: relative;
	margin: 0 auto;
}

.sceneList .scene:hover .scene-overlay {
	background: rgba(40, 40, 40, .2);
}

.sceneList .scene .scene-overlay {
	z-index: 10;
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.sceneList .scene {
	width: 100%;
	height: 90px;
	margin-bottom: 2px;
	position: relative;
	text-align: center;
}

.sceneList .scene .scene-image {
	position: relative;
	float: left;
	left: 0;
	height: 100%;
	width: 120px;
	background-size: cover;
}

.sceneList .scene .scene-image img {
	height: 100%;
}

.sceneList .scene .scene-info {
	right: 0;
	height: 100%;
	background-color: #ece6dc;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 15px;
	color: #1e1e1e;
}

.magazineContent .image .time,
.sceneList .scene .time {
	position: absolute;
	bottom: 0;
	right: 0;
	background: $darkColor;
	color: #fff;
	padding: 1px 9px;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 14px;
}

.magazineContent .image .time {
	padding: 2px 7px;
}

.sceneList .scene .number {
	height: 24px;
	width: 24px;
	line-height: 24px;
	border-radius: 50%;
	background: $darkColor;
	text-align: center;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	margin-top: 12px;
}

.sceneList .scene .number.pink {
	background: #008a8d;
}

.sceneList .scene .description {
	text-align: center;
}

.sceneList .scene:last-child {
	margin-bottom: 0;
}

.sceneList.searchResult .scene-image {
	float: right;
	width: 90px;
}

.videoContainer {
	width: 100%;
	height: 335px;
}

::-webkit-input-placeholder {
	color: #999999 !important;
}

:-moz-placeholder {
	color: #999999 !important;
}

::-moz-placeholder {
	color: #999999 !important;
}

:-ms-input-placeholder {
	color: #999999 !important;
}

input[name="tussenvoegsel"]::-webkit-input-placeholder {
	color: lightgrey;
}

input[name="tussenvoegsel"]:-moz-placeholder {
	color: lightgrey;
}

input[name="tussenvoegsel"]::-moz-placeholder {
	color: lightgrey;
}

input[name="tussenvoegsel"]:-ms-input-placeholder {
	color: lightgrey;
}

.zPriority {
	z-index: 10;
}

.content.lessons.podium {
	padding: 0;
}

.content.podium .contentTabs {
	margin: 0 auto;
}

.content.podium .contentTabs .tab a {
	vertical-align: middle;
}

.content.podium .contentTabs .tab {
	cursor: pointer;
	position: relative;
	height: 35px;
	box-sizing: border-box;
	padding: 0 20px;
	border-right: 1px solid #505050;
	width: 103px;
	float: left;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 12px;
	text-align: center;
	color: #505050;
}

.content.podium .contentTabs .tab:last-child {
	border-right: 0;
}

.content.podium .contentTabs .tab a:hover,
.content.podium .contentTabs .tab.active a {
	color: #fff !important;
}

.content.podium .tabContent {
	font-size: 0;
}

.modelTiles {
	position: relative;
	width: 312px;
	margin: 15px auto 0;
}

.modelTiles .tile.add {
	box-shadow: none;
	background-image: url("img/icon-camera.png");
	background-repeat: no-repeat;
	background-size: 78px 78px;
	background-position: center;
}

.modelTiles .tile {
	font-size: 16px;
	margin: 2px;
	display: inline-block;
	position: relative;
	background-size: cover;
	width: 100px;
	height: 100px;
	-webkit-box-shadow: inset 0 -30px 35px -15px rgba(0, 0, 0, .7);
	-moz-box-shadow: inset 0 -30px 35px -15px rgba(0, 0, 0, .7);
	box-shadow: inset 0 -30px 35px -15px rgba(0, 0, 0, .7);
}

.modelTiles.big .tile {
	width: 150px;
	height: 150px;
	margin: 3px;
}

.modelTiles.big .tile .favorites .icon {
	vertical-align: middle;
	width: 12px;
	height: 11px;
}

.modelTiles.big .tile .favorites span {
	font-size: 14px;
}

.modelTiles .tile .favorites {
	position: absolute;
	right: 5px;
	bottom: 5px;
}

.modelTiles .tile .favorites span {
	vertical-align: middle;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	color: #fff;
	font-size: 11px;
}

.modelTiles .tile .favorites .icon {
	vertical-align: initial;
	display: inline-block;
	height: 9px;
	width: 10px;
	background-image: url("img/icon-heart.png");
	background-size: cover;
}

.content .inputs.favorites {
	border-spacing: 0 8px;
}

.content .inputs.favorites {
	width: 100%;
}

.content .inputs.favorites .inputRow {
	margin: 10px auto;
	height: auto;
	width: 100%;
	position: relative;
	background: $darkColor;
	border: 1px solid #353535;
}

.content .inputs.favorites .inputRow .image {
	box-shadow: inset 40px 40px 60px -30px #1e1e1e;
	width: 80px;
	height: 80px;
	position: absolute;
	top: 0;
	right: 0;
	background-size: cover;
}

.content .inputs.favorites .inputRow .info {
	margin: 0 8px;
	height: 80px;
	width: 100%;
	padding-right: 100px;
	display: table;
}

.content .inputs.favorites .inputRow .info .text {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.content .inputs.favorites .inputRow .info .text .smallTitle {
	margin-bottom: 3px;
	font-weight: inherit;
	font-family: megateam, sans-serif;
	color: #008a8d;
	font-size: 14px;
}

.content .inputs.favorites .inputRow .info .text .smallBody {
	font-family: HarmoniaSansStd-Light, sans-serif;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;
	line-height: 14px;
}

.content .inputs.favorites .inputRow .image .imgOverlay .number {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	color: #008a8d;
	height: 25px;
	background: #fff;
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	width: 28px;
}

.content .inputs.favorites .inputRow .image .imgOverlay .number p {
	z-index: 2;
	position: relative;
	margin-top: 2px;
}

.content .inputs.favorites .inputRow .image .imgOverlay .number:after,
.content .inputs.favorites .inputRow .image .imgOverlay .number:before {
	border-top: 25px solid #fff;
	bottom: -15px;
	content: '';
	height: 0;
	position: absolute;
	width: 0;
}

.content .inputs.favorites .inputRow .image .imgOverlay .number:after {
	border-right: 25px solid transparent;
	left: 0;
}

.content .inputs.favorites .inputRow .image .imgOverlay .number:before {
	border-left: 25px solid transparent;
	right: 0;
}

.content .inputs.favorites .inputRow .dropdown {
	display: none;
	position: relative;
	width: 100%;
	z-index: 3;
	overflow: hidden;
	height: 0;
	margin-top: -80px;
}

.content .inputs.favorites .inputRow .dropdown .removeAll {
	text-align: right;
	height: 80px;
	background: rgba(30, 30, 30, 0.9);
	position: relative;
	display: block;
}

.content .inputs.favorites .inputRow .dropdown .remove {
	height: 60px;
	position: relative;
	display: block;
}

.content .inputs.favorites .inputRow .dropdown .remove:nth-child(even) {
	background: #e5ddd0;
}

.content .inputs.favorites .inputRow .dropdown .remove:nth-child(odd) {
	background: #ece6dc;
}

.content .inputs.favorites .inputRow .dropdown li .icon {
	cursor: pointer;
	margin: 18px 14px;
	float: right;
	background-image: url('img/close-icon.png');
	background-size: cover;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	box-shadow: 0 1px 0 0 rgba(4, 4, 4, 0.4);
}

.content .inputs.favorites .inputRow .dropdown .removeAll .icon {
	display: inline-block;
	margin: 28px 14px;
}

.content .inputs.favorites .inputRow .dropdown li .icon,
.content .inputs.favorites .inputRow .dropdown li .number {
	display: inline-block;
}

.content .inputs.favorites .inputRow .dropdown .removeAll p {
	display: inline-block;
	line-height: 16px;
	margin: 33px 0;
	color: #fff;
	font-size: 12px;
}

.content .inputs.favorites .inputRow .dropdown li .removeTxt {
	text-decoration: none;
	color: #1e1e1e;
	line-height: 60px;
	display: inline-block;
	font-family: $mainFont;
	font-size: 15px;
}

.content .inputs.favorites .inputRow .dropdown li .number {
	margin: 18px 2px 18px 13px;
	text-align: center;
	line-height: 24px;
	color: #fff;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: $darkColor;
	font-family: $mainFont;
	font-size: 12px;
}

.register p {
	width: auto !important;
}

.inputRow .information {
	position: absolute;
	right: 15px;
	top: 10px;
	width: 32px;
	height: 32px;
	z-index: 1000;
}

.changePassword .inputRow .information {
	right: 15px;
	top: 5px;
	width: 20px;
	height: 20px;
}

.changePassword .inputRow .information .info-icon {
	background-size: 2px 11.5px;
	padding: 8px;
}

.changePassword .passwordStrength {
	height: 4px;
	margin-top: -7px;
}

.inputRow .select .information {
	top: -5px;
	right: 55px;
}

.inputRow .information .info-icon {
	background-image: url("img/GCP_information.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 4px 16px;
	display: block;
	padding: 14px;
	border: 2px solid $lightestColor;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
}

.registerPage .inputRow .information {
	width: 20px;
	height: 20px;
}

.registerPage .inputRow .information .info-icon {
	background-size: 2px 11px;
	padding: 8px;
}

.passwordStrength {
	width: 100%;
	height: 5px;
	background-size: 260px;
	background-repeat: no-repeat;
	margin-left: 10px;
	margin-top: -8px;
}

.passwordStrength.gray {
	background-image: url("img/passwordStrength-empty.png");
}

.passwordStrength.red {
	background-image: url("img/passwordStrength-weak.png");
}

.passwordStrength.orange {
	background-image: url("img/passwordStrength-moderate.png");
}

.passwordStrength.green {
	background-image: url("img/passwordStrength-good.png");
}

.passwordStrength.lime {
	background-image: url("img/passwordStrength-perfect.png");
}

.styledSelect {
	width: 359px;
	font-size: 15px;
	color: #aaaaaa;
	line-height: 30px;
	background: url("img/dropwDownArrow.png") 320px center no-repeat #fff;
	background-size: 20px auto;
}

.styledSelect.register {
	position: absolute;
	left: 0;
	width: 100%;
	background: url("img/dropwDownArrow.png") no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
	margin: 20px 0 0;
	color: #1e1e1e !important;
	background-position-y: 5px;
}

.styledSelect.register.open {
	background: url("img/dropwDownArrowUp.png") no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.styledSelect.register.short {
	background-position: 90% 30%;
}

.styledSelect .options {
	position: absolute;
	width: 100%;
	left: 0;
	background: #fff;
	display: none;
	z-index: 6;
	overflow: auto;
}

.styledSelect .option {
	border-bottom: 1px solid $darkColor;
	margin: 0 20px;
	cursor: default;
	font-family: $mainLightFont;
	font-size: 17px;
}

.styledSelect .option.default,
.styledSelect .option:last-of-type {
	border-bottom: 0;
}

.styledSelect.register .option {
	font-size: 17px;
	border: 1px solid $darkColor;
	border-bottom: none;
	border-left: none;
	font-family: $mainLightFont;
	border-right: none;
	padding-top: 6px;
	padding-bottom: 6px;
	width: 90%;
	margin: -1px auto 0;
	padding-left: 10px;
	z-index: 20;
	cursor: pointer;
}

.registerPage .styledSelect.register .option {
	font-size: 15px;
}

.styledSelect.register .options {
	border: 1px solid $darkColor;
	border-top: none;
	left: -1px;
	margin-top: 0;
	width: calc(100% + 2px);
	max-height: 220px;
}

.styledSelect.register .option.default {
	line-height: 30px;
	height: 38px;
	border-top: none;
	border-bottom: 0;
	padding-bottom: 0;
	margin: 0;
	padding-top: 0;
	width: 98%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.registerPage .styledSelect.register .option.default {
	line-height: 20px;
	height: 27px;
}

.kwaliteitsregisterField .styledSelect.register .option.default {
	padding-right: 25%;
}

.kwaliteitsregisterField .styledSelect.register {
	background-position: 82% 30%;
}

.hasInfo .styledSelect.register .option.default,
.hasInfo.styledSelect.register .option.default {
	padding-right: 85px;
}

.editableDiv {
	width: 32%;
	padding: 1px !important;
	font-size: 17px;
	float: left;
	text-align: center;
	color: $darkColor;
	text-transform: uppercase;
}

.styledSelect.frontPage {
	margin-top: 0;
	background-position: 90% center;
}

.styledSelect.frontPage .option.default {
	line-height: 38px;
}

.innerSearch {
	font-size: 17px;
	font-family: $mainLightFont;
}

.editableDiv.center {
	border: 1px solid $darkColor;
	border-bottom: 0;
	border-top: 0;
	height: 23px;
}

.greenButton {
	color: #fff;
	background: #3cc0c1;
	line-height: 30px;
	padding: 0 15px;
	width: 215px;
	font-size: 15px;
	cursor: pointer;
}

.selectRole .selectParent {
	display: inline-block;
	text-align: left;
	margin-bottom: 40px;
}

.selectRole .greenButton {
	display: inline-block;
	margin-left: 40px;
	text-align: left;
}

.content.progress .loader-circle .timer {
	position: relative;
	font-size: 130px;
	width: 130px;
	height: 130px;
	float: left;
	z-index: 2;
}

.content.progress .loader-circle .timer>.slice {
	position: absolute;
	width: 130px;
	height: 130px;
	clip: rect(0, 130px, 130px, 65px);
}

.content.progress .loader-circle .timer>.slice.gt50 {
	clip: rect(auto, auto, auto, auto);
}

.content.progress .loader-circle .timer>.slice>.pie {
	border: 4px solid #008a8d;
	position: absolute;
	width: 122px;
	height: 122px;
	clip: rect(0, 65px, 130px, 0);
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.content.progress .loader-circle .timer>.slice>.pie.fill {
	-moz-transform: rotate(180deg)!important;
	-webkit-transform: rotate(180deg)!important;
	-o-transform: rotate(180deg)!important;
	transform: rotate(180deg)!important;
}

.content.progress .loader-circle .timer.fill>.percent {
	display: none;
}

.content.progress .loader-circle .timer.fill>.slice>.pie {
	border: transparent;
	background-color: #008a8d;
	width: 130px;
	height: 130px;
}

.content.progress .loader-circle {
	box-shadow: none;
	position: relative;
	width: 130px;
	height: 130px;
	background-color: $darkColor;
	border: 2px solid $darkColor;
	border-radius: 50%;
	display: block;
}

.content.progress .loader-circle .inner {
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 122px;
	height: 122px;
	line-height: 90px;
	border-radius: 50%;
	border: 4px solid rgba(255, 255, 255, .3);
	z-index: 0;
}

.content.progress .loader-circle .inner .text {
	position: absolute;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	color: #fff;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 11px;
	letter-spacing: 1px;
}

.content.progress .loader-circle .inner .icon {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.content.progress .tiles {
	margin: 0 auto;
	width: 300px;
}

.content.progress .tiles tr {
	text-align: center;
	float: left;
	margin: 15px 8px 8px;
	max-width: 134px;
}

.content.progress .tiles tr:nth-child(2n+3) {
	clear: both;
}

.content.progress .tiles .tile .progress {
	position: relative;
	display: block;
}

.content.progress .tiles .tile .progress .complete {
	display: none;
}

.content.progress .tiles .tile.complete .progress .complete {
	display: block;
	background: url("img/correct-dark.png");
	background-color: #008a8d;
	background-size: contain;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	position: absolute;
	right: 0;
	top: 0;
	margin: auto;
	z-index: 34;
	-webkit-box-shadow: 0 0 15px 0 rgba(50, 50, 50, .75);
	-moz-box-shadow: 0 0 15px 0 rgba(50, 50, 50, .75);
	box-shadow: 0 0 15px 0 rgba(50, 50, 50, .75);
}

.content.progress .tiles .tile .text {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	display: inline-block;
	color: #fff;
	font-size: 13px;
	margin-top: 10px;
}

.content.progress .icon {
	width: 55px;
	height: 55px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
}

.content.progress .icon.chair {
	background-image: url('img/chair-icon.png');
}

.content.progress .icon.scissors {
	background-image: url('img/scissors-icon.png');
}

.content.progress .icon.calc {
	background-image: url('img/calc-icon.png');
}

.content.progress .icon.hair {
	background-image: url('img/hair-icon.png');
}

.content.progress .icon.drop {
	background-image: url('img/drop-icon.png');
}

.content.progress .icon.brush {
	background-image: url('img/brush-icon.png');
}

.slideMenu {
	display: none;
	position: fixed;
	top: 0;
	top: env(safe-area-inset-top);
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 999;
}

.slideMenu .menuContainer {
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background: $midColor;
	position: absolute;
	right: -280px;
	top: 0;
	height: 100%;
	max-width: 320px;
	width: 100%;
	padding-bottom: 0;
	padding-bottom: env(safe-area-inset-bottom);
}

.slideMenu .menuContainer.searching {
	background: #fff;
}

.slideMenu .menuHeader {
	text-align: center;
	width: 100%;
	float: left;
	padding-bottom: 40px;
}

.slideMessages .menuHeader {
	text-align: center;
	height: 55px;
}

.slideMenu .profilePicture {
	width: 164px;
	height: 164px;
	margin: 60px auto 25px;
}

.slideMenu .profileName {
	font-family: $mainLightFont;
	font-size: 24px;
	color: #fff;
	display: inline-block;
}

.slideMenu .topIcon {
	position: absolute;
	top: 0;
	border: 0;
}

.slideMenu .topIcon.left {
	left: 0;
}

.slideMenu .topIcon.right {
	right: 0;
}

.slideMenu .topIcon.right.messages {
	right: 35px;
	background: #008a8d;
	line-height: 30px;
	font-family: $semiBoldFont;
	font-size: 16px;
	color: #fff;
}

.slideMenu .topIcon.right.messages.hasNew {
	background: #e11b3c;
}

.slideMenu .search {
	display: none;
	width: 100%;
	height: 85px;
	background: #fff;
	position: absolute;
	top: 0;
}

.slideMenu .search input[type="text"] {
	background: transparent;
	border: none;
	font-family: 'open_sanslight', sans-serif;
	font-size: 17px;
	color: #000;
	margin: 18px 20px;
	width: 249px;
	margin-right: 50px;
}

.slideMenu .search .searchico {
	background-image: url('img/search.png');
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 27px;
	margin-right: 25px;
}

.slideMenu .menuItems {
	display: table;
	width: 100%;
}

.slideMenu .menuItems .item {
	border-bottom: 1px solid #353535;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 100%;
	height: 85px;
	display: table-cell;
	font-family: $mainLightFont;
	font-size: 24px;
	color: #fff;
	text-transform: lowercase;
}

.slideMenu .menuItems .item:first-child {
	border-top: 1px solid #353535;
}

.slideMenu .menuItems .item:hover {
	background-color: $themeColor;
	color: $midColor;
}

.slideMenu .menuItems .item .tdLink {
	height: 85px;
}

.slideMessages .menuItems .item {
	padding: 10px;
	height: 65px;
	font-family: "HarmoniaSansStd-Light",sans-serif;
	font-size: 17px;
	color: #fff;
}

.slideMessages .menuItems .item:hover {
	background-color: $themeColor;
	color: $midColor;
}

.slideMessages .menuItems .item .msgTitle {
	font-size: 10px;
	text-transform: uppercase;
	font-family: $mainFont;
}

.slideMessages .menuItems .item.msgRow {
	text-align: left;
}

.slideMessages .menuItems .item.msgRow .msgTitle {
	color: $themeColor;
}

.slideMessages .menuItems .item.msgRow:hover .msgTitle {
	color: $midColor;
}

.slideMessages .menuItems .item.msgRow .msgImage {
	background-size: cover;
}

.slideMessages .menuItems .item.msgRow .msgTag {
	width: 65px;
	height: 65px;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.slideMessages .menuItems .item.iconRow {
	text-align: left;
}

.slideMessages .menuItems .item.iconRow .msgTitle {
	color: #008a8d;
}

.slideMessages .menuItems .item.iconRow .msgImage {
	background-size: cover;
}

.slideMessages .menuItems .item.iconRow .msgIcon,
.slideMessages .menuItems .item.iconRow .msgText {
	display: inline-block;
}

.slideMessages .menuItems .item.iconRow .msgText {
	margin-left: 10px;
}

.slideMessages .menuItems .item.iconRow .msgTag {
	width: 65px;
	height: 65px;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
}

.slideMessages .menuItems .item.warning {
	background: url("img/alertIcon.png") 10px 10px no-repeat;
	background-size: 65px;
	padding-top: 75px;
}

.slideMessages .updateContainer {
	box-sizing: border-box;
	margin-top: 50px;
	padding: 30px;
	min-height: 130px;
	text-align: center;
	color: #fff;
	background: url("img/whiteCheck.png") 35px 65px #3cc0c1 no-repeat;
	background-size: 35px;
	font-size: 16px;
	font-family: $mainFont;
}

.slideMessages .dateHeader {
	font-size: 19px;
	font-weight: bold;
	margin: 15px 0 7px;
}

.slideMessages .updatedDate {
	font-size: 19px;
}

.slideMessages .updateContainer.hasNew {
	background-image: url("img/whiteExclam.png");
	background-color: #e11b3c;
}

.slideMessages .updateContainer:not(.hasNew) .updateText.hasNew {
	display: none;
}

.slideMessages .updateContainer.hasNew .updateText.noHasNew {
	display: none;
}

.slideMessages .updateContainer .viewLink {
	position: absolute;
	right: 15px;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.msgIcon.msgAlert {
	width: 45px;
	height: 41px;
	background: url('img/alertIcon.png');
	background-size: cover;
}

.luvIcon {
	vertical-align: initial;
	display: inline-block;
	height: 10px;
	width: 12px;
	background-image: url("img/icon-heart.png");
	background-size: cover;
}

.luvsDisplay {
	z-index: 1;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	width: 100px;
	height: 100px;
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: auto;
}

.luvsDisplay {
	cursor: pointer;
	z-index: 1;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	width: 100px;
	height: 100px;
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: auto;
}

.luvsDisplay .icon.heart {
	width: 43px;
	height: 38px;
	background: url('img/heart_big_empty.png');
	background-size: cover;
}

.luvsDisplay .icon.heart.active {
	background-image: url('img/heart_big.png');
}

.luvsDisplay .number {
	font-family: $mainFont;
	color: #fff;
	font-size: 15px;
	top: 60px;
	display: block;
	height: 15px;
	text-align: center;
}

.modelImageContainer {
	width: 100%;
}

.modelImageContainer .focusimage {
	width: 100%;
	background-size: cover;
}

.modelImageContainer .nav {
	width: 16px;
	height: 42px;
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	margin: auto;
	background-size: cover;
}

.modelImageContainer .nav.prev {
	background-image: url('img/navArrowLeft.png');
	left: 20px;
}

.modelImageContainer .nav.next {
	background-image: url('img/navArrowRight.png');
	right: 20px;
}

.slideMessages .menuItems .item.msgRow .msgTag span {
	font-size: 40px;
	color: #008a8d;
	line-height: 65px;
}

.slideMessages .menuItems .item.msgRow .msgText {
	width: 180px;
	display: inline-block;
}

.slideMessages .menuItems .item.msgRow .msgBody {
	font-size: 16px;
	font-family: $mainLightFont;
	margin-top: 5px;
	line-height: 22px;
}

.slideMessages .menuItems .item.msgRow .msgBody a {
	color: #fff;
	display: block;
}

.slideMessages .menuItems .item.msgRow:hover .msgBody a {
	color: $midColor;
}

.slideMessages .menuItems .item.examRow {
	text-align: center;
}

.slideMessages .menuItems .item.examRow .msgTitle {
	color: #008a8d;
}

.slideMessages .menuItems .item.examRow .msgBody {
	text-transform: uppercase;
	font-size: 16px;
	width: 250px;
	margin: 0 auto;
}

.hardCenter {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.topIcon {
	width: 30px;
	height: 30px;
	background-color: $darkColor;
	background-image: none;
	border-radius: 50%;
	position: relative;
}

.topIcon.left {
	float: left;
	margin-left: 10px;
	margin-top: 11px;
}

.topIcon.right {
	float: right;
	margin-right: 10px;
	margin-top: 11px;
}

.topIcon.helpIcon .icon {
	background: url('img/questionmark.png');
	height: 12px;
	width: 12px;
	display: block;
	background-size: cover;
}

.topIcon.closeIcon .icon {
	margin: 9px;
	background: url('img/smallWhiteCross.png');
	height: 13px;
	width: 13px;
	display: block;
	background-size: cover;
}

.topIcon.searchIcon {
	margin-top: 15px;
	margin-right: 15px;
}

.topIcon.searchIcon .icon {
	background: url('img/search.png');
	height: 15px;
	width: 15px;
	display: block;
	background-size: cover;
}

a.infoTooltip {
	position: relative;
	z-index: 24;
}

a.infoTooltip:active,
a.infoTooltip:focus {
	z-index: 25;
	text-decoration: none;
}

a.infoTooltip:active .tooltip,
a.infoTooltip:focus .tooltip {
	display: block;
	position: absolute;
	top: 35px;
	left: -90px;
}

a.infoTooltip .tooltip:after {
	border-style: solid;
	border-color: #fff transparent;
	display: block;
	width: 0;
	top: -17px;
	bottom: auto;
	left: auto;
	right: 122px;
	border-width: 0 18px 17px;
	content: "";
	position: absolute;
}

a.infoTooltip .tooltip {
	z-index: 9999999;
	position: relative;
	padding: 20px;
	margin: 1em auto 3em;
	color: #1e1e1e;
	background: #fff;
	margin-top: 10px;
	font-family: 'open_sanslight', sans-serif;
	font-size: 15px;
	width: 280px;
}

a.infoTooltip .tooltip .overlay {
	z-index: 9999;
	position: fixed;
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
}

a.infoTooltip .tooltip {
	display: none;
}

.profileDetails {
	padding: 20px;
	background: #fff;
	font-family: 'open_sanslight', sans-serif;
	color: #1e1e1e;
	position: relative;
	max-width: 768px;
	box-sizing: border-box;
	margin: 0 auto;
}

.editProfilePage {
	.profileDetails {
		padding: 0;
	}
}

.profileDetails p {
	font-size: 15px;
	margin-bottom: 12px;
	min-height: 33px;
}

.profileDetails p .subheading {
	position: relative;
	font-family: $mainFont;
	font-size: 10px;
	color: #008a8d;
	display: block;
	margin-top: 8px;
}

.profileDetails p .subheading:before {
	border-top: 1px solid #008a8d;
	content: "";
	position: absolute;
	width: 100%;
	margin-top: -8px;
}

.profileDetails .profilePicture {
	float: left;
	display: inline-block;
	width: 80px;
	height: 80px;
	margin-bottom: 15px;
}

.profileDetails .profileText {
	margin-top: 18px;
	left: 10px;
	position: relative;
	display: inline-block;
}

.profileDetails .profileName {
	font-family: $mainFont;
}

.profileDetails .profileClass {
	font-size: 15px;
}

.defaultButton.editProfile {
	margin-top: 30px !important;
	color: #fff;
	text-transform: uppercase;
}

.kwaliteitsregisterSelect {
	display: none;
}

.kwaliteitsregisterSelect.show {
	display: table-cell;
}

.content.profile {
	padding: 0;
	margin: auto;
}

.profile .editAvatar {
	position: absolute;
	top: 50px;
	right: -35px;
	width: 25px;
	height: 20px;
	background: url('img/icon-camera.png');
	background-size: cover;
}

.profile .inputs tr {
	background: #fff !important;
}

.profile .inputs input,
.profile .inputs textarea {
	color: #1e1e1e !important;
}

.profile .inputs textarea {
	margin: 0 !important;
	height: 95px;
	width: 254px !important;
	font-size: 15px !important;
	line-height: 22px;
}

.profile .profileDetails {
	padding-top: 0;
	background: transparent;
}

.profile .profileDetails p .subheading {
	margin-top: 20px;
}

.profile .profileDetails p .subheading:before {
	margin-top: -4px;
}

.profile .inputs {
	position: relative;
	width: 310px;
	margin: 0 auto;
}

.profile .inputs .inputRow {
	position: relative;
	width: 100% !important;
	padding: 3px 10px 0 5px;
}

.profile .inputs .inputRow input {
	width: 100% !important;
	margin-top: 0;
}

.profile .inputs .inputRow.error .errorMessage {
	position: absolute;
	top: -80px;
	z-index: 999;
	left: 0;
	right: 0;
	max-width: initial;
}

.profile .inputs .inputRow.error .errorMessage:after {
	border-width: 17px 18px 0;
	top: auto;
	bottom: -17px;
	left: 0;
	right: 0;
	margin: auto;
}

.wvBaseContainer .profile.error table.inputs tr td,
.wvBaseContainer .profile.error table.inputs tr td .trSim {
	border-color: #df213c;
}

.wvBaseContainer .profile.error table.inputs tr td span {
	color: #df213c;
}

.profile .inputs .trSim.error,
.profile .inputs .inputRow.error {
	border-color: #ff0000 !important;
}

.profile .inputs .inputRow.error span {
	color: #ff0000 !important;
}

.profile.error .submitButton {
	background-color: #ff0000 !important;
	border-color: #ff0000 !important;
	color: #fff;
}

.profile.error .inputRow.error span {
	color: #008a8d !important;
}

.profileExplanation {
	color: #008a8d;
	text-align: center;
	margin: 0 auto;
}

.profile .tagline {
	position: relative !important;
	top: 2px !important;
	left: -10px !important;
}

.profile .requiredMarker {
	position: relative !important;
	left: -5px !important;
}

.userInfoSelect {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 240px;
}

.topIcon {
	width: 30px;
	height: 30px;
	background-color: $darkColor;
	background-image: none;
	border-radius: 50% !important;
	position: relative;
}

.topIcon.white {
	background-color: #fff;
	-webkit-box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
	-moz-box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
	box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
}

.topIcon.left {
	float: left;
	margin-left: 10px;
	margin-top: 11px;
}

.topIcon.right {
	float: right;
	margin-right: 10px;
	margin-top: 11px;
}

.topIcon.helpIcon .icon {
	background: url('img/questionmark.png');
	height: 12px;
	width: 12px;
	display: block;
	background-size: cover;
}

.topIcon.closeIcon.white .icon {
	background: url('img/smallBlackCross.png');
	height: 13px;
	width: 13px;
	display: block;
	background-size: cover;
}

.topIcon.searchIcon {
	margin-top: 27px;
	margin-right: 25px;
}

.topIcon.searchIcon .icon {
	background: url('img/search.png');
	height: 15px;
	width: 15px;
	display: block;
	background-size: cover;
	position: absolute;
}

.dropShadow {
	border: 1px solid #c5c4c1;
	-webkit-box-shadow: 0 0 30px 0 rgba(50, 50, 50, 0.3);
	-moz-box-shadow: 0 0 30px 0 rgba(50, 50, 50, 0.3);
	box-shadow: 0 0 30px 0 rgba(50, 50, 50, 0.3);
}

.vcenter {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.profilePlaceholder {
	background-image: url('img/cover320.png');
}

.cropImageWrap {
	width: 200px;
	height: 200px;
	position: relative;
	margin: 60px auto;
}

.cropImageWrap .avatar {
	width: 200px;
	height: 200px;
	overflow: hidden;
}

.cropImageWrap .closeIcon {
	position: absolute;
	top: 18px;
	right: 8px;
}

.zoomControls {
	width: 32px;
	height: 63px;
	display: block;
}

.zoomControls .zoomControl {
	cursor: pointer;
	position: relative;
	width: 30px;
	height: 30px;
	background: rgba(60, 192, 193, 0.75);
	margin: 1px;
}

.zoomControls .zoomControl .icon {
	width: 12px;
	height: 12px;
	background-size: cover;
	position: absolute;
}

.zoomControls .zoomControl.zoomIn .icon {
	background-image: url('img/whitePlus.png');
}

.zoomControls .zoomControl.zoomOut .icon {
	background-image: url('img/whiteMinus.png');
}

.profile .cropImageWrap .zoomControls {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto;
	text-align: center;
}

.rounded-elem {
	width: 100px;
	height: 100px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.rounded-image1 {
	overflow: hidden;
	width: 100px;
	height: 100px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 auto;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.avatarWrap {
	position: relative;
	width: 80px;
	margin: 0 auto;
}

.avatar {
	width: 80px;
	height: 80px;
}

.defaultButton {
	display: block;
	text-decoration: none;
	height: 60px;
	background: $darkColor;
	border: 1px solid #353535;
	max-width: 280px;
	margin: 10px auto 0;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 16px;
	color: #fff;
	text-align: c nte;
	line-height: 58px;
	cursor: pointer;
}

.defaultButton.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.fullWidth {
	width: 100%;
}

.desktopOnly {
	display: none;
}

.subPlaceholder {
	display: inline-block;
}

div.listViewAddFile {
	position: absolute;
	right: 30px;
}

div.listViewAddFolder {
	position: absolute;
	right: 80px;
}

.content.subjects .progress {
	margin-top: 30px;
}

.content.subjects .title {
	height: 60px;
	line-height: 75px;
}

.content.subjects .title span {
	font-size: 30px;
	color: #008a8d;
}

.content.subjects .intro {
	max-width: initial;
}

.content.subject .submitBtn.minigame,
.content.subjects .submitBtn.minigame {
	width: 295px;
	height: 60px;
}

.content .tableTitle {
	width: 100%;
	text-align: center;
	color: #008a8d;
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 10px;
	margin-top: 30px;
	margin-bottom: 8px;
}

.content.subjects .inputs.subjects .inputRow .info {
	width: 90%;
	text-align: left;
	margin-left: 10px;
	padding: 0;
}

.content.subjects .inputs.subjects .inputRow .info .date {
	font-size: 15px;
	top: 8px;
}

.content.subjects .inputs.subjects .inputRow .progress {
	display: block;
	bottom: 9px;
	position: absolute;
	height: 5px;
	left: 10px;
	right: auto;
}

.content.subjects .inputs.subjects .inputRow .progress .progressbar {
	width: 207px;
}

.content.subjects .inputs.subjects .inputRow .numbers {
	font-family: HarmoniaSansStd-SemiBd, sans-serif;
	font-size: 11px;
	color: #646464;
	position: absolute;
	right: 0;
	bottom: 5px;
	width: 65px;
}

.content.subjects .inputs.subjects .inputRow .numbers .number {
	width: 30px;
	float: left;
}

.content.subjects .inputs.subjects .inputRow .numbers .number span {
	color: #646464;
	margin-left: 0;
	position: relative;
	font-size: 11px;
}

.content.subjects .inputs.subjects .inputRow .numbers .number.active span {
	color: #008a8d;
}

.content.subject .intro {
	width: 280px;
	max-width: 300px;
}

.content.subject .title.hasSub {
	height: auto;
	line-height: 45px;
}

.content.subject .title span {
	font-size: 30px;
}

.content.subject .inputs.lessons.followup .inputRow .info {
	top: auto;
}

.content.subject .subTitleContent .progressLoader {
	width: 46px;
	height: 46px;
	position: relative;
	display: block;
}

.content.subject .submitBtn.minigame {
	margin-top: 25px;
}

.content.subject .subTitleContent .progressLoader {
	margin: 30px auto 15px;
	box-shadow: none;
	border: 0;
}

.content.subject .subTitleContent .progressLoader .inner {
	border: 3px solid rgba(255, 255, 255, 0.3);
}

.content.subject .subTitleContent .progressLoader .inner .icon {
	background-size: contain;
	width: 25px;
	max-height: 25px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.content.subject .subTitleContent .progressLoader .timer,
.content.subject .subTitleContent .progressLoader .timer .slice {
	width: 46px;
	height: 46px;
}

.content.subject .subTitleContent .progressLoader .timer>.slice {
	width: 46px;
	height: 46px;
	clip: rect(0, 46px, 46px, 23px);
}

.content.subject .subTitleContent .progressLoader .timer>.slice.gt50 {
	clip: rect(auto, auto, auto, auto);
}

.content.subject .subTitleContent .progressLoader .timer>.slice>.pie {
	border: 3px solid #008a8d;
	width: 40px;
	height: 40px;
	clip: rect(0, 23px, 46px, 0);
	border-radius: 50%;
}

.content.subject .subTitleContent .progressLoader .inner {
	width: 40px;
	height: 40px;
}

.inputField {
	width: 100%;
	min-height: 60px;
	cursor: text;
	border: 1px solid #93785c;
	display: block;
	position: relative;
	padding: 15px 10px;
	box-sizing: border-box;
	margin-top: 20px;
}

.inputField.light {
	background: #fff;
}

.inputField.light input[type="text"] {
	color: $darkColor;
}

.inputField.light textarea {
	color: $darkColor;
}

.inputField.dark {
	background: $darkColor;
}

.inputField.dark input[type="text"] {
	color: #fff;
}

.inputField.dark textarea {
	color: #fff;
}

.inputField .tagline {
	left: 0;
	top: 0;
	text-transform: uppercase;
}

.inputField input[type="text"] {
	width: 100%;
	float: left;
	background: transparent;
	border: none;
	height: 25px;
	font-size: 17px;
	font-family: "open_sanslight",sans-serif;
}

.inputField textarea {
	overflow: auto;
	height: 100px;
	width: 100%;
	background: transparent;
	border: none;
	font-size: 17px;
	font-family: "open_sanslight",sans-serif;
}

.inputField .button.addTag {
	position: absolute;
	right: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 23px;
	height: 23px;
	background: #fff;
	background: url("img/plusIcon.png");
	background-size: cover;
	cursor: pointer;
}

.inputField .tagline {
	position: absolute;
	color: #008a8d;
	font-size: 10px;
	margin: 5px 5px 5px 10px;
	font-family: $semiBoldFont;
}

.inputField.error {
	border-color: #ff1432;
}

.inlineRemove {
	width: 10px;
	height: 10px;
	background-image: url('img/cross-dark.png');
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: baseline;
	margin: 2px 5px;
}

.inputOptions {
	position: absolute;
	left: 0;
	font-size: 17px;
	font-family: $mainLightFont;
	margin-top: 4px;
	display: none;
	background: #fff;
	width: 100%;
	border: 1px solid $darkColor;
	z-index: 1;
	margin-left: -1px;
	padding: 0;
	box-sizing: content-box;
}

.inputOptions .option {
	cursor: pointer;
	padding: 0 10px;
	line-height: 34px;
	font-size: 14px;
	color: #8a8a8a;
	border-bottom: 1px solid rgba(147, 120, 76, 0.3);
}

.inputOptions .option:last-child {
	border-bottom: 0;
}

.inputOptions .option:hover {
	color: $darkColor;
	background: #f5f2ed;
}

.inputOptions.dark {
	background: $darkColor;
}

.inputOptions.dark .option {
	color: #8a8a8a;
}

.inputOptions.dark .option:hover {
	color: #fff;
	background: #303030;
}

.noResize {
	resize: none;
}

.profile .profileDetails .avatar {
	margin-top: 5px;
}

.profileDetails .editProfile {
	width: 280px;
	margin: 0 auto;
	background: #3cc0c1;
	border: 0;
	text-align: center;
}

.content.addModel .profileDetails {
	text-align: center;
}

.content.addModel .cropImageWrap {
	text-align: center;
}

.content.addModel .step {
	text-transform: uppercase;
	color: #fff;
	font-family: "HarmoniaSansStd-Light",sans-serif;
	text-align: center;
	margin-top: 35px;
	font-size: 20px;
}

.content.addModel .title {
	margin-top: 10px;
}

.content.addModel .pictureText {
	font-size: 14px;
	color: #008a8d;
	font-family: "harmoniaSansStd-Light",sans-serif;
}

.content.addModel .closeIcon {
	width: 24px;
	height: 24px;
	top: 9px;
	right: -12px;
}

.content.addModel .closeIcon .icon {
	width: 11px;
	height: 11px;
}

.content.addModel .zoomControls {
	top: auto;
}

.addModelButton .addPictureButton {
	background-image: url('img/icon-camera.png');
	width: 100px;
	height: 100px;
	background-size: contain;
	margin: 0 auto;
	text-align: center;
	cursor: pointer;
}

.addModelButton.disabled .addPictureButton {
	background-image: url('img/icon-camera-disabled.png');
	cursor: default;
}

.addModelButton .buttonText {
	color: #fff;
	font-size: 12px;
	margin: 10px 0;
	display: block;
	font-family: $mainFont;
}

.addModelButton.disabled .buttonText {
	color: #626262;
}

.content.addModel .subText {
	color: #fff;
	font-size: 15px;
	padding: 0;
}

.pictureCollection {
	z-index: 1;
	margin: 0 auto;
	width: 100%;
	margin-bottom: 50px;
}

.pictureCollection .picturesWrap {
	margin-left: 90px;
}

.pictureCollection .picture {
	position: relative;
	width: 100px;
	height: 100px;
	background-size: cover;
	display: inline-block;
	margin-left: -90px;
}

.pictureCollection .picture.first {}

.pictureCollection .picture:not(.first) {
	opacity: 0.5;
}

.pictureCollection .picture .overlay {
	opacity: 0.5;
	background: #000;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

.content.addModel .smallTitle,
.searchResults .smallTitle {
	color: $lightestColor;
	font-size: 10px;
	font-family: "HarmoniaSansStd-SemiBd",sans-serif;
	text-transform: uppercase;
	text-align: left;
	margin: 15px 0;
}

.smallHeader {
	color: #008a8d;
	font-size: 14px;
	font-family: "HarmoniaSansStd-Light",sans-serif;
	text-transform: uppercase;
	text-align: center;
	margin: 10px 0;
}

.tags {
	margin: 10px 0;
}

.tags .tag {
	padding: 7px;
	font-size: 16px;
	background: #008a8d;
	margin: 2px;
	display: inline-block;
	color: #fff;
}

.tags .tag .remove {
	width: 8px;
	height: 10px;
	background-image: url('img/cross-dark.png');
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: baseline;
	margin: 0 5px;
}

.content.addModel .publish {
	float: left;
	margin-top: 30px;
	color: #fff;
	width: 100%;
}

.content.addModel .publish label.styled {
	width: 100%;
	float: left;
	text-align: left;
}

.content.addModel .publish label.styled p {
	max-width: 200px;
}

.socialButtons .button {
	margin: 0 5px;
	display: inline-block;
	position: relative;
	width: 60px;
	height: 60px;
	background: $darkColor;
	border-radius: 50%;
}

.socialButtons .button .icon {
	width: 30px;
	height: 30px;
	background-size: cover;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	position: absolute;
}

.socialButtons .button .twitter {
	background-image: url('img/twitterLogo.png');
}

.socialButtons .button .facebook {
	background-image: url('img/facebookLogo.png');
}

.socialButtons .button .instagram {
	background-image: url('img/instagramLogo.png');
}

.clear {
	clear: both;
}

.footer {
	width: 100%;
	padding-top: 20px;
}

.footer .footerTop {
	text-align: center;
	padding-bottom: 20px;
}

.footer .footerTop .heading {
	margin-bottom: 5px;
}

.footer .heading {
	font-family: 'HarmoniaSansStd-Light', sans-serif;
	margin: 15px 0;
	text-transform: uppercase;
	color: #008a8d;
}

.footer .showLicense span {
	color: $darkColor;
}

.footer h3 {
	font-size: 15px;
}

.footer h2 {
	font-size: 20px;
}

.footer .footerTop p {
	color: $darkColor;
	font-size: 25px;
}

.footer .footerFold.folded {
	display: none;
}

.footer .footerFold {
	background: #e5ddd0;
	padding: 20px;
	text-align: center;
	position: relative;
}

.footer .footerFold .wrap {
	max-width: 280px;
	margin: 0 auto;
}

.footer .cancel {
	text-transform: uppercase;
	position: absolute;
	top: 20px;
	right: 20px;
	color: #fff;
	font-family: 'HarmoniaSansStd-Light', sans-serif;
}

.docent-icon .letter {
	position: absolute;
	color: #fff;
	font-size: 12px;
	margin: 2px 2px 2px 5px;
	font-family: $semiBoldFont;
	z-index: 11;
}

.docent-icon:after {
	top: 0;
	left: 0;
	border-top: 30px solid #008a8d;
	border-right: 30px solid transparent;
	content: '';
	position: absolute;
	height: 0;
	width: 0;
	z-index: 10;
}

.docent-icon.large:after {
	border-right-width: 40px;
	border-top-width: 40px;
}

.docent-icon.large .letter {
	font-size: 12px;
	margin: 6px 6px 6px 8px;
}

.docent-icon.large .text {
	color: #008a8d;
	margin-left: 35px;
	padding-top: 9px;
	position: absolute;
	font-size: 10px;
}

.docent-avatar {
	position: absolute;
	top: 0;
	left: 0;
	margin: 3px;
	width: 21px;
	height: 21px;
	border-radius: 50%;
}

.docent-avatar.large {
	width: 30px;
	height: 30px;
}

.searchWrap {
	width: 100%;
	float: left;
	height: 85px;
	position: relative;
	border-top: 1px solid #353535;
	font-family: $mainLightFont;
	font-size: 24px;
	color: #fff;
	text-align: center;
	line-height: 85px;
	text-transform: lowercase;
}

.searchResultsWrap {
	width: 320px;
}

.searchResults {
	display: none;
	width: 320px;
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
}

.searchResults .smallTitle {
	text-align: center;
}

.searchResults .modelTiles .tile {
	margin: 1px;
}

.profileListing {
	width: 100%;
	position: relative;
	margin: 0 auto;
}

.profileListing .item {
	border-top: 2px solid #b39b74;
	height: 80px;
	padding-left: 7px;
}

.profileListing .item:first-child {
	border: none;
}

.profileListing span {
	display: block;
	font-size: 15px;
	font-family: "open_sanslight",sans-serif;
}

.profileListing .item .avatar {
	border-radius: 50%;
	display: table-cell;
	width: 63px;
	height: 63px;
	margin: 7px 10px;
	background-size: 63px 63px;
	background-repeat: no-repeat;
}

.profileListing .item .text {
	margin: 7px 0;
	display: table-cell;
	width: 220px;
	height: 63px;
	vertical-align: middle;
	padding-left: 7px;
}

.searchResult {
	max-width: 310px !important;
}

.noScroll {
	overflow: hidden;
	overflow-y: hidden;
	overflow-x: hidden;
}

.dropSmallShadow {
	-webkit-box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
	-moz-box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
	box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.55);
}

.popupTextGroup {
	font-weight: bold;
}

.magazineBlocks {
	margin: 50px auto;
	text-align: center;
}

.magazineBlocks .blockTitle {
	font-size: 20px;
	font-family: 'open_sanssemibold',sans-serif;
	margin-bottom: 1em;
	color: #000;
}

.magazineBlocks .magWrap {
	display: inline-block;
	width: 223px;
	height: 223px;
	border: 1px solid #353535;
	background: #ffffff;
	margin: 10px;
}

.magazineBlocks .smallTitle {
	font-family: "open_sansregular",sans-serif;
	color: $darkColor;
	font-size: 16px;
}

.magazineBlocks .image {
	width: 221px;
	height: 123px;
}

.magazineBlocks .text {
	text-align: center;
	font-size: 16px;
	color: $themeColor;
	display: table-cell;
	width: 221px;
	vertical-align: middle;
	height: 98px;
}

.pagePadding {
	padding: 0 15px;
}

.blueLinkText,
a.blueLinkText {
	text-transform: uppercase;
	color: #2b78e4;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 44px;
	font-weight: bold;
}

.blueBorder {
	height: 1px;
	background: #2b78e4;
	margin: 15px 0;
}

.ratingBlock {
	background: #fff;
	margin-bottom: 15px;
	-webkit-box-shadow: 0 1px 1px 0 #aaa;
	box-shadow: 0 1px 1px 0 #aaa;
	font-size: 13px;
}

.rbPerson {
	padding: 10px;
}

.rbPerson table td {
	vertical-align: middle;
}

.rbInfo {
	padding: 0 10px;
}

.rbInfoText {
	margin-top: 10px;
}

.rbPersonIcon {
	width: 40px;
	height: 40px;
	background-size: 40px 40px;
	border-radius: 50%;
	margin-right: 12px;
}

.rbInfoBorder {
	height: 1px;
	background: #bebebe;
}

.rbInfoTextHeader {
	font-family: 'open_sanssemibold',sans-serif;
	color: #000;
	text-transform: uppercase;
	font-size: 10px;
}

.footer-edit .inactive {
	border-right-color: #6b6b6b;
}

.footer-edit .inactive a {
	background: #999999;
}

.themeRating {
	height: 60px;
	width: 280px;
	padding: 0 15px;
	background: $darkColor;
	border: 1px solid #353535;
	margin: 10px auto 0;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	overflow: hidden;
}

.themeRating.open {
	height: auto;
}

.themeRatingHeader {
	line-height: 58px;
	text-transform: uppercase;
}

.themeRatingTitle {
	display: inline-block;
	width: 200px;
}

.themeRatingGrade {
	background: #1e1e1e;
	width: 40px;
	height: 40px;
	margin-top: 10px;
	border-radius: 50%;
	color: #008a8d;
	display: inline-block;
	line-height: 40px;
	font-family: 'open_sanssemibold',sans-serif;
	text-align: center;
}

.themeRatingPhoto {
	width: 40px;
	height: 40px;
	background-size: 40px 40px;
	border-radius: 50%;
	display: inline-block;
}

.themeRatingName {
	display: inline-block;
	width: 200px;
	margin-left: 2px;
	padding-top: 5px;
}

.themeRatingNameTitle {
	color: #008a8d;
	font-family: $mainFont;
	font-size: 10px;
	text-transform: uppercase;
}

.themeRatingInfoText {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 15px;
	line-height: 22px;
}

.ratingThemeSubs {
	height: 0;
	overflow: hidden;
}

.ratingThemeSubs.open {
	height: auto;
	border-top: 1px solid #2b78e4;
	margin-bottom: 15px;
}

.searchHeader {
	font-family: 'open_sansregular',sans-serif;
	font-size: 11px;
	text-transform: uppercase;
	color: #999999;
}

.filmlesIntro {
	max-width: 790px;
	font-size: 18px !important;
	padding: 0 20px;
	color: #1e1e1e;
	font-family: 'open_sanslight', sans-serif;
	margin: 45px auto;
}

.closeOverlayButton {
	width: 14px;
	height: 14px;
	background: url('img/GCP_close_overlay.png');
	background-size: cover;
	position: fixed;
	top: 0;
	right: 0;
	margin: 25px;
}

h1.magazineTitle {
	font-size: 45px;
	text-align: center;
	color: $midColor;
	border-top: 2px solid $midColor;
	border-bottom: 2px solid $midColor;
	padding-bottom: 5px;
	font-family: $mainFont;
	font-weight: normal;
	letter-spacing: 1px;
}

.subtitleData {
	font-size: 0;
	color: $midColor;
}

.subtitleData .data {
	font-family: $mainFont;
	border-bottom: 2px solid $midColor;
	width: 50%;
	display: inline-block;
	font-size: 18px;
	padding: 10px 0;
}

.subtitleData .data:nth-child(even) {
	border-left: 2px solid $midColor;
	text-align: right;
}

.topWrap {
	max-width: 245px;
	margin: 0 auto;
}

.cancelButton {
	text-align: center;
	margin-top: 35px;
}

.doubleText {
	font-size: 0;
	margin-bottom: 15px;
}

.doubleText .half {
	font-size: 16px;
	width: 100%;
	display: inline-block;
}

.doubleText .half.left {
	float: left;
}

.doubleText .half.right {
	float: right;
}

.scoreBoard .score {
	width: 100%;
	height: 89px;
	border-bottom: 2px solid #fff;
	display: table;
}

.scoreBoard .score:nth-child(odd) {
	background: rgba(233, 233, 233, 0.2);
}

.scoreBoard .score:last-child {
	border-bottom: 0;
}

.score .number {
	margin: 8px 12px;
	background: $themeColor;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	color: $midColor;
	font-size: 13px;
	font-family: $mainmediumFont;
	min-width: 26px;
	padding: 4px 10px;
}

.score .text {
	display: inline-block;
	font-family: $mainLightFont;
}

.scoreBoard.tagScores {
	margin-bottom: 20px;
}

.scoreBoard.tagScores .score .text {
	padding: 5px 30px;
}

.score .scoreWrap {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
}

.score .text b {
	font-family: $mainBoldFont;
}

.totalWrap {
	padding: 20px;
}

.totalWrap .text {
	color: #262626;
	font-size: 17px;
	text-align: center;
	font-family: $mainFont;
}

.totalWrap .text b {
	font-family: $mainBoldFont;
}

.totalWrap .totalPercent {
	width: 90px;
	height: 90px;
	display: table;
	background: $themeColor;
	border-radius: 50%;
	margin: 10px auto;
}

.totalWrap .totalPercentText {
	display: table-cell;
	color: $midColor;
	vertical-align: middle;
	text-align: center;
	font-size: 14px;
	font-family: $mainFont;
}

.totalWrap .percent {
	font-size: 35px;
	display: block;
	line-height: 30px;
	margin-top: 10px;
}

.toetsCover .totalWrap {
	margin-top: -15px;
}

.toetsCover .totalWrap .totalPercent {
	margin: 0 auto;
}

.toetsCover .score .number {
	position: absolute;
	left: 10px;
	transform: translateY(-50%);
	margin: 0;
	top: 50%;
}

.wvProductBlocks.wvBrief .productBlock .productImg {
	border-radius: 0;
	position: relative;
	overflow: visible;
	margin-top: 25px;
}

.wvProductBlocks.wvBrief .productBlock .productImg .number {
	font-family: $mainmediumFont;
	font-size: 20px;
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	color: $midColor;
	width: 34px;
	height: 34px;
	top: -50px;
	background: $themeColor;
	border-radius: 50%;
	line-height: 34px;
	text-align: center;
}

.header-gcp {
	border-bottom: 1px solid #ccc9c8;
	width: 100%;
	height: 74px;
	background-color: #fff;
	text-align: center;
	display: table;
	z-index: 1;
	position: fixed;
	top: 0;
	top: env(safe-area-inset-top);
}

.header-gcp .wrap {
	display: table-cell;
	vertical-align: middle;
}

.header-gcp .wrap .logo {
	background-image: url('img/GCP_logo.png');
	background-size: cover;
	width: 55px;
	height: 52px;
	margin: 5px auto 0;
}

.header-gcp .wrap .logo img,
.wvBaseContainer .baseHeader .logo img {
	max-height: 52px;
	margin-top: 5px;
}

.header-gcp .interactions {
	top: 0;
	right: 16px;
}

.header-gcp .cart {
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
	background: url("img/cart.png") center no-repeat;
	background-size: contain;
	width: 44px;
	height: 35px;
	line-height: 44px;
	line-height: 26px;
	text-align: center;
	color: #fff;
	font-weight: bold;
	padding-left: 4px;
}

.header-gcp .cart.hasBackBtn {
	left: 100px;
}

.header .messageTrigger {
	color: #fff;
	display: inline-block;
	margin-right: 0.5em;
	margin-top: 10px;
	border: 1px solid #fff;
	border-radius: 100%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
}

.hasInteractions .interactions .notifications,
.header .interactions .item,
.header-gcp .nav .icon {
	line-height: 35px;
	width: 35px;
	height: 35px;
	margin-right: 12px;
	margin-top: 8px;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
}

.hasInteractions .interactions .notifications {
	background: #018a8d no-repeat center center;
}

.submenu.gcp {
	margin-top: 0;
	padding-top: 8px;
}

.gcp .submenuPages .item {
	padding-top: 8px;
	font-family: $mainFont;
	font-size: 18px;
}

.gcp .submenuPages {
	top: 0;
}

.tools .content .intro.tools {
	padding-top: 25px;
}

.community .content,
.news .content,
.tools .content,
.training .content {
	padding-bottom: 0;
}

.community .content .title span,
.news .content .title span,
.tools .content .title span,
.training .content .title span {
	font-family: $mainFont;
	font-size: 68px;
	letter-spacing: 2px;
}

.community .content .title,
.news .content .title,
.tools .content .title,
.training .content .title {
	margin-top: 40px;
}

.community .content .intro .introTitle,
.news .content .intro .introTitle,
.tools .content .intro .introTitle,
.training .content .intro .introTitle {
	color: #fff;
	font-family: $mainLightFont;
	font-size: 20px;
	opacity: 0.7;
	letter-spacing: 2px;
}

.community .content .intro,
.news .content .intro,
.tools .content .intro,
.training .content .intro {
	width: 100%;
	max-width: 600px;
}

.community .headerSpacer.startHeader,
.news .headerSpacer.startHeader,
.tools .headerSpacer.startHeader,
.training .headerSpacer.startHeader {
	height: 74px;
}

.community .contentWrap,
.news .contentWrap,
.tools .contentWrap,
.training .contentWrap {
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
}

.buttonGroup {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	height: 55px;
}

.filterButtons {
	width: 250px;
	margin: 0 auto;
	font-size: 0;
}

.filterButtons .filter {
	margin: 0 auto;
	width: 125px;
	height: 55px;
	display: inline-block;
	font-family: $mainFont;
	font-size: 17px;
	text-align: center;
	cursor: pointer;
	line-height: 55px;
	background-color: #fff;
	opacity: 0.4;
	color: #000;
}

.filterButtons .filter.active {
	background-color: $themeColor;
	color: $midColor;
	opacity: 1;
}

.buttonGroup .editTraining {
	font-size: 16px;
	text-align: center;
	margin: 10px auto;
	width: 100%;
	display: block;
}

.buttonGroup .editTraining {
	font-family: $mainFont;
	font-size: 18px;
	color: #fff;
}

.buttonGroup .editTraining .editIcon {
	background-image: url('img/editTrainingIcon.png');
	width: 18px;
	height: 18px;
	display: inline-block;
	background-size: 18px;
	margin: 2px 6px 0 0;
}

.buttonGroup .editTraining .saveIcon {
	background-image: url('img/completed.png');
	width: 17px;
	height: 14px;
	display: inline-block;
	background-size: cover;
	margin: 4px;
}

.trainingBlocks {
	margin: 32px 20px 0;
	position: relative;
}

.trainingBlocks .block .imgWrap {
	height: 123px;
	width: 100%;
}

.trainingBlocks .block .imgWrap img {
	width: 100%;
	height: 100%;
}

.trainingBlocks .block {
	cursor: pointer;
	display: inline-block;
	width: 220px;
	border: 1px solid #373737;
	margin-right: 8px;
	margin-top: 10px;
	position: relative;
}

.trainingBlocks .block .blockContent {
	background-color: #fff;
}

.trainingBlocks .block .blockContent .title {
	font-size: 20px;
	color: #00848d;
	font-family: $mainLightFont_italic;
	margin-top: 0;
	border-top: 1px solid #00848d;
	line-height: 58px;
	height: 48px;
}

.trainingBlocks .block .blockContent .trainingTitle {
	font-size: 20px;
	color: #00848d;
	font-family: $mainLightFont_italic;
	margin-top: 0;
	border-top: 1px solid #00848d;
	line-height: 58px;
	height: 48px;
	border-bottom: 1px solid #00848d;
}

.trainingBlocks .block .blockContent .subTitle {
	font-size: 17px;
	font-family: $mainLightFont;
	color: #3cc0c1;
	text-align: center;
	padding-bottom: 30px;
	max-width: 180px;
	margin: auto;
}

.trainingBlocks .block:hover .blockContent {
	background-color: #3cc0c1;
}

.trainingBlocks .block:hover .blockContent .subTitle {
	color: #fff;
}

.trainingStatus {
	background-repeat: no-repeat;
	color: $lightColor;
	font-family: $mainBoldFont;
	letter-spacing: 2px;
	text-align: center;
	line-height: 57px;
	text-transform: uppercase;
	font-size: 12px;
	position: absolute;
	width: 57px;
	height: 57px;
	border-radius: 50px;
	background-color: $themeColor;
	top: 12px;
	left: 50%;
	margin-left: -28px;
}

.trainingStatus.restart {
	width: auto;
	transform: translateX(-50%);
	margin-left: 0;
	padding: 0 10px;
}

.trainingStatus.hasScore {
	font-size: 18px;
}

.trainingStatus.agenda {
	background-image: url('img/agenda.png');
	background-size: 30px;
	background-position: 14px;
}

.trainingStatus.plus {
	background-image: url('img/plus.png');
	background-size: 25px 25px;
	background-position: 16px;
}

.trainingStatus.completed {
	background-image: url('img/completed.png');
	background-size: 25px 20px;
	background-position: 16px;
}

.trainingStatus.fit {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-position: center;
	background-size: initial;
	left: 0;
	top: 0 !important;
	margin: 0;
}

.trainingStatus.locked {
	background-image: url('img/locked.png');
	background-size: 26px 32px;
	background-position: 15px;
}

.trainingStatus.progress {
	background-color: transparent;
}

.trainingBlocks .block .imgWrap .progressLoader {
	width: 57px;
	height: 57px;
	position: relative;
	display: block;
}

.trainingBlocks .block .imgWrap .progressLoader {
	box-shadow: none;
	border: 0;
}

.trainingBlocks .block .imgWrap .progressLoader .inner {
	border: 3px solid rgba(255, 255, 255, 0.3);
}

.trainingBlocks .block .imgWrap .progressLoader .inner .icon {
	background-size: contain;
	width: 25px;
	max-height: 25px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.content.subject .subTitleContent .progressLoader .timer .slice,
.trainingBlocks .block .imgWrap .progressLoader .timer {
	width: 60px;
	height: 60px;
}

.trainingBlocks .block .imgWrap .progressLoader .timer>.slice {
	width: 57px;
	height: 57px;
	clip: rect(0, 69px, 78px, 0px);
}

.trainingBlocks .block .imgWrap .progressLoader .timer>.slice.gt50 {
	clip: rect(auto, auto, auto, auto);
}

.trainingBlocks .block .imgWrap .progressLoader .timer>.slice>.pie {
	border: 3px solid #008a8d;
	width: 57px;
	height: 57px;
	clip: rect(0, 70px, 69px, 0px);
	border-radius: 50%;
}

.trainingBlocks .block .imgWrap .progressLoader .inner {
	width: 54px;
	height: 54px;
}

.trainingBlocks .block .imgWrap .loader-circle .timer>.slice>.pie {
	width: 54px;
	height: 54px;
}

.trainingBlocks .program tr td {
	cursor: pointer;
}

.trainingBlocks .trainingStatus {
	cursor: pointer;
}

.trainingBlocks .program.noclick tr td {
	cursor: default;
}

.trainingBlocks .editTraining {
	text-align: right;
	position: absolute;
	right: 0;
	top: -36px;
	font-family: $mainFont;
	font-size: 18px;
	color: #fff;
}

.trainingBlocks .editTraining .editIcon {
	background-image: url('img/editTrainingIcon.png');
	width: 18px;
	height: 18px;
	display: inline-block;
	background-size: 18px;
	margin: 2px 6px 0 0;
}

.trainingBlocks .editTraining .saveIcon {
	background-image: url('img/completed.png');
	width: 17px;
	height: 14px;
	display: inline-block;
	background-size: cover;
	margin: 4px;
}

.header-quickscan .wrap {
	width: 280px;
	margin: 43px auto;
	text-align: center;
}

.header-quickscan .wrap .headerTitle {
	color: #00848d;
	font-size: 20px;
	font-family: $mainmediumFont;
}

.header-quickscan .wrap .headerSubTitle {
	font-size: 18px;
	font-family: $mainLightFont;
}

.header-quickscan {
	padding: 15px 15px 0;
}

.closeCross {
	background-image: url('img/closeCross.png');
	width: 14px;
	height: 14px;
	float: right;
	background-size: 14px;
}

.header-quickscan .loper {
	overflow: hidden;
	margin: 14px auto;
	background-color: #3bbec0;
	border-radius: 5px;
	width: 280px;
	height: 23px;
	line-height: 23px;
	position: relative;
}

.header-quickscan .loper .step {
	font-family: $mainFont;
	font-size: 16px;
	display: inline-block;
	color: rgba(255, 255, 255, 0.5);
	width: 35px;
	text-align: center;
	margin-left: -3px;
}

.header-quickscan .loper .progress {
	position: absolute;
	text-align: right;
	z-index: 9;
	background-color: #00888b;
	color: #fff;
	padding-right: 14px;
	-webkit-box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
}

.quickscanContent {
	margin: auto;
	width: 300px;
}

.quickscanContent .title {
	font-family: $mainmediumFont;
	letter-spacing: 0.72px;
	font-size: 20px;
	color: #00848d;
	margin-bottom: 26px;
}

.quickscanContent .subtitle {
	text-align: left;
	font-family: $mainLightFont;
	font-size: 20px;
	color: #00848d;
	margin-bottom: 26px;
}

.quickscanContent .subtitle,
.quickscanContent .title {
	text-align: left;
	width: 100%;
}

.quickscanContent .text {
	color: #00848d;
	font-family: $mainLightFont;
	font-size: 17px;
	line-height: 24px;
}

.quickscanContent .startButton {
	background-color: #3cc0c1;
	text-align: center;
	margin: 80px auto 25px;
	color: #fff;
	width: 250px;
	height: 55px;
	line-height: 55px;
	cursor: pointer;
	font-size: 15px;
}

.quickscanContent .navButtons {
	width: 295px;
	position: relative;
	margin: 15px auto 20px;
}

.quickscanContent .navButtons.spaced {
	padding: 50px 0;
}

.quickscanContent .navButtons .navButton {
	cursor: pointer;
	width: 110px;
	height: 55px;
	background-color: #3cc0c1;
	color: #fff;
	text-align: center;
	line-height: 55px;
	font-size: 18px;
	font-family: $mainFont;
}

.quickscanContent .navButtons .navButton.disabled {
	background-color: #5c6263;
	opacity: 0.3;
}

.quickscanContent .navButtons .back {
	float: left;
}

.quickscanContent .navButtons .next {
	float: right;
}

.quickscanContent input {
	border: 1px solid #fff;
	display: inline-block;
	height: 65px;
	width: 222px;
	line-height: 65px;
	font-family: $mainLightFont;
	font-size: 17px;
	color: #939393;
	padding: 10px;
}

.quickscanContent input::-webkit-input-placeholder {
	color: #939393;
}

.quickscanContent input:-moz-placeholder {
	color: #939393;
}

.quickscanContent input::-moz-placeholder {
	color: #939393;
}

.quickscanContent input:-ms-input-placeholder {
	color: #939393;
}

.quickscanContent .centerTekst {
	text-align: center;
}

.quickscanContent .searchBlock {
	z-index: 99;
	bordeR: 1px solid #000;
	width: 282px;
	min-height: 67px;
	position: relative;
	margin: 10px 0 160px;
	font-size: 0;
}

.quickscanContent .searchBlock .search {
	background-color: #00848d;
	cursor: pointer;
	bordeR-left: 1px solid #000;
	width: 58px;
	height: 65px;
	display: inline-block;
	background-image: url('img/searchButton.png');
	background-repeat: no-repeat;
	background-size: 26px;
	background-position: 16px;
}

.quickscanContent .searchBlock .search.disabled {
	background-image: url("img/searchDisabled.png");
}

.quickscanContent .searchBlock .search.plus {
	background-image: url("img/searchPlus.png");
}

.searchOpen {
	background-color: #fff;
	border: 1px solid #000;
	position: absolute;
	width: 100.5%;
	left: -1px;
	z-index: 9999;
	display: none;
}

.searchOpen .searchItem {
	cursor: pointer;
	padding: 0 9px;
	min-height: 52px;
	line-height: 52px;
	font-family: $mainLightFont;
	font-size: 17px;
	text-align: left;
}

.searchOpen .searchItem .innersearch {
	padding: 2px 0;
}

.searchOpen .searchItem:not(:first-child) .innerSearch {
	border-top: 1px solid #000;
}

.quickscan .quickscanContent .loader-circle {
	border: 1px solid #909090;
	position: relative;
	width: 254px;
	height: 254px;
	background: none;
}

.quickscan .quickscanContent .loader-circle .timer {
	width: 254px;
	height: 254px;
}

.quickscan .quickscanContent .loader-circle .inner {
	width: 190px;
	top: 50%;
	margin-top: -97px;
	border: 1px solid #888888;
	height: 190px;
	background-color: #fff;
	-webkit-box-shadow: inset 0 5px 15px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: inset 0 5px 15px 5px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 5px 15px 5px rgba(0, 0, 0, 0.1);
}

.quickscan .quickscanContent .loader-circle .inner .text {
	font-size: 14px;
	line-height: normal;
	width: 165px;
	color: #999999;
	margin: 40px auto 0;
	text-transform: none;
	font-weight: 300;
}

.quickscan .quickscanContent .loader-circle .inner .text:not(.hasimage) {
	padding: 23px;
}

#circle {
	position: absolute;
	color: #aab2b3;
	top: -18px;
	left: -18px;
	background: transparent;
}

.loader-circle .gt50 .fill {
	display: block;
}

.loader-circle .fill {
	display: none;
}

.quickscan .quickscanContent .loader-circle .timer>.slice {
	top: 9px;
	left: 7px;
	width: 254px;
	height: 254px;
}

.quickscan .quickscanContent .loader-circle .timer>.slice>.pie {
	box-sizing: content-box;
	border: 19px solid #17c1bc;
	position: absolute;
	width: 202px;
	height: 202px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-webkit-transform: rotate(442deg);
	transform: rotate(442deg);
	clip: rect(0, 0px, 129px, 0);
	border-radius: 50%;
}

.quickscan .quickscanContent .loader-circle .sliderNumber {
	text-align: center;
	width: 19px;
	line-height: 19px;
	height: 19px;
	cursor: pointer;
	font-family: $mainFont;
	font-size: 11px;
	position: absolute;
	z-index: 9;
	color: #afb1b3;
}

.sliderNumber.zero {
	top: 9px;
	left: 46%;
}

.sliderNumber.one {
	top: 24px;
	left: 68.5%;
	height: 21px !important;
	width: 21px !important;
}

.sliderNumber.two {
	top: 24%;
	left: 83%;
}

.sliderNumber.three {
	top: 47%;
	left: 90%;
}

.sliderNumber.four {
	top: 69%;
	left: 83%;
}

.sliderNumber.five {
	top: 86.5%;
	left: 65%;
}

.sliderNumber.six {
	top: 90%;
	left: 44%;
}

.sliderNumber.seven {
	top: 83%;
	left: 21%;
}

.sliderNumber.eight {
	top: 67%;
	left: 7.6%;
}

.sliderNumber.nine {
	top: 48%;
	left: 3%;
}

.sliderNumber.ten {
	top: 25%;
	left: 9%;
}

.sliderNumber.fifteen {
	top: 10%;
	left: 24%;
}

.sliderNumber.active {
	color: #fff !important;
}

.quickscan .quickscanContent .sliderNumber.selected:before {
	content: "";
	width: 29px;
	height: 19px;
	border-radius: 0 90px 90px 0;
	-moz-border-radius: 0 90px 90px 0;
	-webkit-border-radius: 0 90px 90px 0;
	background: #3cc0c1;
	position: absolute;
	display: inline-block;
	top: -3px;
	left: 0;
	z-index: -1;
	transform: rotate(-123deg);
}

.quickscan .quickscanContent .sliderNumber.zero1:before {
	transform: rotate(180deg);
	content: "";
	width: 28px;
	height: 19px;
	border-radius: 45px 90px 90px 20px;
	-moz-border-radius: 45px 90px 90px 20px;
	-webkit-border-radius: 45px 90px 90px 20px;
	background: #3cc0c1;
	position: absolute;
	display: inline-block;
	top: 1px;
	left: 0;
	z-index: -1;
}

.quickscan .quickscanContent .sliderNumber.selected.zero:before {
	top: 5px;
	left: 5px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.quickscan .quickscanContent .sliderNumber.selected.zero:before {
	top: -1px;
	left: 0;
	width: 20px;
	height: 20px;
}

.quickscan .quickscanContent .sliderNumber.one.selected:before {
	transform: rotate(25deg);
	top: 2px;
}

.quickscan .quickscanContent .sliderNumber.two.selected:before {
	transform: rotate(60deg);
	top: 7px;
	left: 0;
}

.quickscan .quickscanContent .sliderNumber.three.selected:before {
	transform: rotate(85deg);
	left: -5px;
}

.quickscan .quickscanContent .sliderNumber.four.selected:before {
	transform: rotate(123deg);
	left: -2px;
}

.quickscan .quickscanContent .sliderNumber.five.selected:before {
	transform: rotate(146deg);
}

.quickscan .quickscanContent .sliderNumber.six.selected:before {
	transform: rotate(178deg);
	top: 1px;
}

.quickscan .quickscanContent .sliderNumber.seven.selected:before {
	transform: rotate(206deg);
	top: 2px;
}

.quickscan .quickscanContent .sliderNumber.eight.selected:before {
	transform: rotate(-123deg);
	left: -7px;
}

.quickscan .quickscanContent .sliderNumber.nine.selected:before {
	transform: rotate(-93deg);
	left: -5px;
}

.quickscan .quickscanContent .sliderNumber.ten.selected:before {
	transform: rotate(-60deg);
	left: -4px;
	top: -1px;
	width: 30px;
}

.quickscan .quickscanContent .sliderNumber.fifteen.selected:before {
	transform: rotate(-35deg);
	width: 25px;
	top: -2px;
	left: 0;
}

.pie-start {
	width: 245px;
	height: 245px;
	position: absolute;
	top: 5px;
	left: 0;
}

.pie-end {
	width: 245px;
	height: 242px;
	position: absolute;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	top: 5px;
	left: 5px;
}

.pie-in {
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	background: #17c1bc;
}

.pie-end .pie-in {
	margin-left: -11px;
}

.quickscan .quickscanContent .loader-circle .timer>.slice {
	top: 5px;
	left: 6px;
	width: 245px;
	height: 245px;
}

.quickscan .quickscanContent .loader-circle .timer>.slice>.pie {
	box-sizing: content-box;
	border: 19px solid #17c1bc;
	position: absolute;
	width: 205px;
	height: 205px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	clip: rect(0, 122px, 245px, 0);
	border-radius: 50%;
}

.quickscan .quickscanContent .loader-circle .sliderNumber {
	text-align: center;
	width: 19px;
	line-height: 19px;
	height: 19px;
	cursor: pointer;
	font-family: $mainFont;
	font-size: 11px;
	position: absolute;
	z-index: 9;
	color: #afb1b3;
}

.sliderNumber.zero {
	top: 9px;
	left: 46%;
}

.sliderNumber.one {
	top: 24px;
	left: 68.5%;
	height: 21px !important;
	width: 21px !important;
}

.sliderNumber.two {
	top: 24%;
	left: 83%;
}

.sliderNumber.three {
	top: 47%;
	left: 90%;
}

.sliderNumber.four {
	top: 69%;
	left: 83%;
}

.sliderNumber.five {
	top: 86.5%;
	left: 65%;
}

.sliderNumber.six {
	top: 90%;
	left: 44%;
}

.sliderNumber.seven {
	top: 83%;
	left: 21%;
}

.sliderNumber.eight {
	top: 67%;
	left: 7.6%;
}

.sliderNumber.nine {
	top: 48%;
	left: 3%;
}

.sliderNumber.ten {
	top: 25%;
	left: 9%;
}

.sliderNumber.fifteen {
	top: 10%;
	left: 24%;
}

.quickscan .quickscanContent .loader-circle .numberWrap {
	z-index: 999999;
	position: absolute;
	width: 256px;
	height: 256px;
}

.quickscan .quickscanContent .loader-circle .number {
	pointer-events: none;
	width: 256px;
	height: 256px;
	position: absolute;
}

.quickscan .quickscanContent .loader-circle .number span.active {
	color: #fff !important;
}

.quickscan .quickscanContent .loader-circle .number span {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	display: inline-block;
	margin-left: 110px;
	margin-top: 2px;
	cursor: pointer;
	font-family: $mainFont;
	font-size: 15px;
	position: absolute;
	z-index: 9;
	color: #afb1b3;
	pointer-events: all;
}

.deg30 {
	-ms-transform: rotate(30deg);
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg);
}

.deg30 span {
	-ms-transform: rotate(-30deg);
	-webkit-transform: rotate(-30deg);
	transform: rotate(-30deg);
}

.deg60 {
	-ms-transform: rotate(60deg);
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg);
}

.deg60 span {
	-ms-transform: rotate(-60deg);
	-webkit-transform: rotate(-60deg);
	transform: rotate(-60deg);
}

.deg90 {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.deg90 span {
	-ms-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.deg120 {
	-ms-transform: rotate(120deg);
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg);
}

.deg120 span {
	-ms-transform: rotate(-120deg);
	-webkit-transform: rotate(-120deg);
	transform: rotate(-120deg);
}

.deg150 {
	-ms-transform: rotate(150deg);
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg);
}

.deg150 span {
	-ms-transform: rotate(-150deg);
	-webkit-transform: rotate(-150deg);
	transform: rotate(-150deg);
}

.deg180 {
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.deg180 span {
	-ms-transform: rotate(-180deg);
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.deg210 {
	-ms-transform: rotate(210deg);
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg);
}

.deg210 span {
	-ms-transform: rotate(-210deg);
	-webkit-transform: rotate(-210deg);
	transform: rotate(-210deg);
}

.deg240 {
	-ms-transform: rotate(240deg);
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg);
}

.deg240 span {
	-ms-transform: rotate(-240deg);
	-webkit-transform: rotate(-240deg);
	transform: rotate(-240deg);
}

.deg270 {
	-ms-transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

.deg270 span {
	-ms-transform: rotate(-270deg);
	-webkit-transform: rotate(-270deg);
	transform: rotate(-270deg);
}

.deg300 {
	-ms-transform: rotate(300deg);
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg);
}

.deg300 span {
	-ms-transform: rotate(-300deg);
	-webkit-transform: rotate(-300deg);
	transform: rotate(-300deg);
}

.deg330 {
	-ms-transform: rotate(330deg);
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg);
}

.deg330 span {
	-ms-transform: rotate(-330deg);
	-webkit-transform: rotate(-330deg);
	transform: rotate(-330deg);
}

.sliderNumber.active {
	color: #fff !important;
}

.slideContent {
	margin: 25px auto;
	position: relative;
}

.slideContent ul {
	left: 0;
}

.slideContent .item {
	width: 70px;
	height: 145px;
	display: inline-block;
	position: relative;
	margin-top: 25px;
	border: 1px solid rgba(102, 102, 102, 0.2);
	-webkit-box-shadow: inset 0 1px 18px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: inset 0 1px 18px 0 rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 18px 0 rgba(0, 0, 0, 0.2);
}

.slideContent .item.active {
	width: 105px;
	margin: 0 25px;
	height: 202px;
}

.slideContent .item.active .slideTitle {
	font-size: 14px;
}

.slideContent .item.active .itemOverlay {
	display: none;
}

.slideContent .item .slideTitle {
	margin-top: 16px;
	color: #fff;
	font-family: $mainFont;
	text-align: center;
	max-width: 116px;
	font-size: 10px;
}

.slideContent .item .itemOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
}

.slideContent .item .itemLogo {
	background-image: url('img/quickscan-stap5.png');
	background-repeat: no-repeat;
	background-position: center center;
	top: 43px;
	left: 8px;
	background-size: contain;
	height: 55px;
	width: 50px;
	position: absolute;
	opacity: 0.3;
}

.slideContent .item.active .itemLogo {
	width: 70px;
	height: 80px;
	left: 18px;
	top: 58px;
	opacity: 1;
}

.slideContent .item .itemLogo.onderzoeker {
	background-image: url('img/onderzoeker-logo.png');
}

.slideContent .item .itemLogo.sponsor {
	background-image: url('img/sponsor-logo.png');
}

.slideContent .item .itemLogo.monitor {
	background-image: url('img/monitor-logo.png');
}

.slideContent .item .itemLogo.metc-lid {
	background-image: url('img/metc-logo.png');
}

.slideContent .item .itemLogo.research-professional {
	background-image: url('img/researchprof-logo.png');
}

.slideContent .item .itemLogo.auditor {
	background-image: url('img/auditor-logo.png');
}

.slideContent .item .itemLogo.overig {
	background-image: url('img/overig-logo.png');
}

.slideContent .item .itemLogo.initirend-onderzoeker {
	background-image: url('img/initierendondz-logo.png');
}

.slideContent .item .information {
	opacity: 0.3;
	position: absolute;
	left: 25px;
	bottom: 11px;
	width: 22px;
	height: 22px;
	background-image: url("img/GCP_overlayInformation.png");
	background-repeat: no-repeat;
	background-size: contain;
}

.slideContent .item.active .information {
	opacity: 1;
}

.slideContent .item.active .information {
	width: 25px;
	height: 25px;
	left: 41px;
	bottom: 18px;
}

.selectButton {
	position: relative;
}

.selectButton .select {
	position: absolute;
	cursor: pointer;
}

.selectButton .select.left {
	left: 27px;
	width: 13px;
	height: 28px;
	background-size: contain;
	background-image: url('img/GCP_arrowLeftLarge.png');
}

.selectButton .select.right {
	right: 27px;
	top: 0;
	width: 13px;
	height: 28px;
	background-size: contain;
	background-image: url('img/GCP_arrowRightLarge.png');
}

.selectButton .select.radiobutton {
	position: relative;
	margin: 15px auto;
	text-align: center;
	width: 50px;
}

.selectButton label.styled {
	color: #00848d;
}

.selectButton label.styled [type="checkbox"]+span,
.selectButton label.styled [type="radio"]+span {
	float: inherit;
}

.fullscreen.overlay {
	display: none;
	z-index: 1000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.stap9Overlay.fullscreen.overlay {
	display: block;
	background-color: #3cc0c1;
}

.fullscreen.overlay .overlayContent {
	width: 300px;
	margin: 30px auto 0;
}

.fullscreen.overlay .centerContent {
	text-align: center;
}

.fullscreen.overlay .information {
	background-image: url('img/GCP_overlayInformation.png');
	margin: 0 auto;
	width: 33px;
	height: 33px;
}

.fullscreen.overlay .itemLogo {
	background-image: url('img/GCP_guickscanLogo.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	width: 210px;
	height: 208px;
	margin: 50px auto 0;
	opacity: 0.4;
}

.fullscreen.overlay .itemLogo.onderzoeker {
	background-image: url('img/onderzoeker-logo.png');
}

.fullscreen.overlay .itemLogo.sponsor {
	background-image: url('img/sponsor-logo.png');
}

.fullscreen.overlay .itemLogo.monitor {
	background-image: url('img/monitor-logo.png');
}

.fullscreen.overlay .itemLogo.metc-lid {
	background-image: url('img/metc-logo.png');
}

.fullscreen.overlay .itemLogo.research-professional {
	background-image: url('img/researchprof-logo.png');
}

.fullscreen.overlay .itemLogo.auditor {
	background-image: url('img/auditor-logo.png');
}

.fullscreen.overlay .itemLogo.overig {
	background-image: url('img/overig-logo.png');
}

.fullscreen.overlay .itemLogo.initirend-onderzoeker {
	background-image: url('img/initierendondz-logo.png');
}

.fullscreen.overlay .itemTitle {
	font-size: 22px;
	color: #fff;
	margin: 35px 0 0;
}

.fullscreen.overlay .itemSubtitle {
	font-size: 20px;
	color: #fff;
}

.fullscreen.overlay .itemContent {
	font-size: 18px;
	color: #fff;
	line-height: 24px;
	margin: 20px 0 0;
}

.fullscreen.overlay .itemContent.centerContent {
	margin: 150px 0 0;
	text-align: center;
}

.fullscreen.overlay .itemContent .loader {
	opacity: 0.7;
}

.fullscreen.overlay .close {
	top: 20px;
	right: 15px;
	background-image: url('img/closeWVsmall.png');
	position: absolute;
	background-size: 30px 30px;
	width: 30px;
	height: 30px;
}

.fullscreen.overlay .closeQuickscan {
	top: 20px;
	right: 15px;
	background-image: url('img/closeWVsmall.png');
	position: absolute;
	background-size: 30px 30px;
	width: 30px;
	height: 30px;
}

.quickscanCompleted {
	position: absolute;
	color: #fff;
	text-align: center;
	width: 100%;
	left: 0;
	z-index: 9;
	-webkit-box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.15);
}

.stap10 .quickscanContent {
	text-align: center;
}

.quickscanContent .selectContent {
	margin: 10px 0 30px;
}

.quickscanContent .column {
	width: 100%;
	display: block;
}

.quickscanContent .column label.styled {
	font-size: 14px;
	text-align: left;
	vertical-align: middle;
	display: inline-block;
	height: 40px;
}

.quickscanContent .column label.styled [type="checkbox"]+span,
.quickscanContent .column label.styled [type="radio"]+span {
	float: left;
}

.quickscanContent .column label.styled .textLine {
	display: inline-block;
	float: right;
	width: 250px;
	vertical-align: middle;
}

.quickscanContent .column label.styled .textLine br {
	display: none;
}

.quickscanContent .columnLarge {
	width: 180px;
	border-right: 1px solid #000;
	display: inline-block;
}

.quickscanContent .columnSmall {
	width: 110px;
	display: inline-block;
	height: 150px;
}

.quickscanContent .columnLarge label.styled,
.quickscanContent .columnSmall label.styled {
	width: 49%;
	margin: 0 0 20px;
}

.quickscanContent .columnSmall label.styled {
	width: 100%;
	margin: 60px 0 20px 14px;
}

.quickscanContent .columnLarge label.styled [type="checkbox"]+span,
.quickscanContent .columnLarge label.styled [type="radio"]+span,
.quickscanContent .columnSmall label.styled [type="checkbox"]+span,
.quickscanContent .columnSmall label.styled [type="radio"]+span {
	float: left;
	width: 80px;
	height: 80px;
	background: url('img/GCP_checkboxLarge.png');
	background-size: 80px 80px;
	text-align: center;
	padding: 20px 10px;
	display: inline-block;
	color: #3cc0c1;
	font-size: 15px;
}

.quickscanContent .columnLarge label.single [type="checkbox"]+span,
.quickscanContent .columnLarge label.single [type="radio"]+span,
.quickscanContent .columnSmall label.single [type="checkbox"]+span,
.quickscanContent .columnSmall label.single [type="radio"]+span {
	padding: 28px 15px 25px;
}

.quickscanContent .columnLarge label.styled:checked+span,
.quickscanContent .columnSmall label.styled:checked+span {
	background: url('img/GCP_checkboxLargeChecked.png');
	color: #fff;
	background-size: 80px 80px;
}

.quickscanBlocks {
	width: 300px;
	margin: 0 auto;
}

.quickscanBlocks table.inputs.lessons.followup tr {
	float: left;
	height: 80px;
	margin: 5px;
}

.quickscanBlocks table.inputs.lessons.followup tr td {
	background: #fff;
	width: 300px;
	height: 80px;
	position: relative;
	border: 1px solid #353535;
}

.quickscanBlocks table.inputs.lessons.followup tr td .info {
	top: auto;
	bottom: 0;
	padding: 0;
	right: 30px;
	width: 160px;
	height: 80px;
	margin: 8px 0 0;
	display: table;
	position: absolute;
}

.quickscanBlocks table.inputs.lessons.followup tr td .info .text {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.quickscanBlocks table.inputs.lessons.followup tr td .info .text .smallTitle {
	color: #00848d;
	font-family: $mainLightFont_italic;
	font-size: 20px;
	border-top: 1px solid #00848d;
	border-bottom: 1px solid #00848d;
	display: inline-block;
	margin: 0 0 10px;
}

.quickscanBlocks table.inputs.lessons.followup tr td .info .text p {
	font-size: 17px;
	color: #3cc0c1;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image,
.quickscanBlocks table.inputs.lessons.followup tr td.fullWidth .status {
	z-index: 2;
	position: absolute;
	left: 0;
	top: 0;
	background-size: 120px 80px;
	background-position: -40px 0;
	width: 80px;
	height: 80px;
}

.quickscanBlocks table.inputs.lessons.followup tr td .bookOverlay,
.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay {
	background: rgba(30, 30, 30, .5);
	height: 100%;
	width: 100%;
	position: relative;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .start,
.quickscanBlocks table.inputs.lessons.followup tr td.fullWidth .status .start {
	background: url('img/quickscan_start.png');
	background-size: 50px 50px;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .completed,
.quickscanBlocks table.inputs.lessons.followup tr td.fullWidth .status .completed {
	background: url('img/quickscan_afgerond.png');
	background-size: 50px 50px;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .vrijstelling {
	display: none;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .vrijstelling .skipBtn {
	display: block;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .vrijstelling {
	text-align: center;
	padding: 10px 20px;
	color: #fff;
	font-family: $mainExtraLightFont;
	font-size: 14px;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .skipTitle {
	font-size: 20px;
	margin: 0 0 10px;
	letter-spacing: 3px;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .skipBtn {
	text-align: center;
	margin: 0 auto;
	display: block;
	color: #3cc0c1;
	font-size: 18px;
}

.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .skipBtn .arrowRight {
	display: inline-block;
	width: 11px;
	height: 26px;
	margin-left: 5px;
	margin-top: 2px;
	background-image: url("img/GCP_arrowRight.png");
	background-repeat: no-repeat;
}

.navButtons {
	margin: 20px auto 0;
	width: 300px;
	text-align: center;
}

.navButtons .navContinue {
	display: block;
	background: #3cc0c1;
	text-align: center;
	color: #fff;
	padding: 15px 0;
	font-size: 18px;
	font-family: $mainFont;
}

.navButtons .navContinue .arrowRight {
	display: inline-block;
	width: 11px;
	height: 26px;
	margin-left: 10px;
	background-image: url("img/GCP_arrowWhiteRight.png");
	background-repeat: no-repeat;
}

.navButtons.jsDataRequest.disabled {
	pointer-events: none;
}

.program {
	display: none;
}

.program.active {
	display: block;
}

.searchOverlay {
	display: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	position: fixed;
	background-color: #000;
	opacity: 0.15;
}

.centerText {
	text-align: center;
}

.leftText {
	text-align: left;
}

.rightText {
	text-align: right;
}

.profile .profileSection {
	width: 95%;
	max-width: 300px;
	margin: 0 auto;
}

.profile .profileBlock {
	display: block;
	width: 100%;
}

.profile .profileBlock {
	padding-bottom: 40px;
}

.profile .profileBlock.noMobile {
	padding-bottom: 0;
}

.profile .profileBlock.indented {
	padding: 0;
}

.profile .profileBlock .avatar {
	width: 133px;
	height: 133px;
	margin: 25px auto;
}

.profile .profileBlock .blockText {
	font-size: 16px;
	font-family: $mainFont;
}

.content.profile .profileBlockTitle,
.profile .profileBlock .profileBlockTitle {
	font-family: $mainFont;
	font-size: 20px;
	color: $lightestColor;
	width: 100%;
	border-bottom: 1px solid $lightestColor;
	padding-bottom: 5px;
}

.content.profile .profileBlockTitle {
	width: 100%;
	margin: 0 auto 20px;
	max-width: 298px;
	position: relative;
}

.content.profile .styled-outer {
	color: #000000;
	line-height: 25px;
	margin: 5px 0;
	position: relative;
	text-align: left;
}

.content.profile .styled-outer label.styled {
	display: inline-block;
	width: auto;
}

.content.profile .profileBlock.notifSettings .navButtons {
	width: 100%;
	margin: 0;
}

.content.profile .profileBlock.notifSettings .navButtons .navButton {
	border: none;
	outline: none;
	max-width: 100%;
}

.content.profile .profileBlock .information {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 2px 0 0 15px;
}

.content.profile .profileBlock .information .info-icon {
	background-image: url("img/GCP_information.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 2px 11px;
	padding: 8px;
	display: block;
	border: 2px solid $lightestColor;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
}

.content.profile .profileBlock .informationMessage {
	margin-top: 18px;
}

.content.profile .profileBlock .informationMessage:after {
	right: calc( 100% - 31px);
}

.profile .profileBlock .blockText.textTitle {
	font-size: 18px;
	font-family: $mainmediumFont;
	height: 30px;
}

.profile .programPackage.online {
	background-image: url('img/packageOnline.png');
	width: 100px;
	height: 113px;
	margin: 20px auto;
}

.profile .programPackage {
	background: no-repeat center center;
	height: 113px;
	margin: 20px auto;
	background-size: contain;
	background-image: url('img/packageOnline.png');
	opacity: 0.2;
}

.profile .packageInfo {
	font-size: 17px;
}

.profile .packageProvider {
	color: #00848d;
	font-size: 12px;
	margin-top: 10px;
}

.profile .packageInfo span {
	color: $lightestColor;
}

.profile .packageInfo .firstSpan {
	margin-left: 15px;
}

.profile .navButtons {
	position: relative;
	margin: 15px auto 10px;
	width: 100%;
}

.navButtons+.navButtons .navButton {
	margin-top: 11px;
}

.profile .navButtons .navButton {
	display: inline-block;
	cursor: pointer;
	width: 110px;
	height: 55px;
	background-color: $themeColor;
	color: $midColor;
	text-align: center;
	line-height: 55px;
	font-size: 18px;
	font-family: $mainFont;
}

.profile .navButtons .navButton.small {
	width: 86px;
}

.profile .navButtons .navButton.full {
	width: 100%;
}

.profile .navButtons .navButton.disabled {
	background-color: #5c6263;
	opacity: 0.3;
}

.profile .navButtons .navButton.back {
	float: left;
}

.profile .navButtons .navButton.next {
	float: right;
}

.profile.navButtons .navButton.after {
	clear: both;
}

.profile .navButtons .navButton .arrow {
	width: 11px;
	height: 27px;
	background: url('img/btnArrow.png');
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px;
}

.profile .noMobile {
	display: none;
}

.profile .mobileOnly {
	display: block;
}

.profile .legenda {
	display: inline-block;
	width: 100%;
	text-align: right;
	padding-top: 10px;
}

.profile .legenda .item {
	display: inline-block;
	margin: 0 5px 5px;
}

.profile .legenda .item span {
	border-radius: 50%;
	width: 15px;
	height: 15px;
	margin-right: 5px;
	display: inline-block;
}

.profile .legenda .item span.blue {
	background: #2fbfc0;
}

.profile .legenda .item span.yellow {
	background: #faa21e;
}

.profile .diagrams .block {
	text-align: center;
	font-size: 17px;
	margin: 20px 0;
}

.profile .diagram {
	width: 297px;
	height: 132px;
	background: url("img/legegrafiek.png") no-repeat center center;
	background-size: 297px 132px;
	display: inline-block;
}

.profile .activities .activity {
	background: #f8f8f8;
	padding: 22px;
	border-bottom: 1px solid #00848d;
}

.profile .activityGroup.last .activity.right {
	border-bottom: none;
}

.profile .activities .activity .icon {
	width: 43px;
	height: 43px;
	float: left;
	margin-right: 15px;
}

.profile .activities .activity .icon img {
	max-width: 100%;
	max-height: 100%;
}

.profile .activities .activity .activitytitle {
	color: #3cc0c1;
	font-size: 14px;
}

.profile .activities .activity .body {
	font-size: 16px;
	font-family: $mainExtraLightFont;
}

.profile .activities .activity .body a {
	color: #00848d;
}

.profile .loadmore {
	width: 100%;
	text-align: center;
	font-size: 17px;
	margin: 10px 0;
	font-family: $mainLightFont;
}

.profile .fullblock {
	width: 100%;
	height: 61px;
	font-size: 16px;
	position: relative;
	padding: 0 55px 0 15px;
	margin: 10px 0;
}

.profile .fullblock a {
	display: table-cell;
	height: 61px;
	vertical-align: middle;
	font-family: $mainFont;
	color: $midColor;
}

.profile .fullblock.blue {
	background: $themeColor;
	color: $buttonTextColor;
}

.profile .fullblock.white {
	border: 1px dashed #b5b5b5;
}

.profile .icon.certificate {
	background: url('img/certificateIcon.png');
	width: 27px;
	height: 28px;
	position: absolute;
	top: 20px;
	right: 10px;
	bottom: 20px;
}

.profile .white .icon.certificate {
	background-image: url('img/certificateIconGray.png');
	background-size: 27px;
}

.profile .downloadfile {
	background: url('img/downloadButton.png');
	width: 52px;
	height: 53px;
	margin: 20px auto;
}

.profile .downloadTitle {
	font-family: $mainBoldFont;
	color: #3cc0c1;
}

.profile .downloadText,
.profile .downloadTitle {
	font-size: 18px;
	display: block;
	margin: 0 auto;
}

.slideMessages .menuItems .item.msgRow .msgTitle {
	display: inline-block;
}

.slideMessages .menuItems .item.msgRow .msgDate {
	display: inline-block;
	font-family: $mainExtraLightFont;
	color: #999999;
}

.close {
	position: absolute;
	top: 30px;
	right: 30px;
	background-image: url("img/GCP_close_overlay.png");
	background-repeat: no-repeat;
	background-size: 14px 14px;
	width: 14px;
	display: block;
}

.wvInformation {
	background: #fff;
	position: absolute;
	color: #00848d !important;
	font-family: $mainmediumFont;
	font-size: 20px;
	line-height: 25px;
	right: 10px;
	top: 10px;
	padding: 2px 11px 0;
	border: 2px solid #00848d;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}

.error .wvInformation {
	color: #df213c !important;
	border-color: #df213c;
}

.wvFeedbackContent {
	text-align: center;
	margin-top: 25px;
}

.content .intro .introTitle {
	text-transform: inherit;
	color: $darkColor;
	font-family: $mainmediumFont;
}

.content .creditDetailsInfo {
	text-align: center;
	margin: 15px 0 10px;
	font-size: 15px;
	color: $darkColor !important;
}

.webshop .intro .error {
	font-size: 15px;
	background: rgba(255, 255, 255, 0.5);
	padding: 10px;
	margin-bottom: 10px;
}

.webshop .content .creditDetailsInfo {
	color: rgba(255, 255, 255, 0.5) !important;
	font-family: $mainLightFont !important;
	margin: 50px 0 10px;
}

.content table.inputs tr td {
	width: 315px;
}

.content table.inputs tr td.error {
	border-color: #df213c;
}

.content table.inputs tr td.error .trSim {
	border-color: #df213c;
}

.content table.inputs tr td.error .trSim input,
.content table.inputs tr td.error .trSim p {
	color: #df213c;
}

.content table.inputs tr td.error span {
	color: #df213c;
}

.content .submitBtn {
	height: 61px;
	max-width: 315px;
}

.content .submitBtn.profile {
	background: $themeColor;
}

.content .submitBtn.register.error,
.content .submitBtn.changePasswordBtn.error {
	background-color: #df213c;
	border-color: #df213c;
	color: #fff;
}

.content.error .submitBtn:not(.changePasswordBtn) {
	background-color: #df213c;
	border-color: #df213c;
	color: #fff;
}

.content .error.wvChooseProduct {
	background-color: #df213c;
	border-color: #df213c;
}

.content .error.errorMessage,
.content.error .errorMessage {
	margin-top: 0;
}

.content .error.errorMessage:after,
.content.error .errorMessage:after {
	border-style: solid;
	border-color: #fff transparent;
	display: block;
	width: 0;
	top: -17px;
	bottom: auto;
	left: auto;
	right: 137px;
	border-width: 0 10px 20px;
	content: "";
	position: absolute;
}

.content .error.errorMessage {
	margin-top: -10px;
}

.content table.inputs tr td.information {
	border: 1px solid #3cc0c1;
}

.informationMessage {
	position: relative;
	padding: 20px;
	margin: 1em auto 3em;
	color: #ffffff;
	background: #3cc0c1;
	margin-top: -25px;
	font-family: $mainLightFont;
	text-align: center;
	font-size: 16px;
	max-width: 315px;
}

.informationMessage:after {
	border-style: solid;
	border-color: #3cc0c1 transparent;
	display: block;
	width: 0;
	top: -17px;
	bottom: auto;
	left: auto;
	right: 150px;
	border-width: 0 10px 20px;
	content: "";
	position: absolute;
}

.informationMessage a {
	color: #fff;
	text-decoration: underline;
}

.content .wvSteps {
	text-align: center;
}

.content .wvSteps .step {
	display: inline-block;
	position: relative;
	margin: 0 17px 0 0;
}

.content .wvSteps .step:last-child {
	margin: 0;
}

.content .wvSteps .step a {
	background-color: #adb8ba;
	line-height: 35px;
	width: 35px;
	height: 35px;
	color: #fff;
	font-size: 18px;
	font-family: $mainmediumFont;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
}

.content .wvSteps .step::before {
	content: " ";
	position: absolute;
	top: 15px;
	left: -10px;
	height: 5px;
	width: 10px;
	background: #adb8ba;
}

.content .wvSteps .step::after {
	content: " ";
	position: absolute;
	top: 15px;
	right: -10px;
	height: 5px;
	width: 11px;
	background: #adb8ba;
}

.content .wvSteps .step:first-child::before {
	display: none;
}

.content .wvSteps .step:last-child::after {
	display: none;
}

.content .wvSteps .step.active a {
	background-color: #3cc0c1;
	-webkit-box-shadow: inset 3px 4px 5px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 3px 4px 5px 0 rgba(0, 0, 0, 0.15);
	-ms-box-shadow: inset 3px 4px 5px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 3px 4px 5px 0 rgba(0, 0, 0, 0.15);
}

.content .wvSteps .step.active::after {
	background: #3cc0c1;
	-webkit-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	-ms-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
}

.content .wvSteps .step.active::before {
	background: #3cc0c1;
	-webkit-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	-ms-box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 2px 3px 2px 0 rgba(0, 0, 0, 0.15);
}

.stepHeader {
	color: #fff;
	font-size: 17px;
	min-height: 225px;
	overflow: hidden;
	text-align: center;
	background: url("img/stepheader.jpg") center no-repeat;
	background-size: cover;
	padding: 20px;
	position: relative;
}

.stepHeader:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 0;
}

.stepHeader .intro {
	z-index: 5;
	position: relative;
}

.stepHeader .introText {
	color: #fff;
	font-family: $mainFont;
}

.stepHeader .introText h1 {
	font-size: 47px;
	font-family: $mainBoldFont;
	margin: 75px 0 5px;
	letter-spacing: 3px;
}

.stepnav {
	border: 2px solid #fff;
	font-size: 16px;
	display: inline-block;
	position: absolute;
	left: 30px;
	top: -40px;
	margin: 0;
}

.stepHeaderSpacer{
	height:50px;
}

.stepnav .stepText {
	display: inline-block;
	padding: 15px 25px;
	font-family: $mainBoldFont;
}

.stepnav a {
	display: inline-block;
	border: 0 solid #fff;
	width: 51px;
	height: 51px;
}

.stepnav a:after {
	display: block;
	content: "";
	background: url("img/arrow-left-big.png") center no-repeat;
	width: 100%;
	background-size: contain;
	height: 17px;
	margin: 17px 0;
}

.stepnav a.prev {
	border-right-width: 2px;
}

.stepnav a.next {
	border-left-width: 2px;
}

.stepnav a.next:after {
	transform: rotate(180deg);
}

.stepnav a.disabled:after {
	opacity: 0.5;
}

.header-gcp .stepnav {
	border-color: #000;
}

.contact.information {
	margin-top: 2em;
	text-align: left;
	display: inline-block;
	z-index: 5;
	position: relative;
	cursor: default;
}

.contact.information h2 {
	font-size: 17px;
	font-family: $mainBoldFont;
	color: #fff;
}
.webshop-product-overlay .information h2 {
	font-size: 17px;
	font-family: $mainBoldFont;
}

.contact.information .infoRow,
.webshop-product-overlay .information .infoRow {
	font-size: 15px;
	margin: 10px 0;
	padding-left: 20px;
	background: none center left no-repeat;
	background-size: contain;
	line-height: 11px;
	color: #fff;
}

.contact.information .infoRow a {
	color: #fff;
	font-family: $mainFont;
}

.contact.information .infoRow.phone,
.webshop-product-overlay .information .infoRow.phone {
	background-image: url("img/phoneIcon.png");
}

.contact.information .infoRow.mail,
.webshop-product-overlay .information .infoRow.mail {
	background-image: url("img/mailIcon.png");
}

.webshop-overlay .contact.information {
	position: absolute;
	top: 20px;
	right: 20px;
}

.content .wvSelectedProduct {
	width: 300px;
	display: block;
	margin: 25px auto 0;
}

.content .wvProductSteps {
	width: 300px;
	display: block;
	margin: 15px auto 0;
}

.content .wvProductStep input {
	position: absolute;
	visibility: hidden;
}

.content .wvProductSteps .wvStepsInformation {
	display: block;
	text-align: center;
	padding: 10px 0;
	font-family: $mainFont;
	font-size: 15px;
}

.content .wvProductSteps .wvStepsInformation img {
	position: relative;
	top: 3px;
}

.content .wvProductSteps .column {
	float: left;
	width: 280px;
	display: inline-block;
}

.content .wvProductSteps .column:nth-child(even) {
	margin-right: 30px;
}

.content .wvProductSteps .column .wvProductStep {
	width: 300px;
	float: left;
	margin-bottom: 15px;
	display: inline-block;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	background: #fff;
	position: relative;
}

.content .wvProductSteps .column .wvProductStep .imgOverlay {
	background: rgba(40, 40, 40, 0.3);
	z-index: 10;
	position: absolute;
	width: 100%;
	height: 100%;
}

.content .wvProductSteps .column .wvProductStep.selected .imgOverlay {
	display: none;
}

.content .wvProductSteps .column .wvProductStep .stepContent {
	background: #fff;
	width: 140px;
	min-height: 158px;
	padding: 10px;
	display: inline-block;
}

.content .wvProductSteps .column .wvProductStep .stepContent .wvStepTitle {
	color: #00848d;
	margin-bottom: 10px;
	font-size: 14px;
}

.content .wvProductSteps .column .wvProductStep .stepContent .wvStepTitle strong {
	font-family: $mainmediumFont;
}

.content .wvProductSteps .column .wvProductStep .checkOverlay {
	position: absolute;
	z-index: 20;
	left: 210px;
	top: 55px;
	background-image: url("img/GCP_checkboxStep.png");
	width: 52px;
	height: 52px;
	padding: 12px 10px;
	text-align: center;
}

.content .wvProductSteps .column .wvProductStep .checkOverlay.selected {
	text-indent: -9999px;
	overflow: hidden;
	background-image: url("img/GCP_checkboxChecked.png");
}

.content .wvProductGroup.user {
	border-bottom: 2px solid #707070;
	background: linear-gradient(to top, rgba(112, 112, 112, 0.3) 0%, rgba(112, 112, 112, 0) 10%);
}

.content .wvProductBox {
	display: table;
	text-align: center;
	width: 100%;
	margin: 0 auto;
}

.content .wvProductBox .wvProduct {
	display: inline-block;
	width: calc(100% - 20px);
	height: 350px;
	margin: 30px 10px;
	padding: 0 20px;
	text-align: left;
	position: relative;
	color: #000;
	background:#fff;
}

a.wvReadMore {
	position: absolute;
	bottom: 0;
	right: 0;
	background: white;
	padding-left: 10px;
	color: #19a288;
	text-decoration: underline;
	z-index:10;
}

.wvProductText {
	margin-top: 190px;
}

.content .wvProductBox.step2 .wvProduct {
	margin: 0;
}

.content .wvProductBox .wvProduct .wvProductTitle {
	font-size: 20px;
	font-family: $mainBoldFont;
	margin: 0 0 10px;
	line-height: 21px;
}

.content .wvProductBox .wvProduct .wvProductTitle .wvProductDivider::after {
	content: "|";
	padding: 0 5px;
	display: inline-block;
	width: 5px;
	height: 20px;
	font-family: 'argumentumblack',sans-serif;
}

.content .wvProductBox .wvProduct .wvProductTitle strong {
	font-family: $mainBoldFont;
}

.content .wvPageBack {
	float: left;
	width: 9px;
	height: 21px;
	margin-right: 15px;
	color: #00848d;
	text-indent: 15px;
	font-size: 16px;
	font-family: $mainFont;
	background-image: url("img/GCP_arrowLeft.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .wvProductTitle .wvProductToggle {
	float: right;
	margin-top: 7px;
	width: 26px;
	height: 11px;
	background-image: url("img/GCP_arrowUp.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .wvProductTitle .wvProductToggle.open {
	background-image: url("img/GCP_arrowDown.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .wvProductInfo {
	max-height: 55px;
	overflow: hidden;
	font-family: $mainLightFont;
	font-size: 12px;
	position: relative;
}

.content .wvProductBox .wvProduct .wvProductInfo p {
	margin: 0 0 10px;
	line-height: 18px;
}

.content .wvProductBox .wvProduct .wvProductInfo ul {
	list-style-type: none;
}

.content .wvProductBox .wvProduct .wvProductInfo ul li {
	font-family: $mainmediumFont;
}

.content .wvProductBox .wvProduct .wvProductInfo ul li::before {
	content: "\2713";
	margin-right: 0.2em;
	color: inherit;
}

.content .wvProductBox .wvProduct .wvProductImage {
	height: 180px;
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center;
}

.content .wvProductBox .wvProduct .wvProductImage.lightProduct {
	background-image: url("img/productLight.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .wvProductImage.onlineProduct {
	background-image: url("img/productOnline.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .wvProductImage.blendedProduct {
	background-image: url("img/productBlended.png");
	background-repeat: no-repeat;
}

.content .wvProductBox .wvProduct .price {
	color:#19a288;
	margin-bottom: 5px;
}

.content .wvProductBox .wvProduct .price .amount {
	font-size: 20px;
	font-weight: bold;
	font-family: $mainBoldFont;
	display:inline-block;
}

.content .wvProductBox .wvProduct .price .vat {
	display:inline-block;
	line-height: 26px;
}

.wvChooseProductWrap {
	text-align:center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -16px;
}

.content .wvChooseProduct {
	display: block;
	background: $themeColor;
	text-align: center;
	color: $buttonTextColor;
	padding: 6px 0;
	font-size: 15px;
	line-height: 27px;
	font-family: $mainFont;
}

.content .step1 .wvChooseProduct {
	display: inline-block;
	padding: 0 20px;
	font-size: 20px;
	line-height: 32px;
	font-family: $mainBoldFont;
	font-weight: bold;
	text-transform: uppercase;
}

.content .user .wvChooseProduct {
	background: $themeColor;
}

.content .openButton {
	color: $themeColor;
	display: block;
	border: 1px solid $themeColor;
	text-align: center;
	padding: 5px;
	margin: 7px 0;
	font-weight: bold;
	font-family: $mainmediumFont;
}

.content .user .openButton {
	color: $themeColor;
	border-color: $themeColor;
}

.content .shopBox {
	border: 2px solid #fff;
	color: #fff;
	max-width: 700px;
	margin: 30px auto;
	font-family: $mainLightFont;
	display: flex;
	flex-flow: row wrap;
}

.content .shopBox.centerText {
	padding: 15px;
	position: relative;
}

.content .shopBox.centerText .boxTitle {
	font-family: $mainBoldFont;
	font-size: 24px;
}

.content .shopBox .block {
	display: inline-block;
	width: 100%;
}

.content .shopBox .blockHeader {
	background: #2e2f2e;
	text-align: center;
	font-size: 16px;
	line-height: 75px;
	font-family: $mainBoldFont;
}

.content .shopBox .innerBlock {
	padding: 15px;
}

.content .shopBox .innerBlock.blockRight {
	padding: 25px 10px;
	max-width: 300px;
	margin: auto;
}

.content .shopBox.centerText .innerBlock img {
	width: 190px;
	margin: 25px 0;
}

.content .shopBox.centerText .innerBlock .loader {
	margin: 40px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	border-left: 1.1em solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: spinner 1.1s infinite linear;
	animation: spinner 1.1s infinite linear;
}

.content .shopBox.centerText .innerBlock .loader,
.content .shopBox.centerText .innerBlock .loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.content .shopBox.centerText .innerBlock .sub-text {
	font-family: $mainFont;
	font-size: 18px;
}

.content .shopBox.centerText .wvPayProduct {
	margin: 10px auto;
	width: 200px;
	height: 40px;
	font-size: 15px;
	line-height: 40px;
}

.content .shopBox.centerText .wvPayProduct .arrowRight {
	margin-top: 6px;
}

.content .shopBox .innerBlock .discountTitle {
	font-family: $mainBoldFont;
	font-size: 14px;
	padding: 0;
}

.content .shopBox.webshopLogin.shopboxwrapper{
	display: flex;
  	justify-content: center;
	column-gap: 2px;
	row-gap: 2px;
	border:none;
}

.social-links {
	margin: 25px auto 0;
}

.social-links .shareBtn {
	margin: 10px 0 0;
	font-family: $mainLightFont;
	font-size: 13px;
}

.social-links .shareBtn div {
	font-family: $mainBoldFont;
}

.socialBtn {
	width: 32.5px;
	height: 32.5px;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	margin: 0 5px;
}

.socialBtn.facebook {
	background-image: url('img/social-facebook-icon.png');
}

.socialBtn.linkedin {
	background-image: url('img/social-linkedin-icon.png');
}

.socialBtn.email {
	background-image: url('img/social-mail-icon.png');
}

.content .webshopLogin .innerBlock {
	padding: 15px 15px 25px;
}

.content .webshopLogin .blockHeader {
	background: transparent;
	line-height: 60px;
}

.content .webshopLogin .innerBlock .introText {
	padding: 0 15px;
	color: rgba(255, 255, 255, 0.6);
	line-height: 17px;
	font-size: 15px;
}

.content .wvChooseProduct.stap5 {
	color: #fff !important;
	position: inherit;
	text-decoration: none !important;
}

.content .wvOptions .wvChooseProduct {
	position: inherit;
	bottom: 0;
}

.content .orderDiscount .wvChooseProduct {
	position: inherit;
	bottom: 0;
}

.content .payBlock .wvChooseProduct {
	position: inherit;
	bottom: 0;
}

.content .wvSelectedProduct a.wvChooseProduct {
	margin: 10px 0;
}

.content .wvChooseProduct .arrowRight {
	display: inline-block;
	width: 8px;
	height: 19px;
	margin: 4px 0 0 10px;
	background-image: url("img/GCP_arrowWhiteRight.png");
	background-repeat: no-repeat;
	background-size: contain;
}

.content .wvOptions {
	margin: 0 auto;
	clear: both;
	display: block;
	width: 300px;
	position: relative;
}

.content .orderBox .orderInformation {
	text-align: center;
	margin: 20px 0;
	font-family: $mainmediumFont;
	font-size: 15px;
}

.content .shopBox .conditions span {
	float: none;
	margin: 2px 10px 0 0;
}

.content .shopBox label.styled [type="checkbox"]:checked+.circle {
	float: none;
	margin: 2px 10px 0 0;
	cursor: pointer;
}

.content .shopBox .orderOptions{
	display: inline-block;
	color: #fff;
}

.content .shopBox .orderOptions a {
	color: #fff;
	text-decoration: underline;
}

.content .orderBox .orderTable .tableBackground {
	position: absolute;
	left: 0;
	width: 60%;
	height: 100%;
}

.content .orderBox .orderTable table {
	position: relative;
	z-index: 10;
	width: 100%;
	display: block;
}

.content .orderBox .orderTable table th {
	width: 30%;
	font-family: $mainBoldFont;
	font-size: 15px;
	text-align: left;
	padding: 10px 5px 5px 15px;
}

.content .orderBox .orderTable table td {
	position: relative;
	vertical-align: middle;
	font-family: $mainLightFont;
	font-size: 15px;
	padding: 5px 5px 5px 10px;
}

.content .orderBox .orderTable table td .delete-icon {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 50%;
	background-image: url("img/webshop-cross-icon.png");
	width: 16px;
	height: 16px;
	background-size: contain;
	background-repeat: no-repeat;
	-moz-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.content .orderBox .orderTable table td .description {
	color: rgba(255, 255, 255, 0.5);
	font-size: 13px;
	line-height: 14px;
	font-family: $mainLightFont;
	margin-top: 5px;
}

.content .orderBox .orderTable table td .description p {
	margin: 0 0 5px;
}

.content .orderBox .orderTable table td .description ul {
	list-style-type: none;
}

.content .orderBox .orderTable table td .description ul li::before {
	content: "\2713";
	margin-right: 0.2em;
}

.content .orderBox .orderTable table td.indentOptionSmall {
	text-indent: 5px;
}

.content .orderBox .orderTable table td.inverseIndentOption {
	font-size: 13px;
	line-height: 20px;
	color: rgba(255, 255, 255, 0.5);
}

.content .orderBox .orderTable table td.inverseIndentOption.subTotal {
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.7);
}

.content .orderBox .orderTable table td.divider {
	padding: 0;
}

.content .orderBox .orderTable table td.divider div {
	display: block;
	height: 1px;
	background: #fff;
	margin: 5px 0;
}

.content .orderBox .orderTable table td .remExtra {
	cursor: pointer;
}

.content .orderBox .orderTable table td .wvProductImage {
	width: 66px;
	height: 85px;
	background-size: contain;
	background-repeat: no-repeat;
}

.content .orderBox .orderOptions {
	display: block;
	margin: 15px 0;
}

.content .orderBox .orderOptions label.styled {
	float: right;
	width: 55%;
	color: #00848d;
}

.content .orderBox .orderOptions label.styled a {
	text-decoration: underline;
	color: #00848d;
}

.content .orderBox .orderOptions label.styled [type="checkbox"]+span,
.content .orderBox .orderOptions label.styled [type="radio"]+span {
	float: left;
	margin-right: 10px;
}

.content .orderDiscount {
	margin: 0 auto;
	width: 280px;
}

.content .orderDiscount .inputRow {
	position: relative;
}

.content .orderDiscount .inputRow input[type='submit'],
.content .orderDiscount .inputRow .checkDiscount {
	position: absolute;
	background: $themeColor;
	color: $midColor;
	top: 0;
	right: 0;
	width: 70px;
	margin: 0;
	height: 58px;
	border-left: 1px solid $darkColor;
	word-break: normal;
	white-space: normal;
	font-size: 14px;
	text-align: center;
	font-family: $mainLightFont;
}

.content .payBlock {
	width: 280px;
	margin: 15px auto 0;
	position: relative;
}

.content .wvPayProduct {
	display: block;
	margin: 10px 0;
	text-align: center;
	font-size: 18px;
	font-family: $mainFont;
	border: 0;
}

.content .wvPayProduct .arrowRight {
	display: inline-block;
	width: 11px;
	height: 26px;
	margin-left: 10px;
	margin-top: 15px;
	background-image: url("img/GCP_arrowWhiteRight.png");
	background-repeat: no-repeat;
}

.content .paymentProviders {
	width: 280px;
	text-align: center;
	margin: 60px auto 0;
}

.content .inputRow.disabled {
	background-color: #dfdfdf;
}

.content .inputRow.extraInput .tagSmal {
	left: inherit;
	right: 10px;
}

.content .inputRow.extraInput input[type='text'] {
	width: 200px;
}

.content .inputRow.extraInput .inputSmall {
	width: 50px !important;
	border-left: 1px solid $darkColor;
	margin: 0;
	padding: 21px 5px 16px;
}

.content .payBlock .errorMessage:not([style*="display:none"]) .paymentProviders {
	opacity: 0.5;
}

.content .payedOke {
	width: 280px;
	margin: 0 auto;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
}

.content .payedOke img {
	margin-top: 30px;
	padding-bottom: 20px;
}

.content .payedOke a {
	color: $darkColor;
	text-decoration: underline;
}

.content .payedOke a.submitBtn {
	color: #fff;
	text-decoration: none;
}

.content .payedOke .shareBtn {
	display: block;
	margin: 0 0 10px;
}

.content .payedOke .socialBtn {
	width: 43px;
	height: 43px;
	display: inline-block;
	background-image: url('img/GCP_socialButtons.png');
	text-decoration: none;
	margin: 0 10px 0 0;
}

.content .payedOke .socialBtn.twitter {
	background-position: 0 0;
}

.content .payedOke .socialBtn.facebook {
	background-position: -43px 0;
}

.content .payedOke .socialBtn.linkedin {
	background-position: -87px 0;
}

.content .payedOke .socialBtn.email {
	background-position: 43px 0;
}

.fontLight {
	font-family: $mainLightFont !important;
}

.fontRegular {
	font-family: $mainFont !important;
}

.fontMedium {
	font-family: $mainmediumFont !important;
}

.fontBold {
	font-family: $mainBoldFont !important;
}

.noStyle {
	background: none !important;
}

.noStyle .inputRow {
	border: 0 !important;
}

.styled .tagline {
	display: inline-block;
	font-size: 15px;
	line-height: 15px;
	color: #fff;
	a {
		color: #fff !important;
	}
}

.content table.inputs tr.smallRow {
	height: 10px;
}

.content table.inputs tr td.hasRoom {
	padding-right: 20px;
}

.trSim {
	border: 1px solid $darkColor;
	background: #fff;
	width: 85%;
	display: inline-block;
}

.inputRow.error .trSim {
	border-color: #df213c;
	color: #df213c;
}

.trSim.disabled {
	background: #dfdfdf;
	pointer-events: none;
}

.rowTrigger {
	display: inline-block;
}

.content .rowTrigger .styled p.text {
	right: auto;
	text-align: left;
	font-family: $mainmediumFont;
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	padding: 28px 0 0 11px;
}

.trSim input[type="text"] {
	width: 218px !important;
}

.trSim .tagline {
	position: absolute;
	top: 0;
	left: 0;
	color: $midColor;
	font-size: 12px;
	margin: 3px 3px 3px 10px;
	font-family: $mainLightFont;
}

.rowTrigger .submitBtn.big {
	font-family: $mainLightFont;
	border: 1px solid $darkColor;
	width: 90%;
	margin: 0 0 0 10px;
	height: 25px;
	line-height: 22px;
	margin-bottom: 10px;
	background-color: #3cc0c1;
	font-weight: normal !important;
	font-size: 17px;
}

.rowTrigger .submitBtn.big.off {
	background-color: #919191;
	margin-bottom: 0;
}

.linkedInButton {
	background: url('img/linkedinButtonNew.png');
	width: 280px;
	height: 47px;
	background-size: cover;
	display: block;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.underline {
	text-decoration: underline !important;
	display: inline-block;
	color: $midColor !important;
}

.privacyRegister {
	display: inline-block;
	font-size: 15px;
	line-height: 15px;
	color: #fff;
}

.privacyRegister .underline {
	color: #fff !important;
}

.styledSelect.date {
	z-index: 9999;
	text-align: center;
	color: $darkColor;
	display: inline-block;
	width: 90px;
}

.styledSelect.date .options {
	left: 0;
	text-align: center;
	cursor: pointer;
	border: 1px solid $darkColor;
	max-height: 316px;
	overflow: auto;
	top: 0;
}

.styledSelect.date .options .option {
	text-align: center;
	cursor: pointer;
}

.styledSelect.register .options {
	z-index: 99999;
}

.styledSelect.date:not(:last-child) {
	border-right: 1px solid $darkColor;
	cursor: pointer;
}

.smallOptions {
	width: 102% !important;
}

.styledSelect.date .option {
	margin: 0;
}

.registerPage .styledSelect.date.day {
	text-align: right;
}

.registerPage .styledSelect.date.day .option.default {
	margin-right: 10px;
}

.registerPage .styledSelect.date.month {
	width: 35px;
}

.registerPage .styledSelect.date.year {
	text-align: left;
}

.registerPage .styledSelect.date.year .option.default {
	margin-left: 10px;
}

.required-fields {
	max-width: 170px;
	margin: 10px auto 0;
	text-align: right;
	color: #fff;
}

@media (max-width: 420px) {
	.content {
		padding: 0;
	}
	.content.smaller {
		padding: 0 20px;
	}
	.gcp .submenuPages {
		overflow: hidden;
	}
	.community .content,
	.news .content,
	.tools .content,
	.training .content {
		text-align: center;
	}
	.trainingBlocks {
		margin: 32px 20px 40px;
	}
	.header-gcp .interactions {
		right: 11px;
		top: 0;
	}
	.gcp .submenuPages .item {
		font-size: 14px;
	}
	.community .content .title span,
	.news .content .title span,
	.tools .content .title span,
	.training .content .title span {
		font-size: 37px;
	}
	.community .content .intro .introTitle,
	.news .content .intro .introTitle,
	.tools .content .intro .introTitle,
	.training .content .intro .introTitle {
		font-size: 18px;
	}
	.trainingBlocks .block .trainingStatus {
		left: 12px;
		top: 12px;
		margin-lefT: 0;
	}
	.trainingBlocks .block {
		width: 267px;
	}
	.trainingBlocks .block .blockContent {
		width: 183px;
		display: inline-block;
		height: 82px;
	}
	.trainingBlocks .block .imgWrap {
		width: 81px;
		height: 82px;
		border-right: 1px solid $darkColor;
		display: inline-block;
		margin-right: -2px;
		position: relative;
	}
	.selectRole {
		text-align: center;
		width: 100%;
	}
	.quickscanBlocks table {
		border-collapse: separate;
	}
	.blue-button.cta-button {
		margin-top: 10px;
	}
	.community .content .intro .introTitle,
	.training .content .intro .introTitle {
		padding: 0 10px;
	}
}

@media (min-width: 768px) {
	.profile .profileSection {
		max-width: 100%;
	}
	.content.profile .profileBlockTitle {
		max-width: 100%;
	}
	.fullscreen.overlay .overlayContent {
		width: 435px;
	}
	.fullscreen.overlay .itemTitle {
		font-size: 38px;
	}
	.fullscreen.overlay .itemContent {
		font-size: 20px;
		line-height: 30px;
	}
	.slideContent .item {
		width: 90px;
	}
	.slideContent .item .itemLogo {
		left: 21px;
	}
	.slideContent .item .information {
		left: 34px;
	}
	.selectButton .select {
		position: absolute;
	}
	.selectButton .select.left {
		left: 132px;
		top: -120px;
		background-image: url('img/GCP_arrowLeftLarge.png');
		width: 18px;
		height: 42px;
	}
	.selectButton .select.right {
		right: 132px;
		top: -120px;
		width: 18px;
		height: 42px;
	}
	.quickscanContent .column {
		width: 49%;
		display: inline-block;
	}
	.quickscanContent .column label.styled {
		font-size: 16px;
		height: 65px;
	}
	.quickscanContent .column label.styled [type="checkbox"]+span,
	.quickscanContent .column label.styled [type="radio"]+span {
		margin: 10px 0;
	}
	.quickscanContent .column label.styled .textLine {
		width: 175px;
	}
	.quickscanContent .columnLarge {
		width: 300px;
	}
	.quickscanContent .columnSmall {
		width: 160px;
	}
	.quickscanContent .columnLarge label.styled [type="checkbox"]+span,
	.quickscanContent .columnLarge label.styled [type="radio"]+span,
	.quickscanContent .columnSmall label.styled [type="checkbox"]+span,
	.quickscanContent .columnSmall label.styled [type="radio"]+span {
		width: 105px;
		height: 105px;
		background-size: 105px 105px;
		padding: 25px 15px;
		font-size: 21px;
	}
	.quickscanContent .columnLarge label.single [type="checkbox"]+span,
	.quickscanContent .columnLarge label.single [type="radio"]+span,
	.quickscanContent .columnSmall label.single [type="checkbox"]+span,
	.quickscanContent .columnSmall label.single [type="radio"]+span {
		padding: 38px 15px 25px;
	}
	.quickscanContent .columnLarge label.styled:checked+span,
	.quickscanContent .columnSmall label.styled:checked+span {
		background-size: 105px 105px;
	}
	.stap10 .quickscanContent .subtitle,
	.stap10 .quickscanContent .title {
		text-align: center;
	}
	.quickscanBlocks {
		width: 740px;
	}
	.quickscanBlocks table.inputs.lessons.followup tr {
		height: 221px;
	}
	.quickscanBlocks table {
		border-collapse: separate;
	}
	.quickscanBlocks table.inputs.lessons.followup tr td {
		height: 221px;
		width: 221px;
		border-collapse: separate;
	}
	.quickscanBlocks table.inputs.lessons.followup tr td .image,
	.quickscanBlocks table.inputs.lessons.followup tr td.fullWidth .status {
		width: 221px;
		height: 123px;
		background-size: 120%;
		left: auto;
		right: 0;
	}
	.quickscanBlocks table.inputs.lessons.followup tr td .info {
		top: auto;
		bottom: 0;
		padding: 0;
		left: 0;
		right: 0;
		width: 221px;
		height: 97px;
		display: table;
		position: inherit;
	}
	.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .vrijstelling {
		display: block;
	}
}

.formOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: none;
	background: rgba(75, 75, 75, 0.15);
}

.information {
	cursor: pointer;
}

.informationMessage {
	top: 100%;
	position: absolute;
	padding: 10px 20px;
	color: $midColor;
	background: $themeColor;
	border: 1px solid $themeColor;
	border-top: 0;
	left: 0;
	margin-top: 10px;
	font-family: $mainLightFont;
	text-align: center;
	font-size: 16px;
	z-index: 1500;
	margin-left: -1px;
	width: calc(100% + 2px);
	display: none;
	box-shadow: 0 0 43px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 43px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0 0 43px rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0 0 43px rgba(0, 0, 0, 0.15);
}

.informationMessage a {
	color: $midColor;
}

.informationMessage:after {
	border-style: solid;
	border-color: $themeColor transparent;
	display: block;
	width: 0;
	top: -17px;
	bottom: auto;
	left: auto;
	right: 130px;
	border-width: 0 10px 20px;
	content: "";
	position: absolute;
}

.content .submitBtn {
	max-width: 280px;
}

.content .submitBtn.edit {
	max-width: 295px;
	width: 295px;
}

.magazineCover .contentText ul {
	margin-left: 14px;
}

body:not(.quickscan) .loader-circle .timer>.slice {
	box-sizing: content-box;
	position: absolute;
	width: 114px;
	height: 114px;
}

.quickscanContent .hasimage img {
	max-width: 100px;
}

.quickscan .quickscanContent .loader-circle {
	margin-bottom: 25px;
	margin-top: 20px;
}

.orButton {
	text-align: center;
	font-size: 16px;
	margin: 15px 0;
}

.content.login .intro .introTitle {
	font-size: 16px;
	color: $darkColor;
}

.IE.quickscan .quickscanContent .loader-circle .number span,
.MSIE.quickscan .quickscanContent .loader-circle .number span {
	margin-left: 125px;
}

.buttonGroup .editTraining .addIcon {
	background-image: url('img/plusIcon.png');
	width: 17px;
	height: 17px;
	display: inline-block;
	background-size: cover;
	margin: 4px;
}

.underConstructionProfile {
	height: auto;
	margin-top: 10px;
	display: none;
}

.profile .profileSection.right.underConstruction .profileBlock {
	display: none;
}

.underConstructionProfile img {
	width: 100%;
}

.profile .profileSection.right.underConstruction .profileBlockTitle {
	margin-top: 50px;
}

@media (max-width: 768px) {
	.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .vrijstelling {
		display: block;
		font-size: 0;
	}
	.quickscanBlocks table.inputs.lessons.followup tr td .image .imgOverlay .skipTitle {
		font-size: 15px;
		letter-spacing: 1px;
		margin-left: -16px;
	}
}

.contentText ul,
.contentText ol {
	font-size: 15px;
	margin-left: 18px;
	margin-bottom: 2em;
}

@media (min-width: 768px) {
	.profile .profileSection.right.underConstruction .profileBlockTitle {
		margin-top: 0;
	}
	.magazineCover .contentText ul,
	.magazineCover .contentText ol {
		margin-left: 16px;
	}
	body:not(.quickscan) .loader-circle .timer>.slice {
		position: absolute;
		width: 158px;
		height: 158px;
		clip: rect(0, 160px, 160px, 79px);
	}
	body:not(.quickscan) .loader-circle .timer>.slice.gt50 {
		clip: rect(0, 160px, 160px, 0);
	}
	body:not(.quickscan) .loader-circle .timer>.slice>.pie {
		border-width: 5px;
		position: absolute;
		width: 148px;
		height: 148px;
		clip: rect(0, 79px, 158px, 0);
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}
	.content.login .intro .introTitle {
		font-size: 20px;
	}
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info .text,
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info .text h3 {
		margin-top: 12px;
	}
}

.editProfilePage {
	max-width: 325px;
}

.optionalRibbon span {
	-moz-transform: rotate(323deg);
	-webkit-transform: rotate(323deg);
	-o-transform: rotate(323deg);
	-ms-transform: rotate(323deg);
	transform: rotate(323deg);
	font-weight: bold;
	color: #fff !important;
	font-size: 14px !important;
	width: 95px;
	text-align: center;
	margin: 21px -11px !important;
	display: block;
}

.optionalRibbon {
	background: url('img/overlaydef_blank.png');
	background-size: cover;
	width: 95px;
	height: 72px;
	position: absolute;
	z-index: 5;
	top: -7px;
	left: -7px;
	pointer-events: none;
}

.optionalRibbon.hasNew {
	background-image: url("img/overlaydef_red.png");
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-resolution: 1.5dppx) {
	.close {
		background-image: url("img/GCP_close_overlay.png");
		background-size: 28px 28px;
	}
	.content .wvProductBox .wvProduct .wvProductInfo .wvProductImage.lightProduct {
		background-image: url("img/productLight-2x.png");
		background-size: 102px 109px;
		background-repeat: no-repeat;
	}
	.content .wvProductBox .wvProduct .wvProductInfo .wvProductImage.onlineProduct {
		background-image: url("img/productOnline-2x.png");
		background-size: 102px 109px;
		background-repeat: no-repeat;
	}
	.content .wvProductBox .wvProduct .wvProductInfo .wvProductImage.blendedProduct {
		background-image: url("img/productBlended-2x.png");
		background-size: 102px 109px;
		background-repeat: no-repeat;
	}
	.content .wvProductBox .wvProduct .wvProductTitle .wvProductBack {
		background-image: url("img/GCP_arrowLeftLarge.png");
		background-size: 9px 21px;
		background-repeat: no-repeat;
	}
	.content .wvProductBox .wvProduct .wvProductTitle .wvProductToggle {
		background-image: url("img/GCP_arrowUpLarge.png");
		background-size: 26px 11px;
		background-repeat: no-repeat;
	}
	.content .wvProductBox .wvProduct .wvProductTitle .wvProductToggle.open {
		background-image: url("img/GCP_arrowDownLarge.png");
		background-size: 26px 11px;
		background-repeat: no-repeat;
	}
}

body.faq {
	background: white;
}

.faq .submenu {
	margin-top: 10px;
}

.faq .submenu .item {
	color: $darkColor;
}

.faq .submenu .item.active {
	border-bottom: 2px solid $darkColor;
}

.faqcontent {
	max-width: 100%;
	padding: 0 20px;
	margin: 100px auto 20px;
	display: block;
}

.faqcontent .title span {
	color: #00848d;
	font-size: 48px !important;
}

.faqcontent .faqItem {
	display: block;
	border-bottom: 1px solid #3cc0c1;
	margin: 0 auto 20px;
	padding-left: 20px;
	max-width: 525px;
	background: url("img/arrow-left.png") left 5px no-repeat;
	background-size: 10px;
}

.faqcontent .faqItem.open {
	background-image: url("img/arrow-down-faq.png");
	background-size: 15px;
}

.faqcontent .faqItem:last-child {
	border-bottom: 0;
}

.faqcontent .faqItem .text {
	font-family: $mainFont;
	font-size: 16px;
	margin-bottom: 10px;
}

.faqcontent .faqItem .answer {
	margin-top: 15px;
}

.faqcontent .faqItem .answer p {
	font-size: 14px;
	color: #666666;
}

.faqcontent .btn {
	display: inline-block;
	font-size: 10px;
	font-family: "HarmoniaSansStd-SemiBd",sans-serif;
	padding: 3px 10px;
	color: #ffffff;
	background: $darkColor;
	margin-bottom: 10px;
	margin-top: 10px;
	border: 0;
	cursor: pointer;
}

.faqcontent .btn.btn-large {
	font-size: 12px;
	padding: 5px 10px;
}

.faqcontent .btn.btn-larger {
	font-size: 12px;
	padding: 7px 12px;
}

@media (max-width: 420px) {
	.faqcontent {
		margin: 20px auto;
	}
	.faqcontent .title span {
		font-size: 24px !important;
	}
}

.accordionContent {
	display: none;
	overflow: hidden;
}

.accordionTrigger {
	cursor: pointer;
}

.browserCheck {
	text-align: center;
	font-size: 15px;
	color: #fff;
	font-family: $mainLightFont;
	width: 600px;
	margin: 0 auto;
}

.browserCheck .title {
	color: #00959f;
	font-size: 48px;
	border-bottom: 1px solid #00959f;
	font-family: $mainFont;
	height: auto !important;
	line-height: 45px !important;
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.browserCheck .text {
	clear: both;
}

.browserCheck .resultText {
	display: none;
}

.browserCheck .checkCol {
	margin: 30px 0;
	float: left;
}

.browserCheck .checkCol.yourSystem {
	float: right;
}

.browserCheck .colTitle {
	color: #00959f;
	margin-bottom: 15px;
	font-family: 'open_sanslight', sans-serif;
	font-weight: bold;
}

.browserCheck .checkCol .block {
	color: #424242;
	background: #fff;
	border: 5px solid #fff;
	border-width: 0 5px;
}

.browserCheck .checkCol .text {
	height: 105px;
	width: 200px;
	display: table-cell;
	vertical-align: middle;
	padding: 25px;
	border-bottom: 1px solid #b2b2b2;
}

.browserCheck .checkCol .block:last-of-type .text {
	border-bottom: 0;
}

.browserCheck .checkCol.yourSystem .text {
	padding-right: 70px;
}

.browserCheck .checkCol.yourSystem .block {
	background: url("img/failed.png") 135px center no-repeat #fff;
	background-size: 30px;
}

.browserCheck .checkCol.yourSystem .block.passed {
	background-image: url("img/passed.png");
	background-size: 37px;
}

.browserCheck .passed .resultText.passText {
	display: block;
}

.browserCheck .failed .resultText.failText {
	display: block;
}

.ecBackgroundFade {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.4);
}

.searchLoader {
	width: 100%;
	height: 200px;
	background: url('img/loading.gif') center center no-repeat;
}

.padding-small {
	padding: 3px;
}

.padding-medium {
	padding: 5px;
}

.padding-8 {
	padding: 8px;
}

.padding-large {
	padding: 10px;
}

.padding-extralarge {
	padding: 15px;
}

.padding-extend {
	padding: 20px;
}

.padding-small-top {
	padding-top: 5px;
}

.padding-8-top {
	padding-top: 8px;
}

.padding-medium-top {
	padding-top: 10px;
}

.padding-large-top {
	padding-top: 15px;
}

.padding-extralarge-top {
	padding-top: 25px;
}

.padding-extend-top {
	padding-top: 35px;
}

.padding-extended-top {
	padding-top: 50px;
}

.padding-small-bottom {
	padding-bottom: 5px;
}

.padding-8-bottom {
	padding-bottom: 8px;
}

.padding-medium-bottom {
	padding-bottom: 10px;
}

.padding-large-bottom {
	padding-bottom: 15px;
}

.padding-extralarge-bottom {
	padding-bottom: 25px;
}

.padding-extend-bottom {
	padding-bottom: 35px;
}

.padding-small-right {
	padding-right: 5px;
}

.padding-8-right {
	padding-right: 8px;
}

.padding-medium-right {
	padding-right: 10px;
}

.padding-large-right {
	padding-right: 15px;
}

.padding-extralarge-right {
	padding-right: 25px;
}

.padding-extend-right {
	padding-right: 35px;
}

.padding-small-left {
	padding-left: 5px;
}

.padding-8-left {
	padding-left: 8px;
}

.padding-medium-left {
	padding-left: 10px;
}

.padding-large-left {
	padding-left: 15px;
}

.padding-extralarge-left {
	padding-left: 25px;
}

.padding-extend-left {
	padding-left: 35px;
}

.margin-small-top {
	margin-top: 5px;
}

.margin-medium-top {
	margin-top: 10px;
}

.margin-large-top {
	margin-top: 15px;
}

.margin-larger-top {
	margin-top: 20px;
}

.margin-extralarge-top {
	margin-top: 25px;
}

.margin-extend-top {
	margin-top: 35px;
}

.margin-extended-top {
	margin-top: 50px;
}

.margin-small-bottom {
	margin-bottom: 5px;
}

.margin-medium-bottom {
	margin-bottom: 10px;
}

.margin-large-bottom {
	margin-bottom: 15px;
}

.margin-larger-bottom {
	margin-bottom: 20px;
}

.margin-extralarge-bottom {
	margin-bottom: 25px;
}

.margin-extend-bottom {
	margin-bottom: 35px;
}

.margin-extended-bottom {
	margin-bottom: 50px;
}

.margin-small-right {
	margin-right: 5px;
}

.margin-medium-right {
	margin-right: 10px;
}

.margin-large-right {
	margin-right: 15px;
}

.margin-larger-right {
	margin-right: 20px;
}

.margin-extralarge-right {
	margin-right: 25px;
}

.margin-extend-right {
	margin-right: 35px;
}

.margin-extended-right {
	margin-right: 50px;
}

.margin-small-left {
	margin-left: 5px;
}

.margin-medium-left {
	margin-left: 10px;
}

.margin-large-left {
	margin-left: 15px;
}

.margin-larger-left {
	margin-left: 20px;
}

.margin-extralarge-left {
	margin-left: 25px;
}

.margin-extend-left {
	margin-left: 35px;
}

.margin-extended-left {
	margin-left: 50px;
}

.margin-exaggerate-bottom {
	margin-bottom: 125px;
}

.force-no-margin {
	margin: 0 !important;
}

.margin-auto {
	margin: 0 auto;
}

.text-white {
	color: #ffffff !important;
}

.text-black {
	color: #000000 !important;
}

.text-dark {
	color: $midColor !important;
}

.texteditor {
	display: block;
	width: 100%;
	height: 150px;
	border: 1px solid #cccccc;
	border-top: 24px solid #cccccc;
}

.font-argumentum {
	font-family: $mainFont;
}

.font-argumentum-bold {
	font-family: $mainBoldFont;
}

.font-argumentum-medium {
	font-family: $mainmediumFont;
}

.font-argumentum-light {
	font-family: $mainLightFont;
}

.font-argumentum-ultralight {
	font-family: $mainExtraLightFont;
}

.font-lora {
	font-family: "loraregular",sans-serif;
}

.font-lora-bold {
	font-family: "lorabold",sans-serif;
}

.font-lora-italic {
	font-family: "loraitalic",sans-serif;
}

.font-lora-bold-italic {
	font-family: "lorabold_italic",sans-serif;
}

.response-title {
	font-size: 20px;
}

.button.has-icon {
	border: 1px solid #ff0000;
	background-color: #ff8888;
	color: #fff;
	outline: none;
	display: inline-block;
	cursor: pointer;
	font-size: 85%;
	padding: 8px 8px 3px;
}

.button.has-icon.edit {
	border: 1px solid #9C9711;
	background-color: #F2EF8A;
	color: #000;
}

.button.delete,
.button.edit {
	float: right;
	margin: 0 10px;
}

.button.has-icon.edit:hover {
	color: #000;
}

.button.has-icon:hover {
	color: #ff0000;
}

.button.has-icon .icon {
	top: -3px;
}

.blue-button {
	background-color: $themeColor;
	color: $buttonTextColor;
	display: inline-block;
	border: 0;
	padding: 5px 21px;
	cursor: pointer;
	font-size: 16px;
}

.rateButton {
	background-color: transparent;
	outline: none;
	border: 0;
	display: inline-block;
}

.rating {
	display: block;
	border-top: 1px solid $themeColor;
	border-bottom: 1px solid $themeColor;
	text-align: center;
	position: relative;
}

.rating .icon {
	margin: 0 5px;
	&:before {
		content: "";
	}
}

.rating .info {
	color: $themeColor;
	font-size: 14px;
}

.rating .rating-amount {
	position: absolute;
	right: 30px;
	top: 20px;
	color: $themeColor;
	font-size: 20px;
}

.icon {
	position: relative;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
}

.icon.icon-delete {
	background-image: url('img/icons/cross-octagon.png');
}

.icon.icon-add-comment {
	background-image: url('img/icons/icon-add-comment.png');
}

.icon.icon-star {
	background-image: url('img/icons/icon-star.png');
	background-size: contain;
}

.icon.icon-star-empty {
	background-image: url('img/icons/icon-star-empty.png');
	background-size: contain;
}

.bg-white {
	background-color: #fff;
}

.bg-gray {
	background-color: $midColor;
}

.page-block {
	display: block;
	width: auto;
	margin: auto;
}

.registerPage .date {
	font-size: 14px;
	line-height: 20px;
}

.main-title {
	font-size: 20px;
}

.main-intro {
	font-size: 18px;
}

.comment-block {
	display: block;
	width: 100%;
	border-top: 2px solid #cccccc;
	padding-top: 15px;
	padding-bottom: 10px;
	position: relative;
}

.comment-block .button.delete {
	position: absolute;
	top: 10px;
	right: 10px;
}

.comment-user .comment-user-avatar {
	border-radius: 50%;
	width: 89px;
	height: 89px;
	display: inline-block;
	background-size: cover;
	background-position: center center;
}

.comment-user .comment-user-text {
	margin-left: 15px;
	display: inline-block;
	font-size: 16px;
	color: $darkColor;
}

.comment-user {
	margin: 15px 0;
	position: relative;
}

.article-comments.floating {
	position: absolute;
	top: -40px;
	right: 0;
}

.comment-block .comment-body {
	font-weight: normal !important;
	font-family: $mainFont !important;
	font-size: 14px !important;
	color: #000 !important;
}

.blocks-list {
	width: 100%;
	max-width: 432px;
	margin: 0 auto;
	position: relative;
}

.blocks-list.main-list {
	text-align: center;
	padding: 0 20px;
}

.blocks-list-item {
	padding: 0 20px;
	display: block;
}

.blocks-list-item:not(.no-hover):hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.blocks-list-item:first-child .blocks-list-item-inner {
	border-top: 1px solid #a7a7a7;
}

.blocks-list-item-inner {
	padding: 10px 8px;
	border-bottom: 1px solid #a7a7a7;
	color: #fff;
}

.blocks-list-item-image {
	display: none;
	background-size: cover;
	background-position: center center;
}

.blocks-list-item-content {
	display: inline-block;
	font-size: 16px;
	line-height: 22px;
	width: 100%;
}

.blocks-list-item-texts {
	height: 70px;
	overflow: hidden;
	position: relative;
}

.blocks-list-item-texts .intro-block {
	display: none;
}

.blocks-list-item-texts .intro-block.show {
	display: block;
}

.blocks-list-item .item-title {
	text-overflow: ellipsis;
	overflow-x: hidden;
	max-width: 280px;
	word-wrap: nowrap;
	height: 22px;
	display: block;
	white-space: nowrap;
}

.blocks-list-item-meta {
	font-size: 14px;
	margin-top: 5px;
}

.blocks-list-item-wrap .community-user-title {
	font-family: $mainBoldFont;
	font-size: 14px;
	color: #fff;
}

.blocks-list-item-wrap .community-user-subtitle {
	font-size: 14px;
	color: #fff;
	font-family: $mainLightFont;
}

.meta-left {
	float: left;
}

.meta-right {
	float: right;
}

.meta-item {
	display: inline-block;
	margin: 0 5px;
}

.font-blue {
	color: $themeColor;
}

.icon.icon-comment {
	background-image: url('img/icon-like.png');
}

.icon.icon-likes {
	background-size: contain;
	background-image: url('img/icon-like-blue.png');
	vertical-align: text-top;
}

.icon.icon-likes-white {
	width: 13px;
	background-image: url('img/icon-like-white.png');
	vertical-align: text-top;
}

.icon.icon-video-play {
	width: 32px;
	height: 32px;
	background-image: url('img/icons/icon-video-play.png');
	background-size: cover;
}

.icon.icon-file-pdf {
	width: 30px;
	height: 33px;
	background-image: url('img/icons/icon-file-pdf.png');
	background-size: cover;
}
.icon.icon-file-pdf::before {
	content: "";
}

.align-left {
	text-align: left !important;
}

.align-center {
	text-align: center !important;
}

.article-date,
.article-time {
	font-size: 14px;
	color: #fff;
	text-align: left;
	display: inline-block;
}

.article-time {
	margin-left: 20px;
}

.wvBaseContainer .article.intro .introTitle {
	margin-top: 25px;
	font-size: 24px;
	line-height: 30px;
	color: $themeColor;
	font-weight: bold;
	font-family: "loraregular",sans-serif;
	word-wrap: break-word;
	&.text-white-tools {
		color: #fff;
	}
}

.wvBaseContainer .article.intro .introText {
	color: #fff;
	font-weight: bold;
	font-family: "loraregular",sans-serif;
}

.wvBaseContainer .new-article.intro .introTitle {
	color: #fff;
	font-size: 22px;
	font-family: $mainFont;
}

.wvBaseContainer .new-article.intro .introText {
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	text-align: left;
}

.wvBaseContainer .article-comments {
	color: #fff;
	font-size: 23px;
	line-height: 18px;
	text-align: right;
	margin: 10px 0;
}

.wvBaseContainer .article-comments .icon {
	width: 34px;
	height: 34px;
	vertical-align: text-bottom;
}

.wvBaseContainer .article-comments .article-source {
	float: left;
	font-size: 16px;
}

.wvBaseContainer .article .image-subtitle b,
.wvBaseContainer .article .image-subtitle strong {
	font-family: "lorabold_italic",sans-serif;
	vertical-align: bottom;
}

.wvBaseContainer .article .image-subtitle {
	font-size: 16px;
	color: #fff;
}

.wvBaseContainer .article .bodyText {
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	margin: 20px 0;
}

.article p {
	padding: 10px 0;
}

.article .date span {
	margin-right: 10px;
}

.article .video-time p {
	padding: 0;
}

.article .readConfirm {
	margin-bottom: 20px;
}

.article .readConfirm p {
	text-align: left;
	position: absolute;
	right: auto;
	left: 30px;
	line-height: 25px;
	padding: 0;
	color: #fff;
	font-size: 18px;
}

.article .readConfirm span {
	float: none !important;
}

.no-click {
	pointer-events: none;
}

.new-article .inputRow .tagline {
	font-size: 12px;
	color: #00848d;
	display: block;
}

textarea.invisible {
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	border: 0;
	text-transform: none;
	text-decoration: none;
}

textarea.medium {
	height: 65px;
}

textarea.large {
	height: 130px;
}

.blue-button.cta-button .icon {
	margin-right: 5px;
	vertical-align: baseline;
}

.blue-button.cta-button {
	padding: 15px;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px;
	min-width: 200px;
}

.cta-icon-button .icon {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 15px;
	right: 15px;
}

.cta-icon-button {
	width: 100%;
	background: $themeColor;
	position: relative;
	padding: 0 80px 0 10px;
	font-size: 17px;
	line-height: 60px;
	display: inline-block;
	color: $midColor;
}

.button-switch {
	width: 282px;
	font-size: 0;
	height: 55px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
}

.button-switch-item {
	text-transform: none;
	text-align: center;
	padding: 11px 0;
	font-size: 16px;
	color: $buttonTextColor !important;
	width: 49%;
	display: inline-block;
	height: 100%;
	background: $themeColor;
	position: relative;
}

.button-switch-item:not(.active):before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.3);
	z-index: 1;
}

.button-switch-item .icon {
	display: inline-block;
	vertical-align: middle;
}

.tools .article .introTitle {
	font-family: $mainFont !important;
}

.tools .article .introText {
	font-family: $mainExtraLightFont !important;
}

.tools .article .videoContainer {
	height: 155px;
}

.imageWrap {
	position: relative;
}

.download-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(40, 40, 40, 0.8);
	text-align: center;
}

.download-overlay-inner {
	width: 180px;
	height: 110px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
}

.download-overlay .download-text {
	font-size: 32px;
	color: #fff;
	font-family: $mainLightFont_italic;
}

.download-overlay .download-icon {
	width: 67px;
	height: 67px;
	background-image: url('img/icons/download-big.png');
	background-size: cover;
	display: block;
	margin: 0 auto;
}

.rounded {
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
}

body.mainPortal {
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

.mainPortal {
	padding: 0;
	background: #ffffff;
}

.action .mainPortal {
	background: transparent;
}

.mainPortal .center-text {
	text-align: center;
}

.mainPortal .container {
	position: relative;
}

.mainPortal .headImage {
	position: relative;
	height: 650px;
	width: 100%;
	display: block;
	background-size: cover;
	background-position: 40% top;
	background-repeat: no-repeat;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	margin-top: env(safe-area-inset-top);
}

.mainPortal .userAction {
	position: absolute;
	display: block;
	bottom: 0;
	width: 100%;
	color: #fff;
	text-align: center;
	z-index: 10;
}

.mainPortal .userAction .topAction {
	padding: 30px 0;
}

.mainPortal .userAction .topAction .logo {
	background-image: url('img/h-logo.png');
	background-size: cover;
	width: 60px;
	height: 70px;
	margin: 25px auto;
	display: block;
}

.mainPortal .tagline b,
.mainPortal .tagline strong {
	font-family: $mainmediumFont;
}

.mobileOnly {
	display: block;
}

.gtMobile {
	display: none;
}

.mainPortal .userAction .topAction .tagline {
	font-size: 20px;
	line-height: 25px;
	letter-spacing: 0;
	font-family: $mainLightFont;
}

.mainPortal .userAction .bodyAction {
	background: #d2d2d2;
	padding: 30px 20px;
	font-family: $mainLightFont;
}

.mainPortal .userAction .bodyAction label p {
	font-size: 13px;
	color: $darkColor;
	line-height: 14px;
}

.mainPortal .userAction .bodyAction p {
	color: $darkColor;
	font-size: 22px;
	line-height: 26px;
	margin-bottom: 25px;
}

.loginForm .btn,
.mainPortal .userAction .bodyAction .btn {
	display: block;
	cursor: pointer;
	height: 55px;
	background-color: $themeColor;
	color: $buttonTextColor;
	text-align: center;
	line-height: 55px;
	font-size: 18px;
	font-family: $mainFont;
	margin: 10px 0;
}

.loginForm .btn {
	max-width: 280px;
	margin: 20px auto 0;
}

.mainPortal .userAction .bodyAction .btn:hover {
	color: $buttonTextColor;
	text-decoration: none;
}

.mainPortal .container b,
.mainPortal .container strong {
	font-family: $mainBoldFont;
	font-size: 18px;
	line-height: 24px;
}

.mainPortal .container {
	width: 100%;
	display: block;
	padding: 0 18px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 22px;
	font-family: $mainLightFont;
	background-size: cover;
	background-repeat: no-repeat;
}

.mainPortal .container h1 {
	text-transform: lowercase;
	font-size: 40px;
	line-height: 40px;
	letter-spacing: 0;
	color: #a08250;
	text-align: center;
	margin-bottom: 80px;
}

.mainPortal .container img {
	max-width: 100%;
}

.mainPortal .container .firstImg {
	margin: 30px 0;
}

.mainPortal .container .secondImg {
	margin-top: 50px;
}

.mainPortal .container p {
	margin: 20px 0;
}

.mainPortal .container .infoText {
	width: 100%;
	margin: 40px auto 0;
	text-align: center;
	color: #1e1e1e;
	font-size: 15px;
	line-height: 20px;
	padding: 0 5px;
}

.blue-button-small {
	padding: 10px 38px;
	background-color: #62c6cb;
	display: inline-block;
	color: #fff !important;
	font-size: 18px;
	font-family: $mainFont;
	text-decoration: none;
	letter-spacing: 1px;
}

.globalFooter .footer {
	background: #ffffff;
	margin-bottom: 0 !important;
}

.mainPortal,
.globalFooter {
	.footer {
		display: block;
		width: 100%;
		padding: 18px;
		text-align: center;
		color: #a08250;
		margin-bottom: 10px;
		.container {
			padding: 0;
		}
		.footer-links {
			display: inline-block;
			a {
				line-height: 45px;
				margin: 0 5px;
				text-decoration: underline;
				font-size: 14px;
				text-transform: uppercase;
				color: $darkColor;
			}
		}
		.footer-logo-wrap {
			display: inline-block;
			text-align: center;
			margin-left: 5px;
			.footer-logo {
				width: 51px;
				height: 34px;
				background-image: url('img/gcp-footer-logo.png');
				background-size: cover;
				display: inline-block;
			}
		}
	}
}

.mainPortal .userAction .topAction .logo.mygcp-logo {
	width: 174px;
	height: 105px;
	background: url('img/mygcp-logo.png') center transparent;
	background-size: contain;
	background-repeat: no-repeat;
}

.mainPortal .container .homepage-header {
	font-family: $mainmediumFont;
	font-size: 22px;
	line-height: 29px;
	color: #1e1e1e;
}

.noPadding {
	padding: 0 !important;
}

.mainPortal .container .logos {
	width: 100%;
	text-align: center;
	margin-top: 40px;
}

.mainPortal .container .logos .logo {
	height: 55px;
	margin: 10px;
	display: inline-block;
	clear: both;
}

.mainPortal .container .logos .logo img {
	max-height: 100%;
	max-width: 100%;
}

.noSidePadding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.mainPortal .action {
	margin: 0;
	max-width: none;
}

.mainPortal .action .headImage {
	height: auto;
	z-index: 1;
}

.mainPortal .action .userAction {
	position: relative;
}

.mainPortal .action .content.login {
	overflow: visible;
}

.mainPortal .action .userAction .bodyAction {
	color: $darkColor;
	font-size: 13px;
	line-height: 17px;
}

.loginForm .submitBtn {
	background: #5ac6cc;
	border: 0;
}

.mainPortal .action .submitBtn {
	background: #5ac6cc;
	border: 0;
	margin-top: 40px;
}

.mainPortal .action .submitBtn.login,
.mainPortal .action .submitBtn.register {
	margin-top: 0;
	width: 280px;
}

.mainPortal .action .bodyObject {
	padding: 15px;
	color: #fff;
}

.mainPortal .checklist .item {
	position: relative;
	padding: 0 40px 0 55px;
	margin-top: 20px;
	display: block;
}

.mainPortal .checklist .item .check {
	height: 25px;
	width: 31px;
	background-image: url('img/checklist-check.png');
	background-size: cover;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.mainPortal.register .action h1 {
	margin-bottom: 5px;
}

.mainPortal .action .inputRow.halfsize input {
	width: 110px;
}

.mainPortal .action .content table.inputs {
	border-spacing: 0 15px;
	text-align: left;
}

.metalink {
	text-align: left;
	font-family: $mainmediumFont;
	font-size: 16px;
	margin-top: 10px;
}

.metalink a {
	text-decoration: underline;
	font-family: $mainLightFont;
	color: #fff;
}

.bodyAction .metalink a {
	color: #313131;
}

.login .bodyAction .metalink a {
	color: #fff;
}

.whiteIntro h1,
.whiteTitle,
.whiteTitle b {
	font-size: 36px !important;
	color: #fff !important;
	font-family: $mainmediumFont !important;
}

.whiteIntro h1 {
	line-height: 38px !important;
}

.content .intro.smaller {
	max-width: 260px;
}

.whiteIntro {
	font-size: 16px !important;
	color: #fff !important;
	margin-bottom: 0 !important;
}

.inputRow.disabled,
.inputs tr.disabled {
	opacity: 0.75;
	pointer-events: none;
}

.vertical-center {
	vertical-align: middle;
}

.load-more {
	display: inline-block;
	position: relative;
	z-index: 10;
}

.load-more-bar {
	cursor: pointer;
	width: 100%;
	height: 55px;
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 18px;
	font-family: $mainmediumFont;
	text-align: center;
	z-index: 10;
	color: #000;
	padding: 18px 0;
}

.load-more-shadow {
	width: 100%;
	height: 1px;
	bottom: -1px;
	z-index: 9;
	position: absolute;
	-webkit-box-shadow: 0 0 65px 25px rgba(255, 255, 255, 0.75);
	-moz-box-shadow: 0 0 65px 25px rgba(255, 255, 255, 0.75);
	box-shadow: 0 0 65px 25px rgba(255, 255, 255, 0.75);
}

.load-more-icon {
	background-image: url('img/icons/icon-load-more.png');
	background-size: cover;
	width: 29px;
	height: 13px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 15px;
	position: relative;
	z-index: 10;
}

.load-more-spacing {
	height: 80px;
	display: block;
}

.ajax-search input {
	height: 100%;
	width: calc(100% - 60px);
	border: 0;
	padding: 20px;
	font-family: $mainLightFont;
	font-size: 17px;
}

.webshop .ajax-search .inner-filter {
	line-height: 38px;
	width: calc(100% - 90px);
	background: #fff;
	border: 1px solid $darkColor;
}

.webshop .ajax-search .filter-icon {
	background-image: url('img/webshop-filter-icon.png');
	background-repeat: no-repeat;
	background-size: contain;
	width: 19px;
	height: 20px;
	display: inline-block;
	margin: 9px;
}

.webshop .ajax-search input {
	width: calc(100% - 99px);
	padding: 0 10px 0 0;
	font-size: 15px;
}

.webshop .ajax-search .filter-button {
	cursor: pointer;
	border: 1px solid $lightColor;
	width: 90px;
	background-color: $themeColor;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.webshop .ajax-search .filter-button-icon {
	background-image: url('img/webshop-filter-remove-icon.png');
	background-repeat: no-repeat;
	background-size: contain;
	width: 23px;
	height: 20px;
	display: inline-block;
	margin: 9px 5px 7px 9px;
}

.webshop .ajax-search .filter-button-text {
	display: inline-block;
	width: 44px;
	font-size: 11px;
	color: $buttonTextColor;
	padding: 6px 0;
	font-family: $mainLightFont;
}

.ajax-search .search-button:after {
	content: "";
	position: absolute;
	background-image: url('img/search.png');
	background-size: 26px 27px;
	background-position: center center;
	background-repeat: no-repeat;
	width: 60px;
	height: 100%;
	right: 0;
	top: 0;
	opacity: 0.4;
}

.ajax-search .search-button {
	background-color: $themeColor;
	width: 60px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.webshop .ajax-search {
	height: 40px;
	background: transparent;
	border: none;
}

.beheer .ajax-search .search-button {
	background-color: #2b78e4;
}

.ajax-search {
	display: block;
	margin: 20px;
	height: 63px;
	background: #fff;
	border: 1px solid $darkColor;
	position: relative;
}

.ajax-search-count {
	display: none;
	font-size: 18px;
}

.ajax-search-count span {
	font-weight: bold;
}

.oneliner {
	height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.newsblockoverflow {
	width: 100%;
	max-height: 48px;
	overflow: hidden;
	font-family: $mainLightFont;
}

.profileDetails tr.editDisabled {
	background: #dfdfdf !important;
}

.changePassword .introText {
	color: #fff !important;
}

.profileDetails .quickscanProfile .searchBlock.open {
	z-index: 150;
}

.profileDetails .quickscanProfile .searchBlock {
	position: relative;
	z-index: 99;
	font-size: 0;
	width: auto;
}

.profile .inputs .inputRow.quickscanProfile input.pickTag {
	width: calc(100% - 60px) !important;
	margin: 0 !important;
	padding: 3px 3px 3px 10px;
	height: 38px;
}

.profileDetails .quickscanProfile .search {
	background-color: $themeColor;
	cursor: pointer;
	display: inline-block;
	background-image: url('img/searchButton.png');
	background-repeat: no-repeat;
	background-size: 26px;
	background-position: 50%;
	width: 60px;
	height: 58px;
	border-left: 1px solid #000;
	position: absolute;
	top: -19px;
}

.hidden {
	display: none;
}

.pos-table {
	display: table;
}

.pos-table .table-cell {
	display: table-cell;
}

.pos-table .vertical-center {
	vertical-align: middle;
}

.mlAdmin .action .btnAdd,
.mlAdmin .assetBtn,
.mlAdmin .modules .btnAdd {
	display: inline-block;
	background: #f1f1f1;
	font-size: 14px;
	padding: 7px 15px;
	border: 1px solid #ccc;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	color: #333;
	transition: all 0.5s;
}

.assetBtn:hover,
.mlAdmin .action .btnAdd:hover,
.mlAdmin .modules .btnAdd:hover {
	background: #999;
	border: 1px solid #666;
	color: #fff;
	cursor: pointer;
}

.mlAdmin .editEntry .editInputInfo {
	display: block;
	padding: 7px;
	background: #BDE5F8;
	border: 1px solid #00529B;
	margin: 0 0 10px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.mlAdmin .editEntry .module-content,
.mlAdmin .modules .module-content {
	width: 100%;
}

.mlAdmin .editEntry .module-content th,
.mlAdmin .modules .module-content th {
	text-align: left;
	background: #f1f1f1;
	padding: 7px;
	font-weight: normal;
	font-size: 14px;
	border-bottom: 1px solid #ccc;
}

.mlAdmin .editEntry .module-content td,
.mlAdmin .modules .module-content td {
	padding: 5px;
	border-bottom: 1px solid #f1f1f1;
	vertical-align: middle;
	font-size: 13px;
}

.mlAdmin .modules {
	padding: 20px;
}

.mlAdmin .modules h1 {
	font-size: 25px;
	padding: 0 0 5px;
	margin: 0 0 15px;
	border-bottom: 1px solid #4F4F4F;
}

.multilingual .infoText {
	font-size: 16px;
	margin-bottom: 15px;
}

.mlTable tbody td:not(:last-of-type):not(:first-of-type) {
	border-right: 1px solid #000;
}

.mlTable tbody td a{
	color:black;
	&:hover{
		color:#333;
		text-decoration: underline;
	}
}

.mlTable td:not(:first-of-type),
.mlTable th:not(:first-of-type) {
	padding: 0 10px 10px;
}

.mlTable td {
	padding-bottom: 10px;
}

.mlTable .fromMain {
	opacity: 0.3;
}

.groupNav {
	margin: 10px 16px 0;
	padding: 0;
	list-style-type: none;
}

.groupNav li {
	display: inline-block;
	padding: 5px 0;
	font-size: 15px;
}

.groupNav li:after {
	content: "\00BB";
	font-size: 22px;
	position: relative;
	top: -7px;
	padding: 0 5px;
}

.groupNav li:first-child:after,
.groupNav li:last-child:after {
	display: none;
}

.bgBar {
	position: relative;
	width: 100%;
	height: 175px;
	display: table;
	margin-top: 7px;
	background-size: cover;
}

.bgBar .infoBar {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-family: $mainmediumFont;
	color: #fff;
	font-size: 10px;
	position: relative;
	z-index: 2;
}

.bgBar:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1;
}

.bgBar .infoText {
	width: 75%;
	margin: 20px auto;
}

.bgBar .infoTitle {
	font-family: $mainmediumFont;
	font-size: 34px;
	color: #fff;
}

.icon.changelog {
	background-image: url("img/icon-changelog.png");
	width: 34px;
	height: 34px;
	display: inline-block;
	background-size: cover;
	margin: 5px 0;
}

.changelog .row {
	background: #e5e5e5;
	min-height: 65px;
}

.changelog .row:nth-child(even) {
	background: #fff;
}

.changelog .row .small-items {
	display: block;
}

.changelog .row .item {
	position: relative;
	color: #3d3d3d;
	margin: 0 20px;
	text-align: left;
	font-family: $mainFont;
	font-size: 10px;
}

.changelog .row.seen .item {
	color: #a2a2a2;
}

.changelog .row .small {
	width: 52px;
	margin-top: 5px;
}

.changelog .row .large {
	width: 38%;
	left: 100px;
	position: absolute;
	top: 15px;
	margin: 0;
}

.changelog .row.show .large {
	position: relative;
	top: -35px;
	left: 0;
	width: 100%;
	padding: 0 20px;
	transition: left 750ms 35ms, padding-left 750ms 35ms, width 350ms linear;
	-webkit-transition: left 750ms 35ms, padding-left 750ms 35ms, width 350ms;
	-moz-transition: left 750ms 35ms, padding-left 750ms 35ms, width 350ms;
	-o-transition: left 750ms 35ms, padding-left 750ms 35ms, width 350ms;
}

.changelog .row .read-more {
	bottom: 9px;
	text-decoration: underline;
	width: 45px;
	left: 100px;
	position: absolute;
	margin: 0;
	cursor: pointer;
}

.changelog .row .inner {
	text-align: center;
	padding: 7px 0;
	position: relative;
	min-height: 65px;
}

.changelog .row .buttonwrap {
	cursor: pointer;
	position: absolute;
	bottom: 18px;
	right: 20px;
	width: 80px;
	margin: 0;
}

.changelog .row .buttonwrap.back {
	right: auto;
}

.changelog .row .back-log {
	display: none;
}

.changelog .row.show .back-log {
	display: none;
	cursor: pointer;
	position: absolute;
	bottom: 18px;
	left: 20px;
	width: 76px;
	margin: 0;
	color: #3d3d3d;
	text-align: left;
	font-family: $mainFont;
	font-size: 10px;
}

.changelog .row.show .back-log {
	display: block;
}

.state-button.yellow {
	background-color: #ffc100;
}

.state-button.red {
	background-color: #e31537;
}

.state-button {
	width: 52px;
	height: 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	color: #fff;
	padding: 5px 0;
	text-align: center;
	font-family: $mainBoldFont;
	font-size: 10px;
}

.changelog .version-notation {
	font-family: $mainLightFont;
}

.changelog .date-notation {
	font-size: 10px;
	font-family: $mainBoldFont;
}

.changelog .title {
	font-size: 11px;
	font-family: $mainBoldFont;
}

.changelog .row.show .title {
	margin-left: 75px;
	transition: margin-left 750ms 35ms linear;
	-webkit-transition: margin-left 750ms 35ms;
	-moz-transition: margin-left 750ms 35ms;
	-o-transition: margin-left 750ms 35ms;
}

.changelog .text li {
	margin-left: 15px;
}

.changelog .row.show .text {
	margin-bottom: 40px;
	margin-top: 45px;
	margin-right: 0;
	transition: margin 500ms ease-in 35ms;
	-webkit-transition: margin 500ms 35ms;
	-moz-transition: margin 500ms 35ms;
	-o-transition: margin 500ms 35ms;
}

.changelog .collapsed .collapse-button {
	background-position: 0 10px;
}

.changelog .collapse-button {
	display: none;
	width: 15px;
	height: 10px;
	background-image: url("img/collapse-sprite.png");
	background-size: 15px 19px;
	background-position: 0 0;
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
}

.changelog .collapse-content {
	overflow: hidden;
}

.changelog .collapsed .collapse-content {
	height: 26px;
}

.changelog .collapsed {
	position: relative;
	overflow: hidden;
}

.changelog .subtitle-notation {
	display: inline-block;
	width: 64px;
	font-size: 11px;
	font-family: $mainBoldFont;
}

.changelog .arrow-button {
	background-image: url("img/arrow-button.png");
	background-size: cover;
	width: 8px;
	height: 22px;
	display: inline-block;
	margin: 3px 0;
	float: right;
}

.changelog .back .arrow-button {
	transform: rotate(180deg) translateY(25%);
	position: absolute;
	left: -20px;
	margin: 0;
}

.changelog .arrow-button.left {
	background-image: url("img/arrow-button-left-grey.png");
	margin-right: 4px;
}

.changelog-shadow,
.popup-shadow {
	top: 0;
	bottom: 0;
	position: fixed;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99;
}

.popup.changelog {
	width: 90%;
	height: 90%;
	position: fixed;
	max-width: none;
	max-height: 627px;
	overflow: hidden;
	z-index: 1000;
}

.popup.changelog .header-title {
	font-family: $mainmediumFont;
}

.popup.changelog .header-top {
	font-size: 18px;
	margin-bottom: 12px;
}

.popup.changelog .changelog-header {
	width: 100%;
	height: 120px;
	background: #e01c3c;
	text-align: center;
	color: #fff;
	font-family: $mainLightFont;
	font-size: 20px;
	padding: 16px;
}

.popup.changelog .changelog-header .icon {
	background-size: contain;
	width: 60px;
	height: 60px;
	position: absolute;
	top: 0;
	margin: 50px 10px 0;
}

.popup.changelog .changelog-header .icon.close {
	cursor: pointer;
	background-image: url("img/changelog-close-popup-icon.png");
	right: 0;
}

.popup.changelog .changelog-header .icon.exclamation {
	background-image: url("img/changelog-popup-icon.png");
	left: 0;
}

.popup.changelog .changelog-body {
	height: calc(100% - 122px);
	max-height: 507px;
	font-family: $mainLightFont;
}

.popup.changelog .changelog-body {
	overflow-y: scroll;
	.left {
		display: block;
		padding: 30px;
		text-align: left;
		font-size: 18px;
	}
	.right {
		display: block;
		background: #f6f6f6;
		padding: 30px 38px;
	}
}

@media screen and (min-width: 768px) {
	.popup.changelog .changelog-body {
		overflow: hidden;
		.left {
			width: calc(100% - 287px);
			float: left;
			height: 100%;
			overflow-y: scroll;
		}
		.right {
			float: left;
			height: 100%;
			width: 287px;
			padding: 30px 38px;
			overflow-y: scroll;
		}
	}
}

.toetsBody .popup.changelog .changelog-body .left {
	width: 100%;
}

.toetsBody .popup.changelog .changelog-body .right {
	display: none;
}

.popup.changelog .right .intro {
	margin: 15px 0;
}

.popup.changelog .intro {
	font-family: $mainBoldFont;
	font-size: 14px;
}

.popup.changelog .miniature .inputs {
	width: 200px !important;
}

.popup.changelog .miniature .inputs .text {
	padding: 0;
}

.popup.changelog .miniature .inputs .subTitle {
	letter-spacing: 0;
	line-height: 15px;
}

.popup.changelog .miniature.content table.inputs.lessons.followup tr td {
	width: 200px !important;
	height: 145px !important;
}

.popup.changelog .miniature.content table.inputs.lessons.followup tr td .image {
	width: 100%;
	height: 123px;
	position: absolute;
	left: 0;
	top: 0;
	background-size: cover;
}

.popup.changelog .miniature .infoWrap {
	height: 85px;
}

.popup.changelog .changed-list {
	font-family: $mainBoldFont;
	font-size: 14px;
}

.popup.changelog .changed-list .item {
	padding: 9px 0;
}

.popup.changelog .changed-list .item.active .changed {
	opacity: 1;
}

.popup.changelog .changed-list .item .changed {
	width: 60px;
	float: left;
	opacity: 0;
}

.popup.changelog .changed-list .item .changed .icon {
	width: 19px;
	height: 19px;
	margin: 0;
}

.popup.changelog .changed-list .item .title {
	float: left;
	width: 130px;
	text-align: left;
	font-size: 14px;
}

.popup.changelog .changelog-body img {
	width: 100%;
	margin-bottom: 20px;
}

.popup.changelog .changelog-body .left .item {
	padding: 20px 0;
	font-size: 14px;
	font-family: $mainLightFont;
}

.popup.changelog .changelog-body .left .text {
	line-height: 18px;
	padding: 0;
}

.popup.changelog .changelog-body .left .text:before {
	content: "";
	width: 12px;
	height: 12px;
	display: inline-block;
	float: left;
	background: #e01c3c;
	border-radius: 50%;
	margin: 3px 5px 0 0;
}

.popup.changelog .changelog-body .text li {
	margin-left: 15px;
}

.datatable .listViewSubBar {
	display: block;
	height: 33px;
	line-height: 33px;
	background: #ccc;
	border-bottom: 1px solid #bebebe;
	border-top: 1px solid #fff;
}

.datatable .listViewSubBar .sub-item {
	font-size: 14px;
	display: inline-block;
	width: 20%;
}

.datatable.col-5 .listViewSubBar .sub-item {
	width: 16%;
}

.datatable .listViewSubBar .sub-item:first-child {
	width: 30%;
	margin-left: 10px;
}

.datatable .listViewItem,
.datatable a.listViewItem {
	cursor: pointer;
	font-size: 14px;
	display: block;
	height: 44px;
	width: 100%;
	line-height: 44px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #bebebe;
	color: #000;
	position: relative;
}

.datatable a.listViewItem.locked {
	pointer-events: none;
}

.datatable .listViewItem.odd,
.datatable a.listViewItem.odd {
	background: #f2f2f2;
}

.datatable .listViewItem.even,
.datatable a.listViewItem.even {
	background: #e6e6e6;
}

.datatable .listViewItem span {
	width: 20%;
	display: inline-block;
	margin-left: 0;
}

.datatable.col-5 .listViewItem span {
	width: 16%;
}

.datatable .listViewItem span:first-child,
.datatable a.listViewItem span:first-child {
	margin-left: 15px;
	width: 30%;
}

.datatable .listViewItem.main span:first-child {
	width: auto;
	padding-right: 5px;
}

.datatable .listViewItem span.second,
.datatable a.listViewItem span.second {
	position: absolute;
	left: 60%;
}

.datatable .triple a.listViewItem span.second {
	position: absolute;
	left: 33%;
	padding-left: 10px;
}

.datatable .triple a.listViewItem span.third {
	position: absolute;
	left: 66%;
	padding-left: 10px;
}

.datatable a.listViewItem .verticalLine {
	position: absolute;
	top: -1px;
	bottom: 0;
	left: 60%;
	width: 1px;
	background: #bebebe;
}

.datatable .triple a.listViewItem .verticalLine {
	left: 33%;
}

.datatable .triple a.listViewItem .verticalLine.second {
	left: 66%;
}

.datatable a.listViewItem:first-of-type .verticalLine {
	top: 0;
}

.datatable a.listViewItem.highlight {
	background-color: #2b78e4;
	color: #fff!important;
}

.datatable .listViewItem .editButton {
	margin: 5px 5px 5px 30px;
	width: 30px;
	height: 30px;
}

.datatable.acceptlist .listViewItem .editButton {
	float: right;
	margin-right: 50px;
}

.registerLinks table {
	margin: 10px 0;
}

.registerLinks table td,
.registerLinks table th:not(:first-child) {
	padding: 2px 20px;
	border: 1px solid gray;
	vertical-align: middle;
}

.print-button {
	display: none;
	width: 41px;
	height: 41px;
	margin: 0 auto;
	background-image: url('img/print-btn.png');
	background-size: cover;
	cursor: pointer;
}

@media print {
	* {
		display: none;
	}
	.changelog,
	.changelog *,
	body,
	html {
		display: block;
	}
	body {
		background-color: #ffffff;
	}
	.bgBar {
		display: none !important;
	}
	.changelog .buttonwrap,
	.changelog .read-more {
		display: none;
	}
	.changelog .buttonwrap,
	.changelog .read-more {
		display: none;
	}
	.changelog .row .collapsed {
		display: inline-block;
		top: 0;
		overflow: visible;
		position: relative;
		width: calc(100% - 250px);
	}
	.changelog .row .collapse-content {
		height: auto;
	}
	.changelog .row .inner {
		text-align: left;
	}
	.changelog .row .inner li span,
	.changelog .row .inner p,
	.changelog .row .inner p span {
		font-size: 12px !important;
		font-family: $mainLightFont !important;
	}
}

.webshop-header-overlay,
.webshop-overlay {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 9999;
	overflow: auto;
}

.webshop-header-overlay.show,
.webshop-overlay.show,
.webshop-product-overlay.show {
	display: block;
}

.webshop-header-overlay .inner,
.webshop-overlay .inner{
	color: #fff;
	font-family: $mainLightFont;
	margin: auto;
	text-align: center;
	max-width: 500px;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	max-height: 100%;
	overflow: auto;
	padding: 70px 10px 0 !important;
	transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
}

@media screen and (min-width: 768px) {
	.webshop-header-overlay .inner,
	.webshop-overlay .inner {
		left: 50%;
		right: auto;
		transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}
}

.webshop-product-overlay .inner {
	text-align: left;
	width: 100%;
	padding: 40px 10px 0;
}

.webshop-header-overlay .inner .overlay-title,
.webshop-overlay .inner .overlay-title,
.webshop-product-overlay .inner .overlay-title {
	font-size: 24px;
	font-family: $mainBoldFont;
	margin: 0 0 15px;
}

.document-overlay .inner .overlay-frame-document {
	display: block;
	width: 100%;
	height: 60vh;
}

.webshop-product-overlay .inner .overlay-title {
	font-size: 19px;
	margin-left: 53%;
	margin-top: 4px;
	margin-bottom: 8px;
}

.webshop-header-overlay .inner .overlay-txt,
.webshop-overlay .inner .overlay-txt {
	font-size: 18px;
	line-height: 22px;
}

.overlay-txt ul {
	margin-left: 25px;
}

.webshop-overlay .inner .overlay-list {
	display: block;
}

.webshop-overlay .inner .overlay-list .langpicker {
	width: 75%;
	margin: 20px auto 40px;
	text-align: left;
}

.webshop-overlay .inner .overlay-list .langpicker .lang {
	width: auto;
	height: auto;
	margin: 0;
	display: block;
	padding: 10px 15px;
	font-size: 18px;
	color: #fff;
	background: rgba(255, 255, 255, 0.05);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.webshop-overlay .inner .overlay-list .langpicker .lang:focus,
.webshop-overlay .inner .overlay-list .langpicker .lang:hover {
	background: rgba(255, 255, 255, 0.1);
}

.webshop-overlay .inner .overlay-list .langpicker .lang .flag {
	display: inline-block;
	width: 25px;
	height: 19px;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: 2px;
	margin-right: 10px;
}

.webshop-overlay .inner .overlay-list .langpicker .lang .flag.flag-nl {
	background-image: url('img/nl.svg');
}

.webshop-overlay .inner .overlay-list .langpicker .lang .flag.flag-uk {
	background-image: url('img/gb.svg');
}

.webshop-overlay .inner .overlay-list .langpicker .lang .flag.flag-usa {
	background-image: url('img/us.svg');
}

.webshop-overlay .inner .navButtons {
	max-width: 295px;
	margin-top: 30px;
	display: block;
}

.webshop-product-overlay .inner .overlay-txt {
	font-size: 15px;
	line-height: 17px;
	margin-top: 105px;
}

.webshop-header-overlay .inner .overlay-btn-title,
.webshop-overlay .inner .overlay-btn-title {
	width: 295px;
	margin: auto;
	text-align: left;
}

.webshop-header-overlay .inner .overlay-btn,
.webshop-overlay .inner .overlay-btn {
	width: 295px;
	height: 40px;
	background: #fff;
	color: #000;
	margin: auto;
	line-height: 40px;
	font-size: 14px;
}

.webshop-header-overlay .inner .overlay-icon,
.webshop-overlay .inner .overlay-icon,
.webshop-product-overlay .inner .overlay-icon {
	background-size: contain;
	background-repeat: no-repeat;
	margin: 30px auto;
}

.webshop-header-overlay .inner .overlay-close-icon,
.webshop-overlay .inner .overlay-close-icon,
.webshop-product-overlay .inner .overlay-close-icon {
	background-image: url('img/overlay-close-icon.png');
	width: 42.5px;
	height: 42.5px;
	margin: 100px auto 0;
	cursor: pointer;
}

.document-overlay .inner .overlay-close-icon {
	margin: 20px auto 0;
}

.webshop-product-overlay .inner .overlay-close-icon {
	margin: 50px auto 0;
}

.webshop-mail-icon {
	width: 38.5px;
	height: 28.5px;
	background-image: url('img/webshop-mail-icon.png');
}

.webshop-cart-empty-icon {
	height: 38px;
	width: 44.5px;
	background-image: url('img/webshop-cart-empty-icon.png');
}

.webshop-cart-error-icon {
	height: 38px;
	width: 44.5px;
	background-image: url('img/webshop-cart-error-icon.png');
}

.productOverlayBody {
	background: #efefef;
}

.webshop-product-overlay .wvChooseProductWrap {
	bottom: auto;
}

.webshop-product-overlay .inner .top {
	margin: 0 0 25px;
	min-height: 122px;
}

.webshop-product-overlay .inner .middle {
	margin: 0 0 40px;
}

.webshop-product-overlay .inner .top .info {
	display: inline-block;
	width: 60%;
	padding: 40px 0 0;
}

.webshop-product-overlay .inner .wvProductImage {
	width: 45%;
	height: 120px;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	background-position: center;
}

.webshop-product-overlay .inner .overlay-price {
	font-family: $mainBoldFont;
	color: #19a288;
	margin-left: 53%;
	font-size: 18px;
}

.webshop-product-overlay .inner .amount {
	display: inline-block;
}

.webshop-product-overlay .inner .overlay-vat {
	display: inline-block;
	font-family: $mainFont;
	font-size: 13px;
	margin-top: 4px;
}

.webshop-product-overlay .inner .overlay-dots {
	display: inline-block;
	font-size: 9px;
	margin: 5px 15px;
}

.webshop-product-overlay .inner .overlay-dots .dot {
	display: inline-block;
	text-align: center;
	line-height: 19px;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	background: #5ac6cc;
	margin: 0 5px;
}

.webshop-product-overlay .inner .all-devices {
	text-align: center;
	padding: 50px 0 10px;
}

.webshop-product-overlay .inner .webshop-devices-icon {
	height: 35px;
	width: 47px;
	background-image: url('img/alldevices.svg');
	margin: 0;
	display: inline-block;
}

.webshop-product-overlay .inner .all-devices .devices-text {
	font-size: 12px;
	font-family: $mainBoldFont;
	margin: 5px 0 0 5px;
	display: inline-block;
}

.webshop-product-overlay .inner .overlay-ChooseProduct {
	display: inline-block;
	background: $themeColor;
	text-align: center;
	color: $buttonTextColor;
	font-size: 20px;
	line-height: 54px;
	font-family: $mainBoldFont;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0 30px;
}

.scroller-container {
	position: absolute;
	width: 45%;
	height: 120px;
}

.webshop-product-overlay .image-scroller {
	margin: auto;
	width: 100%;
	height: 160px;
	white-space: nowrap;
	overflow: hidden;
	font-size: 0;
}

.webshop-product-overlay .image-scroller .image-content {
	display: inline-block;
	width: 100%;
	height: 120px;
	position: relative;
}

.webshop-product-overlay .image-scroller .scroller-image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.webshop-product-overlay .image-scroller .scroller-text {
	font-size: 11px;
	margin: 5px 0 0;
	overflow: hidden;
}

.webshop-product-overlay .scroller-container .btn {
	position: absolute;
	padding: 5px;
	top: 50px;
	cursor: pointer;
}

.webshop-product-overlay .scroller-container .btn.next {
	right: 0;
}

.webshop-product-overlay .scroller-container .btn.prev {
	left: 0;
}

.webshop-product-overlay .scroller-container .btn span {
	background-image: url("img/image-scroller-sprite.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	width: 9.5px;
	height: 17.5px;
	display: block;
}

.webshop-product-overlay .scroller-container .btn.next span {
	background-position: -12px 0;
}

.webshop-product-overlay .scroller-container .image-count {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 16px;
	font-family: $mainBoldFont;
}

.webshop-product-overlay .information {
	display:none;
	margin: 0 0 0 5%;
}

.action.editmultilingual,
.modules.multilingual {
	padding: 10px 15px;
}

.modules.multilingual th {
	text-align: left;
	font-family: $semiBoldFont;
}

.hasInteractions .interactions .headerAvatar.dummyAvatar {
	cursor: pointer;
}

.wvBaseContainer.webshop {
	font-size: 15px;
}

.licenseBlock.expired .overlay {
	font-family: $mainFont;
}

.wvChooseProduct.emptyyourcart {
	max-width: 280px;
	margin: auto;
}

.highcharts-credits {
	display: none !important;
}

.graphPageContainer {
	max-width: 1282px;
	margin: 0 auto;
}
.dataPageContainer{
	margin: 0 auto;
}

.dataPage {
	font-family: $mainFont;
}

.dataPage,
.graphPage {
	margin: 0 36px 36px;
}

.dataPageContainer .dataPage{
	margin: 0 0px 36px 0px;
}

.graphPage {
	margin: 60px 36px 36px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 60px;
	column-gap: 60px;
}

.orgStats .subTitle {
	font-size: 23px;
}

.orgStats h1 {
	font-size: 51px;
	font-family: $mainmediumFont;
	margin-bottom: 0.5em;
}

.orgStats select.orgSelect option {
	font-size: 14px;
}

.orgStats select.orgSelect optgroup {
	font-size: 12px;
	height: 0;
	margin-bottom: 35px;
}

.orgStats select.orgSelect {
	border: 0;
	font-size: 51px;
	font-family: $mainmediumFont;
	margin-bottom: 0.5em;
	max-width: calc(100% - 250px);
	min-width: 50%;
	width: auto;
}

.large-warning .title {
	font-size: 16px;
	font-weight: bold;
}

.large-warning:hover {
	color: #b50a0a;
}

.large-warning {
	border: 2px solid #b50a0a;
	background: #ffdddd;
	border-radius: 10px;
	padding: 10px;
	margin: 20px 0 0;
	font-size: 14px;
	color: #b50a0a !important;
	display: block;
}

.updated {
	font-size: 15px;
}

.updated span {
	font-family: $mainBoldFont;
}

.graphPage .block {
	background: #fff;
	display: block;
	padding: 20px;
	width: 100%;
}

.filterHeader,
.graphBlock h2 {
	padding: 34px;
}

.graphBlock h2 {
	padding: 34px 0;
}

.graphPage .block.fullWidth {
	display: block;
	width: 100%;
	position: relative;
	grid-column: span 2;
}

.graphPage .block.fullWidth .outerContainer {
	text-align: center;
}

.graphBlock {
	padding: 0 !important;
}

.graphPage .block h2 {
	text-align: center;
	font-size: 40px;
	font-family: $mainBoldFont;
}

.graphPage .block.semiDonut h2 {
	padding-bottom: 0;
}

.graphContainer {
	width: 100%;
	position: relative;
	.nodata{
		margin:30px;
		text-align:center;
	}
}

.highcharts-data-label {
	text-align: center;
	text-shadow: 2px 1px 0 #000;
	color: #fff;
}

.filterHeader>div {
	vertical-align: middle;
	font-size: 20px;
}

.filterHeader .filterButtons {
	width: auto;
	margin: 0 26px;
}

.filterHeader .filterDesc {
	width: 130px;
}

.filterHeader .button {
	background: #b2b2b2;
	color: #d9d9d9;
	width: 130px;
	padding: 10px;
	font-weight: bold;
	display: inline-block;
	font-size: 30px;
	border-radius: 3px;
	text-align: center;
	margin: 4px 4px 4px 10px;
	cursor: pointer;
}

.filterHeader .button.active {
	background: #2b78e4;
	color: #fff;
}

a.toTotalList {
	color: #000;
	font-weight: bold;
	top: 1em;
	right: 1em;
	font-size: 17px;
	font-family: $mainBoldFont;
	margin: -1em 0 1em;
	display: block;
}

.toTotalList div {
	display: inline-block;
}

.toTotalList .text {
	width: 140px;
}

.toTotalList .button {
	background: url("img/hamburger-dotted.png") center no-repeat #2b78e4;
	background-size: 25px 20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 5px;
}

.dataPage .dataTable {
	width: 100%;
	text-align: left;
}

.dataPage .dataTable th {
	padding: 10px;
	background: #4c4c4c;
	color: #fff;
	font-family: $mainBoldFont;
	cursor: pointer;
}

.dataPage .dataTable td {
	padding: 10px;
	color: #1a1a1a;
	font-family: $mainBoldFont;
}

.dataPage .dataTable td.break,
.dataPage .dataTable th.break {
	word-break: break-word;
	max-width: 170px;
	min-width: 100px;
}

.dataPage .dataTable td,
.dataPage .dataTable th {
	min-width: 100px;
}

.dataPage .dataTable td.tiny,
.dataPage .dataTable th.tiny {
	min-width: 20px;
}

.dataPage .dataTable td.id.tiny, .dataPage .dataTable th.id.tiny{
	position:sticky;
	left:0;
	z-index:1;
}
.dataPage .dataTable td.name, .dataPage .dataTable th.name{
	position:sticky;
	left:41px;
	z-index:1;
}
.dataPage .dataTable td.surname, .dataPage .dataTable th.surname{
	position:sticky;
	left:141px;
	z-index:1;
}

.dataPage .dataTable tbody tr, .dataPage .dataTable tfoot tr {
	td.id.tiny, td.name, td.surname{
		background: #e9e9e9;
	}
}
.dataPage .dataTable tbody tr:nth-of-type(2n), .dataPage .dataTable tfoot tr:nth-of-type(2n) {
	td.id.tiny, td.name, td.surname{
		background: #dadada;
	}
}

.dataPage .dataTable tfoot{
	border-top:2px solid black;
}

.dataPage .dataTable th.notesCol,
.dataPage .dataTable td.notesCol {
	min-width: 260px;
}

.dataPage .dataTable td{
	a, a:link, a:visited {
		color: #1a1a1a;
		text-decoration: underline;
	}
}


.filterHeader > div.filterActions{
	display:flex;
	column-gap: 20px;
	.filterbuttonwrapper{
		margin-top:13px;
		margin-right: 40px;
		white-space: nowrap;
	}
	h1{
		margin-top: -7px;
		margin-right: 60px;
	}
	.fieldContainer input[type=text]{
		width:220px;
	}
	.fieldContainer select{
		width:100%;
	}

}


.dataPage .dataTable td.nowrap {
	white-space: nowrap;
}

.dataPage .dataTable tbody tr, .dataPage .dataTable tfoot tr {
	background: #e9e9e9;
}

.dataPage .dataTable tbody tr:nth-of-type(even), .dataPage .dataTable tfoot tr:nth-of-type(even) {
	background: #dadada;
}

.dataPage .dataTable tbody td.progress {
	text-align: center;
}

.dataPage .dataTable .licProgress {
	position: relative;
}

.dataPage .dataTable .licProgress div {
	position: relative;
	z-index: 10;
}

.dataPage .dataTable div.progressInner {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}

.dataPage .filterHeader {
	background: #218b8e;
	color: #fff;
	position: fixed;
	top: 100px;
	height: 170px;
	left: 0;
	right: 0;
}

.dataPage .filterHeader h1 {
	font-size: 30px;
}

.dataPage .table{
	width: 100%;
	margin-top: 165px;
	position: relative;
}

.fieldContainer:first-of-type {
	margin-left: 90px;
}

.fieldContainer:last-of-type {
	margin-right: 10px;
}

.fieldContainer input {
	width: 100px;
	padding: 5px;
}

.headerButton {
	border: #000;
	background: #fff;
	padding: 5px;
	cursor: pointer;
}

.headerButton.refreshButton {
	background: url("img/trechter.png") 5px center #00848d no-repeat;
	background-size: 20px;
	padding-left: 30px;
	border: 1px solid #000;
}

.headerButton.clearfilterIcon {
	background-image: url("img/filter-remove-icon-white.png");
	margin: 0 5px;
	background-size: 25px;
}

.fieldContainer {
	display: inline-block;
	position: relative;
}

.filterHeader label {
	position: absolute;
	left: 0;
	top: -25px;
}

.filterHeader .filterActions.right {
	position: absolute;
	right: 10px;
	top: 10px;
}

.highcharts-legend-item span {
	white-space: normal !important;
	width: 220px;
}

.noMobile {
	display: none;
}

.donutTitle {
	text-align: center;
	display: block;
}

.gaugeChart .highcharts-tooltip>span {
	text-align: center;
	width: 80px;
	white-space: normal !important;
	line-height: 18px;
}

.gaugeChart .highcharts-tooltip>span span {
	line-height: 27px;
}

.toetsWerkvorm .navContainer {
	display: block;
	position: relative;
	max-width: 472px;
	margin: 0 auto;
}

.toetsWerkvorm .navContainer .quizNav {
	position: absolute;
	display: inline-block;
	margin: 0;
	border: 2px solid $midColor;
	width: 85px;
	background: $themeColor;
	top: 50%;
	transform: translateY(-50%);
}

.toetsWerkvorm .navContainer .quizNav a {
	font-family: $mainLightFont;
	color: $midColor;
	font-weight: bold;
	letter-spacing: 1px;
	display: block;
	padding: 5px 10px;
}

.toetsWerkvorm .navContainer .quizNav.next {
	right: 12px;
	left: auto;
}

.toetsWerkvorm .navContainer .quizNav.prev {
	left: 12px;
}

.graphPageContainer .werkvormDownloadButton, .dataPageContainer .werkvormDownloadButton{
	width: 35px;
	height: 35px;
	margin-top: 57px;
	margin-right: 40px;
}

.vrijstellingcomment{
	width:100%;
}

.vrijstellingcommented{
	color: gray;
	margin: 4px 0px 12px 25px;
	font-size: 14px;
}

.scoreText {
	color: #fff;
	font-size: 30px;
	letter-spacing: 1px;
}

.toetsPage .contentSubTitle,
.toetsPage .contentText {
	color: #fff;
}

.toetsPage {
	margin-bottom: 100px;
}

.toetsPage .navContainer {
	margin: 50px 0;
}

.quizNav.fadedInput {
	opacity: 0.15;
	pointer-events: none;
}

.toetsMedia img {
	width: 100%;
	margin-top: 30px;
}

.toetsCover .totalPercent {
	position: relative;
}

.toetsCover .totalPercent.passed:before {
	content: "";
	position: absolute;
	height: 100%;
	width: 200px;
	background: url("img/leftRadiant.png") center right no-repeat;
	background-size: auto 100%;
	right: 100%;
}

.toetsCover .totalPercent.passed:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 200px;
	background: url("img/rightRadiant.png") center left no-repeat;
	background-size: auto 100%;
	left: 100%;
}

@media (max-width: 768px) {
	.outerContainer {
		overflow: hidden;
	}
	.semiDonut .highcharts-container {
		margin-top: -100px;
	}
	.donutTitle {
		font-size: 1em;
		margin-top: 3em;
	}
}

@media (min-width: 768px) {
	.noMobile {
		display: block;
	}
	.mobileOnly {
		display: none;
	}
	.graphPage .block {
		padding: 20px;
	}
	a.toTotalList {
		position: absolute;
		margin: 0;
	}
	.filterHeader,
	.graphBlock h2 {
		padding: 39px;
	}
	.filterHeader .button {
		margin: 0 20px;
	}
	.fullWidth .graphContainer {
		width: 33%;
		display: inline-block;
	}
	.noTable {
		display: none;
	}
	.toetsWerkvorm .navContainer .quizNav.next {
		right: 0;
	}
	.toetsWerkvorm .navContainer .quizNav.prev {
		left: 0;
	}
	.popup.changelog .changelog-header .icon {
		margin: 30px;
	}
}

.moduleLockedOverlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 9999;
	font-size: 14px;
}

.moduleLockedOverlay .overlayInner {
	color: #fff;
	font-family: $mainLightFont;
	margin: auto;
	text-align: center;
	max-width: 75%;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	max-height: 100%;
	overflow: auto;
	padding: 70px 10px 0 !important;
	transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
}

.moduleLockedOverlay .moduleTitle {
	margin-top: 25px;
	font-size: 54px;
	color: #fff;
	font-weight: bold;
	font-family: $mainFont;
	word-wrap: break-word;
}

.moduleLockedOverlay .subTitle {
	font-family: $mainExtraLightFont;
	font-size: 16px;
	margin-bottom: 10px;
}

.moduleLockedOverlay .closeOverlay {
	color: #ffffff;
	font-size: 16px;
	display: inline-block;
}

.moduleLockedOverlay .first {
	margin-bottom: 1px;
	display: inline-block;
}

@media screen and (min-width: 768px) {
	.moduleLockedOverlay .overlayInner {
		width: 500px;
	}
	.moduleLockedOverlay .moduleTitle {
		font-size: 68px;
	}
	.moduleLockedOverlay .subTitle {
		font-size: 24px;
		margin-bottom: 25px;
	}
}

.listViewItemTopper {
	margin: 60px 20px 30px;
	font-size: 30px;
	border-bottom: 1px solid #000;
}

.datatable.filtering .listViewItem.filter-found {
	display: block;
}

.datatable.filtering .listViewItem {
	display: none;
}

.datatable.filtering.filter-no-results {
	display: none;
}

.datatable.filtering.filter-no-results .listViewSubBar {
	display: none;
}

.datatable.filtering.filter-no-results .filter-not-found-error {
	display: block;
}

.filter-not-found-error {
	display: none;
	text-align: center;
	font-size: 18px;
	color: #fd0000;
	font-weight: bold;
	margin-top: 10px;
}

.publishContent {
	margin-top: 20px;
	font-size: 15px;
}

.publishContent .locked {
	color: red !important;
}

.publishContent .hasLockingContent {
	font-weight: bold;
}

.publishContent .pending {
	color: green !important;
}

.publishContent h1 {
	font-size: 20px;
}

.publishContent .publishBlock {
	margin-top: 20px;
}

.adminTable {
	margin: 10px 0;
}

.adminTable th {
	text-align: left;
	padding: 5px 10px 0 0;
}

.adminTable td {
	padding: 5px 10px 0 0;
}

.popupClonePageTpl .success-message {
	margin: 20px;
	color: green;
	padding: 5px;
	margin-top: 0;
	font-size: 16px;
}

.dataTable .licensecode {
	word-break: break-word;
}

.dataPage .partTable.dataTable thead{
	position: sticky;
	top: 105px;
	z-index: 2;
}

.dataTable .notes {
	position: relative;
	.noteActionWrap{
		display:none;
	}
	.save {
		display: inline-block;
		width: 75%;
		text-align: center;
		background: #218b8e;
		color: #fff;
		padding: 2px;
		cursor: pointer;
		height: 19px !important;
	}
	.delete {
		background-image: url(img/trashIcon.png);
		display: inline-block;
		width: 25%;
		background-size: 27px;
		background-color: #fff;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		height: 19px !important;
	}
	.loader {
		opacity: 0;
		visibility: hidden;
		background: url(img/loader.gif);
		background-size: 45%;
		background-color: rgba(255, 255, 255, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.4s linear;
		&.show {
			opacity: 1;
			visibility: visible;
		}
		&.done {
			background-image: url(img/checklist-check.png);
		}
	}
	textarea {
		width: 100%;
		background-color: rgba(255,255,255,0.5);
  		border: 1px solid rgba(0,0,0,0.2);
	}
}

.wvProduct .banner{
	padding:0px 0px 0px 50px;
	background:url(img/star-black.png) 20px center no-repeat #f6ec1d;
	background-size:20px;
	color:#000;
	font-family:'argumentumbold',sans-serif;
	line-height:25px;
	position: absolute;
	left:0;
	top:0;
	right:0;
	z-index: 2;
}

.cookieBar{
	display:none;
	background:black;
	height:74px;
	width: 100%;
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	z-index: 100;

	.cookieBarBtn{
		display: table-cell;
		text-align: right;
		vertical-align: middle;
		padding: 2px 15px 2px 15px;
		.front-btn{
			height:38px;
			padding: 5px 80px 0 20px;
		}
	}
	.cookieBarTxt{
		color:white;
		line-height:15px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 2px 15px 2px 15px;
		a{
			text-decoration: underline;
		}
	}
}



@media screen and (max-width: 768px){
	.stepHeader .introText h1 {
		font-size: 40px;
		margin: 25px 0 0px;
		letter-spacing: 0px;
	}
	.stepHeaderSpacer {
		height: 0px;
	}
	.contact.information{
		right: 5px;
		position: absolute;
		bottom:5px;
	}

	.contact.information h2, .webshop-product-overlay .information h2 {
		font-size: 14px;
	}

	.contact.information .infoRow, .webshop-product-overlay .information .infoRow {
		font-size: 11px;
		margin: 4px 0;
	}

	.stepnav {
		left: 0;
		top: 130px;
	}

	.stepnav a {
		width: 32px;
		height: 32px;
	}

	.stepnav .stepText {
		padding: 5px 5px;
	}

	.stepnav a:after {
		height: 14px;
		margin: 10px 0;
	}

	.wvProductText {
		margin-top: 14px;
		margin-left: 60%;
	}
	.content .wvProductBox .wvProduct .wvProductImage {
		right: 46%;
		height: 150px;
	}
	.content .wvProductBox .wvProduct .wvProductTitle {
		font-size: 16px;
		margin: 0px 0 6px;
		line-height: 18px;
	}

	.content .wvProductBox .wvProduct .price .amount {
		font-size: 14px;
	}
	.content .wvProductBox .wvProduct .price .vat {
		line-height: 18px;
	}
	.content .wvProductBox .wvProduct .price {
		margin-bottom: 4px;
	}
	.content .wvProductBox .wvProduct .wvProductInfo p {
		line-height: 12px;
	}

	.content .wvProductBox .wvProduct .wvProductInfo {
		font-size: 10px;
		max-height: 36px;
	}
	.content .wvProductBox .wvProduct {
		display: inline-block;
		height: 150px;
		margin: 22px 10px;
	}
	.wvProduct .banner {
		right: 46%;
	}
}

.toetsBody .score{
	border:1px solid black;
}

.webshop-overlay.error .overlay-txt .message{
	display:none;
	margin-bottom:12px;
}


.styledSelect .searchoptionsholder .searchoptions{
	width: calc(100% - 13px);
	border: 1px solid #aaa;
	margin: 0;
	margin-left: 7px;
	padding: 10px 3px 10px 9px;
	margin-top: 2px;
	color:#666;
}

.styledSelect.backend .searchoptionsholder .searchoptions {
  border: 1px solid #ececec;
  margin: 0px;
  background: #fcfcfc;
  width:100%;
}

.styledSelect.backend {
	position: relative;
	left: 0;
	width: 100%;
	background: url("img/dropwDownArrow.png") no-repeat #fff;
	background-position: 99% 30%;
	background-size: 20px auto;
	margin: 0;
	color: #1e1e1e !important;
	background-position-y: 5px;
	margin-bottom: 12px;
	border: 1px solid #ececec;
	border-radius: 2px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.styledSelect.backend.open {
	background: url("img/dropwDownArrowUp.png") no-repeat #fff;
	background-position: 99% 30%;
	background-size: 20px auto;
}

.styledSelect.backend .option {
	font-size: 14px;
	border: 1px solid #ececec;
	border-bottom: none;
	border-left: none;
	border-right: none;
	padding-top: 6px;
	padding-bottom: 6px;
	width: 100%;
	margin: -1px auto 0;
	padding-left: 6px;
	z-index: 20;
	cursor: pointer;
}

.registerPage .styledSelect.backend .option {
	font-size: 14px;
}

.styledSelect.backend .options {
	border: 1px solid #ececec;
	border-top: none;
	left: -1px;
	margin-top: 0;
	width: calc(100% + 2px);
	max-height: 400px;
	border-radius: 1px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.styledSelect.backend .option.default {
	line-height: 30px;
	height: 38px;
	border-top: none;
	border-bottom: 0;
	padding-bottom: 0;
	margin: 0;
	padding-top: 0;
	width: 99%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.styledSelect.backend .option.default {
	line-height: 31px;
	height: 31px;
}

.hasInfo .styledSelect.backend .option.default,
.hasInfo.styledSelect.backend .option.default {
	padding-right: 85px;
}

.selectParent.styledSelect.backend .selectArrow {
	top:1px;
	right: -1px;
}

.highcharts-title{
	font-size: 17px;
  	font-family: argumentumregular, sans-serif;
}

.profile{
	.inputRow span.tagline.marginleft{
		text-transform: lowercase;
	}
	.inputRow .tagline.marginleft{
		margin-left:20px;
	}
	.inputRow input.bignumber_input{
		width: 70% !important;
		position: absolute;
		top: 25px;
		margin-left:10px;
	}
	.inputRow.noleftborder{
		border-left:none;
	}
	.requiredmessage{
		position:relative; top:-60px;font-size:12px;font-family:'argumentumlight',sans-serif;color:#627072;width:295px;margin:8px auto;text-align:right;
	}
}


.classroomwrapper{
	min-height: calc(100vh - 240px);
	.classroom {
		.content{
			margin-bottom:60px;
		}
		.smallColumn, .mediumColumn{
			margin-top:30px;
			margin-bottom:30px;
			width:280px;
			margin-left:auto;
			margin-right:auto;
			.labelSelectMeeting{
				color:black;
				font-size:16px;
				line-height:22px;
			}
		}
		.mediumColumn{
			width:468px;
		}
		.submitBtn{
			border-radius:25px;
			height: 50px;
			line-height: 50px;
			font-family: argumentumregular, sans-serif;
			text-transform: uppercase;
			background:#2b78e4;
			border:none;
		}

		.intro .introText {
			font-family: argumentumregular, sans-serif;
			color:black;
			margin-top:12px;
			margin-bottom:0px;
		}

		.inputBlock.selectMeeting{
			margin-bottom:60px;
		}

		.formInputs .inputRow span{
			left: 7px;
		}

		.formInputs .outerRow{
			width:100%;
		}
		.formInputs .inputRow{
			border-radius:25px;
			width:100%;
			padding-left: 7px;
			padding-right: 7px;
		}

		.styledSelect.register .option.default {
			line-height: 20px;
			height: 27px;
		}

		.selectParent.styledSelect.register.open{
			border-left: 1px solid #1d427a;
			border-right: 1px solid #1d427a;
			width: calc(100% + 2px);
			margin-left: -1px !important;
		}

		.inputBlock {
			margin-top: 20px;
			margin-right: 0px;
			margin-bottom: 16px;
			margin-left: 0px;
		}

		.styledSelect.register, .styledSelect.register.open{
			background-position-y: 1px;
		}

		.selectedMeeting{
			background:white;
			padding:30px;
			text-align: center;
			position: relative;
			min-height: 320px;

			.selectedMeetingHeader{
				color: #292d2d;
				font-family: $mainFont;
				display: inline-block;
				border-top: 1px solid #d42041;
				border-bottom: 1px solid #d42041;
				padding: 2px 10px;
				font-size:20px;
				line-height:27px;
				font-style:italic;
			}
			.selectedMeetingInfo{
				margin-top:20px;
				.selectedMeetingDateWrapper{
					margin-bottom:20px;
					.selectedMeetingDateLabel{
						font-size:20px;
						line-height:27px;
						font-weight:bold;
					}
					.selectedMeetingDateValue{
						font-size:30px;
						line-height:34px;
					}

					.selectedMeetingDescriptionLabel, .selectedMeetingLinkLabel{
						font-size:20px;
						line-height:27px;
						font-weight:bold;
					}
					.selectedMeetingDescriptionValue, .selectedMeetingLinkValue{
						font-size:16px;
						line-height:20px;
					}
					.selectedMeetingLinkValue a{
						word-break: break-all;
					}

				}
			}
			.selectedMeetingAction a{
				text-decoration: underline;
				color:black;
				font-size:17px;
				font-style:italic;
			}

		}

	}

	.classroompopup{
		display:none;
		position:absolute;
		top:110px;
		left:0px;
		right:0px;
		bottom:0px;
		z-index: 6;
		background:white;
		text-align: center;

		&.inlinepopup{
			top:0px;
			background:white;
			.classroompopupBody{
				padding-left:30px;
				padding-right:30px;
				margin-top: 20px;
				.confirmOK{
					display:none;
				}
			}

			.classroompopupHeaderConfirmOK{
				background: #148E00 url("img/confirm-ok-white.svg") no-repeat center center;
				background-size: 16px 14px !important;
				border-radius: 50%;
				width: 40px !important;
				height: 40px !important;
				position: static;
  				margin: 0 auto 30px auto;
				display:none;
			}

		}

		.classroompopupHeader{
			margin-top:30px;

			.classroompopupHeaderClose{
				width: 55px;
				height: 55px;
				position: static;
				margin: 0 auto 30px auto;
				display: block;

				span{
					background: #d42041 url("img/cross.png") no-repeat center center;
					background-size: 23px 23px !important;
					border-radius: 50%;
					display: block;
					width: 55px !important;
					height: 55px !important;
					margin: 0;
				}
			}
			.classroompopupHeaderTitle{
				color: #292d2d;
				font-family: $mainFont;
				display: inline-block;
				border-top: 1px solid #d42041;
				border-bottom: 1px solid #d42041;
				padding: 2px 10px;
				font-size:42px;
				line-height:52px;
				text-transform: uppercase;
				font-style:italic;
			}

		}
		.classroompopupBody{
			margin-top:30px;
			.intro{
				font-size:20px;
				line-height:27px;
				font-family: $mainFont;
				font-weight:bold;
				margin:0px 0px 10px 0px;
			}
			.main{
				font-size:30px;
				line-height:34px;
				font-family: $mainFont;
				margin:0px 0px 30px 0px;
			}
			.omschrijving{
				font-size:18px;
				line-height:19px;
				font-family: $mainFont;
				font-style: normal;
				margin: 0px 0px 30px 0px;
			}
			.extra{
				font-size:18px;
				line-height:19px;
				font-family: $mainFont;
				font-style:italic;
				margin:0px 0px 30px 0px;
			}
		}
		.classroompopupButtons{
			.submitBtn{
				border-radius: 25px;
				height: 50px;
				line-height: 40px;
				font-family: $mainFont;
				text-transform: uppercase;
				background: #8c0021;
				border: none;
				max-width: 200px;
				display: block;
				width: 100%;
				margin: 10px auto 0;
				font-size: 20px;
				color: #fff;
				text-align: center;
				cursor: pointer;
				-webkit-appearance: none;
			}
		}
	}
}

.beheer .meetinglist{
	table{
		th, td{
			text-align: center;
			padding:10px 10px 10px 10px;
			&:first-child{
				text-align:left;
			}
		}
		th {
			border-top:1px solid #dee2e6;
			border-bottom:2px solid #dee2e6;
			text-decoration: underline;
			&.register{
				-ms-writing-mode: tb-rl;
				-webkit-writing-mode: vertical-rl;
				writing-mode: vertical-rl;
				transform: rotate(180deg);
				white-space: nowrap;
				text-align: left;
			}
		}
		td{
			border-bottom:1px solid #dee2e6;
			&.edit{
				img{
					width:18px;
				}
			}
			&.description{
				width:20%;
				div{
					margin-left:auto;
					margin-right:auto;
					text-overflow: ellipsis;
  					overflow: hidden;
					max-width:300px;
					max-height: 40px;
				}

			}
		}
		a{
			color:#292D2D;
			&.attendancelink{
				background-color: #292d2d;
				color: #fff;
				width: 22px;
				height: 22px;
				display: inline-block;
				border-radius: 22px;
				line-height: 22px;
				&:hover{
					background-color: #e11b3c;
				}
			}
		}
	}
}

.importTable{
	width: 100%;
	margin-bottom: 10px;

	th, td {
		text-align: left;
		padding: 5px;
	}
	tr {
		border: 0px solid #dee2e6;
		border-bottom-width: 1px;

		&.error {
			background-color: #f8d7da;
			border-color: #dfc2c4;
		}
	}
}


.compareLanguage {
	font-size: 14px;
}

.compareText {
	font-size: 0px;
	margin-bottom: 5px;
}

.compareEqual, .compareInsert, .compareDelete {
	display: inline;
	font-size: 12px;
}

.compareInsert {
	background-color: #d1e7dd;
}

.compareDelete {
	background-color: #f8d7da;
}

.trainingBlocks .programBlock .werkvormStartButton.werkvormStart .icon.locked{
	background-image: url("img/locked-dark.png");
	background-repeat: no-repeat;
	width: 40px;
	height: 52px;
	background-size: contain;
	margin: 14px 0px 0px 1px;
}

.authenticate{
	margin-top:40px;
	a, a:link, a:visited {
		color:$themeColor;
		&.submitBtn{
			color: $buttonTextColor;	
		}
	}
	.qrCode{
		margin:10px 0px 20px 0px;
		.small{
			font-size: 14px;
		}
	}
	input.form-input{
		margin: 12px 0px 8px 0px;
		-webkit-user-select: auto !important;
  		-webkit-appearance: none;
		border-radius: 5px;
		border: 1px solid #ccc;
		font-family: "SanFransisco", arial, tahoma, verdana, sans-serif;
		font-size: 25px;
		width: 262px;
  		height: 45px;
		padding: 0 0 0 25px;
		letter-spacing: 25px;
		&:focus {
			border: 1px solid #00b6f0;
			box-shadow: 0 0 0 3px rgba(0,182,240,.5);
		}
	}
}

.listView.attendance{
	margin-bottom:80px;

	.listViewItem{
		display:flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
		div.checkbox{
			display:block;
			position: relative;
			top: unset;
			right: unset;
			margin-left: auto;
		}
		> div{
			flex: 1 1 0;
  			width: 0;
			padding-left:10px;
			padding-right:10px;
			line-height: 16px;
		}
	}
}

.meetinglist .sortable th{
	cursor:pointer;
}

.stepHeader {
	background: none;
}

.stepHeader::after {
	background: none;
}

body:not(.login) .bgImage {
	background-color: #f7f8f8 !important;
	background-image: none !important;
}

body.login .bgImage, body.forgotPass .bgImage, body.register .bgImage, body.documentPage .bgImage, body.webshop .bgImage, body.changePass .bgImage, body.activate .bgImage {
	background-color: rgba(0,0,0,.75) !important;
	background-image: url("img/stepheader.jpg") !important;
}
  
body.login .bgImage::after, body.forgotPass .bgImage::after, body.register .bgImage::after, body.documentPage .bgImage::after, body.webshop .bgImage::after, body.changePass .bgImage::after, body.activate .bgImage::after {
	background: rgba(0,0,0,.75);
	opacity: 1;
}

body.login .formInputs.block:not(:last-of-type), body.forgotPass .formInputs.block:not(:last-of-type), body.register .formInputs.block:not(:last-of-type), body.documentPage .formInputs.block:not(:last-of-type), body.webshop .formInputs.block:not(:last-of-type), body.changePass .formInputs.block:not(:last-of-type), body.activate .formInputs.block:not(:last-of-type) {
	border-bottom: none;
	background: none;
}

body:not(.login) .bgImage::after {
	background: none;
	background-color: #000000;
	opacity: .75;
}
.action .userAction {
	min-height: calc(100vh - 351px);
}
  
.mainPortal .footer {
	background: #fff;
}
  
.webshop-header-overlay, .webshop-overlay {
	background: rgba(0,0,0,.9);
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
}

