.vrnbody .header-gcp .wrap .logo {
	background-image: url('img/vrn_base_logo.png');
	width: 189px;
	height: 46px;
}

.vrnbody .baseHeader .logo {
	background-image: url('img/vrn_base_logo.png');
	width: 420px;
	height: 102px;
}

.vrnbody.mainPortal.login .action .bodyObject .logo {
	background-image: url('img/vrn_base_logo.png');
	width: 290px;
	height: 70px;
	background-size: cover;
	margin-top: 30px;
}

.vrnbody .header .interactions .item.openSlideMessages,
.vrnbody .hasInteractions .interactions .item.openSlideMessages {
	background: #0078ae;
}

.vrnbody .cta-icon-button {
	background: #0078ae;
}

.vrnbody .ajax-search .search-button {
	background-color: #0078ae;
}

.vrnbody .font-blue {
	color: #0078ae;
}

.vrnbody .blue-button {
	background-color: #0078ae;
}

.vrnbody .icon.icon-comment {
	background-image: url('img/icon-like-vrn.png');
}

.vrnbody .icon.icon-likes {
	background-image: url('img/icon-like-blue-vrn.png');
}

.vrnbody .icon.icon-star {
	background-image: url('img/icons/icon-star-vrn.png');
}

.vrnbody .icon.icon-star-empty {
	background-image: url('img/icons/icon-star-empty-vrn.png');
}

.vrnbody .optionalRibbon {
	background: url('img/overlaydef_blank-vrn.png');
	background-size: cover;
}

.vrnbody .checklist .item .check {
	background-image: url('img/checklist-check-vrn.png');
}

.vrnbody .wvBaseContainer .article.intro .introTitle {
	color: #0078ae;
}

.vrnbody label.styled [type="checkbox"]:checked+span,
.vrnbody label.styled [type="radio"]:checked+span {
	background-image: url('img/VRN_checkbox_checked.png');
}

.vrnbody .searchWrap {
	color: #0078ae;
}

.vrnbody .slideMenu .menuItems .item {
	color: #0078ae;
}

.vrnbody .filterButtons .filter.active {
	background-color: #0078ae;
}

.vrnbody .trainingStatus {
	background-color: #0078ae;
}

.vrnbody .content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
	border-color: #0078ae;
}

.vrnbody .content table.inputs.lessons.followup tr td .info .text .smallTitle {
	color: #0078ae;
	border-color: #0078ae;
}

.vrnbody .content table.inputs.lessons.followup tr td .info .text .subTitle {
	color: #0078ae;
}

.vrnbody .profile .profileBlock .profileBlockTitle,
.vrnbody .content.profile .profileBlockTitle {
	color: #0078ae;
	border-color: #0078ae;
}

.vrnbody .profile .navButtons .navButton {
	background-color: #0078ae;
}

.vrnbody .profile .fullblock.blue {
	background: #0078ae;
}

.vrnbody .profileDetails .quickscanProfile .search {
	background-color: #0078ae;
}

.vrnbody .content table.inputs tr td span {
	color: #0078ae;
}

.vrnbody .trSim .tagline {
	color: #0078ae;
}

.vrnbody .userAction .bodyAction .btn,
.vrnbody .action .submitBtn,
.vrnbody .blue-button-small,
.vrnbody .content .orderDiscount .inputRow .checkDiscount,
.vrnbody .content .submitBtn.register,
.vrnbody .content .submitBtn.activateLicenseBtn,
.vrnbody .content .submitBtn.forgotPassword,
.vrnbody .content .submitBtn.changePassword {
	background: #0078ae;
}

.vrnbody .zoomControls .zoomControl {
	background: rgba(0, 120, 174, 0.75);
}

.vrnbody .content .submitBtn.changePasswordBtn {
	background: #0078ae;
	border-color: #0078ae;
}

.vrnbody .informationMessage {
	background: #0078ae;
	border-color: #0078ae;
}

.vrnbody .informationMessage:after {
	border-color: #0078ae transparent;
}

.vrnbody .inputRow .information .info-icon {
	border-color: #0078ae;
	background-image: url("img/VRN_information.png");
}

.vrnbody .content.profile .profileBlock.notifSettings .information .info-icon {
	border-color: #0078ae;
	background-image: url("img/VRN_information.png")
}

.vrnbody .profileDetails .editProfile {
	background: #0078ae;
}

.vrnbody a.paginatePage.active {
	background: #0078ae;
}

.vrnbody h1.magazineTitle {
	color: #0078ae;
	border-color: #0078ae;
}

.vrnbody .subtitleData {
	color: #0078ae;
}

.vrnbody .subtitleData .data {
	border-color: #0078ae;
}

.vrnbody .magazineCover .tableContent table tr th {
	color: #0078ae;
}

.vrnbody .magazineCover .tableContent .indicator.active {
	background: #0078ae;
}

.vrnbody .magazineCover .tableContent table tr td .status.active {
	color: #0078ae;
}

.vrnbody .nextLesson h1 {
	color: #0078ae;
}

.vrnbody .nextLesson .lessonTitle {
	border-color: #0078ae;
	color: #0078ae;
}

body:not(.quickscan).vrnbody .loader-circle .timer>.slice>.pie {
	border-color: #0078ae;
}

.vrnbody .werkvormTitle {
	color: #0078ae;
	border-color: #0078ae;
}

.vrnbody a.werkvormStartButton.completed {
	background: #0078ae;
}

.vrnbody .wvHeader .wvHeaderTitle {
	color: #0078ae;
	border-color: #0078ae;
}

.vrnbody .wvBlanksWrap .answer:hover,
.vrnbody .wvBlanksWrap .answer.selected {
	background: #0078ae;
}

.vrnbody .download-overlay .download-icon {
	background-image: url('img/icons/download-big-vrn.png');
}

.vrnbody .wvFeedbackTitle {
	color: #0078ae;
}

.vrnbody a.werkvormDownloadButton .icon.download {
	background-image: url("img/downloadAssetVRN.png");
}

.vrnbody .nextPageHeader {
	color: #0078ae;
}

.vrnbody .nextPageNr {
	background: #0078ae;
}

.vrnbody .wvStellingAnswer.selected {
	background: #0078ae;
}

.vrnbody .wvInterviewContent .contentCol .headText {
	color: #0078ae;
}

.vrnbody .wvInterviewContent .number {
	color: #0078ae;
}

.vrnbody .wvInterviewContent .question {
	color: #0078ae;
}

.vrnbody .wvInterviewContent .quote {
	background-color: #0078ae;
}

.vrnbody .wvProductBlocks .productBlock.firstBlock .title {
	color: #0078ae;
}

.vrnbody .wvProductBlocks .productBlock .subTitle {
	color: #0078ae;
}

.vrnbody .wvProductBlocks.wvBrief .productBlock .productImg .number {
	background: #0078ae;
}

.vrnbody .wvListAnswer.selected {
	background: #0078ae;
	border-bottom-color: #0078ae;
}

.vrnbody .imageDescription {
	border-color: #0078ae;
	color: #0078ae;
}

.vrnbody .wvWatbijwatContent .blockGrid .blockSet .arrows .nav {
	background-color: #0078ae;
}

.vrnbody .magazineContent.summary {
	background: #0078ae;
}

.vrnbody .magazineContent.summary a.paginatePage.active {
	color: #0078ae;
}

.vrnbody .score .number {
	color: #0078ae;
}

.vrnbody .rating {
	border-color: #0078ae;
}

.vrnbody .rating .info {
	color: #0078ae;
}

.vrnbody .rating .rating-amount {
	color: #0078ae;
}

.vrnbody .required {
	color: #0078ae !important;
}

.vrnbody .profile .packageInfo span,
.vrnbody .content.addModel .smallTitle,
.vrnbody .searchResults .smallTitle {
	color: #0078ae;
}

.vrnbody .content .wvSteps .step.active a {
	background-color: #0078ae;
}

.vrnbody .content .wvSteps .step.active::after {
	background: #0078ae;
}

.vrnbody .content .wvSteps .step.active::before {
	background: #0078ae;
}

.vrnbody .content .wvProductBox .wvProduct .wvProductTitle {
	color: #0078ae;
}

.vrnbody .content .wvChooseProduct {
	background: #0078ae;
}

.vrnbody .content .wvProductSteps .column .wvProductStep .stepContent .wvStepTitle {
	color: #0078ae;
}

.vrnbody .content .wvPageBack {
	color: #0078ae;
	background-image: url("img/VRN_arrowLeft.png");
}

.vrnbody .content .wvProductSteps .column .wvProductStep .checkOverlay.selected {
	background-image: url('img/VRN_checkboxChecked.png');
}

.vrnbody .checkedBoxchecked {
	background-image: url('img/VRN_checkbox_checked.png') !important;
}

.vrnbody .styledSelect.register {
	background: url(img/dropwDownArrow-vrn.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.vrnbody .styledSelect.register.open {
	background: url(img/dropwDownArrowUp-vrn.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.vrnbody .content .orderBox .orderTable table td {
	color: #0078ae;
}

.dctfbody .content .orderBox .orderTable table td.divider {
	background: #0078ae;
}

.vrnbody .content .orderBox .orderOptions label.styled {
	color: #0078ae;
}

.vrnbody .content .orderBox .orderOptions label.styled a {
	color: #0078ae;
}

.vrnbody .content .orderDiscount .inputRow input[type='submit'] {
	background: #0078ae;
}

.vrnbody .faqcontent .title span {
	color: #0078ae;
}

.vrnbody .faqcontent .faqItem {
	border-color: #0078ae;
}

.vrnbody .wvBlanksWrap .selectAnswer .answerOption:hover {
	background: #0078ae;
}

.vrnbody .content .rowTrigger .styled p.text {
	color: #0078ae;
}

.vrnbody .bgImage::after {
	background: #E8E8E8;
	opacity: 0.5
}

@media (min-width: 768px) {
	.vrnbody.mainPortal.login .action .bodyObject .logo {
		width: 350px;
		height: 85px;
	}
}

.dctfbody .header-gcp .wrap .logo {
	background-image: url('img/dctf_base_logo.png');
	width: 154px;
	height: 52px;
}

.dctfbody .baseHeader .logo {
	background-image: url('img/dctf_base_logo.png');
	width: 400px;
	height: 135px;
}

.dctfbody .header .interactions .item.openSlideMessages,
.dctfbody .hasInteractions .interactions .item.openSlideMessages {
	background: #262d86;
}

.dctfbody .cta-icon-button {
	background: #262d86;
}

.dctfbody .ajax-search .search-button {
	background-color: #262d86;
}

.dctfbody .font-blue {
	color: #262d86;
}

.dctfbody .blue-button {
	background-color: #262d86;
}

.dctfbody .icon.icon-comment {
	background-image: url('img/icon-like-dctf.png');
}

.dctfbody .icon.icon-likes {
	background-image: url('img/icon-like-blue-dctf.png');
}

.dctfbody .icon.icon-star {
	background-image: url('img/icons/icon-star-dctf.png');
}

.dctfbody .icon.icon-star-empty {
	background-image: url('img/icons/icon-star-empty-dctf.png');
}

.dctfbody .checklist .item .check {
	background-image: url('img/checklist-check-dctf.png');
}

.dctfbody .optionalRibbon {
	background: url('img/overlaydef_blank-dctf.png');
	background-size: cover;
}

.dctfbody .wvBaseContainer .article.intro .introTitle {
	color: #262d86;
}

.dctfbody label.styled [type="checkbox"]:checked+span,
.dctfbody label.styled [type="radio"]:checked+span {
	background-image: url('img/DCTF_checkbox_checked.png');
}

.dctfbody .searchWrap {
	color: #262d86;
}

.dctfbody .slideMenu .menuItems .item {
	color: #262d86;
}

.dctfbody .filterButtons .filter.active {
	background-color: #262d86;
}

.dctfbody .trainingStatus {
	background-color: #262d86;
}

.dctfbody .content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
	border-color: #262d86;
}

.dctfbody .content table.inputs.lessons.followup tr td .info .text .smallTitle {
	color: #262d86;
	border-color: #262d86;
}

.dctfbody .content table.inputs.lessons.followup tr td .info .text .subTitle {
	color: #262d86;
}

.dctfbody .profile .profileBlock .profileBlockTitle,
.dctfbody .content.profile .profileBlockTitle {
	color: #262d86;
	border-color: #262d86;
}

.dctfbody .profile .navButtons .navButton {
	background-color: #262d86;
}

.dctfbody .profile .fullblock.blue {
	background: #262d86;
}

.dctfbody .profileDetails .quickscanProfile .search {
	background-color: #262d86;
}

.dctfbody .content table.inputs tr td span {
	color: #262d86;
}

.dctfbody .trSim .tagline {
	color: #262d86;
}

.dctfbody .userAction .bodyAction .btn,
.dctfbody .action .submitBtn,
.dctfbody .blue-button-small,
.dctfbody .content .orderDiscount .inputRow .checkDiscount,
.dctfbody .content .submitBtn.register,
.dctfbody .content .submitBtn.activateLicenseBtn,
.dctfbody .content .submitBtn.forgotPassword,
.dctfbody .content .submitBtn.changePassword {
	background: #262d86;
}

.dctfbody .zoomControls .zoomControl {
	background: rgba(38, 45, 134, 0.75);
}

.dctfbody .content .submitBtn.changePasswordBtn {
	background: #262d86;
	border-color: #262d86;
}

.dctfbody .informationMessage {
	background: #262d86;
	border-color: #262d86;
}

.dctfbody .informationMessage:after {
	border-color: #262d86 transparent;
}

.dctfbody .inputRow .information .info-icon {
	border-color: #262d86;
	background-image: url("img/DCTF_information.png");
}

.dctfbody .content.profile .profileBlock.notifSettings .information .info-icon {
	border-color: #262d86;
	background-image: url("img/DCTF_information.png")
}

.dctfbody .profileDetails .editProfile {
	background: #262d86;
}

.dctfbody a.paginatePage.active {
	background: #262d86;
}

.dctfbody h1.magazineTitle {
	color: #262d86;
	border-color: #262d86;
}

.dctfbody .subtitleData {
	color: #262d86;
}

.dctfbody .subtitleData .data {
	border-color: #262d86;
}

.dctfbody .magazineCover .tableContent table tr th {
	color: #262d86;
}

.dctfbody .magazineCover .tableContent .indicator.active {
	background: #262d86;
}

.dctfbody .magazineCover .tableContent table tr td .status.active {
	color: #262d86;
}

.dctfbody .nextLesson h1 {
	color: #262d86;
}

.dctfbody .nextLesson .lessonTitle {
	border-color: #262d86;
	color: #262d86;
}

body:not(.quickscan).dctfbody .loader-circle .timer>.slice>.pie {
	border-color: #262d86;
}

.dctfbody .werkvormTitle {
	color: #262d86;
	border-color: #262d86;
}

.dctfbody a.werkvormStartButton.completed {
	background: #262d86;
}

.dctfbody .wvHeader .wvHeaderTitle {
	color: #262d86;
	border-color: #262d86;
}

.dctfbody .wvBlanksWrap .answer:hover,
.dctfbody .wvBlanksWrap .answer.selected {
	background: #262d86;
}

.dctfbody .download-overlay .download-icon {
	background-image: url('img/icons/download-big-dctf.png');
}

.dctfbody .wvFeedbackTitle {
	color: #262d86;
}

.dctfbody a.werkvormDownloadButton .icon.download {
	background-image: url("img/downloadAssetDCTF.png");
}

.dctfbody .nextPageHeader {
	color: #262d86;
}

.dctfbody .nextPageNr {
	background: #262d86;
}

.dctfbody .wvStellingAnswer.selected {
	background: #262d86;
}

.dctfbody .wvInterviewContent .contentCol .headText {
	color: #262d86;
}

.dctfbody .wvInterviewContent .number {
	color: #262d86;
}

.dctfbody .wvInterviewContent .question {
	color: #262d86;
}

.dctfbody .wvInterviewContent .quote {
	background-color: #262d86;
}

.dctfbody .wvProductBlocks .productBlock.firstBlock .title {
	color: #262d86;
}

.dctfbody .wvProductBlocks .productBlock .subTitle {
	color: #262d86;
}

.dctfbody .wvProductBlocks.wvBrief .productBlock .productImg .number {
	background: #262d86;
}

.dctfbody .wvListAnswer.selected {
	background: #262d86;
	border-bottom-color: #262d86;
}

.dctfbody .imageDescription {
	border-color: #262d86;
	color: #262d86;
}

.dctfbody .wvWatbijwatContent .blockGrid .blockSet .arrows .nav {
	background-color: #262d86;
}

.dctfbody .magazineContent.summary {
	background: #262d86;
}

.dctfbody .magazineContent.summary a.paginatePage.active {
	color: #262d86;
}

.dctfbody .score .number {
	color: #262d86;
}

.dctfbody .rating {
	border-color: #262d86;
}

.dctfbody .rating .info {
	color: #262d86;
}

.dctfbody .rating .rating-amount {
	color: #262d86;
}

.dctfbody .required {
	color: #262d86 !important;
}

.dctfbody .profile .packageInfo span,
.dctfbody .content.addModel .smallTitle,
.dctfbody .searchResults .smallTitle {
	color: #262d86;
}

.dctfbody .content .wvSteps .step.active a {
	background-color: #262d86;
}

.dctfbody .content .wvSteps .step.active::after {
	background: #262d86;
}

.dctfbody .content .wvSteps .step.active::before {
	background: #262d86;
}

.dctfbody .content .wvProductBox .wvProduct .wvProductTitle {
	color: #262d86;
}

.dctfbody .content .wvChooseProduct {
	background: #262d86;
}

.dctfbody .content .wvProductSteps .column .wvProductStep .stepContent .wvStepTitle {
	color: #262d86;
}

.dctfbody .content .wvPageBack {
	color: #262d86;
	background-image: url("img/DCTF_arrowLeft.png");
}

.dctfbody .content .wvProductSteps .column .wvProductStep .checkOverlay.selected {
	background-image: url('img/DCTF_checkboxChecked.png');
}

.dctfbody .checkedBoxchecked {
	background-image: url('img/DCTF_checkbox_checked.png') !important;
}

.dctfbody .styledSelect.register {
	background: url(img/dropwDownArrow-dctf.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.dctfbody .styledSelect.register.open {
	background: url(img/dropwDownArrowUp-dctf.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.dctfbody .content .orderBox .orderTable table td {
	color: #262d86;
}

.dctfbody .content .orderBox .orderTable table td.divider {
	background: #262d86;
}

.dctfbody .content .orderBox .orderOptions label.styled {
	color: #262d86;
}

.dctfbody .content .orderBox .orderOptions label.styled a {
	color: #262d86;
}

.dctfbody .content .orderDiscount .inputRow input[type='submit'] {
	background: #262d86;
}

.dctfbody .faqcontent .title span {
	color: #262d86;
}

.dctfbody .faqcontent .faqItem {
	border-color: #262d86;
}

.dctfbody .wvBlanksWrap .selectAnswer .answerOption:hover {
	background: #262d86;
}

.dctfbody .content .rowTrigger .styled p.text {
	color: #262d86;
}

.dctfbody .mainPortal .footer .footer-logo-wrap.DCTF .footer-logo {
	height: 40px;
	margin: 3px 0;
}

.dctfbody .mainPortal .footer .footer-logo-wrap.DCTF {
	margin-right: 0;
	float: none;
}

.dctfbody .mainPortal.login .action .bodyObject .logo {
	background-image: url('img/dctf_base_logo.png');
	width: 275px;
	height: 93px;
	background-size: cover;
	margin-top: 30px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.NFU {
	background-image: url('img/logo-NFU.png');
	width: 40px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.STZ {
	background-image: url('img/logo-STZ.png');
	width: 29px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.NEFARMA {
	background-image: url('img/logo-Nefarma.png');
	width: 85px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.ACRON {
	background-image: url('img/logo-acron.png');
	width: 100px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.NVFG {
	background-image: url('img/logo-NVFG.png');
	width: 63px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.VVN {
	background-image: url('img/logo-VVN.png');
	width: 75px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.ZONMW {
	background-image: url('img/logo-zonmw.png');
	width: 182px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.VWS {
	background-image: url('img/logo-VWS.png');
	width: 100px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.CCMO {
	background-image: url('img/logo-CCMO.png');
	width: 111px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.NVMETC {
	background-image: url('img/logo-nvmetc.png');
	width: 60px;
}

.dctfbody .mainPortal .footer .footer-logo-wrap .footer-logo.EMWO {
	background-image: url('img/logo-emwo.png');
	width: 158px;
}

.dctfbody .bgImage::after {
	background: #E8E8E8;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.dctfbody.login .footerPadding {
		padding-bottom: 126px;
	}
}

.amcbody .register .action .bodyObject .logo {
	background-image: url('img/amc_base_logo.png');
	width: 275px;
	height: 130px;
	background-size: cover;
	margin-top: 30px;
}

.amcbody .register .checklist .item .check {
	background-image: url('img/checklist-check-amc.png');
}

.amcbody.register .content .submitBtn.register {
	background: #AA273B;
}

.amcbody.register .inputRow .information .info-icon {
	border-color: #AA273B;
	background-image: url("img/AMC_information.png");
}

.amcbody .content.profile .profileBlock.notifSettings .information .info-icon {
	border-color: #AA273B;
	background-image: url("img/AMC_information.png")
}

.amcbody.register .styledSelect.register {
	background: url(img/dropwDownArrow-amc.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.amcbody.register .styledSelect.register.open {
	background: url(img/dropwDownArrowUp-amc.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto;
}

.amcbody.register .content table.inputs tr td span {
	color: #AA273B;
}

.amcbody.register .checkedBoxchecked {
	background-image: url('img/AMC_checkbox_checked.png') !important;
}

.amcbody.register .informationMessage {
	background: #AA273B;
	border-color: #AA273B;
}

.amcbody.register .informationMessage:after {
	border-color: #AA273B transparent;
}

.amcbody.register .bgImage::after {
	background: #505050;
	opacity: .5;
}

@media (min-width: 1024px) {
	.amcbody.mainPortal.register .action .bodyObject {
		width: 550px;
		margin-top: 50px;
	}
}

@media (min-width: 768px) {
	.amcbody.mainPortal.register .action .bodyObject .logo {
		width: 315px;
		height: 150px;
	}
}

.nfubody .header-gcp .wrap .logo {
	background-image: url('img/nfu_base_logo.png');
	width: 46px;
	height: 46px
}

.nfubody .baseHeader .logo {
	background-image: url('img/nfu_base_logo.png');
	width: 420px;
	height: 102px
}

.nfubody.mainPortal.login .action .bodyObject .logo {
	background-image: url('img/nfu_base_logo.png');
	width: 290px;
	height: 70px;
	background-size: cover;
	margin-top: 30px
}

.nfubody .header .interactions .item.openSlideMessages,
.nfubody .hasInteractions .interactions .item.openSlideMessages {
	background: #C0133E
}

.nfubody .cta-icon-button {
	background: #C0133E
}

.nfubody .ajax-search .search-button {
	background-color: #C0133E
}

.nfubody .font-blue {
	color: #C0133E
}

.nfubody .blue-button {
	background-color: #C0133E
}

.nfubody .icon.icon-comment {
	background-image: url('img/icon-like-nfu.png')
}

.nfubody .icon.icon-likes {
	background-image: url('img/icon-like-blue-nfu.png')
}

.nfubody .icon.icon-star {
	background-image: url('img/icons/icon-star-nfu.png')
}

.nfubody .icon.icon-star-empty {
	background-image: url('img/icons/icon-star-empty-nfu.png')
}

.nfubody .optionalRibbon {
	background: url('img/overlaydef_blank-nfu.png');
	background-size: cover
}

.nfubody .checklist .item .check {
	background-image: url('img/checklist-check-nfu.png')
}

.nfubody .wvBaseContainer .article.intro .introTitle {
	color: #C0133E
}

.nfubody label.styled [type="checkbox"]:checked+span,
.nfubody label.styled [type="radio"]:checked+span {
	background-image: url('img/NFU_checkbox_checked.png')
}

.nfubody .searchWrap {
	color: #C0133E
}

.nfubody .slideMenu .menuItems .item:not(.msgRow) {
	color: #C0133E
}

.nfubody .slideMenu .menuItems .item.msgRow .msgTitle {
	color: #C0133E
}

.nfubody .filterButtons .filter.active {
	background-color: #C0133E
}

.nfubody .trainingStatus {
	background-color: #C0133E
}

.nfubody .content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
	border-color: #C0133E
}

.nfubody .content table.inputs.lessons.followup tr td .info .text .smallTitle {
	color: #C0133E;
	border-color: #C0133E
}

.nfubody .content table.inputs.lessons.followup tr td .info .text .subTitle {
	color: #C0133E
}

.nfubody .profile .profileBlock .profileBlockTitle,
.nfubody .content.profile .profileBlockTitle {
	color: #C0133E;
	border-color: #C0133E
}

.nfubody .profile .navButtons .navButton {
	background-color: #C0133E
}

.nfubody .profile .fullblock.blue {
	background: #C0133E
}

.nfubody .profileDetails .quickscanProfile .search {
	background-color: #C0133E
}

.nfubody .content table.inputs tr td span {
	color: #C0133E
}

.nfubody .trSim .tagline {
	color: #C0133E
}

.nfubody .userAction .bodyAction .btn,
.nfubody .action .submitBtn,
.nfubody .blue-button-small,
.nfubody .content .orderDiscount .inputRow .checkDiscount,
.nfubody .content .submitBtn.register,
.nfubody .content .submitBtn.activateLicenseBtn,
.nfubody .content .submitBtn.forgotPassword,
.nfubody .content .submitBtn.changePassword {
	background: #C0133E
}

.nfubody .zoomControls .zoomControl {
	background: rgba(192, 19, 62, 0.75)
}

.nfubody .content .submitBtn.changePasswordBtn {
	background: #C0133E;
	border-color: #C0133E
}

.nfubody .informationMessage {
	background: #C0133E;
	border-color: #C0133E
}

.nfubody .informationMessage:after {
	border-color: #C0133E transparent
}

.nfubody .inputRow .information .info-icon {
	border-color: #C0133E;
	background-image: url(img/NFU_information.png)
}

.nfubody .content.profile .profileBlock.notifSettings .information .info-icon {
	border-color: #C0133E;
	background-image: url(img/NFU_information.png)
}

.nfubody .profileDetails .editProfile {
	background: #C0133E
}

.nfubody a.paginatePage.active {
	background: #C0133E
}

.nfubody h1.magazineTitle {
	color: #C0133E;
	border-color: #C0133E
}

.nfubody .subtitleData {
	color: #C0133E
}

.nfubody .subtitleData .data {
	border-color: #C0133E
}

.nfubody .magazineCover .tableContent table tr th {
	color: #C0133E
}

.nfubody .magazineCover .tableContent .indicator.active {
	background: #C0133E
}

.nfubody .magazineCover .tableContent table tr td .status.active {
	color: #C0133E
}

.nfubody .nextLesson h1 {
	color: #C0133E
}

.nfubody .nextLesson .lessonTitle {
	border-color: #C0133E;
	color: #C0133E
}

body:not(.quickscan).nfubody .loader-circle .timer>.slice>.pie {
	border-color: #C0133E
}

.nfubody .werkvormTitle {
	color: #C0133E;
	border-color: #C0133E
}

.nfubody a.werkvormStartButton.completed {
	background: #C0133E
}

.nfubody .wvHeader .wvHeaderTitle {
	color: #C0133E;
	border-color: #C0133E
}

.nfubody .wvBlanksWrap .answer:hover,
.nfubody .wvBlanksWrap .answer.selected {
	background: #C0133E
}

.nfubody .download-overlay .download-icon {
	background-image: url(img/icons/download-big-nfu.png)
}

.nfubody .wvFeedbackTitle {
	color: #C0133E
}

.nfubody a.werkvormDownloadButton .icon.download {
	background-image: url(img/downloadAssetNFU.png)
}

.nfubody .nextPageHeader {
	color: #C0133E
}

.nfubody .nextPageNr {
	background: #C0133E
}

.nfubody .wvStellingAnswer.selected {
	background: #C0133E
}

.nfubody .wvInterviewContent .contentCol .headText {
	color: #C0133E
}

.nfubody .wvInterviewContent .number {
	color: #C0133E
}

.nfubody .wvInterviewContent .question {
	color: #C0133E
}

.nfubody .wvInterviewContent .quote {
	background-color: #C0133E
}

.nfubody .wvProductBlocks .productBlock.firstBlock .title {
	color: #C0133E
}

.nfubody .wvProductBlocks .productBlock .subTitle {
	color: #C0133E
}

.nfubody .wvProductBlocks.wvBrief .productBlock .productImg .number {
	background: #C0133E
}

.nfubody .wvListAnswer.selected {
	background: #C0133E;
	border-bottom-color: #C0133E
}

.nfubody .imageDescription {
	border-color: #C0133E;
	color: #C0133E
}

.nfubody .wvWatbijwatContent .blockGrid .blockSet .arrows .nav {
	background-color: #C0133E
}

.nfubody .magazineContent.summary {
	background: #C0133E
}

.nfubody .magazineContent.summary a.paginatePage.active {
	color: #C0133E
}

.nfubody .score .number {
	color: #C0133E
}

.nfubody .rating {
	border-color: #C0133E
}

.nfubody .rating .info {
	color: #C0133E
}

.nfubody .rating .rating-amount {
	color: #C0133E
}

.nfubody .required {
	color: #C0133E!important
}

.nfubody .profile .packageInfo span,
.nfubody .content.addModel .smallTitle,
.nfubody .searchResults .smallTitle {
	color: #C0133E
}

.nfubody .content .wvSteps .step.active a {
	background-color: #C0133E
}

.nfubody .content .wvSteps .step.active::after {
	background: #C0133E
}

.nfubody .content .wvSteps .step.active::before {
	background: #C0133E
}

.nfubody .content .wvProductBox .wvProduct .wvProductTitle {
	color: #C0133E
}

.nfubody .content .wvChooseProduct {
	background: #C0133E
}

.nfubody .webshop .ajax-search .filter-button {
	background-color: #C0133E;
}

.nfubody .content .openButton {
	color: #C0133E;
	border-color: #C0133E;
}

.nfubody .content .wvProductSteps .column .wvProductStep .stepContent .wvStepTitle {
	color: #C0133E
}

.nfubody .content .wvPageBack {
	color: #C0133E;
	background-image: url(img/NFU_arrowLeft.png)
}

.nfubody .content .wvProductSteps .column .wvProductStep .checkOverlay.selected {
	background-image: url(img/NFU_checkboxChecked.png)
}

.nfubody .checkedBoxchecked {
	background-image: url(img/NFU_checkbox_checked.png)!important
}

.nfubody .styledSelect.register {
	background: url(img/dropwDownArrow-vrn.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto
}

.nfubody .styledSelect.register.open {
	background: url(img/dropwDownArrowUp-vrn.png) no-repeat #fff;
	background-position: 90% 30%;
	background-size: 20px auto
}

.nfubody .content .orderBox .orderTable table td {
	color: #C0133E
}

.nfubody .content .orderBox .orderOptions label.styled {
	color: #C0133E
}

.nfubody .content .orderBox .orderOptions label.styled a {
	color: #C0133E
}

.nfubody .content .orderDiscount .inputRow input[type='submit'] {
	background: #C0133E
}

.nfubody .faqcontent .title span {
	color: #C0133E
}

.nfubody .faqcontent .faqItem {
	border-color: #C0133E
}

.nfubody .wvBlanksWrap .selectAnswer .answerOption:hover {
	background: #C0133E
}

.nfubody .content .rowTrigger .styled p.text {
	color: #C0133E
}

.nfubody .bgImage::after {
	background: #E8E8E8;
	opacity: .5
}

.nfubody .webshop-product-overlay .information .infoRow.mail,
.nfubody .contact.information .infoRow.mail {
	background-image: url(img/mailIcon_NFU.png);
}

.nfubody .webshop-product-overlay .information .infoRow.phone,
.nfubody .contact.information .infoRow.phone {
	background-image: url(img/phoneIcon_NFU.png);
}

.nfubody.toetsBody .werkvormPopup.toetsCover .werkvormBackground {
	background: #C0133E;
}

.nfubody.toetsBody .wvHeader .wvHeaderTitle {
	color: #fff;
	border-color: #fff;
}

.nfubody.toetsBody .toetsContent.cover .intro p {
	color: #fff;
}

.nfubody.toetsBody .toetsWerkvorm .navContainer .quizNav {
	background: #C0133E;
}

.nfubody.toetsBody .wvHotspotContent .answer.userAnswer {
	background: rgba(192, 19, 62, 0.75);
}

.nfubody .wvVolgordeList .arrow.up,
.nfubody .wvVolgordeList .arrow.down {
	background-color: #C0133E
}

.nfubody .nav-item.active {
	background-color: #C0133E
}

.nfubody.mainPortal .userAction .topAction .logo.mygcp-logo {
	width: 338px;
	height: 67px;
	background-image: url('img/nfu_homepage_logo.png');
}

@media (min-width: 768px) {
	.nfubody.mainPortal.login .action .bodyObject .logo {
		width: 350px;
		height: 85px
	}
	.nfubody.mainPortal .userAction .topAction .logo.mygcp-logo {
		width: 290px;
		height: 58px;
	}
}
