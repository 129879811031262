@media screen and (min-width: 768px) {
	.content .intro.smaller {
		max-width: 407px;
	}
	.blocks-list.ajax-search-result {
		max-width: 432px;
	}
	a.blocks-list-item {
		width: 432px;
		display: inline-block;
	}
	.blocks-list-item:nth-child(2n+2) {
		margin-left: 0px;
	}
	.backgroundImage {
		height: 300px
	}
	.magazineContent {
		margin-top: 200px
	}
	.contentWrap {
		padding: 0;
		width: 596px;
		margin: 0 auto
	}
	.contentTitle {
		font-size: 68px
	}
	.contentText {
		font-size: 14px
	}
	.contentText strong,
	.contentSubTitle {
		font-size: 19px
	}
	.doubleImage {
		font-size: 0
	}
	.doubleImage .imageWrap {
		width: 50%;
		display: inline-block
	}
	.doubleImage .imageWrap.first {
		padding-right: 8px
	}
	.doubleImage .imageWrap.second {
		padding-left: 8px
	}
	.imageDescription {
		margin-bottom: 28px;
		font-size: 14px;
	}
	.paginate {
		height: 102px
	}
	.paginateLeft,
	.paginateRight {
		display: block
	}
	a.paginatePage {
		font-size: 16px;
		line-height: 50px;
		height: 50px
	}
	a.paginatePage.active {
		font-size: 24px;
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
	.paginateFade {
		background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%)
	}
	.filmscene .paginateFade {
		background: linear-gradient(to right, rgba(236, 230, 220, 1) 0%, rgba(236, 230, 220, 1) 10%, rgba(236, 230, 220, 0) 40%, rgba(236, 230, 220, 0) 60%, rgba(236, 230, 220, 1) 90%, rgba(236, 230, 220, 1) 100%)
	}
	.werkvormStartImage {
		height: 445px
	}
	.werkvormStart {
		height: 445px;
		margin-bottom: 58px
	}
	.werkvormType {
		font-size: 30px
	}
	.werkvormTitle {
		font-size: 42px
	}
	a.werkvormStartButton,
	a.werkvormDownloadButton {
		font-size: 32px;
		line-height: 115px;
		width: 115px;
		height: 115px
	}
	a.werkvormStartButton.completed .icon.completed,
	a.werkvormDownloadButton .icon.download {
		width: 59px;
		height: 48px;
	}

	.trainingBlocks .programBlock .werkvormStartButton.werkvormStart .icon.locked{
		width: 53px;
		height: 64px;
		background-size: contain;
		margin: 20px 0px 0px 2px;
	}

	.wvHeaderBack {
		width: 55px;
		height: 55px;
		position: static;
		margin-bottom: 10px
	}
	.wvHeaderBack span {
		background-image: url(img/closeWVmid.png);
		background-size: 55px 55px !important;
		width: 55px !important;
		height: 55px !important;
		margin: 0
	}
	.wvHeaderBack.white span {
		background-size: 23px 23px !important;
	}
	.nextPageHeader {
		font-size: 30px;
	}
	a.nextPageLink {
		padding: 20px 20px 28px 20px;
		width: 435px;
		margin: 0 auto
	}
	.nextPageNr {
		font-size: 15px;
		line-height: 30px;
		width: 30px;
		height: 30px
	}
	.nextPageTitle {
		font-size: 22px
	}
	.werkvormWrap {
		margin-top: 28px;
		width: 472px;
		margin-left: auto;
		margin-right: auto
	}
	.werkvormWrap.maxWidth {
		width: 100%;
	}
	.werkvormWrap.scorePaginaToets {
		width: calc(100% - 26px);
	}
	.wvListContainer {
		padding: 0
	}
	.wvListContainer .intro,
	.wvWatbijwatContent .intro {
		font-size: 25px;
		margin-bottom: 30px
	}
	.wvListContainer .introSub {
		font-size: 18px;
		margin-bottom: 40px
	}
	a.wvListText {
		font-size: 19px;
		padding: 15px 45px 15px 45px;
	}
	.wvCheckButtonWrap {
		width: 130px;
	}
	a.wvCheckButton {
		width: 130px;
		height: 130px;
		line-height: 20px;
		font-size: 28px
	}
	.wvFeedbackContent {
		width: 472px;
		margin-left: auto;
		margin-right: auto
	}
	.wvListAnswers {
		margin-bottom: 50px
	}
	.wvFeedbackTitle {
		font-size: 40px
	}
	.wvFeedbackText {
		font-size: 20px;
		padding-left: 0;
		padding-right: 0
	}
	.werkvormWrap.stellingWrap {
		width: 596px;
	}
	.wvStellingIntro .intro {
		font-size: 24px;
		width: 470px;
	}
	.wvStellingWrap {
		width: 480px;
		margin: 0 auto;
		margin-bottom: 42px;
	}
	.wvStellingAnswer {
		font-size: 28px;
		width: 227px;
		line-height: 76px;
	}
	.wvStellingAnswer:first-child {
		margin-right: 10px;
	}
	.wvBerichtContent {
		max-width: none;
		margin: auto;
	}
	.wvBerichtContent .chatLeft {
		background-size: 20px 34px;
		width: 20px;
		height: 34px;
		top: 30px;
		left: -19px;
	}
	.wvBerichtContent .chatRight {
		background-size: 20px 34px;
		width: 20px;
		height: 34px;
		top: 26px;
		right: -19px;
	}
	.wvBerichtContent .row {
		width: 457px;
		margin-bottom: 16px;
	}
	.wvBerichtContent .studentInfo {
		color: #1e1e1e;
		top: 6px;
		left: 10px;
		width: 435px;
		padding-right: 10px;
	}
	.wvBerichtContent .studentInfo p {
		padding: 0 35px 10px 0px;
		line-height: 28px;
		font-size: 18px;
	}
	.wvBerichtContent .row .rowCol .chatImage img {
		margin-bottom: 12px;
	}
	.wvBerichtContent .row .rowCol .chatImage {
		display: block;
		float: left;
		padding: 2px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding-left: 1px;
		padding-right: 3px;
	}
	.wvBerichtContent .image_placeholder {
		display: none;
	}
	.wvBerichtContent .row .rowCol>img {
		width: 70px;
		height: 70px;
		position: relative;
		margin-left: 5px;
		margin-top: 4px;
	}
	.wvBerichtContent .row.hasChatImage .imageMessage img {
		width: 70px;
		height: 70px;
		position: relative;
	}
	.wvBerichtContent .intro {
		font-size: 26px;
		line-height: 30px;
	}
	.wvBerichtContent .studentTitle,
	.wvBerichtContent .studentSubtitle {
		font-size: 16px;
	}
	.wvBerichtContent .studentInfo p {
		font-size: 18px;
	}
	.wvBerichtContent .studentTitle {
		width: 410px;
	}
	.wvBerichtContent .studentInfo {
		padding: 0px;
	}
	.wvVolgordeIntro .intro {
		width: 465px;
		margin: 33px auto 25px;
		margin-bottom: 36px;
		line-height: 30px;
		font-size: 24px;
	}
	.wvVolgordeList .arrow {
		top: 22px;
	}
	.wvVolgordeList .arrow.up {
		right: 15px;
	}
	.wvVolgordeList .arrow.down {
		left: 15px;
	}
	.wvVolgordeList {
		width: 472px;
	}
	.wvVolgordeList .answer {
		margin: 0 auto 13px;
		height: 58px;
	}
	.wvVolgordeList .answerText {
		width: 350px;
		font-size: 18px;
		margin-top: 2px;
	}
	.wvFeedbackOrder {
		width: 275px;
		margin-bottom: 35px;
		line-height: 58px;
		height: auto;
	}
	.wvStappenplan .productBlock .titleGroup {
		text-align: left;
	}
	.wvStappenplan .productBlock .title {
		font-size: 25px;
	}
	.wvProductBlocks .productBlock.left .textCol .colContent,
	.wvProductBlocks .productBlock.left .col {
		float: right;
	}
	.wvProductBlocks .title {
		top: 13px;
	}
	.wvProductBlocks .productBlock .productImg {
		width: 347px;
		height: 347px;
		max-width: 347px;
		max-height: 347px;
		margin: 0 auto;
	}
	.wvProductBlocks .productBlock.firstBlock .productImg {
		width: 347px;
		height: 347px;
	}
	.wvProductBlocks .productBlock.firstBlock .title {
		font-size: 55px;
		width: 350px;
	}
	.wvProductBlocks .productBlock .innerBlock {
		max-width: 320px;
		padding-bottom: 23px;
		padding-top: 22px;
	}
	.wvProductBlocks .productBlock .col {
		width: 50%;
		float: left;
		padding: 20px 20px 20px 20px;
	}
	.wvProductBlocks .productBlock .subTitle {
		margin-bottom: 20px;
		font-size: 12px;
	}
	.wvProductBlocks .productBlock.firstBlock .subTitle {
		width: 292px;
		margin: auto;
	}
	.wvProductBlocks .productBlock .subTitle {
		width: auto;
		margin: auto;
		font-size: 25px;
	}
	.wvProductBlocks .text p {
		width: 295px;
		margin: 16px auto 5px;
		font-size: 16px;
	}
	.wvProductBlocks .productBlock.firstBlock .text p {
		margin: 18px auto 0;
	}
	.wvProductBlocks .productBlock.firstBlock .col {
		width: 100%;
	}
	.wvProductBlocks .productBlock.firstBlock .innerBlock {
		max-width: 512px;
		margin-top: 0;
		padding: 0;
	}
	.wvProductBlocks .productBlock .innerBlock {
		max-width: 780px;
		padding-bottom: 44px;
	}
	.wvProductBlocks .productBlock:not(.firstBlock) .text p {
		width: 350px;
	}
	.wvProductBlocks .productBlock .productImg img {
		max-height: 346px;
		max-width: initial;
	}
	.wvProductBlocks .productBlock .text p {
		max-width: 450px;
		width: auto;
		font-size: 18px;
		line-height: 30px;
	}
	.wvProductBlocks .productBlock .text ul li {
		font-size: 18px;
		line-height: 30px;
	}
	.wvInterviewContent {
		top: -260px;
		width: auto;
		max-width: 768px;
		margin-top: -80px;
		margin-left: -384px;
		left: 50%;
	}
	.wvInterviewContent .contentCol {
		width: 100%;
		margin-top: -135px;
	}
	.wvInterviewContent .answer {
		padding: 42px 85px;
	}
	.wvInterviewContent .question {
		margin: 0 100px;
	}
	.wvInterviewContent .contentCol .intro {
		line-height: 30px;
		font-size: 20px;
		padding: 60px 85px 45px;
	}
	.werkvormWrap.wider {
		width: 600px;
	}
	.wvWatbijwatContent {
		margin-bottom: 36px;
	}
	.wvWatbijwatContent .blockGrid .blockSet {
		display: inline-block;
		margin: 0px;
		margin-left: -10px;
		width: 210px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .item {
		display: block;
		margin: 10px;
		height: 189px;
		width: 189px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .ghost {
		top: 152px;
		left: 0;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows {
		width: 175px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows.top {
		margin-top: 5px;
		left: 7px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.left {
		display: block;
		margin: 4px 0px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.right {
		display: block;
		margin: 4px 0px;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.top {
		display: none;
	}
	.wvWatbijwatContent .blockGrid .blockSet .arrows .nav.bottom {
		display: none;
	}
	.wvWatbijwatContent .blockGrid .blockSet .text .vcenter div.itemText {
		padding-top: 30px;
		font-size: 20px;
		width: 189px;
		height: 189px;
	}
	.wvHotspotContent {
		width: auto;
	}
	.wvHotspotContent .hotspotImgContainer {
		margin-bottom: 42px;
	}
	.baseHeader .tagline {
		font-size: 27px
	}
	.baseHeader .logo {
		height: 121px;
		width: 216px;
		margin-top: 0;
	}
	.content .intro .introTitle {
		font-size: 20px;
	}
	.content .intro {
		max-width: 450px;
		padding: 8px;
		margin: 0 auto;
	}
	.header-gcp .nav .icon,
	.header .nav .icon,
	.header .interactions .item,
	.hasInteractions .interactions .item {
		margin-right: 10px;
		margin-top: 8px;
	}
	.header-gcp .interactions .langpicker,
	.webshop-overlay .inner .overlay-list .langpicker {
		margin-top: 10px;
	}
	.header-gcp.hasInteractions .interactions .item.hasNew.openSlideMessages {
		margin-top: 10px;
	}
	.header-gcp.hasInteractions .interactions .item {
		margin-top: 8px;
	}
	.header .nav .icon:last-child,
	.header .interactions .item:last-child,
	.hasInteractions .interactions .item:last-child {
		margin-right: 10px;
	}
	.header-gcp .interactions .langpicker .lang {
		display: inline-block;
		width: 43px;
		height: 32px;
		margin: 0 3px;
	}
	.bgBar .infoText {
		width: 570px;
	}
	.bgBar {
		height: 365px;
		display: table;
		margin-top: 24px
	}
	.bgBar .infoBar {
		font-family: $mainBoldFont;
		font-size: 17px;
	}
	.bgBar .infoTitle {
		font-family: $mainLightFont;
		font-size: 72px;
	}
	.bgBar .infoText {
		width: 100%;
	}
	.icon.changelog {
		width: 56px;
		height: 56px;
		margin: 15px 0
	}
	.changelog .row {
		min-height: 75px
	}
	.changelog .row .inner {
		padding: 15px 0;
	}
	.changelog .row .item {
		display: inline-block;
		font-size: 12px
	}
	.changelog .row .small {
		width: 85px
	}
	.state-button {
		width: 85px;
		height: 35px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		padding: 8px 0;
		font-size: 15px;
		margin-top: 7px;
	}
	.changelog .date-notation {
		font-size: 14px;
	}
	.changelog .row .large {
		width: 385px;
		position: relative;
		margin: 0 20px;
		left: 0;
		top: 0;
	}
	.changelog .collapsed .collapse-content {
		height: 45px;
	}
	.changelog .title {
		font-size: 12px;
	}
	.changelog .row .large.read-more {
		display: none;
	}
	.changelog .row .buttonwrap {
		padding-top: 20px;
		cursor: pointer;
		position: relative;
		width: 85px;
		top: 0;
		left: 0;
	}
	.changelog .collapse-button {
		display: block;
	}
	.changelog .row .read-more {
		display: none;
	}
	.changelog .text {
		padding-right: 20px;
	}
	.changelog .row .small-items {
		display: inline-block;
	}
	.print-button {
		display: block;
	}
	.header-gcp .interactions {
		top: 12px;
	}
	.content table.inputs.lessons tr td {
		width: 619px;
	}
	.content table.inputs.lessons tr {
		height: 100px;
	}
	.content table.inputs.lessons tr td .progress {
		left: 21px;
	}
	.content table.inputs.lessons tr td .progress .progressbar {
		width: 577px
	}
	.content .indicator {
		line-height: 50px;
		font-size: 25px !important;
		height: 51px;
		width: 51px;
	}
	.content table.inputs.lessons tr td.started .info .indicator {
		margin: 5px;
		margin-left: 20px;
		margin-top: 5px;
	}
	.content table.inputs.lessons tr td .info {
		width: 100%;
	}
	.content table.inputs.lessons tr td .info .date {
		font-size: 25px;
		top: 23px;
	}
	.content table.inputs.lessons tr td.started .info .date {
		top: 18px;
	}
	.content table.inputs.lessons tr td .info .indicator {
		margin-left: 20px;
		margin-top: 11px;
	}
	.content .title {
		height: 120px;
		line-height: 125px;
	}
	.content .title span {
		font-size: 70px;
	}
	.content .intro {
		box-sizing: initial;
		width: 475px;
	}
	.content .intro .introText {
		font-size: 16px;
		margin-bottom: 22px;
		line-height: 20px;
	}
	.submenu-spacer {
		height: 50px;
		margin-top: 18px;
		display: block;
	}
	.submenu {
		height: 50px;
		margin-top: 18px;
	}
	.submenu .item {
		font-size: 17px;
		margin-bottom: 20px;
	}
	.wvIntro {
		font-size: 40px;
		max-width: 380px;
		margin: 35px auto;
	}
	.wvInspiratieContent {
		max-width: 768px;
		margin: 0 auto;
	}
	.wvInspiratieContent .inspiratiePicture {
		height: 768px;
		width: 768px;
	}
	.wvBaseContainer .content table.inputs.followup {
		width: 700px;
		border-spacing: 0 0
	}
	.content .subTitleContent .date {
		font-size: 20px;
	}
	.content table.inputs.lessons.followup tr {
		float: left;
		height: 221px;
		margin: 5px
	}
	.content table.inputs.lessons.followup tr td {
		width: 221px;
		height: 221px
	}
	.content table.inputs.lessons.followup tr td .info {
		width: 100%;
		height: 97px;
		bottom: 10px;
	}
	.content table.inputs.lessons.followup tr td .info .text b {
		display: inline-block
	}
	.content table.inputs.lessons.followup tr td .image,
	.content table.inputs.lessons.followup tr td.fullWidth .status {
		width: 100%;
		height: 123px
	}
	.content table.inputs.lessons.followup tr td.fullWidth .info {
		top: auto;
		width: 175px;
		padding: 0;
		left: 0;
		right: 0;
		margin: auto
	}
	.content table.inputs.lessons.followup tr td .image .imgOverlay .completed,
	.content table.inputs.lessons.followup tr td.fullWidth .status .complete,
	.content table.inputs.lessons.followup tr td.fullWidth .status .incomplete,
	.content table.inputs.lessons.followup tr td.fullWidth .status .bookUpload {
		width: 58px;
		height: 58px
	}
	.content table.inputs.lessons.followup tr td .info .text {
		margin-top: 12px;
	}
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info .text h3,
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info .text {
		margin-bottom: 5px;
		margin-top: 3px;
		font-size: 17px;
		line-height: 16px
	}
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info .text h3 {
		font-size: 20px !important;
	}
	.content table.inputs.lessons.followup tr td:not(.fullWidth) .info {
		top: auto;
		bottom: 0;
		padding: 0;
		position: relative;
		margin: auto;
	}
	.content .subTitleContent .date {
		font-size: 20px
	}
	.content table.inputs.lessons.followup .loader-circle .timer {
		font-size: 50px;
		width: 50px;
		height: 50px;
	}
	.content table.inputs.lessons.followup .loader-circle .timer>.slice {
		width: 50px;
		height: 50px;
		clip: rect(0, 50px, 50px, 25px)
	}
	.content table.inputs.lessons.followup .loader-circle .timer>.slice>.pie {
		border-width: 4px;
		width: 42px;
		height: 42px;
		clip: rect(0, 25px, 50px, 0);
	}
	.content table.inputs.lessons.followup .loader-circle .timer.fill>.slice>.pie {
		width: 50px;
		height: 50px
	}
	.content table.inputs.lessons.followup .loader-circle {
		width: 50px;
		height: 49px;
		border-width: 2px;
	}
	.content table.inputs.lessons.followup .loader-circle .inner {
		width: 42px;
		height: 42px;
		line-height: 90px;
		border: 4px solid rgba(255, 255, 255, .3);
	}
	.content table.inputs.lessons.followup .loader-circle .inner .text {
		font-size: 11px;
		letter-spacing: 1px
	}
	.content .orderDiscount .inputRow input[type='submit'] {
		font-size: 17px;
		height: 58px;
		width: 65px;
	}
	.infoWrap {
		width: 100%;
		height: 98px;
		position: relative;
		margin-top: 123px;
	}
	.magazineContent.magazineCover {
		width: 388px;
		margin-top: 0;
		margin-right: auto;
		position: relative;
		float: left
	}
	.backgroundImage.magazineCover {
		height: auto;
		bottom: 0;
		left: 388px;
		overflow: hidden;
		position: fixed;
		width: auto;
	}
	.magazineCover .contentWrap {
		width: 309px;
	}
	.magazineCover .contentWrap .magazine h1 {
		font-size: 25px;
	}
	.magazineCover .contentWrap .subTitle {
		font-size: 50px;
		line-height: 50px;
		width: auto;
	}
	.magazineCover .contentWrap .contentText {
		font-size: 15px;
	}
	.magazineCover .contentWrap .contentText ul li {
		font-size: 15px;
		line-height: 30px;
	}
	.contentText ul,
	.contentText ol {
		margin-bottom: 2em;
		margin-left: 18px;
	}
	.magazineCover .contentText ul,
	.magazineCover .contentText ol {
		margin-left: 16px;
	}
	.magazineCover .tableContent table tr td {
		padding: 5px 40px;
	}
	.magazineCover .nextLesson {
		width: 310px;
	}
	.magazineCover .nextLesson .nextLessonImg img {
		width: 90px;
		height: 90px;
	}
	.magazineCover .nextLesson .lessonText {
		height: 90px;
		width: 220px;
		left: 90px;
	}
	.magazineCover .nextLesson .nextLessonImg {
		display: inline-block;
		position: absolute;
		left: 0;
		height: 90px;
		width: 90px;
	}
	.magazineCover .nextLesson .subTitle {
		font-size: 14px;
	}
	.magazineCover .nextLesson .lessonTitle {
		font-size: 15px;
	}
	.magazineCover .nextLesson .lessonInfo {
		font-size: 15px;
	}
	.magazineCover .nextLesson h1 {
		font-size: 26px;
	}
	.filmles.backgroundImage {
		position: relative;
		width: 100%!important;
		height: 100%;
		overflow: hidden;
		top: auto;
		left: auto;
		right: auto
	}
	.filmles.magazineContent {
		position: relative;
		top: auto;
		width: 100%;
		padding-top: 20px;
		margin-top: -170px;
		background-color: #fff
	}
	.filmles.magazineContent .magazine,
	.filmles.backgroundImage .magazine {
		margin-top: 0;
		width: 100%;
		display: inline-block;
	}
	.filmles.magazineContent .magazine h1,
	.filmles.backgroundImage .magazine h1 {
		font-size: 30px !important;
		color: #a08250
	}
	.filmles.magazineContent .magazine {
		margin-bottom: 20px
	}
	.filmles.backgroundImage .image-text {
		margin-top: -200px;
		display: block;
		position: relative;
		top: auto;
	}
	.filmles.backgroundImage .image-text .subTitle {
		color: #1e1e1e;
		width: 613px;
		font-size: 50px;
		line-height: 50px;
	}
	.filmles.backgroundImage .loader-circle {
		position: relative;
		bottom: auto;
		top: 20px;
	}
	.filmles.magazineContent .circles-link {
		position: absolute;
		top: -90px;
		margin-left: 35px
	}
	.filmles.magazineContent .circles-link .circle {
		height: 120px;
		width: 120px
	}
	.filmles.magazineContent .circles-link .link {
		display: block;
		text-align: center;
		font-size: 15px;
		margin-top: 8px
	}
	.filmles.magazineContent .circles-link .link span {
		vertical-align: middle;
		letter-spacing: 1px
	}
	.filmles.magazineContent .circles-link .circle.circle-left {
		width: 100px;
		height: 100px;
		margin-top: 10px
	}
	.filmles.magazineContent .contentWrap {
		width: auto;
	}
	.filmles.magazineContent .sceneList {
		width: 725px;
		margin: 0 auto
	}
	.filmles.magazineContent .sceneList .scene .time {
		padding: 2px 11px;
		font-size: 14px
	}
	.filmles.magazineContent .sceneList .scene {
		width: 221px;
		height: 248px;
		display: inline-block;
		margin: 9px
	}
	.filmles.magazineContent .sceneList .scene .scene-image {
		height: 124px;
		width: 100%;
		top: 0;
		float: none;
	}
	.filmles.magazineContent .sceneList .scene .scene-info {
		position: absolute;
		height: 124px;
		width: 100%;
		bottom: 0
	}
	.filmles.magazineContent .sceneList .scene .description {
		padding: 5px 17px 17px
	}
	.filmles.magazineContent .sceneList .scene .number {
		margin-top: 15px
	}
	.filmles .nextLesson.cover h1 {
		margin-bottom: 25px;
		font-size: 30px
	}
	.filmles .nextLesson.cover .lessonInfo {
		height: 126px
	}
	.filmles .nextLesson.cover .lessonInfo .nextLessonImg {
		height: 126px;
		width: 126px;
	}
	.filmles .nextLesson.cover .lessonInfo .nextLessonImg img {
		height: 126px;
		width: 126px
	}
	.filmles .nextLesson.cover .lessonInfo .lessonText {
		height: 126px;
		width: 308px;
		left: 126px
	}
	.filmles .nextLesson.cover .lessonInfo .lessonText .lessonTitle {
		font-size: 21px
	}
	.filmles .nextLesson.cover .lessonInfo .lessonText .lessonDesc {
		font-size: 22px
	}
	.filmles .nextLesson.cover {
		width: 434px
	}
	.filmscene .scene-header .header-wrap {
		width: 90%;
		margin: 0 auto;
		position: relative
	}
	.filmscene .scene-header .header-wrap .videoContainer.content-video {
		height: 403px
	}
	.filmscene .contentWrap .videoContainer.content-video {
		width: auto;
		height: 470px
	}
	.filmscene .sceneListFooter {
		width: 384px;
		margin-bottom: 75px
	}
	.filmscene .sceneListFooter .scene {
		height: 107px
	}
	.filmscene .sceneListFooter .scene .scene-image {
		width: 147px
	}
	.filmscene .sceneListFooter .scene .scene-info {
		width: 237px;
		font-size: 18px
	}
	.filmscene .sceneListFooter .scene .number {
		width: 28px;
		height: 28px;
		line-height: 28px;
		font-size: 14px
	}
	.filmscene .sceneListFooter .scene .time {
		padding: 2px 11px;
		font-size: 14px
	}
	.filmscene .scene-header .header-wrap {
		width: 718px;
		padding: 200px 0 50px;
	}
	.filmscene .contentWrap .videoContainer.content-video,
	.container.filmscene .scene-header .header-wrap .videoContainer.content-video {
		height: 403px
	}
	.filmles.filmscene.magazineContent .contentWrap {
		width: 612px
	}
	.filmscene .scene-header .subtitle {
		width: 560px;
		font-size: 30px;
		line-height: 30px;
		margin-bottom: 30px
	}
	.filmscene .contentTitle {
		font-size: 50px;
		line-height: 50px;
		letter-spacing: 4px
	}
	.filmscene .scene-header .title {
		line-height: 60px;
		font-size: 60px
	}
	.filmscene .tip.title {
		font-size: 19px
	}
	.filmscene .tip {
		font-size: 18px;
		line-height: 30px
	}
	.filmscene .contentWrap .videoContainer.content-video {
		height: 335px
	}
	.filmscene .contentWrap .cta {
		height: 445px
	}
	.filmscene .contentWrap .cta .cta-title h1 {
		font-size: 50px;
		margin-top: 27%
	}
	.filmscene .contentWrap .cta .cta-button {
		width: 116px;
		height: 116px
	}
	.filmscene .contentWrap .cta .cta-button span {
		font-size: 25px;
		line-height: 116px
	}
	.filmscene .contentWrap .title-text {
		font-size: 30px
	}
	.filmscene .contentWrap .werkvormStartImage {
		width: 612px;
		left: auto;
		margin: 0 auto;
	}
	.videoContainer .overlay .buttons .sideButton {
		margin: 15px 20px;
	}
	.videoContainer .overlay .filmContent {
		width: 100%;
	}
	.videoContainer .overlay .filmContent .title {
		font-size: 30px;
	}
	.videoContainer .overlay .filmContent .subTitle {
		font-size: 40px;
		width: 80%;
		margin-bottom: 35px;
		line-height: 40px;
	}
	.videoContainer .overlay .filmContent .wrap {
		height: 315px;
	}
	.videoContainer .overlay .filmFooter {
		width: 100%;
	}
	.videoContainer .overlay .buttons .button.play .icon {
		top: 45px;
		left: 40%;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 30px solid rgba(0, 0, 0, 0.9);
	}
	.videoContainer .overlay .buttons .button {
		width: 130px;
		height: 130px;
	}
	.videoContainer .overlay .buttons .sideButton .inner {
		height: 100px;
		width: 100px;
	}
	.videoContainer .overlay .buttons .sideButton {
		height: 100px;
		width: 100px;
		line-height: 25px;
		font-size: 15px;
	}
	.videoContainer .nextLesson h1 {
		margin-top: 25px;
		margin-bottom: 25px;
		padding: 0;
	}
	.videoContainer .nextLesson {
		width: 386px;
	}
	.videoContainer .nextLesson .img,
	.videoContainer .nextLessonImg {
		width: 147px;
		height: 107px;
	}
	.videoContainer .lessonText {
		height: 107px;
		width: 237px;
		left: 147px;
	}
	.lessonNumber {
		line-height: 36px;
		height: 34px;
		width: 34px;
		margin: 15px auto 5px;
		font-size: 19px;
	}
	.videoContainer .lessonDesc {
		font-size: 18px !important
	}
	.videoContainer .overlay .filmContent {
		height: 75%;
	}
	.videoContainer .overlay .filmFooter {
		height: 25%;
	}
	.videoContainer .finished .buttons .sideButton .inner {
		height: 150px;
		width: 150px;
		font-size: 25px;
	}
	.videoContainer .finished .buttons .sideButton {
		height: 150px;
		width: 150px;
	}
	.wvAdviceContent {
		margin-top: 163px;
		width: 600px
	}
	.wvAdviceContent .row {
		margin-bottom: 19px;
		width: 452px;
		min-height: 80px
	}
	.wvAdviceContent .row .img {
		width: 72px;
		height: 72px;
		float: left;
		margin-top: 4px;
		margin-left: 15px
	}
	.wvAdviceContent .studentInfo {
		font-size: 18px;
		padding-right: 15px
	}
	.wvAdviceContent .row.right {
		margin-left: 130px
	}
	.wvAdviceContent .row.rowContent {
		width: 458px;
		margin: 0 0 0 -10px;
	}
	.wvAdviceContent .row.rowContent .alignCell {
		font-size: 18px;
	}
	.wvAdviceContent .row {
		width: 460px;
		margin-bottom: 20px;
		min-height: 81px;
		padding: 10px;
	}
	.wvAdviceContent .row.right {
		margin-left: 130px;
	}
	.wvAdviceContent .studentInfo {
		left: 10px;
		width: 420px;
		line-height: 27px
	}
	.wvAdviceContent .studentInfo .subtitle {
		top: 0px;
		font-size: 25px
	}
	.wvAdviceContent .row .image_placeholder {
		width: 60px;
		height: 75px;
	}
	.wvAdviceContent .row.question,
	.adviceContent .row.rowContent {
		width: 472px;
	}
	.magazineContent.summary {
		margin-top: 200px;
		width: 100%;
	}
	.magazineContent.summary .contentWrap {
		width: 768px;
		padding: 25px 87px 40px;
	}
	.content.podium .title {
		height: 135px;
	}
	.content.podium .contentTabs .tab {
		font-size: 15px;
		height: 45px;
		width: 140px;
	}
	.content.podium .tabContent .modelTiles {
		width: 710px;
	}
	.content.podium .tabContent .modelTiles .tile {
		width: 136px;
		height: 136px;
		margin: 3px;
	}
	.content.podium .tabContent .modelTiles .tile.add {
		background-size: 110px 110px;
	}
	.content.podium .tabContent .modelTiles.big .tile {
		width: 230px;
		height: 230px;
	}
	.content.podium .tabContent .modelTiles .tile .favorites span {
		font-size: 14px;
	}
	.content.podium .tabContent .modelTiles .tile .favorites .icon {
		vertical-align: middle;
		height: 11px;
		width: 12px;
	}
	.content .inputs.favorites {
		width: 310px;
		border-spacing: 0;
		float: left;
		margin: 0 5px;
	}
	.content .inputs.lessons.favorites .inputRow {
		width: 310px;
		margin: 5px 0px;
		display: inline-block
	}
	.content .favoritesCollection {
		width: 640px;
		margin: 0 auto;
	}
	.content .inputs.favorites .inputRow .info {
		padding: 0;
		margin: 0 8px;
		height: 91px;
		width: 195px;
		display: table;
	}
	.content .inputs.favorites .inputRow .info .text .smallBody {
		font-size: 16px;
	}
	.content .inputs.favorites .inputRow .info .text .smallTitle {
		font-size: 15px;
	}
	.content .inputs.favorites .inputRow .image {
		width: 90px;
		height: 90px;
	}
	.content .inputs.favorites .inputRow .image .imgOverlay .number {
		width: 30px;
		height: 30px;
		font-size: 18px;
	}
	.content .inputs.favorites .inputRow .dropdown {
		margin-top: -91px;
	}
	.content .inputs.favorites .inputRow .dropdown .removeAll {
		height: 91px;
	}
	.content .inputs.favorites .inputRow .dropdown .removeAll p {
		margin: 37px 0;
	}
	.content .inputs.favorites .inputRow .dropdown .removeAll .icon {
		margin: 32px 14px;
	}
	.content.progress .loader-circle .timer {
		font-size: 152px;
		width: 152px;
		height: 152px;
	}
	.content.progress .loader-circle .timer>.slice {
		width: 152px;
		height: 152px;
		clip: rect(0, 152px, 152px, 76px)
	}
	.content.progress .loader-circle .timer>.slice>.pie {
		border: 6px solid #3cc0c1;
		width: 140px;
		height: 140px;
		clip: rect(0, 76px, 152px, 0);
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%
	}
	.content.progress .loader-circle .timer>.slice>.pie.fill {
		-moz-transform: rotate(180deg)!important;
		-webkit-transform: rotate(180deg)!important;
		-o-transform: rotate(180deg)!important;
		transform: rotate(180deg)!important
	}
	.content.progress .loader-circle .timer.fill>.slice>.pie {
		width: 152px;
		height: 152px
	}
	.content.progress .loader-circle {
		width: 152px;
		height: 152px;
		border: 3px solid $darkColor;
	}
	.content.progress .loader-circle .inner {
		width: 140px;
		height: 140px;
		line-height: 90px;
		border: 6px solid rgba(255, 255, 255, .3);
	}
	.content.progress .tiles {
		width: 648px
	}
	.content.progress .tiles tr {
		margin: 15px 30px;
		max-width: 156px
	}
	.content.progress .tiles .tile.complete .progress .complete {
		right: 2px;
		top: 10px
	}
	.content.progress .tiles tr:nth-child(2n+3) {
		clear: none
	}
	.content.progress .tiles tr:nth-child(3n+4) {
		clear: both
	}
	.content.subjects .title {
		height: 70px;
		line-height: 75px;
		margin-top: 25px
	}
	.content.subjects .title span {
		font-size: 60px
	}
	.content.subjects .intro .introText {
		text-align: center;
		margin: 0 auto
	}
	.content.subjects table.inputs.lessons {
		width: 614px;
		border-spacing: 0 5px
	}
	.content.subjects table.inputs.lessons tr {
		height: 70px;
		float: left;
		margin: 5px;
		margin-bottom: 7px
	}
	.content.subjects table.inputs.lessons tr td {
		height: 70px;
		width: 295px;
		display: block
	}
	.content.subjects>.progress {
		margin-top: 35px
	}
	.content.subject .title span {
		font-size: 60px;
	}
	.content.subject .subTitleContent .date {
		font-size: 30px;
	}
	.content.subject .title.hasSub {
		height: auto;
		line-height: 70px;
	}
	.content.subject .intro {
		width: 471px;
		max-width: 471px;
	}
	.content.subject .submitBtn.minigame {
		margin-top: 0;
	}
	.content.subject .subTitleContent .progressLoader .inner,
	.content.subject .subTitleContent .progressLoader .timer>.slice>.pie {
		border-width: 3px;
	}
	.content.subject table.inputs.lessons.followup tr td .info {
		width: 175px;
		left: 23px;
		margin: 0;
	}
	.content.subject table.inputs.lessons.followup tr td:not(.fullWidth) .info {
		width: 200px;
		left: 0;
		margin: 0 8px;
	}
	.quickscan .quickscanContent .loader-circle .numberWrap {
		width: 286px;
		height: 286px;
	}
	.quickscan .quickscanContent .loader-circle .number {
		width: 100%;
		height: 100%;
	}
	.quickscan .quickscanContent .loader-circle .number span {
		margin-left: -15px;
		margin-top: 8px;
	}
	.pie-start {
		width: 266px;
		height: 266px;
		position: absolute;
		top: 9px;
		left: 7px;
	}
	.pie-end {
		width: 266px;
		height: 266px;
		position: absolute;
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		top: 9px;
		left: 7px;
	}
	.pie-in {
		position: absolute;
		left: 50%;
		margin-left: -11px;
		width: 23px;
		height: 23px;
		border-radius: 50%;
		background: #17c1bc;
	}
	.quickscan .quickscanContent .loader-circle .timer>.slice>.pie {
		clip: rect(0, 133px, 266px, 0);
		border: 23px solid #17c1bc;
		width: 220px;
		height: 220px;
	}
	.quickscan .quickscanContent .loader-circle .timer>.slice {
		width: 266px;
		height: 266px;
		top: 9px;
		left: 7px;
	}
	.loader-circle .timer {
		position: relative;
		font-size: 158px;
		width: 158px;
		height: 158px;
		float: left;
		z-index: 2
	}
	.loader-circle .timer>.slice {
		position: absolute;
		width: 158px;
		height: 158px;
		clip: rect(0, 266px, 266px, 133px);
	}
	.loader-circle .timer>.slice.gt50 {
		clip: rect(auto, auto, auto, auto)
	}
	.loader-circle .timer>.slice>.pie {
		border-width: 5px;
		position: absolute;
		width: 148px;
		height: 148px;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%
	}
	.loader-circle .timer.fill>.percent {
		display: none
	}
	.loader-circle .timer.fill>.slice>.pie {
		border: transparent;
		background-color: #3cc0c1;
		width: 158px;
		height: 158px
	}
	.loader-circle {
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: auto;
		position: absolute;
		width: 158px;
		height: 158px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border-width: 2px;
		border-radius: 50%;
		display: block;
	}
	.loader-circle .inner {
		position: relative;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 148px;
		height: 148px;
		line-height: 148px;
		border-radius: 50%;
		border: 5px solid rgba(255, 255, 255, 0.3);
		z-index: 0
	}
	.loader-circle .inner .text {
		position: absolute;
		font-family: 'HarmoniaSansStd-Light', sans-serif;
		color: #fff;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 20px;
		letter-spacing: 1px;
		font-weight: 700
	}
	.quickscan .quickscanContent .loader-circle .sliderNumber {
		text-align: center;
		width: 35px !important;
		line-height: 35px;
		height: 35px !important;
		cursor: pointer;
		font-family: $mainFont;
		font-size: 15px;
		position: absolute;
		z-index: 9;
		color: #afb1b3;
	}
	.quickscan .quickscanContent .loader-circle {
		width: 286px;
		height: 286px;
	}
	.werkvormWrap.fillInTheBlank {
		width: 100%;
	}
	.wvBlanksWrap {
		width: 590px;
		margin: 32px auto;
	}
	.wvBlanksWrap .title {
		font-size: 32px;
		line-height: 36px;
	}
	.wvBlanksWrap .text {
		font-size: 26px;
		line-height: 50px;
		margin: 18px auto;
		font-family: $mainLightFont;
	}
	.lesblokParent {
		width: 620px
	}
	a.lesblok {
		font-size: 25px
	}
	.lesblokIndicator {
		width: 50px;
		height: 50px;
		line-height: 50px;
		top: 15px
	}
	.lesblokTitle {
		padding: 25px 75px 18px 75px
	}
	.slideMenu .menuContainer {
		max-width: 337px;
	}
	.noProgress .lesblokIndicator {
		top: 25px
	}
	.noProgress .lesblokTitle {
		padding: 35px 75px 35px 75px
	}
	.luvsDisplay {
		width: 130px;
		height: 130px;
		bottom: 45px;
	}
	.luvsDisplay .icon.heart {
		width: 54px;
		height: 48px;
	}
	.luvsDisplay .number {
		font-size: 20px;
		top: 70px;
	}
	.mobileOnly {
		display: none !important;
	}
	.clear {
		clear: both;
	}
	.modelDetails {
		padding: 30px 60px;
	}
	.modelDetails .column {
		width: 50%;
		float: left;
	}
	.modelDetails .profilePicture {
		width: 130px;
		height: 130px;
	}
	.modelDetails .profileText {
		margin-top: 50px;
	}
	.modelDetails .editProfile {
		width: 280px;
	}
	.modelImageContainer {
		width: 768px;
		margin: 0 auto;
	}
	.modelImageContainer .nav {
		width: 28px;
		height: 72px;
	}
	.profile .column {
		width: 280px;
		float: left;
	}
	.profile .column:nth-child(odd) {
		margin-right: 30px;
	}
	.profile .profileDetails {
		max-width: 630px;
		margin: 0 auto;
	}
	.profile .profileDetails .avatarWrap {
		width: 130px;
	}
	.profile .profileDetails .avatar {
		width: 130px;
		height: 130px;
		margin-bottom: 25px;
		margin-top: 5px;
	}
	.content.profile .title {
		margin: 60px auto;
		max-width: 600px;
		line-height: 75px;
		margin-bottom: 50px;
	}
	.profile .noMobile {
		display: block;
	}
	.profile .mobileOnly {
		display: hidden;
	}
	.cropImageWrap {
		width: 400px;
		height: 400px;
		position: relative;
		margin: 60px auto;
	}
	.profile .cropImageWrap .avatar {
		width: 400px;
		height: 400px;
	}
	.cropImageWrap .closeIcon {
		top: 35px;
		right: 55px;
	}
	.content.addModel .profileDetails {
		max-width: 510px;
	}
	.pictureCollection .picturesWrap {
		margin-left: 190px;
	}
	.pictureCollection .picture {
		margin-left: -190px;
		width: 200px;
		height: 200px;
	}
	.socialButtons .button {
		margin: 0 15px;
	}
	.smallHeader {
		margin: 15px 0;
		font-size: 16px;
	}
	.bgImage {
		background-size: cover;
	}
	.toetsContent.cover .intro {
		margin-top: 120px;
	}
	.toetsContent .intro h1 {
		font-size: 350px;
		height: 375px;
	}
	.toetsContent .intro h2 {
		font-size: 70px;
		line-height: 65px;
		width: 730px;
		left: 50%;
		position: relative;
		transform: translateX(-50%);
	}
	.toetsContent .intro h3 {
		font-size: 30px;
	}
	.toetsContent .intro p {
		max-width: 470px;
		margin: 0 auto;
	}
	.toetsContent .werkvormWrap {
		margin-top: 14px;
	}
	.toetsContent .werkvormWrap.large {
		width: 620px;
	}
	.wvToetsTitle {
		font-size: 30px;
	}
	.filmlesIntro {
		margin: 90px auto 45px;
	}
	.doubleText .half {
		font-size: 16px;
		width: calc(50% - 20px);
	}
	.totalWrap {
		padding: 20px;
		margin-top: 15px;
	}
	.totalWrap .percent {
		font-size: 74px;
		display: block;
		line-height: 65px;
		margin-top: 10px;
	}
	.totalWrap .totalPercent {
		width: 190px;
		height: 190px;
		margin: 30px auto;
	}
	.totalWrap .text {
		font-size: 25px;
	}
	.totalWrap .totalPercentText {
		font-size: 25px;
	}
	.trainingBlocks {
		width: 920px;
		margin: 32px auto 60px;
		position: relative;
	}
	.trainingBlocks .block .imgWrap {
		height: 123px;
		width: 100%;
	}
	.trainingBlocks .block .imgWrap img {
		width: 100%;
		height: 100%;
	}
	.trainingBlocks .block {
		cursor: pointer;
		display: inline-block;
		width: 220px;
		border: 1px solid #373737;
		margin-right: 8px;
		margin-top: 10px;
		position: relative;
	}
	.trainingBlocks .block .blockContent {
		background-color: #fff;
		width: 100%;
	}
	.trainingBlocks .block .blockContent .title {
		font-size: 20px;
		color: #00848d;
		font-family: '$mainLightFont_italic';
		margin-top: 0;
		border-top: 1px solid #00848d;
		line-height: 58px;
		height: 48px;
	}
	.trainingBlocks .block .blockContent .trainingTitle {
		font-size: 20px;
		color: #00848d;
		font-family: '$mainLightFont_italic';
		margin-top: 0;
		border-top: 1px solid #00848d;
		line-height: 58px;
		height: 48px;
		border-bottom: 1px solid #00848d;
	}
	.trainingBlocks .block .blockContent .subTitle {
		font-size: 17px;
		font-family: $mainLightFont;
		color: #3cc0c1;
		text-align: center;
		padding-bottom: 30px;
		max-width: 180px;
		margin: auto;
	}
	.trainingBlocks .block:hover .blockContent {
		background-color: #3cc0c1;
	}
	.trainingBlocks .block:hover .blockContent .subTitle {
		color: #fff;
	}
	.trainingBlocks .block .trainingStatus {
		background-repeat: no-repeat;
		color: #fff;
		font-family: $mainBoldFont;
		letter-spacing: 2px;
		text-align: center;
		line-height: 57px;
		text-transform: uppercase;
		font-size: 12px;
		position: absolute;
		width: 57px;
		height: 57px;
		border-radius: 50px;
		background-color: #3cc0c1;
		top: 33px;
		left: 50%;
		margin-left: -28px;
	}
	.trainingBlocks .block .trainingStatus.agenda {
		background-image: url('img/agenda.png');
		background-size: 30px;
		background-position: 14px;
	}
	.trainingBlocks .block .trainingStatus.plus {
		background-image: url('img/plus.png');
		background-size: 25px 25px;
		background-position: 16px;
	}
	.trainingBlocks .block .trainingStatus.completed {
		background-image: url('img/completed.png');
		background-size: 25px 20px;
		background-position: 17px;
	}
	.trainingBlocks .block .trainingStatus.locked {
		background-image: url('img/locked.png');
		background-size: 26px 32px;
		background-position: 15px;
	}
	.trainingBlocks .block .trainingStatus.progress {
		background-color: transparent;
	}
	.trainingBlocks .block .imgWrap .progressLoader {
		width: 57px;
		height: 57px;
		position: relative;
		display: block
	}
	.trainingBlocks .block .imgWrap .progressLoader {
		box-shadow: none;
		border: 0
	}
	.trainingBlocks .block .imgWrap .progressLoader .inner {
		border: 3px solid rgba(255, 255, 255, 0.3)
	}
	.trainingBlocks .block .imgWrap .progressLoader .inner .icon {
		background-size: contain;
		width: 25px;
		max-height: 25px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto
	}
	.trainingBlocks .block .imgWrap .progressLoader .timer,
	.content.subject .subTitleContent .progressLoader .timer .slice {
		width: 60px;
		height: 60px
	}
	.trainingBlocks .block .imgWrap .progressLoader .timer>.slice {
		width: 57px;
		height: 57px;
		clip: rect(0, 69px, 78px, 0px);
	}
	.trainingBlocks .block .imgWrap .progressLoader .timer>.slice.gt50 {
		clip: rect(auto, auto, auto, auto)
	}
	.trainingBlocks .block .imgWrap .progressLoader .timer>.slice>.pie {
		border: 3px solid #008a8d;
		width: 57px;
		height: 57px;
		clip: rect(0, 70px, 69px, 0px);
		border-radius: 50%
	}
	.trainingBlocks .block .imgWrap .progressLoader .inner {
		width: 54px;
		height: 54px;
	}
	.trainingBlocks .block .imgWrap .loader-circle .timer>.slice>.pie {
		width: 54px;
		height: 54px;
	}
	.trainingBlocks {
		width: 690px;
	}
	.header-quickscan .wrap {
		width: 380px;
		margin: 43px auto;
		text-align: center;
	}
	.header-quickscan .wrap .headerTitle {
		color: #00848d;
		font-size: 20px;
		font-family: $mainmediumFont
	}
	.header-quickscan .wrap .headerSubTitle {
		font-size: 18px;
		font-family: $mainLightFont;
	}
	.header-quickscan {
		padding: 15px 15px 0 15px;
	}
	.closeCross {
		background-image: url('img/closeCross.png');
		width: 14px;
		height: 14px;
		float: right;
		background-size: 14px;
	}
	.header-quickscan .loper {
		width: 318px;
		height: 25px;
		line-height: 25px;
	}
	.header-quickscan .loper .step {
		width: 40px;
	}
	.header-quickscan .loper .progress {
		position: absolute;
		text-align: right;
		z-index: 9;
		background-color: #00888b;
		color: #fff;
		padding-right: 15px;
	}
	.quickscanContent .title {
		font-size: 36px;
		text-align: left;
		width: 475px;
		margin: auto;
	}
	.quickscanContent .subtitle {
		width: 475px;
		margin: 20px auto;
		font-size: 20px;
	}
	.quickscanContent {
		margin: auto;
		width: 700px;
		text-align: center;
	}
	.quickscanContent .centerTekst {
		text-align: center;
	}
	.stapIntro .quickscanContent .title {
		text-align: center;
		margin: auto;
	}
	.stapIntro .quickscanContent {
		width: 475px
	}
	.stapRadio .quickscanContent {
		width: 475px
	}
	.stap6 .quickscanContent {
		width: 475px
	}
	.quickscanContent .text {
		text-align: left;
		margin: 16px auto;
		font-size: 20px;
	}
	.quickscanContent .startButton {
		font-family: $mainFont;
		background-color: #3cc0c1;
		text-align: center;
		margin: 80px auto 25px;
		color: #fff;
		font-size: 18px;
		width: 250px;
		height: 55px;
		line-height: 55px;
		cursor: pointer;
	}
	.stapSearch .header-quickscan .wrap .headerTitle {
		font-size: 26px;
	}
	.stapSearch .header-quickscan .wrap .headerSubTitle {
		font-size: 20px;
	}
	.stapSearch .quickscanContent {
		width: 442px;
	}
	.stapSearch .title {
		width: 437px;
	}
	.stapSearch .searchBlock {
		width: 440px;
		min-height: 68px;
	}
	.stapSearch .searchBlock .text {
		width: 437px;
	}
	.stapSearch .searchBlock input {
		width: 380px;
		line-height: 22px;
		font-family: $mainLightFont;
		font-size: 17px;
		color: #000;
		padding: 21px;
	}
	.stapSearch .searchBlock input::-webkit-input-placeholder,
	.stapSearch .searchBlock input:-moz-placeholder,
	.stapSearch .searchBlock input:-moz-placeholder,
	.stapSearch .searchBlock input::-moz-placeholder,
	.stapSearch .searchBlock input:-moz-placeholder,
	.stapSearch .searchBlock input,
	.stapSearch .searchBlock input:-ms-input-placeholder {
		color: #939393;
		line-height: 65px;
	}
	.stapSearch .searchBlock .search {
		width: 58px;
		height: 66px;
	}
	.quickscanContent .navButtons {
		width: 282px;
		margin: auto;
		left: 50%;
		margin-left: -140px;
	}
	.sliderNumber.zero {
		top: 6px;
		left: 45%;
	}
	.sliderNumber.one {
		top: 7%;
		left: 63%;
	}
	.sliderNumber.two {
		top: 22%;
		left: 79%;
	}
	.sliderNumber.three {
		top: 44%;
		left: 86%;
	}
	.sliderNumber.four {
		top: 65%;
		left: 80%;
	}
	.sliderNumber.five {
		top: 81%;
		left: 65%;
	}
	.sliderNumber.six {
		top: 87%;
		left: 44%;
	}
	.sliderNumber.seven {
		top: 81%;
		left: 22%;
	}
	.sliderNumber.eight {
		top: 65%;
		left: 6%
	}
	.sliderNumber.nine {
		top: 44%;
		left: 1%;
	}
	.sliderNumber.ten {
		top: 22%;
		left: 8%;
	}
	.sliderNumber.fifteen {
		top: 7%;
		left: 23%
	}
	.trainingStatus {
		top: 30px !important;
	}
	.headerSpacer.startHeader {
		height: 50px !important;
	}
	.buttonGroup .editTraining {
		text-align: right;
		margin-top: -20px;
	}
	.quickscan .quickscanContent .sliderNumber.zero:before {
		height: 23px;
		top: 7px;
	}
	.quickscan .quickscanContent .sliderNumber.selected:before {
		height: 24px;
		left: 4px;
		top: 6px;
	}
	.quickscan .quickscanContent .sliderNumber.selected.zero:before {
		top: 5px;
		left: 5px;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
	}
	.quickscan .quickscanContent .sliderNumber.one.selected:before {
		transform: rotate(25deg);
		top: 6px;
	}
	.quickscan .quickscanContent .sliderNumber.two.selected:before {
		transform: rotate(60deg);
		top: 3px;
		left: 2px;
	}
	.quickscan .quickscanContent .sliderNumber.three.selected:before {
		transform: rotate(85deg);
		left: 4px;
	}
	.quickscan .quickscanContent .sliderNumber.four.selected:before {
		transform: rotate(123deg);
		left: 4px;
	}
	.quickscan .quickscanContent .sliderNumber.six.selected:before {
		transform: rotate(178deg);
		top: 5px;
	}
	.quickscan .quickscanContent .sliderNumber.seven.selected:before {
		transform: rotate(206deg);
		top: 6px;
	}
	.quickscan .quickscanContent .sliderNumber.eight.selected:before {
		transform: rotate(-123deg);
		left: 4px;
	}
	.quickscan .quickscanContent .sliderNumber.nine.selected:before {
		transform: rotate(-93deg);
		left: 4px;
	}
	.quickscan .quickscanContent .sliderNumber.ten.selected:before {
		transform: rotate(-60deg);
		left: 6px;
		top: -1px;
		width: 30px;
	}
	.quickscan .quickscanContent .sliderNumber.fifteen.selected:before {
		transform: rotate(-35deg);
		width: 37px;
		top: 6px;
		left: 0;
	}
	.profile .profileSection {
		display: inline-block;
		padding: 11px;
		width: 325px;
	}
	.profile .profileSection.right {
		width: 325px;
	}
	.profile .navButtons {
		width: 280px;
	}
	.content.profile {
		padding: 20px;
		text-align: center;
		width: 990px;
		margin: auto;
	}
	.content.profile.editProfilePage {
		padding: 20px 0;
		.profileDetails {
			padding: 0;
		}
	}
	.profile .profileBlock {
		text-align: right;
		display: inline-block;
	}
	.profile .profileSection.right .profileBlock {
		width: 300px;
		text-align: right;
		display: inline-block
	}
	.profile .profileSection.right .profileBlockTitle {
		width: 300px;
	}
	.profile .profileBlockTitle {
		text-align: left;
	}
	.blockTitle {
		margin: 20px 0 20px 5px;
	}
	.navButton.small:nth-child(2) {
		margin-left: 8px;
	}
	.navButton.small:nth-child(3) {
		margin-left: 8px;
	}
	.profile .activities .activity {
		text-align: left;
	}
	.profile .activities .activityGroup {
		background-color: #f8f8f8;
	}
	.profile .activities .activity:after {
		clear: both;
		content: " ";
		display: none;
	}
	.profile .fullblock {
		text-align: left;
	}
	.profile .diagram {
		width: 297;
		height: 132px;
		background-size: 297px 132px;
	}
	.quickscanContent .text {
		line-height: 30px;
	}
	.wvBlanksWrap .title {
		letter-spacing: 0.72px;
		line-height: 40px;
	}
	.wvBlanksWrap .text {
		letter-spacing: 0.72px;
	}
	.wvStellingIntro .intro {
		letter-spacing: 0.72px;
		font-size: 26px;
		width: 495px;
	}
	.content .intro {
		max-width: 400px;
	}
	.stepHeader .information {
		float: right;
		margin-top: -150px;
		margin-right: 10px;
	}
	.stepHeader .introText h1 {
		margin: 45px 0 5px;
	}
	.stepnav {
		top: -10px;
	}
	.content table.inputs tr td {
		width: 280px;
	}
	.content .submitBtn {
		max-width: 280px;
	}
	.content .submitBtn.edit {
		max-width: 310px;
		width: 310px;
	}
	.informationMessage {
		max-width: 282px;
	}
	.informationMessage:after {
		right: 132px;
	}
	.styled-outer {
		text-align: left;
	}
	.webshop .content table.inputs tr td {
		width: 280px;
	}
	.webshop-product-overlay .inner{
		padding: 65px 0;
	}
	.webshop-overlay .inner {
		padding: 0;
	}
	.webshop-product-overlay .inner .top {
		margin: 0 0 10px 0;
		min-height: 250px;
	}
	.webshop-product-overlay .inner .top .info {
		width: 430px;
	}
	.webshop-product-overlay .inner .wvProductImage {
		width: 46%;
		height: 235px;
		max-width: none;
	}
	.webshop-product-overlay .inner .overlay-price {
		width: auto;
		margin-top: 28px;
		font-size: 32px;
		margin-left: 52%;
	}
	.webshop-product-overlay .inner .all-devices {
		text-align: left;
	}
	.scroller-container {
		width: 46%;
		height: 235px;
	}
	.webshop-product-overlay .image-scroller .image-content{
		height: 235px;
	}
	.webshop-product-overlay .image-scroller {
		height: 275px;
	}
	.webshop-product-overlay .image-scroller .scroller-text{
		font-size: 18px;
	}
	.webshop-product-overlay .scroller-container .btn{
		top: 100px;
	}
	.intro.article {
		max-width: 593px;
		width: 593px;
	}
	.intro.article.smaller {
		max-width: 447px;
		width: 447px;
	}
	.article-date,
	.article-time {
		font-size: 18px;
		line-height: 18px;
	}
	.wvBaseContainer .article.intro .introTitle {
		font-size: 32px;
		line-height: 38px;
	}
	.wvBaseContainer .article-comments {
		font-size: 30px;
	}
	.blocks-list-item {
		padding: 0;
	}
	.wvBaseContainer.community .blocks-list-item-image {
		width: 80px;
		height: 80px;
		margin-bottom: 20px;
		position: relative;
	}
	.blocks-list-item-image .image-name {
		position: absolute;
		bottom: -22px;
		font-size: 14px;
		color: #fff;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}
	.blocks-list-item-image {
		display: inline-block;
		width: 91px;
		height: 91px;
	}
	.blocks-list-item-image img {
		width: 100%;
	}
	.blocks-list-item-inner.has-image .blocks-list-item-wrap {
		width: calc(100% - 95px);
	}
	.blocks-list-item-wrap {
		display: inline-block;
		height: 91px;
		position: relative;
		padding: 0px 20px;
		width: 100%;
	}
	.blocks-list-item-meta {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0px 20px;
	}
	.wvBaseContainer .article .bodyText {
		font-size: 16px;
		line-height: 20px;
	}
	.blue-button.cta-button {
		padding: 15px 110px;
	}
	.comment-block .comment-body {
		font-size: 18px !important;
		line-height: 24px;
	}
	.comment-user .comment-user-text {
		font-size: 20px;
		padding: 0;
	}
	.cta-icon-button {
		width: 280px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.blocks-list-item-icon {
		background: none center no-repeat $themeColor;
	}
	.icon-video-block {
		width: 91px;
		height: 91px;
		display: inline-block;
		background-image: url('img/icons/icon-video-play.png');
	}
	.icon-pdf-block {
		width: 91px;
		height: 91px;
		display: inline-block;
		background-image: url('img/icons/icon-file-pdf.png');
	}
	.tools .article .videoContainer {
		height: 255px;
	}
	.download-overlay-inner {
		height: 140px;
	}
	.download-text {
		font-size: 40px;
	}
	.download-overlay .download-icon {
		width: 84px;
		height: 84px;
		margin-top: 10px;
	}
	.mainPortal .headImage {
		height: 770px;
		background-size: cover;
		background-position: center top;
	}
	.mainPortal.base .userAction {
		margin-top: 165px;
	}
	.mainPortal .userAction {
		bottom: 0;
		right: 40px;
		width: 320px;
		top: 0;
		margin: auto;
		height: auto;
	}
	.mobileOnly {
		display: none;
	}
	.gtMobile {
		display: block;
	}
	.mainPortal .headImage .tagline.large {
		color: #fff;
		font-size: 45px;
		line-height: 55px;
		text-align: center;
		font-family: $mainLightFont;
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 50px 0px;
		padding-right: 350px;
		z-index: 1;
	}
	.mainPortal .headImage .tagline.large .inner {
		padding-left: 10px;
		padding-right: 10px;
	}
	.mainPortal .userAction .topAction {
		background: #fff;
		padding: 15px;
	}
	.mainPortal .userAction .topAction .tagline {
		display: none;
	}
	.mainPortal .userAction .topAction .logo.mygcp-logo {
		background-image: url('img/mygcp-logo-dark.png');
		width: 148px;
		height: 142px;
		margin: 0 auto;
	}
	.mainPortal .container strong,
	.mainPortal .container b {
		font-size: 28px;
		line-height: 32px;
	}
	.mainPortal .container {
		width: 585px;
		padding: 0;
		font-size: 20px;
		line-height: 30px;
	}
	.mainPortal .container p {
		margin: 25px 0px;
	}
	.mainPortal .container .logos .logo {
		height: 75px;
	}
	.mainPortal .container h1 {
		font-size: 100px;
		line-height: 110px;
		letter-spacing: 0.025em;
	}
	.mainPortal .container p {
		margin: 50px 0 25px;
	}
	.mainPortal .container .homepage-header {
		font-size: 50px;
		line-height: 56px;
	}
	.mainPortal .footer .container {
		width: auto;
	}
	.mainPortal .footer {
		bottom: 0;
		z-index: 9;
		margin-top: 50px;
		background: #fff;
		padding: 20px 0;
		margin-bottom: 0px;
	}
	.mainPortal .footer .tekst {
		display: inline-block;
		max-width: 360px;
	}
	.mainPortal .footer .image {
		float: right;
		display: inline-block;
		margin: -15px 0 0 0;
	}
	.mainPortal .container .secondImg {
		max-width: 75%;
		margin: 50px auto 0;
	}
	.mainPortal .container .infoText {
		width: 475px;
		font-size: 19px;
		line-height: 30px;
	}
	.mainPortal .footer .footer-links {
		display: inline-block;
	}
	.mainPortal .footer .footer-links a {
		font-size: 18px;
		line-height: 32px;
		margin: 0px 30px;
	}
	.mainPortal .footer .footer-logo-wrap {
		margin-left: 30px;
		display: inline-block;
	}
	.mainPortal .action .container.main {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.action .content.login .forgotPassLink {
		width: 282px;
		padding: 0;
	}
	.mainPortal .footerPadding {
		padding-bottom: 48px;
	}
	.mainPortal .action {
		width: 100%;
		height: 100%;
		display: table;
		position: absolute;
	}
	.mainPortal .action .container.main p {
		margin: 15px 0px;
	}
	.mainPortal .action .container.main strong,
	.mainPortal .action .container b {
		font-family: $mainmediumFont;
		font-size: 28px;
		line-height: 34px;
	}
	.mainPortal .action .container-table {
		display: table;
		height: 100%;
	}
	.mainPortal .action .bodyObject {
		margin-top: 130px;
		font-size: 18px;
		line-height: 28px;
		width: 381px;
		display: inline-block;
		vertical-align: middle;
		padding: 15px 56px;
	}
	.mainPortal .action .userAction {
		width: 351px;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
	}
	.mainPortal.register .action .userAction {
		right: 0;
	}
	.mainPortal.register .action .registered .userAction {
		right: 40px;
	}
	.mainPortal .action .headImage {
		height: 100%;
		position: relative;
		width: 351px;
		right: 46px;
		display: table;
		left: calc(100% - 380px);
	}
	.mainPortal .action .footer {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 100;
	}
	.mainPortal .action .checklist .item {
		font-size: 20px;
		line-height: 24px;
	}
	.globalFooter .footer .footer-links a {
		margin: 0 10px;
	}
	.globalFooter .footer .footer-logo-wrap {
		margin-left: 10px;
	}
	.content .shopBox.centerText {
		padding: 30px;
	}
	.content .shopBox.centerText .innerBlock .sub-text {
		padding: 0 50px;
	}
	.social-links {
		width: 150px;
		position: absolute;
		bottom: 15px;
		right: 40px;
		margin: 0;
	}
	.metalink {
		padding-bottom: 70px;
	}
	.mainPortal.register .registered .userAction {
		right: auto;
		bottom: auto;
		left: 50%;
		top: 40%;
		width: 100%;
		max-width: 351px;
		margin-top: 0;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		position: absolute;
	}
	.mainPortal.register .action .headImage {
		left: 50%;
		top: 0;
		right: auto;
		width: 50%;
		overflow: auto;
	}
	.mainPortal.register .userAction .bodyAction {
		padding: 15px 20px;
	}
	.mainPortal.login .action .bodyObject {
		width: 381px;
		margin-top: 155px;
	}
	.mainPortal.login .action .userAction {
		margin-top: 250px;
		right: 0;
	}
	.mainPortal .action .userAction.forgotPassword {
		right: 0;
	}
	.mainPortal .action .content .intro {
		width: 265px;
	}
	.ajax-search {
		max-width: 432px;
		width: 100%;
		margin: 20px auto;
	}
	.webshop .ajax-search {
		max-width: 380px;
	}
	.webshop .ajax-search .inner-filter {
		width: 280px;
	}
	.formInputs.block.address .rowCol:nth-child(2) {
		margin-top: 64px;
	}
	.formInputs.block.gegevensOpFactuur .rowCol:nth-child(2) {
		margin-top: 64px;
	}
	.formInputs .rowCol {
		margin: 0 30px;
		width: 280px;
		text-align: left;
	}
	.content .wvProductGroup {
		padding: 35px 0px;
	}
	.content .wvProductBox {
		max-width: 1250px;
	}
	.content .wvProductBox .wvProduct {
		width: 340px;
	}
	.webshop-product-overlay .inner .overlay-title{
		font-size: 42px;
		margin-left: 52%;
		margin-top: 0;
	}
	.overlayColoredLine{
		background: $themeColor;
		width: 200px;
		height: 10px;
		margin-left: 52%;
	}
	.webshop-product-overlay .inner .overlay-vat{
		margin-top: 12px;
		font-size: 18px;
	}

	.webshop-product-overlay .wvChooseProductWrap {
		left: auto;
		right: 20px;
		margin-top: -52px;
	}
	.webshop-product-overlay .inner .overlay-ChooseProduct{
		padding: 0 25px;
		font-size: 22px;
		line-height: 40px;
	}
	.webshop-product-overlay .inner .overlay-txt {
		margin-top: 55px;
	}
	.webshop-product-overlay .inner .webshop-devices-icon {
		width: 71px;
		height: 53px;
	}
	.webshop-product-overlay .inner .all-devices .devices-text {
		font-size: 18px;
		margin: 15px 0 0 20px;
	}
}

ol,
ul {
	padding: 0 1em;
}
