$themeColor: #f6ec1d;
$darkColor: #282828;
$midColor: #424147;
$lightColor: #637173;
$lightestColor: #627072;
$lightestColor2: #EFEFEF;
$buttonTextColor: $midColor;


$mainFont: 'argumentumregular';
$mainBoldFont: 'argumentumbold';
$semiBoldFont: 'HarmoniaSansStd-SemiBd';
$mainmediumFont: 'argumentummedium';
$mainLightFont: 'argumentumlight';
$mainExtraLightFont: 'argumentumultra_light';
$mainLightFont_italic: 'argumentumlight_italic';
