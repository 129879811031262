@media screen and (min-width: 1024px) {
	.blocks-list.ajax-search-result {
		max-width: 900px;
	}
	a.blocks-list-item {
		width: 432px;
		display: inline-block;
	}
	.blocks-list-item:nth-child(2n+2) {
		margin-left: 33px;
	}
	.blocks-list-item .blocks-list-item-inner {
		border-top: 1px solid #a7a7a7;
	}
	.blocks-list-item:nth-child(1n+3) .blocks-list-item-inner {
		border-top: 0;
	}
	.backgroundImage {
		height: auto;
		bottom: 0;
		right: 900px
	}
	.magazineContent {
		width: 900px;
		margin-top: 0;
		margin-left: auto;
		-webkit-box-shadow: -45px 0 38px -25px rgba(0, 0, 0, 0.2);
		box-shadow: -45px 0 38px -25px rgba(0, 0, 0, 0.2)
	}
	.backgroundImage.magazineCover {
		right: 0;
	}
	.magazineContent.magazineCover {
		margin-left: initial;
	}
	.wvProductBlocks .productBlock.firstBlock .innerBlock,
	.wvProductBlocks .productBlock .innerBlock {
		max-width: 1024px;
	}
	.wvProductBlocks .productBlock.firstBlock .col {
		width: 50%;
	}
	.wvProductBlocks .productBlock.firstBlock .col.textCol {
		margin-top: 25px;
	}
	.wvProductBlocks .productBlock.firstBlock .productImg {
		width: 417px;
		height: 417px;
		max-width: initial;
		max-height: initial;
		border-radius: 0px;
	}
	.wvProductBlocks .productBlock .col {
		padding: 33px 7px 33px 20px;
	}
	.wvProductBlocks .bottomFeedback {
		margin-top: 22px;
	}
	.wvProductBlocks .productBlock .productImg img {
		max-height: 100%;
	}
	.wvProductBlocks .productBlock.firstBlock .title {
		font-size: 60px;
		line-height: 60px;
	}
	.wvProductBlocks .productBlock.firstBlock .innerBlock .col .colContent .text p {
		max-width: 365px;
		margin: 0 auto;
		font-family: $mainLightFont;
		font-size: 20px;
	}
	.wvProductBlocks .productBlock .text p {
		max-width: 475px;
	}
	.wvProductBlocks .productBlock:not(.firstBlock) .text p {
		margin: 0;
	}
	.wvProductBlocks .productBlock.left .textCol .colContent {
		float: none;
	}
	.wvBlanksWrap {
		width: 680px;
	}
	.magazineCover .magazine {
		margin-top: 35px;
	}
	body:not(.quickscan) .loader-circle .timer {
		font-size: 158px;
		width: 158px;
		height: 158px;
	}
	body:not(.quickscan) .loader-circle .timer>.slice {
		width: 158px;
		height: 158px;
		clip: rect(0, 158px, 158px, 79px)
	}
	body:not(.quickscan) .loader-circle .timer>.slice>.pie {
		border-width: 5px;
		width: 148px;
		height: 148px;
		clip: rect(0, 80px, 160px, 0);
	}
	body:not(.quickscan) .loader-circle .timer>.slice>.pie.fill {
		-moz-transform: rotate(180deg)!important;
		-webkit-transform: rotate(180deg)!important;
		-o-transform: rotate(180deg)!important;
		transform: rotate(180deg)!important;
	}
	body:not(.quickscan) .loader-circle .timer.fill>.slice>.pie {
		width: 158px;
		height: 158px
	}
	body:not(.quickscan) .loader-circle {
		width: 158px;
		height: 158px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border-width: 2px;
	}
	body:not(.quickscan) .loader-circle .inner {
		width: 148px;
		height: 148px;
		line-height: 148px;
		border: 5px solid rgba(255, 255, 255, .3);
	}
	body:not(.quickscan) .loader-circle .inner .text {
		font-size: 20px;
		letter-spacing: 1px;
		font-weight: 700
	}
	.filmles.magazineContent .sceneList {
		width: 968px
	}
	.filmles.backgroundImage {
		width: 100%!important;
		overflow: hidden;
		top: auto;
		left: auto;
		right: auto
	}
	.filmles.magazineContent .nextLesson h1 {
		font-size: 22px
	}
	.filmles.magazineContent {
		max-width: 100%;
		display: inline-block;
		padding-top: 0;
		width: 100%
	}
	.filmles.magazineContent .magazine {
		margin-top: 20px;
		margin-bottom: 20px;
		color: rgba(160, 130, 80, 1)
	}
	.filmles.backgroundImage .image-text .magazine {
		color: rgba(160, 130, 80, 1)
	}
	.filmles.backgroundImage .image-text .subTitle {
		color: #000;
		max-width: 719px;
		width: 719px
	}
	.filmles.backgroundImage .image-text {
		position: relative;
		left: 0;
		right: 0;
		margin: auto;
		margin-top: -200px
	}
	.filmscene .contentTitle {
		margin-top: 50px;
		margin-bottom: 40px;
	}
	.videoContainer .nextLesson h1 {
		margin: 15px 0;
	}
	.summary.magazineContent {
		width: 900px;
		float: right;
		margin-top: 0;
	}
	.summary .paginate {
		height: 102px;
		margin-bottom: 0px;
	}
	.wvProductBlocks .productBlock:not(.firstBlock) .text p {
		width: 475px;
	}
	.wvInterviewContent {
		width: auto;
		margin-left: -386px;
		top: -210px;
	}
	.wvInterviewContent .contentCol {
		margin-top: -185px;
	}
	.wvInterviewContent .answer {
		font-size: 18px;
	}
	.wvInterviewContent .quoteText,
	.wvInterviewContent .question {
		font-size: 24px;
	}
	.wvInterviewContent .quoteText {
		width: 330px;
	}
	.modelImageContainer {
		margin: 50px auto 0px;
	}
	.modelDetails {
		margin: 0px auto 50px;
	}
	.modelImageContainer .nav.prev {
		left: inherit;
	}
	.modelImageContainer .nav.next {
		right: inherit;
	}
	.modelImageContainer .navWrap {
		width: 20px;
		height: 50px;
		position: relative;
	}
	.modelImageContainer .navWrap.right {
		float: right;
		margin-right: -78px;
	}
	.modelImageContainer .navWrap.left {
		float: left;
		margin-left: -78px;
	}
	.toetsContent .werkvormWrap.large {
		width: 800px;
	}
	.toetsContent.cover .intro {
		margin-top: 90px;
	}
	.toetsContent .intro p {
		max-width: 470px;
		margin: 0 auto;
	}
	.tags {
		text-align: left;
	}
	.wvProductBlocks .productBlock .productImg {
		width: 470px;
		height: 470px;
	}
	.magazineCover .contentWrap .contentText {
		font-size: 20px;
		letter-spacing: 1px;
		line-height: 30px;
	}
	.trainingBlocks {
		width: 933px;
	}
	.buttonGroup {
		max-width: 933px;
	}
	.wvBaseContainer .content table.inputs.followup {
		width: 933px;
	}
	.content .wvProductBox .wvProduct .wvProductTitle .wvProductToggle {
		display: none;
	}
	.content .wvSelectedProduct {
		width: 280px;
		display: inline-block;
		margin: 25px 30px 0 0;
	}
	.content .wvProductSteps {
		width: 600px;
		display: inline-block;
	}
	.content .wvProductSteps .column .wvProductStep {
		width: 280px;
	}
	.content .wvProductSteps .column .wvProductStep .stepImage {
		background-size: 136px 158px;
	}
	.content .wvProductSteps .column .wvProductStep .checkOverlay {
		left: 185px;
	}
	.content .shopBox .block {
		width: calc(50% - 2px);
		border:2px solid #fff;
	}
	.content .shopBox .block:last-of-type {
		margin-left: -4px;
	}
	.content .orderBox {}
	.content .shopBox .innerBlock.blockRight {
		padding: 25px;
		max-width: none;
	}
	.content .orderBox .orderTable .tableBackground {
		width: 45%;
	}
	.content .orderBox .orderTable table th {
		width: 30%;
		padding: 10px 0 5px 15px;
	}
	.content .orderBox .orderTable table td {
		padding: 5px 0 5px 15px
	}
	.content .orderBox .orderTable table td.indentOptionSmall {
		text-indent: 20px;
	}
	.content .orderBox .orderOptions label.styled {
		width: 35%;
	}
	.page-block {
		width: 447px;
		margin: 0 auto;
	}
	.mainPortal .headImage .tagline.large b,
	.mainPortal .headImage .tagline.large strong {
		display: block;
	}
	.mainPortal .headImage .tagline.large .inner {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-right: 10px;
	}
	.mainPortal .headImage .tagline.large {
		width: 395px;
		font-size: 70px;
		line-height: 85px;
		text-align: left;
		display: table;
		height: 100%;
		margin: 0px 120px;
		padding-right: 0px;
	}
	.mainPortal .action .userAction {
		margin-top: 85px;
	}
	.mainPortal .action .bodyObject {
		width: 550px;
		margin-top: 130px;
	}
	.mainPortal .action .container.main p {
		margin: 30px 0px;
	}
	.mainPortal.login .action .bodyObject {
		width: 550px;
		margin-top: 0;
	}
	.mainPortal.login .action .userAction {
		margin-top: 85px;
	}
	.mainPortal.register .action .userAction {
		margin-top: 0;
	}
	.mainPortal.register .action .registered .userAction {
		margin-top: 0;
	}
	.mainPortal.register .userAction {
		width: 351px;
	}
	.document-overlay .inner {
		max-width: 1024px;
		width: 90%;
		height: 100%;
	}
	.document-overlay .inner .overlay-frame-document {
		display: block;
		width: 100%;
		height: calc(100% - 70px);
	}
	.webshop-product-overlay .inner {
		padding: 54px 0;
	}
	.webshop-product-overlay .inner .wvProductImage {
		height: 355px;
	}
	.webshop-product-overlay .inner .top {
		min-height: 190px;
	}
	.webshop-product-overlay .inner .overlay-txt {
		margin-top: 20px;
		margin-left: 52%;
	}
	.webshop-product-overlay .information {
		margin-top: -60px;
		display:block;
	}
	.webshop-product-overlay .inner .all-devices{
		margin-left: 52%;
	}
	.scroller-container{
		height: 355px;
	}
	.webshop-product-overlay .image-scroller{
		height: 395px;
	}
	.webshop-product-overlay .image-scroller .image-content{
		height: 355px;
	}
	.webshop-product-overlay .scroller-container .btn {
		top: 165px;
	}
}

@media screen and (min-width: 1025px) {
	.mainPortal.register .action .userAction {
		top: 10%;
		right: 25%;
	}
	.mainPortal.register .registered .userAction {
		top: 35%;
	}
}
