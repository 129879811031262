.icon.icon-performance-log:before {
	content: "\eb8c";
}

.main-tooltip {
	display: none;
	position: absolute;
	z-index: 10000;
	text-align: left;
	top: 0;
	left: 0;
	font-size: 12px;
	font-size: 0.75rem;
	font-weight: normal;
}

.main-tooltip .arrow {
	position: absolute;
	width: 0;
	height: 0;
	display: block;
	border-color: transparent;
	border-style: solid;
}

.main-tooltip .arrow.top {
	top: 100%;
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000;
}

.main-tooltip .arrow.right {
	top: 50%;
	left: -5px;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000;
}

.main-tooltip .arrow.bottom {
	top: -5px;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000;
}

.main-tooltip .arrow.left {
	top: 50%;
	right: -5px;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000;
}

.main-tooltip .content {
	display: block;
	color: #fff;
	text-align: left;
	background-color: #000;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border-radius: 4px;
	word-break: break-word;
	padding: 5px 10px;
	font-family: "muli", sans-serif;
}

.admin,
.beheer {
	.queryLog,
	.performanceLog {
		.page-block {
			width: auto;
			margin: auto;
		}
		.logBlock table.fixedtop td,
		.logBlock table.fixedtop th {
			min-width: auto;
			max-width: none;
		}
		.logBlock a.jsLogAction {
			display: inline-block;
			margin: 0px 5px 0px 5px;
		}
		.jsTip:hover .main-tooltip {
			display: block;
			top: 100%;
			white-space: nowrap;
		}
		.legend {
			border: 1px solid black;
			display: inline-block;
			padding: 5px;
			margin-bottom: 20px;
			margin-right: 20px;
			.row div {
				display: inline-block;
			}
			.box {
				display: inline-block;
				width: 14px;
				height: 14px;
				margin-right: 10px;
			}
			.colorGood {
				background: #009900;
			}
			.colorNeutral {
				background: #000000;
			}
			.colorNotGood {
				background: #FF8000;
			}
			.colorBad {
				background: #ff0000;
			}
		}
		a.btn.btn-success:hover,
		.btn.btn-success:hover,
		a.btn.btn-success.active,
		.btn.btn-success.active {
			background-color: #5dbb5c;
		}
		a.btn.btn-success,
		.btn.btn-success {
			background-color: #81ca80;
			border-color: #5dbb5c;
		}
		a.btn.btn-inline,
		.btn.btn-inline {
			display: inline-block;
			padding: 6px 12px;
			margin: 0 2px;
			font-family: "Source Sans Pro", sans-serif;
		}
		a,
		a:link,
		a:visited {
			color: #777;
			text-decoration: none;
		}
		.page-block .page-body {
			position: relative;
			padding: 15px;
		}
		.logRows td .icon {
			margin: 0 5px;
		}
		table {
			&.datatbl {
				border-collapse: collapse;
				border: 1px solid #ddd;
				border-top-color: rgb(221, 221, 221);
				border-top-style: solid;
				border-top-width: 1px;
				.bg-warning {
					background-color: #fe8d45 !important;
				}
				.bg-danger {
					background-color: #e96562 !important;
				}
				.bg-success {
					background-color: #81ca80 !important;
				}
				th {
					font-size: 1rem;
					padding: 15px 5px;
					text-align: left;
					vertical-align: bottom;
					font-family: "muli-bold", sans-serif;
					background: #f9f9f9;
					cursor: pointer;
					padding-left: 15px;
					white-space: nowrap;
					border-collapse: collapse;
					border: 1px solid #ddd;
					border-top-color: rgb(221, 221, 221);
					border-top-style: solid;
					border-top-width: 1px;
					div {
						display: inline-block;
						min-width: 10px;
					}
					&.sorted {
						div {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 5px 5px 5px;
							border-color: transparent transparent #007bff transparent;
						}
					}
					&.sortedInverse {
						div {
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 5px 5px 0 5px;
							border-color: #007bff transparent transparent transparent;
						}
					}
				}
				th:first-child,
				td:first-child {
					min-width: 300px;
					max-width: 300px;
					white-space: normal;
					word-break: break-word;
				}
				&.dbTable {
					.nr {
						max-width: 60px;
						min-width: 60px;
					}
					th.query {
						max-width: 600px;
						min-width: 600px;
					}
					td.query {
						max-width: 600px;
						min-width: 600px;
						text-align: left;
					}
					th.path,
					td.path {
						width: 300px !important;
						max-width: 300px !important;
						min-width: 300px !important;
					}
				}
				td {
					font-size: 1rem;
					padding: 15px 5px;
					position: relative;
					border-collapse: collapse;
					border: 1px solid #ddd;
					border-top-color: rgb(221, 221, 221);
					border-top-style: solid;
					border-top-width: 1px;
					.diff {
						position: absolute;
						left: 0;
						bottom: 0;
						font-size: 10px;
						padding: 1px 2px;
						min-width: 30px;
						color: #ffffff;
					}
					.truncQuery {
						display: none;
					}
					.fullQuery {
						font-family: monospace;
						max-height: 2.8em;
						overflow: hidden;
					}
					.fullQuery.show {
						max-height: 45em;
						overflow: auto;
						white-space: pre;
					}
				}
			}
			&.fixedtop {
				thead {
					display: block;
					width: 100%;
				}
				tbody {
					display: block;
					width: 100%;
					height: 470px;
					overflow: auto;
				}
				td,
				th:not(:first-child) {
					min-width: 120px;
					max-width: 120px;
				}
			}
		}
		.performanceLogHeader {
			display: inline-block;
			h1 {
				margin: 0px;
				padding: 0px;
				font-size: 25px;
				font-weight: bold;
			}
			#datepicker {
				width: 142px;
				text-align: center;
				padding: 0;
				margin: 0;
				border: none;
				background: transparent;
				display: inline;
				font-weight: bold;
				font-family: "Lato-Regular", sans-serif;
				cursor: pointer;
				text-decoration: underline;
				font-size: 25px;
			}
			span {
				font-size: 18px;
				vertical-align: baseline;
			}
		}
		.performanceLog {
			.page-block {
				.page-body {
					overflow-x: auto;
				}
			}
		}
		.queryLog {
			.tableDiv {
				margin-top: 15px;
			}
			div.logRow {
				padding: 5px 0;
				line-height: 26px;
			}
			.logRows td {
				padding: 5px;
				line-height: 26px;
				&:first-child {
					padding-left: 0;
				}
				.icon {
					margin: 0 5px;
				}
			}
			.explainTable {
				th,
				td {
					padding: 5px;
				}
				i {
					opacity: 0.7;
				}
			}
			.explainQuery {
				width: 100%;
				height: 10em;
				border: 1px solid rgba(0, 0, 0, 0.5);
				overflow: auto;
				font-family: monospace;
			}
			.page-body .jsGroupReport {
				float: right;
				margin-top: -25px;
			}
		}
		.performanceLogOptions {
			display: inline-block;
			max-width: 340px;
			margin-left: 5px;
			.intro {
				margin-bottom: 10px;
			}
		}
		.queryLogIntro {
			overflow: hidden;
		}
		.logBlock {
			display: inline-block;
			&.archived {
				float: right;
			}
		}
		.table-responsive {
			overflow-x: auto;
		}
	}
}
