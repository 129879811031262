@font-face {
	font-family: 'HarmoniaSansStd-SemiBd';
	src: url('fonts/HarmoniaSansStd-SemiBd.eot?#iefix') format('embedded-opentype'), url('fonts/HarmoniaSansStd-SemiBd.otf') format('opentype'), url('fonts/HarmoniaSansStd-SemiBd.woff') format('woff'), url('fonts/HarmoniaSansStd-SemiBd.woff2') format('woff2'), url('fonts/HarmoniaSansStd-SemiBd.ttf') format('truetype'), url('fonts/HarmoniaSansStd-SemiBd.svg#HarmoniaSansStd-SemiBd') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HarmoniaSansStd-Light';
	src: url('fonts/HarmoniaSansStd-Light.eot?#iefix') format('embedded-opentype'), url('fonts/HarmoniaSansStd-Light.otf') format('opentype'), url('fonts/HarmoniaSansStd-Light.woff') format('woff'), url('fonts/HarmoniaSansStd-Light.ttf') format('truetype'), url('fonts/HarmoniaSansStd-Light.svg#HarmoniaSansStd-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
	@font-face {
		font-family: 'HarmoniaSansStd-SemiBd';
		src: url('fonts/HarmoniaSansStd-SemiBd.eot?#iefix') format('embedded-opentype'), url('fonts/HarmoniaSansStd-SemiBd.woff') format('woff'), url('fonts/HarmoniaSansStd-SemiBd.otf') format('opentype'), url('fonts/HarmoniaSansStd-SemiBd.woff2') format('woff2'), url('fonts/HarmoniaSansStd-SemiBd.ttf') format('truetype'), url('fonts/HarmoniaSansStd-SemiBd.svg#HarmoniaSansStd-SemiBd') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'HarmoniaSansStd-Light';
		src: url('fonts/HarmoniaSansStd-Light.eot?#iefix') format('embedded-opentype'), url('fonts/HarmoniaSansStd-Light.woff') format('woff'), url('fonts/HarmoniaSansStd-Light.ttf') format('truetype'), url('fonts/HarmoniaSansStd-Light.otf') format('opentype'), url('fonts/HarmoniaSansStd-Light.svg#HarmoniaSansStd-Light') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}

$fonts: ( open_sanslight: opensans-light-webfont,
open_sansextrabold_italic: opensans-extrabolditalic-webfont,
open_sanslight_italic: opensans-lightitalic-webfont,
open_sansitalic: opensans-italic-webfont,
open_sansregular: opensans-regular-webfont,
open_sansextrabold: opensans-extrabold-webfont,
open_sansbold_italic: opensans-bolditalic-webfont,
open_sanssemibold: opensans-semibold-webfont,
open_sansbold: opensans-bold-webfont,
open_sanssemibold_italic: opensans-semibolditalic-webfont,
argumentumlight: kostic_-_argumentum-light-webfont,
argumentumlight_italic: kostic_-_argumentum-lightitalic-webfont,
argumentummedium: kostic_-_argumentum-medium-webfont,
argumentumitalic: kostic_-_argumentum-italic-webfont,
argumentumthin: kostic_-_argumentum-thin-webfont,
argumentumthin_italic: kostic_-_argumentum-thinitalic-webfont,
argumentummedium_italic: kostic_-_argumentum-mediumitalic-webfont,
argumentumregular: kostic_-_argumentum-regular-webfont,
argumentumultra_light: kostic_-_argumentum-ultralight-webfont,
argumentumultra: kostic_-_argumentum-ultra-webfont,
argumentumultra_italic: kostic_-_argumentum-ultraitalic-webfont,
argumentumblack_italic: kostic_-_argumentum-blackitalic-webfont,
argumentumbold: kostic_-_argumentum-bold-webfont,
argumentumbold_italic: kostic_-_argumentum-bolditalic-webfont,
argumentumblack: kostic_-_argumentum-black-webfont,
loraitalic: lora-italic-webfont,
loraregular: lora-regular-webfont,
lorabold: lora-bold-webfont,
lorabold_italic: lora-bolditalic-webfont);
@each $font,
$file in $fonts {
	@font-face {
		font-family: $font;
		src: url('fonts/#{$file}.eot');
		src: url('fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
		url('fonts/#{$file}.woff') format('woff'),
		url('fonts/#{$file}.ttf') format('truetype'),
		url('fonts/#{$file}.svg') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}
